import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const links = [
    { text: 'Impressum', url: '/impressum/' },
    { text: 'Datenschutz', url: '/datenschutz/' },
    { text: 'FAQ', url: '/faq' },
    {
      text: 'Contact',
      url: 'https://www.in.tum.de/fuer-studierende/tutorbetrieb-der-fakultaet-fuer-informatik/',
    },
  ];

  return (
    <footer className="mt-5 p-2 text-center footer" style={{ color: '#226BB6', fontWeight: '470' }}>
      Copyright &copy; {currentYear} ASTRA |&nbsp;&nbsp;
      {links.map((link, index) => (
        <React.Fragment key={index}>
          <a style={{ textDecoration: 'none' }} href={link.url}>
            {link.text}
          </a>
          {index < links.length - 1 && ' - '}
        </React.Fragment>
      ))}
    </footer>
  );
};

export default Footer;
