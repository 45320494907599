import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// UI Components
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import PageHeader from 'components/ui/page-header/PageHeader';

// Actions
import { clearSemester, createSemester } from 'features/semester/semesterSlice';

const CreateSemester = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const errors = useSelector((state) => state.semester.errors);

  const [formData, setFormData] = useState({
    name: '',
    from: '',
    to: '',
    coursefrom: '',
    courseto: '',
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(createSemester(formData)).unwrap();
      history.push('/semester-overview');
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    return () => {
      dispatch(clearSemester());
    };
  }, [dispatch]);

  return (
    <div className="createSemester">
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Semester Erstellen`} route={`/semester-overview`} />
            <small className="d-block pb-3">* = benötigte Felder</small>

            <form onSubmit={onSubmit}>
              <TextFieldGroup
                label="* Semester Bezeichnung"
                placeholder="Semester Bezeichnung"
                onChange={onChange}
                value={formData.name}
                name="name"
                error={errors?.name}
                info="* Semester Bezeichnung"
              />
              <TextFieldGroup
                label="* Von"
                type="date"
                placeholder="Von"
                onChange={onChange}
                value={formData.from}
                name="from"
                error={errors?.from}
                info="* Semester Start"
              />
              <TextFieldGroup
                label="* Bis"
                type="date"
                placeholder="Bis"
                onChange={onChange}
                value={formData.to}
                name="to"
                error={errors?.to}
                info="* Semester Ende"
              />
              <TextFieldGroup
                label="* Kurse von"
                type="date"
                placeholder="Kurse von"
                onChange={onChange}
                value={formData.coursefrom}
                name="coursefrom"
                error={errors?.coursefrom}
                info="* Semester Kurse Start"
              />
              <TextFieldGroup
                label="* Kurse bis"
                type="date"
                placeholder="Kurse bis"
                onChange={onChange}
                value={formData.courseto}
                name="courseto"
                error={errors?.courseto}
                info="* Semester Kurse Ende"
              />
              <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
            </form>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSemester;
