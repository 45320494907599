import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getForms, downloadPdf } from '../../actions/formsActions';
import PageHeader from '../ui/page-header/PageHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import LinkButton from '../ui/button/LinkButton';
import { faCheckCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormsAdminOverview = () => {
  const dispatch = useDispatch();
  const formsData = useSelector((state) => state.forms.forms);

  useLayoutEffect(() => {
    dispatch(getForms());
  }, [dispatch]);

  const onDownloadClick = (name) => {
    const formData = {
      name: name,
    };
    dispatch(downloadPdf(formData));
  };

  const formatDate = (value) => {
    if (value && moment(value).isValid()) {
      return moment(value).format('DD/MM/YYYY');
    } else {
      return '-';
    }
  };

  const columns = [
    { dataField: 'name', text: 'Formular' },
    {
      dataField: 'uploaded',
      text: 'Uploaded',
      formatter: (cell, row) =>
        row.uploaded ? (
          <FontAwesomeIcon icon={faCheckCircle} className={`text-primary`} />
        ) : (
          <FontAwesomeIcon icon={faXmarkCircle} className={`text-danger`} />
        ),
    },
    { dataField: 'lastUpdate', text: 'Letztes Update', formatter: formatDate },
    {
      dataField: 'download',
      text: 'Herunterladen',
      formatter: (cell, row) => (
        <button
          type="button"
          onClick={() => onDownloadClick(row.shortName)}
          className="btn btn-info"
          disabled={!row.uploaded}
        >
          Herunterladen
        </button>
      ),
    },
  ];

  const formData = [
    {
      name: 'Einstellungsvorschlag',
      shortName: 'Einstellungsvorschlag',
      fileName: 'Einstellungsvorschlag.pdf',
    },
    {
      name: 'Einstellungsvorschlag (Translation)',
      shortName: 'Einstellungsvorschlag_translated',
      fileName: 'Einstellungsvorschlag_translated.pdf',
    },
    {
      name: 'Feststellung der Versicherungspflicht',
      shortName: 'Versicherungspflicht',
      fileName: 'Versicherungspflicht.pdf',
    },
    {
      name: 'Feststellung der Versicherungspflicht (Translation)',
      shortName: 'Versicherungspflicht_translated',
      fileName: 'Versicherungspflicht_translated.pdf',
    },
    {
      name: 'Fragebogen zu Scientology',
      shortName: 'Scientology',
      fileName: 'Scientology.pdf',
    },
    {
      name: 'Fragebogen zu Scientology (Translation)',
      shortName: 'Scientology_translated',
      fileName: 'Scientology_translated.pdf',
    },
    {
      name: 'Fragebogen zur Verfassungstreue',
      shortName: 'Verfassungstreue',
      fileName: 'Verfassungstreue.pdf',
    },
    {
      name: 'Fragebogen zur Verfassungstreue (Translation)',
      shortName: 'Verfassungstreue_translated',
      fileName: 'Verfassungstreue_translated.pdf',
    },
    {
      name: 'Personalbogen Bezuegestelle',
      shortName: 'PersonalbogenBezuegestelle',
      fileName: 'PersonalbogenBezuegestelle.pdf',
    },
    {
      name: 'Personalbogen Bezuegestelle (Translation)',
      shortName: 'PersonalbogenBezuegestelle_translated',
      fileName: 'PersonalbogenBezuegestelle_translated.pdf',
    },
    {
      name: 'Personalbogen Studierende',
      shortName: 'PersonalbogenStudierende',
      fileName: 'PersonalbogenStudierende.pdf',
    },
    {
      name: 'Personalbogen Studierende (Translation)',
      shortName: 'PersonalbogenStudierende_translated',
      fileName: 'PersonalbogenStudierende_translated.pdf',
    },
    {
      name: 'Stipendiumsbescheinigung',
      shortName: 'Stipendiumsbescheinigung',
      fileName: 'Stipendiumsbescheinigung.pdf',
    },
    {
      name: 'Stipendiumsbescheinigung (Translation)',
      shortName: 'Stipendiumsbescheinigung_translated',
      fileName: 'Stipendiumsbescheinigung_translated.pdf',
    },
  ];

  const data = formData.map((form) => {
    const backendForm = formsData?.find((obj) => obj.name === form.fileName);
    return {
      ...form,
      uploaded: !!backendForm,
      lastUpdate: backendForm?.date,
    };
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <PageHeader heading={'Formulare'} route={'/dashboard'} />
          <LinkButton
            to={{
              pathname: `/form-upload`,
            }}
            text={'Neues Formular'}
            variant={'primary'}
          />
          <div className="pt-2">
            <BootstrapTable
              keyField="name"
              data={data}
              columns={columns}
              striped
              classes="table-responsive-md"
              wrapperClasses="table-responsive"
              noDataIndication="Keine Formulare vorhanden"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsAdminOverview;
