import axios from 'axios';

// Get all courses
export const getAllCourses = async () => {
  try {
    const response = await axios.get('/api/course/all');
    return response;
  } catch (error) {
    console.error(error);
  }
};

// Get login user profile
export const getLoginUserProfile = async () => {
  try {
    const response = await axios.get('/api/profile');
    return response;
  } catch (error) {
    console.error(error);
  }
};

// Check if student number has changed
export const checkStudentNumberChanged = async () => {
  try {
    const response = await axios.get('/api/course/changestudentnumber');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
