import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment';
import { deleteEducation } from '../../actions/profileActions';
import LinkButton from '../ui/button/LinkButton';

const Education = ({ deleteEducation, education }) => {
  const onDeleteClick = (id) => {
    if (window.confirm('Are you sure you want to delete this education?')) {
      deleteEducation(id);
    }
  };

  const columns = [
    {
      dataField: 'school',
      text: 'School',
    },
    {
      dataField: 'degree',
      text: 'Degree',
    },
    {
      dataField: 'fieldofstudy',
      text: 'Field Of Study',
    },
    {
      dataField: 'from',
      text: 'Years',
      formatter: (cell, row) => (
        <span>
          <Moment format="DD/MM/YYYY">{moment.utc(row.from)}</Moment> -{' '}
          {row.to === null ? ' Now' : <Moment format="DD/MM/YYYY">{moment.utc(row.to)}</Moment>}
        </span>
      ),
    },
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'edit',
      text: 'Edit',
      formatter: (cell, row) => (
        <LinkButton
          to={{
            pathname: `/edit-education/${row._id}`,
          }}
          text={'Edit'}
          variant={'info'}
        />
      ),
    },
    {
      dataField: 'delete',
      text: 'Delete',
      formatter: (cell, row) => (
        <button onClick={() => onDeleteClick(row._id)} className="btn btn-danger">
          Delete
        </button>
      ),
    },
  ];

  return (
    <div>
      <h4 className="mb-4">Education Credentials</h4>
      <BootstrapTable
        keyField="_id"
        striped
        data={education}
        columns={columns}
        classes="table-responsive-md"
        wrapperClasses="table-responsive"
      />
    </div>
  );
};

Education.propTypes = {
  deleteEducation: PropTypes.func.isRequired,
  education: PropTypes.array.isRequired,
};

export default connect(null, { deleteEducation })(Education);
