import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAdvisorCourses } from 'actions/courseActions';
import PageHeader from 'components/ui/page-header/PageHeader';
import Table from 'components/ui/table/Table';
import SearchBar from 'components/ui/table/SearchBar';
import LinkButton from 'components/ui/button/LinkButton';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const AdvisorCourseOverview = () => {
  const dispatch = useDispatch();
  const { courses } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getAdvisorCourses());
  }, []);

  const courseArray = courses ? courses : [];

  function contractsButton(cell, row, rowIndex, formatExtraData) {
    return (
      <LinkButton
        to={{
          pathname: `/advisor-classes/course/${row._id}`,
        }}
        text={'Contracts'}
        variant={'info'}
      />
    );
  }

  function betrachtenButton(cell, row, rowIndex, formatExtraData) {
    return (
      <LinkButton
        to={{
          pathname: `/check-applications/${row._id}`,
        }}
        text={'Check Applications'}
        variant={'primary'}
      />
    );
  }

  function editRequirementsButton(cell, row, rowIndex, formatExtraData) {
    return (
      <LinkButton
        to={{ pathname: `/advisor-edit-course/${row._id}` }}
        text={'Requirements'}
        variant={'secondary'}
      />
    );
  }

  const numberApplications = (cell, row, rowIndex, formatExtraData) => {
    if (row.applications) {
      return row.applications.length;
    }
  };

  const numberAccepted = (cell, row, rowIndex, formatExtraData) => {
    if (row.applications) {
      return row.applications.filter((x) => x.status === 'Accepted' || x.status === 'Contract').length;
    }
  };

  const columns = [
    {
      dataField: 'metacourse[0].name',
      text: 'Course',
      sort: true,
    },
    {
      dataField: 'semester[0].name',
      text: 'Semester',
      sort: true,
    },
    {
      dataField: 'dummyApplicationsNumber',
      isDummyField: true,
      text: 'Applications',
      formatter: numberApplications,
      sort: true,
    },
    {
      dataField: 'dummyAcceptedNumber',
      isDummyField: true,
      text: 'Accepted',
      formatter: numberAccepted,
      sort: true,
    },
    {
      dataField: 'maxtutornumber',
      text: 'Tutors Needed',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'Edit Requirements',
      isDummyField: true,
      text: 'Edit Requirments',
      formatter: editRequirementsButton,
    },
    {
      dataField: 'dummyView',
      isDummyField: true,
      text: '',
      formatter: betrachtenButton,
    },
    {
      dataField: 'contractsView',
      text: 'View Contracts',
      formatter: contractsButton,
    },
  ];

  return (
    <div className="container">
      <PageHeader heading={`Your Courses`} route={'/dashboard'} />
      <ToolkitProvider bootstrap4 keyField="_id" data={courseArray} columns={columns} search>
        {(props) => (
          <div>
            <SearchBar {...props.searchProps} />
            <hr />
            <Table baseProps={props.baseProps} />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default AdvisorCourseOverview;
