import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { updateEducation } from '../../actions/profileActions';
import TextFieldGroup from '../ui/text-field/TextFieldGroup';
import TextAreaFieldGroup from '../ui/text-field/TextAreaFieldGroup';
import { convertIsoToDate } from 'utils/utils';
import PageHeader from '../ui/page-header/PageHeader';

const EditEducation = ({ errors, match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state);

  const [educationData, setEducationData] = useState({
    school: '',
    degree: '',
    fieldofstudy: '',
    from: '',
    to: '',
    current: false,
    description: '',
  });

  useEffect(() => {
    const { id } = match.params;
    const education = getEducation(id, profile.profile.education);

    if (!education) {
      history.replace('/dashboard');
    } else {
      const updatedTo = education.to ? convertIsoToDate(education.to) : null;
      setEducationData({
        school: education.school,
        degree: education.degree,
        fieldofstudy: education.fieldofstudy,
        from: convertIsoToDate(education.from),
        to: updatedTo,
        current: education.current,
        description: education.description,
      });
    }
  }, []);

  const getEducation = (id, educationData) => {
    return educationData.find((edu) => edu._id === id);
  };

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEducationData({
      ...educationData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const updatedTo = educationData.current ? null : educationData.to;
    const eduData = {
      school: educationData.school,
      degree: educationData.degree,
      fieldofstudy: educationData.fieldofstudy,
      from: educationData.from,
      to: updatedTo,
      current: educationData.current,
      description: educationData.description,
    };
    dispatch(updateEducation(match.params.id, eduData, history));
  };

  return (
    <div className={'edit-education'}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Edit education`} route={'/dashboard'} />
            <p className="lead text-center">Add any school, boot camp, etc. that you have attended</p>
            <small className="d-block pb-3">* = required fields</small>
            <form onSubmit={onSubmit}>
              <TextFieldGroup
                placeholder={'* School'}
                name={'school'}
                onChange={onChange}
                value={educationData.school}
                error={errors?.school}
              />
              <TextFieldGroup
                placeholder={'* Degree or Certification'}
                name={'degree'}
                onChange={onChange}
                value={educationData.degree}
                error={errors?.degree}
              />
              <TextFieldGroup
                placeholder={'* Field of Study'}
                name={'fieldofstudy'}
                onChange={onChange}
                value={educationData.fieldofstudy}
                error={errors?.fieldofstudy}
                info="Field of study"
              />
              <h6>From Date</h6>
              <TextFieldGroup
                type={'date'}
                name={'from'}
                onChange={onChange}
                value={educationData.from}
                error={errors?.from}
              />
              <h6>To Date</h6>
              <TextFieldGroup
                type={'date'}
                name={'to'}
                onChange={onChange}
                value={educationData.to}
                error={errors?.to}
                disabled={educationData.current ? 'disabled' : ''}
              />
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  name={'current'}
                  className="form-check-input"
                  checked={educationData.current}
                  onChange={onChange}
                  id={'current'}
                />
                <label htmlFor="current" className="form-check-label">
                  Current Institution
                </label>
              </div>
              <TextAreaFieldGroup
                placeholder={'Program Description'}
                name={'description'}
                onChange={onChange}
                value={educationData.description}
                error={errors?.description}
                info={'Tell us about the program you were in'}
              />
              <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
              <br />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

EditEducation.propTypes = {
  errors: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default EditEducation;
