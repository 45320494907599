import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

export default function Terms() {
  const [role, settRole] = useState('student');
  const [tc, setTC] = useState(false);
  const [msg, setMsg] = useState('');
  const history = useHistory();
  const [profile, setProfile] = useState();

  useEffect(() => {
    axios
      .get('/api/profile')
      .then((res) => setProfile(res.data))
      .catch((err) => console.log(err));

    if (profile !== undefined) {
      history.push('/dashboard');
    }
  });

  const handleRoleChange = (e) => {
    settRole(e.target.value);
  };

  const handleTCChange = (e) => {
    setTC(!tc);
  };

  const handleMsgChange = (e) => {
    setMsg('* Please accept Terms and Conditions');
  };

  return (
    <div className="container">
      <div>
        <h3 style={{ textAlign: 'center' }}> Terms and Conditions </h3>
        <br />
        <div className="box-scroll">
          <strong> Bedingungen / Terms and Conditions</strong>
          <br />
          <br />
          Für den Tutorbetrieb werden folgende personenbezogene Daten gespeichert: Vorname, Nachname, Emailadresse. Für
          die Rolle "Student“ verarbeiten wir darüber hinaus: Geburtstag, Geburtsort, Geburtsland, Nationalität Diese
          Daten sind für die Erstellung der Arbeitsverträge erforderlich.
          <br />
          <br />
          <strong>English Version</strong>
          <br />
          For the Tutor operations we store the following data: First name, last name, email address For the role
          „Student“ we additionally process: Date of birth, place of birth, country of birth, nationality These data is
          necessary to issue the respective contracts.
          <br />
          <br />
          <strong>Einwilligungserkärung / Statement of Consent</strong>
          <br />
          <br />
          Ich stimme zu, dass meine angegebenen personenbezogenen Daten für den Tutorbetrieb durch die Verwaltung der
          Technischen Universität verarbeitet werden. Ich kann meine Einwilligung jederzeit für die Zukunft widerrufen,
          ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung auf
          Grundlage von Art. 6 Abs. 1 lit. a DSGVO berührt wird.
          <br />
          <br />
          Ihren Widerruf richten Sie bitte an tutorbetrieb@in.tum.de. Unter den gesetzlichen Voraussetzungen besteht ein
          Recht auf Auskunft, sowie auf Berichtigung oder Löschung oder auf Einschränkung der Verarbeitung oder eines
          Widerspruchsrechts gegen die Verarbeitung sowie des Rechts auf Datenübertragbarkeit. Es besteht zudem ein
          Beschwerderecht beim Bayerischen Landesbeauftragten für den Datenschutz. Bei Fragen können Sie Sich gerne an
          uns (tutorbetrieb@in.tum.de) oder an unseren Datenschutzbeauftragten (www.datenschutz.tum.de) wenden.
          <br />
          <br />
          <strong>English Version</strong>
          <br />
          I give my consent to the processing of the provided privacy relevant data for the tutor operation by the
          administration of the Technical University of Munich. I can revoke my agreement at any time for the future,
          without affecting the legitimacy of the data processing until the revocation on the basis of article 6 section
          1 lit. a GDPR.
          <br />
          <br />
          In case of cancellation please direct your request to tutorbetrieb@in.tum.de. Among the legal preconditions
          there exists a right for disclosure, correction or deletion or for limitation of the processing or a right for
          revocation against the processing as well as the right for data transferability. In addition, there is a right
          for complaint at the Bavarian State Delegate for Data Protection. In case of questions feel free to contact us
          (tutorbetrieb@in.tum.de) or our data protection officer (www.datenschutz.tum.de).
          <br />
          <br />
          <Link to="/impressum/">Impressum</Link>
          <br />
          <Link to="/datenschutz/">Datenschutzerklärung</Link>
          <br />
          <br />
        </div>

        <br />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <input type="checkbox" id="tc" name="tc" value="agree" onClick={handleTCChange} />
          <label style={{ marginBottom: '.25rem' }} htmlFor="tc">
            <strong>&nbsp; &nbsp; Accept Terms and Conditions</strong>
          </label>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h6 style={{ color: 'red' }}>{msg}</h6>
        </div>
        <br />
        <div>
          <h5 style={{ display: 'flex', justifyContent: 'center' }}>
            <strong>Select your Role:</strong>
          </h5>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <form>
              <input
                type="radio"
                id="student"
                name="role"
                value="student"
                checked="checked"
                onChange={handleRoleChange}
              />
              <label htmlFor="student">
                <strong>&nbsp; &nbsp; Student</strong>
              </label>
              <br />
              <input type="radio" id="advisor" name="role" value="advisor" onChange={handleRoleChange} />
              <label htmlFor="advisor">
                <strong>&nbsp; &nbsp; Advisor/Course Manager</strong>
              </label>
              <br />
              {/* <input type="radio" id="admin" name="role" value="admin" onChange={handleRoleChange} />
                            <label for="advisor"><strong>&nbsp; &nbsp; Admin</strong></label> */}
            </form>
          </div>
        </div>
        <br />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!tc && role === 'student' && (
            <button
              className="btn btn-lg btn-info"
              onClick={handleMsgChange}
              style={{
                width: '18rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Create Profile
            </button>
          )}
          {!tc && role !== 'student' && (
            <button
              className="btn btn-lg btn-info"
              onClick={handleMsgChange}
              style={{
                width: '18rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Create Profile
            </button>
          )}

          {tc && role === 'student' && (
            <Link
              to={{ pathname: '/create-profile', state: { desiredrole: role } }}
              className="btn btn-lg btn-info"
              style={{
                width: '18rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Create Profile
            </Link>
          )}
          {tc && role !== 'student' && (
            <Link
              to={{
                pathname: '/create-advisorprofile',
                state: { desiredrole: role },
              }}
              className="btn btn-lg btn-info"
              style={{
                width: '18rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Create Profile
            </Link>
          )}
        </div>
        <br />
        <p style={{ textAlign: 'center' }}>
          <strong>*For Advisor/Course Manager role UserAdmin approval is required</strong>{' '}
        </p>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
