import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getApplicationsOfCourse,
  acceptApplication,
  declineApplication,
  appliedApplication,
} from '../../actions/applicationActions';
import { getCurrentProfile } from '../../actions/profileActions';
import { getCourseById, setStudentNumberChanged } from '../../actions/courseActions';
import { TutorAdminDataExport } from '../../actions/formsActions';
import moment from 'moment';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../ui/page-header/PageHeader';
import LinkButton from '../ui/button/LinkButton';
import FilterTable from '../ui/table/FilterTable';

const AdminApplicationView = ({ match }) => {
  const dispatch = useDispatch();
  const application = useSelector((state) => state.application);
  const course = useSelector((state) => state.course);
  const profile = useSelector((state) => state.profile);
  const auth = useSelector((state) => state.auth);
  const { id } = match.params;

  const courseName = course?.course?.metacourse?.name;
  const courseSemester = course?.course?.semester?.name;

  const { applications } = application;
  const entries = applications ? applications : [];

  useEffect(() => {
    dispatch(getApplicationsOfCourse(id));
    dispatch(getCourseById(id));
    dispatch(getCurrentProfile());
    dispatch(setStudentNumberChanged(id, false));
  }, [dispatch, id]);

  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (application.errors) {
      setErrors(application.errors);
    }
  }, [application.errors]);

  const onDownloadClick = (e) => {
    e.preventDefault();
    dispatch(TutorAdminDataExport(id));
  };

  const renderAcceptButton = (row) => {
    if (row?.status === 'Applied') {
      return (
        <button onClick={() => dispatch(acceptApplication(row._id, id))} className="btn btn-primary">
          Annehmen
        </button>
      );
    } else if (row?.status === 'Accepted' || row?.status === 'Declined') {
      return (
        <button onClick={() => dispatch(appliedApplication(row._id, id))} className="btn btn-secondary">
          Zurücksetzen
        </button>
      );
    }
  };

  const renderDeclineButton = (row) => {
    if (row?.status === 'Applied') {
      return (
        <button onClick={() => dispatch(declineApplication(row._id, id))} className="btn btn-danger">
          Ablehnen
        </button>
      );
    }
  };

  const prioFormatter = (value) => {
    if (value === '3') {
      return 'Hoch';
    } else if (value === '2') {
      return 'Mittel';
    } else if (value === '1') {
      return 'Niedrig';
    }
  };

  const detailsButton = () => {
    let icon;
    let iconStyle;
    if (course?.course && profile?.profile) {
      if (
        course?.course.detailschange > profile?.profile.user.lastlogin &&
        auth?.user.role !== course?.course.detailsrole
      ) {
        icon = faExclamationCircle;
        iconStyle = 'text-danger';
      }
    }

    return (
      <LinkButton
        to={{ pathname: `/advisor-edit-course/${id}` }}
        text={`Details`}
        variant={'secondary'}
        icon={icon}
        iconStyle={iconStyle}
      />
    );
  };

  const commentFormatter = (value, row) => {
    let icon;
    let iconStyle;
    if (profile?.profile) {
      if (row.commentdate > moment.utc(profile?.profile.user.lastlogin).unix()) {
        icon = faExclamationCircle;
        iconStyle = 'text-danger';
      }
    }
    return (
      <LinkButton
        to={{ pathname: `/comment/${row._id}` }}
        text={`Kommentar`}
        variant={'info'}
        icon={icon}
        iconStyle={iconStyle}
      />
    );
  };

  const columns = [
    {
      dataField: 'profile.lastname',
      text: 'Nachname',
      sort: true,
    },
    {
      dataField: 'profile.firstname',
      text: 'Vorname',
      sort: true,
    },
    {
      dataField: 'grade',
      text: 'Note',
      sort: true,
    },
    {
      dataField: 'priority',
      text: 'Prio',
      sort: true,
      formatter: prioFormatter,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'dummyView',
      isDummyField: true,
      text: 'Bewerbung ansehen',
      formatter: (cell, row) => (
        <LinkButton to={{ pathname: `/applicationdetails/${row._id}` }} text={`Profile`} variant={'info'} />
      ),
    },
    {
      dataField: 'dummyAccept',
      isDummyField: true,
      text: 'Annehmen',
      formatter: renderAcceptButton,
    },
    {
      dataField: 'dummyDecline',
      isDummyField: true,
      text: 'Ablehnen',
      formatter: renderDeclineButton,
    },
    {
      dataField: 'dummyContract',
      isDummyField: true,
      text: 'Vertrag erstellen',
      formatter: (cell, row) => {
        if (row.status === 'Accepted') {
          return (
            <LinkButton
              to={{ pathname: `/create-contract/${row._id}` }}
              text={`Vertrag erstellen`}
              variant={'primary'}
            />
          );
        }
      },
    },
    {
      dataField: 'dummyComment',
      isDummyField: true,
      text: 'Comment',
      formatter: commentFormatter,
    },
  ];

  const renderCourseApplicationView = () => {
    if (!entries[0]) {
      return <h3 className="text-center">Noch keine Bewerbungen</h3>;
    } else {
      return (
        <>
          <div className="btn-group">
            <button type="button" onClick={(e) => onDownloadClick(e)} className="btn btn-info">
              Vertragsdaten exportieren
            </button>
            <LinkButton to={{ pathname: `/budget-control/${id}` }} text={`Budgetübersicht`} variant={'primary'} />
            {detailsButton()}
          </div>
          <FilterTable data={entries} columns={columns} />
        </>
      );
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <PageHeader heading={`Bewerbungen für ${courseName} in ${courseSemester}`} route={`/course-overview`} />
          {renderCourseApplicationView()}
        </div>
      </div>
    </div>
  );
};

AdminApplicationView.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AdminApplicationView;
