import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Components - Routes
import PrivateRoute from 'components/routes/PrivateRoute';
import PrivateAdminRoute from 'components/routes/PrivateAdminRoute';
import PrivateAdvisorRoute from 'components/routes/PrivateAdvisorRoute';
import PrivateMitarbeiterRoute from 'components/routes/PrivateMitarbeiterRoute';

// Pages - Course
import CourseOverview from 'pages/course/CourseOverview';
import CreateCourse from 'pages/course/CreateCourse';
import EditCourse from 'pages/course/EditCourse';
import EditAdvisorDetails from 'pages/course/EditAdvisorDetails';
import AdvisorCourseOverview from 'pages/course/AdvisorCourseOverview';
import AdvisorEventOverview from 'pages/course/AdvisorEventOverview';

// Pages - Metacourse
import CreateMetacourse from 'pages/metacourse/CreateMetacourse';
import EditMetacourse from 'pages/metacourse/EditMetacourse';
import MetacourseOverview from 'pages/metacourse/MetacourseOverview';
import AdvisorMetacourseOverview from 'pages/metacourse/AdvisorMetacourseOverview';

// Pages - Groups
import CreateGroup from 'pages/groups/CreateGroup';
import GroupsOverview from 'pages/groups/GroupsOverview';
import EditGroup from 'pages/groups/EditGroup';

// Pages - Semester
import SemesterOverview from 'pages/semester/SemesterOverview';
import CreateSemester from 'pages/semester/CreateSemester.js';
import EditSemester from 'pages/semester/EditSemester.js';

// Pages - Notification
import { SendNotification } from 'pages/notifications/SendNotification';
import { NotificationTemplates } from 'pages/notifications/NotificationTemplates';
import CreateNotificationTemplate from 'pages/notifications/CreateNotificationTemplate';
import { NotificationEditTemplate } from 'pages/notifications/NotificationEditTemplate';

// Pages - Profile
import CreateProfile from 'pages/profile/CreateProfile';
import EditProfile from 'pages/profile/EditProfile';
import CreateAdvisorProfile from 'pages/profile/CreateAdvisorProfile';
import Terms from 'pages/profile/Terms';
import AdvisorRequest from 'pages/profile/AdvisorRequest';

import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import Faq from '../components/layout/Faq';
import FAQDetailEdit from '../components/FAQ/FAQDetailEdit';
import FAQDetail from '../components/FAQ/FAQDetail';
import Impressum from '../components/layout/Impressum';
import Datenschutz from '../components/layout/Datenschutz';
import Dashboard from '../components/dashboard/Dashboard';
import AddEducation from '../components/add-credentials/AddEducation';
import AddExperience from '../components/add-credentials/AddExperience';
import EditExperience from '../components/add-credentials/EditExperience';
import EditEducation from '../components/add-credentials/EditEducation';
import TutorOverview from '../components/tutor-overview/TutorOverview';
import TutorApplicationView from '../components/application/TutorApplicationView';
import TutorApply from '../components/application/TutorApply';
import MyApplications from '../components/application/MyApplications';
import TutorUpdateApply from '../components/application/TutorUpdateApply';
import AdvisorApplicationView from '../components/application/AdvisorApplicationView';
import ViewTutorProfile from '../components/tutor-profile/ViewTutorProfile';
import AdvisorCourseApplicationView from '../components/application/AdvisorCourseApplicationView';
import AdminApplicationView from '../components/application/AdminApplicationView';
import AdminAllApplicationView from '../components/application/AdminAllApplicationView';
import MiniContractView from '../components/contract/MiniContractView';
import CreateContract from '../components/contract/CreateContract';
import ContractOverview from '../components/contract/ContractOverview';
import EditContract from '../components/contract/EditContract';
import MyContracts from '../components/contract/MyContracts';
import ViewContract from '../components/contract/ViewContract';
import FormsAdminOverview from '../components/forms/FormsAdminOverview';
import FormsUpload from '../components/forms/FormsUpload';
import ChangeAccountType from '../components/administration/ChangeAccountType';
import CreateSeparateContract from '../components/contract/CreateSeparateContract';
import BudgetControl from '../components/budgetcontrol/BudgetControl';
import BudgetOverview from '../components/budgetcontrol/BudgetOverview';
import MailOverview from '../components/mail/MailOverview';
import CreateMailTemplate from '../components/mail/CreateMailTemplate';
import CreateSeparateContractMail from '../components/mail/CreateSeparateContractMail';
import EditMailTemplate from '../components/mail/EditMailTemplate';
import SendMail from '../components/mail/SendMail';
import SendMailToUser from '../components/mail/SendMailToUser';
import CreateContractMail from '../components/mail/CreateContractMail';
import SignableMail from '../components/mail/SignableMail';
import ContractStats from '../components/contract/ContractStats';
import AddComment from '../components/application/AddComment';
import Help from '../components/help/Help';
import RentalOverview from '../components/rentals/RentalOverview';
import CreateRental from '../components/rentals/CreateRental';
import ViewRental from '../components/rentals/ViewRental';
import Rentalstats from '../components/rentals/Rentalstats';
import RentalstatsConfig from '../components/rentals/RentalstatsConfig';
import CreateRentalApplication from '../components/rentals/CreateRentalApplication';
import StudentRentalView from '../components/rentals/StudentRentalView';
import SSOLogin from '../components/auth/SSOLogin';
import loginView from '../components/auth/LoginView';
import TutorInformationStandalone from '../components/tutor-information/TutorInformationStandalone.js';
import TutorInformationEdit from '../components/tutor-information/TutorInformationEdit.js';

import './App.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

const App = () => {
  return (
    <Router>
      <div className="main-wrapper">
        <div className={'center-main-inner'}>
          <Navbar />
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/impressum" component={Impressum} />
            <Route exact path="/datenschutz" component={Datenschutz} />
            <Route exact path="/ssologin" component={SSOLogin} />
            <Route exact path="/login" component={loginView} />

            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/create-profile" component={CreateProfile} />
            <PrivateRoute exact path="/advisor-request/:name" component={AdvisorRequest} />
            <PrivateRoute exact path="/terms" component={Terms} />
            <PrivateRoute exact path="/edit-profile" component={EditProfile} />
            <PrivateRoute exact path="/add-experience" component={AddExperience} />
            <PrivateRoute exact path="/add-education" component={AddEducation} />
            <PrivateRoute exact path="/edit-experience/:id" component={EditExperience} />
            <PrivateRoute exact path="/edit-education/:id" component={EditEducation} />
            <PrivateRoute exact path="/help" component={Help} />
            <PrivateRoute exact path="/studentrentalview" component={StudentRentalView} />
            <PrivateRoute exact path="/create-advisorprofile" component={CreateAdvisorProfile} />
            <PrivateRoute exact path="/tutorapplication" component={TutorApplicationView} />
            <PrivateRoute exact path="/tutorapply/:id" component={TutorApply} />
            <PrivateRoute exact path="/myapplications" component={MyApplications} />
            <PrivateRoute exact path="/updateapplication/:id" component={TutorUpdateApply} />
            <PrivateRoute exact path="/mycontracts" component={MyContracts} />
            <PrivateRoute exact path="/view-contract/:id" component={ViewContract} />
            <PrivateRoute exact path="/createrentalapplication" component={CreateRentalApplication} />
            <PrivateRoute exact path="/tutor-information" component={TutorInformationStandalone} />
            <PrivateRoute exact path="/tutor-information/edit/:id" component={TutorInformationEdit} />

            <PrivateAdminRoute exact path="/faq/edit/:id" component={FAQDetailEdit} />
            <PrivateAdminRoute exact path="/faq/display" component={FAQDetail} />
            <PrivateAdminRoute exact path="/create-metacourse" component={CreateMetacourse} />
            <PrivateAdminRoute exact path="/course-overview" component={CourseOverview} />
            <PrivateAdminRoute exact path="/create-course" component={CreateCourse} />
            <PrivateAdminRoute exact path="/group-create" component={CreateGroup} />
            <PrivateAdminRoute exact path="/group-overview" component={GroupsOverview} />
            <PrivateAdminRoute exact path="/group/:id" component={EditGroup} />
            <PrivateAdminRoute exact path="/semester-overview" component={SemesterOverview} />
            <PrivateAdminRoute exact path="/allapplications-overview" component={AdminAllApplicationView} />
            <PrivateAdminRoute exact path="/create-semester" component={CreateSemester} />
            <PrivateAdminRoute exact path="/edit-semester/:id" component={EditSemester} />
            <PrivateAdminRoute exact path="/metacourse-overview" component={MetacourseOverview} />
            <PrivateAdminRoute exact path="/course-applications/:id" component={AdminApplicationView} />
            <PrivateAdminRoute exact path="/create-contract/:id" component={CreateContract} />
            <PrivateAdminRoute exact path="/contracts" component={ContractOverview} />
            <PrivateAdminRoute exact path="/edit-contract/:id" component={EditContract} />
            <PrivateAdminRoute exact path="/forms-administration" component={FormsAdminOverview} />
            <PrivateAdminRoute exact path="/form-upload" component={FormsUpload} />
            <PrivateAdminRoute exact path="/changeaccounttype/:id" component={ChangeAccountType} />
            <PrivateAdminRoute exact path="/createseparatecontract/" component={CreateSeparateContract} />
            <PrivateAdminRoute exact path="/budget-control/:id" component={BudgetControl} />
            <PrivateAdminRoute exact path="/budget-overview" component={BudgetOverview} />
            <PrivateAdminRoute exact path="/mail-overview" component={MailOverview} />
            <PrivateAdminRoute exact path="/create-template" component={CreateMailTemplate} />
            <PrivateAdminRoute exact path="/send-mail" component={SendMail} />
            <PrivateAdminRoute exact path="/send-mail-to-user/:id" component={SendMailToUser} />
            <PrivateAdminRoute exact path="/createcontractmail/:id" component={CreateContractMail} />
            <PrivateAdminRoute exact path="/createseparatecontractmail/:id" component={CreateSeparateContractMail} />
            <PrivateAdminRoute exact path="/signablemail/:id" component={SignableMail} />
            <PrivateAdminRoute exact path="/edit-template/:id" component={EditMailTemplate} />
            <PrivateAdminRoute exact path="/contractstats" component={ContractStats} />
            <PrivateAdminRoute exact path="/send-notification" component={SendNotification} />
            <PrivateAdminRoute exact path="/notification-templates" component={NotificationTemplates} />
            <PrivateAdminRoute exact path="/create-notification-template" component={CreateNotificationTemplate} />
            <PrivateAdminRoute exact path="/notification-edit-template/:id" component={NotificationEditTemplate} />

            <PrivateAdvisorRoute exact path="/tutor-overview" component={TutorOverview} />
            <PrivateAdvisorRoute exact path="/advisor-metacourse-overview" component={AdvisorMetacourseOverview} />
            <PrivateAdvisorRoute exact path="/advisor-course-overview" component={AdvisorEventOverview} />
            <PrivateAdvisorRoute exact path="/edit-metacourse/:id" component={EditMetacourse} />
            <PrivateAdvisorRoute exact path="/edit-course/:id" component={EditCourse} />
            <PrivateAdvisorRoute exact path="/advisor-classes" component={AdvisorCourseOverview} />
            <PrivateAdvisorRoute exact path="/check-applications/:id" component={AdvisorApplicationView} />
            <PrivateAdvisorRoute exact path="/comment/:id" component={AddComment} />
            <PrivateAdvisorRoute exact path="/advisor-edit-course/:id" component={EditAdvisorDetails} />
            <PrivateAdvisorRoute exact path="/profile/:id" component={ViewTutorProfile} />
            <PrivateAdvisorRoute exact path="/applicationdetails/:id" component={AdvisorCourseApplicationView} />
            <PrivateAdvisorRoute exact path="/contract/:applicationId" component={MiniContractView} />
            <PrivateAdvisorRoute exact path="/contract/course/:courseId" component={MiniContractView} />
            <PrivateAdvisorRoute exact path="/advisor-classes/course/:courseId" component={MiniContractView} />

            <PrivateMitarbeiterRoute exact path="/rentals-overview" component={RentalOverview} />
            <PrivateMitarbeiterRoute exact path="/new-rental" component={CreateRental} />
            <PrivateMitarbeiterRoute exact path="/rental/:id" component={ViewRental} />
            <PrivateMitarbeiterRoute exact path="/rentalstats" component={Rentalstats} />
            <PrivateMitarbeiterRoute exact path="/rentalstats-config" component={RentalstatsConfig} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
