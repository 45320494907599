import axios from 'axios';

import { GET_METACOURSE, GET_ERRORS, GET_METACOURSES, DELETE_METACOURSE, TUMMETACOURSES_LOADING } from './types';

//Get all Metacourse
export const getMetacourses = () => (dispatch) => {
  axios
    .get('/api/metacourse/')
    .then((res) => dispatch({ type: GET_METACOURSES, payload: res.data }))
    .catch((err) =>
      dispatch({
        type: GET_METACOURSES,
        payload: {},
      })
    );
};

//Get Metacourse of id
export const getMetacourseById = (id) => (dispatch) => {
  axios
    .get(`/api/metacourse/${id}`)
    .then((res) => dispatch({ type: GET_METACOURSE, payload: res.data }))
    .catch((err) =>
      dispatch({
        type: GET_METACOURSE,
        payload: {},
      })
    );
};

//Delete Metacourse of id
export const deleteMetacourseById = (id) => (dispatch) => {
  axios
    .delete(`/api/metacourse/${id}`)
    .then((res) =>
      dispatch({
        type: DELETE_METACOURSE,
        payload: id,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: { err },
      })
    );
};

//Create Metacourse
export const createMetacourse = (metacourseData, history) => (dispatch) => {
  axios
    .post('/api/metacourse', metacourseData)
    .then((res) => {
      history.push('/metacourse-overview');
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Edit Metacourse
export const editMetacourse = (id, metacourseData, history, role) => (dispatch) => {
  axios
    .post(`/api/metacourse/${id}`, metacourseData)
    .then((res) => {
      if (role === 'Admin' || role === 'Manager') {
        history.push('/metacourse-overview');
      } else {
        history.push('/advisor-metacourse-overview');
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Get all Courses form  TUM Online
//TODO: Endpoint provided by TUM Online doesn't provide relevant details need to use other endpoint
export const getTUMMetacourses = () => (dispatch) => {
  // dispatch(setTUMMetaCourseLoading());
  // axios
  //   .get("/api/metacourse/organization/courses/")
  //   .then((res) => dispatch({ type: GET_TUMMETACOURSES, payload: res.data }))
  //   .catch((err) =>
  //     dispatch({
  //       type: GET_TUMMETACOURSES,
  //       payload: {},
  //     })
  //   );
};

export const setTUMMetaCourseLoading = () => {
  return {
    type: TUMMETACOURSES_LOADING,
  };
};
