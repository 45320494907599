import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import PropTypes from 'prop-types';
import SearchBar from './SearchBar';
import Table from './Table';

const FilterTable = ({ data, columns }) => {
  return (
    <ToolkitProvider bootstrap4 keyField="_id" data={data} columns={columns} search>
      {(props) => (
        <div>
          <SearchBar {...props.searchProps} />
          <hr />
          <Table baseProps={props.baseProps} />
        </div>
      )}
    </ToolkitProvider>
  );
};

FilterTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default FilterTable;
