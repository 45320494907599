import { GET_TUMMETACOURSES, TUMMETACOURSES_LOADING } from '../actions/types';

const initialState = {
  tummetacourses: null,
  tummetacourseloading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TUMMETACOURSES_LOADING:
      return {
        ...state,
        tummetacourseloading: true,
      };
    case GET_TUMMETACOURSES:
      return {
        ...state,
        tummetacourses: action.payload,
        tummetacourseloading: false,
      };
    default:
      return state;
  }
}
