import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  faChalkboardTeacher,
  faUserCircle,
  faAddressBook,
  faGraduationCap,
  faFileContract,
  faFile,
  faCalendarAlt,
  faEnvelope,
  faFilePdf,
  faClipboard,
  faLightbulb,
  faPeopleGroup,
  faUserGroup,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { getRentals } from '../../actions/rentalActions';
import ActionList from './ActionList';
import { checkStudentNumberChanged } from './../../services/CourseService';

const AdminProfileActions = () => {
  const dispatch = useDispatch();
  const [studentnumberchanged, setStudentNumberChanged] = useState(false);

  useEffect(() => {
    const checkStudentNumber = async () => {
      const studentnumberchanged = await checkStudentNumberChanged();
      setStudentNumberChanged(studentnumberchanged?.hasChangedStudentNumber);
    };

    dispatch(getRentals());
    checkStudentNumber();
  }, [dispatch]);

  const personalActions = [{ route: '/edit-profile', text: 'Profil bearbeiten', icon: faUserCircle }];

  const tutorManagementActions = [
    { route: '/tutor-overview', text: 'Tutor Übersicht', icon: faAddressBook },
    { route: '/allapplications-overview', text: 'Bewerbungen', icon: faFile },
    {
      route: '/course-overview',
      text: 'Veranstaltungen',
      icon: faGraduationCap,
      extraIcon: faExclamationCircle,
    },
    { route: '/contracts', text: 'Verträge', icon: faFileContract },
  ];

  const setupActions = [
    {
      route: '/semester-overview',
      text: 'Semester Übersicht',
      icon: faCalendarAlt,
    },
    {
      route: '/metacourse-overview',
      text: 'Metaveranstaltungen',
      icon: faGraduationCap,
    },
    { route: '/forms-administration', text: 'Formulare', icon: faFilePdf },
    {
      route: '/faq/display',
      text: 'FAQ bearbeiten',
      icon: faChalkboardTeacher,
    },
    { route: '/tutor-information', text: 'ASTRA-Info', icon: faLightbulb },
  ];

  const rolesActions = [
    { route: '/group-create', text: 'Gruppe erstellen', icon: faUserGroup },
    { route: '/group-overview', text: 'Gruppen', icon: faPeopleGroup },
  ];

  const mailActions = [
    { route: '/send-mail', text: 'Mail versenden', icon: faEnvelope },
    { route: '/mail-overview', text: 'Mail Vorlagen', icon: faAddressBook },
  ];

  const rentalsActions = [{ route: '/rentals-overview', text: 'Verleihübersicht', icon: faClipboard }];

  const notificationsActions = [
    {
      route: '/send-notification',
      text: 'Benachrichtigungen versenden',
      icon: faEnvelope,
    },
    {
      route: '/notification-templates',
      text: 'Benachrichtigung Vorlagen',
      icon: faAddressBook,
    },
  ];

  return (
    <div className="mb-4">
      <ActionList items={personalActions} title="Persönlich" />
      <ActionList items={tutorManagementActions} title="Tutorenmanagement" showExtra={studentnumberchanged} />
      <ActionList items={setupActions} title="Aufstellen" />
      <ActionList items={rolesActions} title="Rollen" />
      <ActionList items={mailActions} title="Mail" />
      <ActionList items={rentalsActions} title="Leihen" />
      <ActionList items={notificationsActions} title="Benachrichtigungen" />
    </div>
  );
};

export default AdminProfileActions;
