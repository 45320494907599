import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';

// UI Components
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import PageHeader from 'components/ui/page-header/PageHeader';
import Spinner from 'components/ui/spinner/Spinner';

// Data and Utilities
import aufenthaltfreieCountries from 'components/common/AufenthaltCountries';
import { currentfieldofstudy } from 'utils/data';
import { isEmpty } from 'utils/utils';
import countryList from 'components/countryselector/country-list';

// Actions
import { createProfile, getCurrentProfile, deleteAccountRequest } from 'actions/profileActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PersonalNumber from 'assets/Personalnummer-1.png';

const EditProfile = ({ history }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);
  const errors = useSelector((state) => state.errors);
  const countryOptions = countryList?.getData();

  //Select options for degree
  const degreeOptions = [
    { label: 'None', value: '' },
    { label: 'Bachelor(FH,Uni)/Diplom(FH)/Master(FH)', value: 'Bachelor' },
    { label: 'Master(Uni)', value: 'Master' },
    { label: 'Diplom(Uni)', value: 'Diplom' },
  ];

  //Select options for scholarship
  const scholarshipOptions = [
    { label: 'No', value: 'No' },
    { label: 'Yes', value: 'Yes' },
  ];

  const [state, setState] = useState({
    lastname: '',
    firstname: '',
    matrikelnummer: '',
    lffpersonalnummer: '',
    birthday: '',
    nationality: '',
    nationality2: '',
    birthplace: '',
    countryofbirth: '',
    aufenthaltend: '',
    stipendiumend: '',
    currentfieldofstudy: '',
    otherfieldstudy: '',
    degree: '',
    handle: '',
    receivemails: false,
    role: '',
    fetch_role_isLoading: true,
    err: null,
  });

  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    dispatch(getCurrentProfile());
    fetchUserRole();
  }, []);

  const fetchUserRole = async () => {
    try {
      setState((prevState) => ({ ...prevState, fetch_role_isLoading: true }));

      const res = await axios.get('/api/auth/role');

      if (res.status >= 400) {
        throw new Error('Server responds with error!');
      }

      const userdata = res.data;

      setState((prevState) => ({
        ...prevState,
        role: userdata.role,
        fetch_role_isLoading: false,
      }));
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        err,
        fetch_role_isLoading: false,
      }));
    }
  };

  const onDeleteClick = () => {
    dispatch(deleteAccountRequest(history));
  };

  useEffect(() => {
    if (profile) {
      const updatedProfile = { ...profile };

      // Handle field defaults
      Object.keys(updatedProfile).forEach((field) => {
        updatedProfile[field] = isEmpty(updatedProfile[field]) ? '' : updatedProfile[field];
      });

      setState((prevState) => ({
        ...prevState,
        ...updatedProfile,
        currentfieldofstudy:
          updatedProfile.currentfieldofstudy === 'Other'
            ? updatedProfile.otherfieldstudy
            : updatedProfile.currentfieldofstudy,
      }));
    }
  }, [profile]);

  const onSubmit = (e) => {
    e.preventDefault();

    const profileData = {
      lastname: state.lastname,
      firstname: state.firstname,
      matrikelnummer: state.matrikelnummer,
      lffpersonalnummer: state.lffpersonalnummer,
      birthday: state.birthday,
      nationality: state.nationality,
      nationality2: state.nationality2,
      birthplace: state.birthplace,
      countryofbirth: state.countryofbirth,
      aufenthaltend: state.aufenthaltend,
      stipendiumend: state.stipendiumend,
      currentfieldofstudy: state.currentfieldofstudy === 'Other' ? state.otherfieldstudy : state.currentfieldofstudy,
      degree: state.degree,
      handle: state.handle,
      receivemails: state.receivemails,
      role: state.role,
    };
    dispatch(createProfile(profileData, history));
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

  let safariTooltip;

  if (isSafari) {
    safariTooltip = (
      <h3 className={'text-danger text-center'}>
        If you experience Problems using Safari please use another Browser. It is recommended to use Chrome or Firefox
      </h3>
    );
  }

  let aufenthaltInput;
  let aufenthaltLabel;
  if (
    aufenthaltfreieCountries.indexOf(state.nationality) === -1 &&
    (aufenthaltfreieCountries.indexOf(state.nationality2) === -1 || state.nationality2 === '')
  ) {
    aufenthaltLabel = (
      <div>
        {' '}
        <label htmlFor="Aufenthalt">Expiration date of residence permit:</label>
        <br />
        <label htmlFor="Aufenthalt" className="fs-6 fst-italic">
          *If you hold an unlimited residence permit please enter 2099 as expiration date
        </label>
      </div>
    );
    aufenthaltInput = (
      <TextFieldGroup
        type={'date'}
        placeholder="Aufenthaltstitel Ende"
        onChange={onChange}
        value={moment.utc(state.aufenthaltend).format('YYYY-MM-DD')}
        name="aufenthaltend"
        error={errors.aufenthaltend}
      />
    );
  }

  let content;

  if (state.role === 'Student') {
    content = (
      <div>
        <form onSubmit={onSubmit}>
          <label htmlFor="firstname">* Firstname:</label>
          <TextFieldGroup
            placeholder="* Firstname"
            onChange={onChange}
            value={state.firstname}
            name="firstname"
            error={errors.firstname}
            disabled="disabled"
          />
          <label htmlFor="lastname">* Lastname:</label>
          <TextFieldGroup
            placeholder="* Lastname"
            onChange={onChange}
            value={state.lastname}
            name="lastname"
            error={errors.lastname}
            disabled="disabled"
          />
          <label htmlFor="birthday">* Birthday:</label>
          <TextFieldGroup
            type={'date'}
            placeholder="* Birthday"
            onChange={onChange}
            value={moment.utc(state.birthday).format('YYYY-MM-DD')}
            name="birthday"
            error={errors.birthday}
            disabled="disabled"
          />
          <label htmlFor="birthplace">* Birthplace:</label>
          <TextFieldGroup
            placeholder="* Birthplace"
            onChange={onChange}
            value={state.birthplace}
            name="birthplace"
            error={errors.birthplace}
            // disabled="disabled"
          />
          <label htmlFor="countryofbirth">* Country of Birth:</label>
          <SelectListGroup
            placeholder="* Country of Birth"
            onChange={onChange}
            value={state.countryofbirth}
            name="countryofbirth"
            error={errors.countryofbirth}
            options={countryOptions}
            // disabled="disabled"
          />
          <label htmlFor="nationality">* Nationality:</label>
          <SelectListGroup
            placeholder="* Nationality"
            onChange={onChange}
            value={state.nationality}
            name="nationality"
            error={errors.nationality}
            options={countryOptions}
          />
          <label htmlFor="nationality2">Second Nationality:</label>
          <SelectListGroup
            placeholder="Second Nationality"
            onChange={onChange}
            value={state.nationality2}
            name="nationality2"
            error={errors.nationality2}
            options={countryOptions}
            info="Please provide your second nationality if you have one"
          />
          {aufenthaltLabel}
          {aufenthaltInput}

          <label htmlFor="stipendiumend">TUM Scholarship:</label>
          <br />
          <label htmlFor="stipendiumend" className="fs-6 fst-italic">
            *Select "yes" only in case your scholarship is granted by TUM
          </label>
          <SelectListGroup
            placeholder="Stipendium End"
            onChange={onChange}
            value={state.stipendiumend}
            name="stipendiumend"
            error={errors.stipendiumend}
            options={scholarshipOptions}
          />

          <label htmlFor="Matrikelnummer">Immatrikulation number:</label>
          <TextFieldGroup
            placeholder="Immatrikulation number"
            onChange={onChange}
            value={state.matrikelnummer}
            name="matrikelnummer"
            error={errors.matrikelnummer}
            disabled="disabled"
          />

          <label htmlFor="lffpersonalnummer" className="label-text">
            LfF-Personalnummer:
          </label>
          <FontAwesomeIcon
            size="md"
            fixedWidth
            icon={faInfoCircle}
            className="mx-1 fas text-primary"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowImage((prev) => !prev)}
          />
          {showImage && <img src={PersonalNumber} alt="Personal Number" className="img-fluid" />}
          <br />
          <label htmlFor="lffpersonalnummer" className="fs-6 fst-italic">
            *This is the 8-digit personnel number present on all salary slips
          </label>
          <TextFieldGroup
            placeholder="LfF-Personalnummer"
            onChange={onChange}
            value={state.lffpersonalnummer}
            name="lffpersonalnummer"
            error={errors.lffpersonalnummer}
          />
          <label htmlFor="currentfieldofstudy">Current Field of Study:</label>
          <SelectListGroup
            placeholder="Current Field of Study"
            onChange={onChange}
            value={state.currentfieldofstudy}
            name="currentfieldofstudy"
            error={errors.currentfieldofstudy}
            options={currentfieldofstudy}
          />
          {state.currentfieldofstudy === 'Other' && (
            <TextFieldGroup
              placeholder="Current Field of Study"
              onChange={onChange}
              value={state.otherfieldstudy}
              name="otherfieldstudy"
              error={errors.otherfieldstudy}
            />
          )}

          <label htmlFor="degree">Degree:</label>
          <SelectListGroup
            placeholder="Degree"
            onChange={onChange}
            value={state.degree}
            name="degree"
            error={errors.degree}
            options={degreeOptions}
          />
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="receivemails"
              onChange={() => {
                setState((prevState) => ({
                  ...prevState,
                  receivemails: !prevState.receivemails,
                }));
              }}
              checked={state.receivemails}
              value={state.receivemails}
            />
            <label className="form-check-label" htmlFor="receivemails">
              receive emails directed to all students
            </label>
          </div>
          <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
        </form>
        <hr />
        <button style={{ marginBottom: '1rem' }} onClick={onDeleteClick} className="btn btn-danger">
          {' '}
          Delete Account Request
        </button>
      </div>
    );
  } else {
    content = (
      <form onSubmit={onSubmit}>
        <label htmlFor="firstname">* Firstname:</label>
        <TextFieldGroup
          placeholder="* Firstname"
          onChange={onChange}
          value={state.firstname}
          name="firstname"
          error={errors.firstname}
        />
        <label htmlFor="lastname">* Lastname:</label>
        <TextFieldGroup
          placeholder="* Lastname"
          onChange={onChange}
          value={state.lastname}
          name="lastname"
          error={errors.lastname}
        />

        <label htmlFor="handle">* Handle:</label>
        <TextFieldGroup
          placeholder="* Handle"
          onChange={onChange}
          value={state.handle}
          name="handle"
          error={errors.handle}
          info={'Select a unique 2 to 3 character handle'}
        />
        <input type="submit" value="Submit" className="btn btn-info btn-block mt-4 mb-2" />
      </form>
    );
  }

  return (
    <div className={'create-profile'}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={'Edit Your Profile'} route={'/dashboard'} />
            {safariTooltip}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <small className="d-block pb-4">* = required fields</small>
            </div>
            {state.fetch_role_isLoading ? <Spinner /> : <>{content}</>}
          </div>
        </div>
      </div>
    </div>
  );
};

EditProfile.propTypes = {
  history: PropTypes.object.isRequired,
};

export default EditProfile;
