import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

const backendUrl = process.env.REACT_APP_BACKEND_URL || '';

export default function LoginView() {
  // This will be used as the return point after authentication.
  // The current location origin is used in case the client hostname
  // differs from the backend hostname.
  const params = new URLSearchParams({
    target: `${window.location.origin}/ssologin`,
  });

  function filterFunction() {
    var input, filter, a, i;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    let div = document.getElementById('myDropdown');
    a = div.getElementsByTagName('a');
    for (i = 0; i < a.length; i++) {
      let txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = '';
      } else {
        a[i].style.display = 'none';
      }
    }
  }

  const checkASTRADomain = () => {
    if (window.location.hostname == 'tutorbetrieb.in.tum.de') {
      return false;
    } else {
      return true;
    }
  };

  const URLchange = () => {
    return (
      <div className="d-flex justify-content-center p-5">
        <h4>
          This website has moved and can now be accessed through{' '}
          <a href="https://astra.cit.tum.de/">https://astra.cit.tum.de</a>. Please update your favourites/bookmark.
        </h4>
      </div>
    );
  };

  return (
    <div className="login">
      <div className="container">
        <div className="row">
          {checkASTRADomain() ? (
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">Log in</h1>
              <p className="lead text-center">Sign in to your ASTRA account</p>
              <br />
              <div style={{ maxWidth: '25rem', margin: 'auto' }} className="d-flex flex-column">
                <div className="d-flex flex-column justify-content-center">
                  {/* <Button id="tum" onClick={submit} className="btn btn-primary">With <strong>TUM</strong> credentials »</Button> */}
                  <a href={`${backendUrl}/api/users/shibboleth/login/tum?${params}`}>
                    <Button className="btn btn-primary w-100">
                      With <strong>TUM</strong> credentials »
                    </Button>
                  </a>
                  <p style={{ fontStyle: 'italic' }} className="text-center">
                    For members of TU Munich
                  </p>
                </div>
                <br />
                <div className="d-flex flex-column justify-content-center">
                  <a href={`${backendUrl}/api/users/shibboleth/login/lmu?${params}`}>
                    <Button className="btn btn-success w-100">
                      With <strong>LMU</strong> credentials »
                    </Button>
                  </a>
                  <p style={{ fontStyle: 'italic' }} className="text-center">
                    For members of LMU Munich
                  </p>
                </div>
                <br />
                <div>
                  <p style={{ fontStyle: 'italic' }} className="text-center">
                    For members of other German universities
                  </p>
                  <Dropdown>
                    <Dropdown.Toggle variant="info" id="dropdown-basic" className="w-100">
                      Find your University
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{
                        height: '200px',
                        overflowY: 'auto',
                        width: '100%',
                      }}
                    >
                      <input
                        style={{ position: 'sticky', top: '-6px' }}
                        className="w-100"
                        type="text"
                        placeholder=" &nbsp;&nbsp; Search..."
                        id="myInput"
                        onKeyUp={filterFunction}
                      />
                      <Dropdown.Item href={`${backendUrl}/api/users/shibboleth/login/heilbronn?${params}`}>
                        Heilbronn University
                      </Dropdown.Item>
                      <Dropdown.Item href={`${backendUrl}/api/users/shibboleth/login/FreieUniversitätBerlin?${params}`}>
                        Freie Universität Berlin
                      </Dropdown.Item>
                      <Dropdown.Item href={`${backendUrl}/api/users/shibboleth/login/tum?${params}`}>
                        Technische Universität Munich (TUM)
                      </Dropdown.Item>
                      <Dropdown.Item href={`${backendUrl}/api/users/shibboleth/login/TUBerlin?${params}`}>
                        Technische Universität Berlin
                      </Dropdown.Item>
                      <Dropdown.Item href={`${backendUrl}/api/users/shibboleth/login/lmu?${params}`}>
                        University of Munich (LMU)
                      </Dropdown.Item>
                      <Dropdown.Item href={`${backendUrl}/api/users/shibboleth/login/RWTHAachen?${params}`}>
                        RWTH Aachen
                      </Dropdown.Item>
                      <Dropdown.Item href={`${backendUrl}/api/users/shibboleth/login/UniFreiburg?${params}`}>
                        Albert-Ludwigs-Universität Freiburg
                      </Dropdown.Item>
                      <Dropdown.Item href={`${backendUrl}/api/users/shibboleth/login/BADresden?${params}`}>
                        BA Dresden
                      </Dropdown.Item>
                      <Dropdown.Item href={`${backendUrl}/api/users/shibboleth/login/kit?${params}`}>
                        Karlsruher Institut für Technologie (KIT)
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <br />
              <br />
            </div>
          ) : (
            <URLchange />
          )}
        </div>
      </div>
    </div>
  );
}
