import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../tutor-information/TutorInformationEdit.css';
import { Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const Block = Quill.import('blots/block');

class AddressBlot extends Block {}
AddressBlot.blotName = 'address';
AddressBlot.tagName = 'address';

Quill.register('formats/address', AddressBlot);

const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['address'],
  ],
};

export default function FAQDetailEdit() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fillData() {
      const res = await axios.get(`/api/other/faqs/${id}`);
      const { title, content } = res.data;
      setTitle(title);
      setContent(content);
      setLoading(false);
    }

    if (id !== 'new') {
      setLoading(true);
      fillData();
    } else {
      setLoading(false);
    }
  }, [id]);

  const Toggle = () => {
    return {
      type: 'TOGGLE',
    };
  };

  async function onSave() {
    const data = {
      title,
      content,
    };

    let currentId = id;
    if (currentId === 'new') {
      await axios
        .post('/api/other/faq', data)
        .then(dispatch(Toggle()))
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios.put(`/api/other/faqs/${currentId}`, data);
    }

    history.push('/faq/display');
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 m-auto d-grid gap-2 mb-3">
          <div className="mb-4">
            <Button variant="secondary" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </div>
          <div className="col-md-12 text-center">
            <h1 className="display-5 mb-4">FAQ Editor</h1>
          </div>
          <div className="d-flex">
            <input
              className="fs-2 w-100"
              placeholder=" Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <ReactQuill theme="snow" value={content} onChange={setContent} modules={quillModules} />
          </div>
          <button className="btn btn-primary" onClick={onSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
