import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import verfassungsPruefung from 'components/common/VerfassungschutzCountries';
import { DSGVOExport } from 'actions/formsActions';
import { updateProfileOfId, getProfile, deleteAccount } from 'actions/profileActions';
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import countryList from 'components/countryselector/country-list';
import aufenthaltfreieCountries from 'components/common/AufenthaltCountries';
import { currentfieldofstudy } from 'utils/data';
import PageHeader from 'components/ui/page-header/PageHeader';
import LinkButton from 'components/ui/button/LinkButton';

const ViewTutorProfile = ({ match, history }) => {
  const dispatch = useDispatch();
  const { profile, errors, auth } = useSelector((state) => state);

  const [state, setState] = useState({
    lastname: '',
    firstname: '',
    user: '',
    gender: '',
    matrikelnummer: '',
    birthday: '',
    nationality: '',
    nationality2: '',
    birthplace: '',
    countryofbirth: '',
    aufenthaltend: '',
    stipendiumend: '',
    degree: '',
    currentfieldofstudy: '',
    role: '',
    lffpersonalnummer: '',
    disabled: true,
  });

  useEffect(() => {
    dispatch(getProfile(match.params.id));

    if (countryList.data[0].value !== '') {
      countryList.setEmpty('Select a Country').getLabel('');
    }
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (errors) {
      setState((prevState) => ({ ...prevState, errors }));
    }

    if (profile.profile) {
      const userProfile = profile.profile;

      const updatedState = {
        lastname: userProfile.lastname || '',
        firstname: userProfile.firstname || '',
        user: userProfile.user || '',
        matrikelnummer: userProfile.matrikelnummer || '',
        nationality: userProfile.nationality || '',
        nationality2: userProfile.nationality2 || '',
        birthday: userProfile.birthday || '',
        birthplace: userProfile.birthplace || '',
        countryofbirth: userProfile.countryofbirth || '',
        currentfieldofstudy: userProfile.currentfieldofstudy || '',
        stipendiumend: userProfile.stipendiumend || '',
        degree: userProfile.degree || '',
        aufenthaltend: userProfile.aufenthaltend || '',
        lffpersonalnummer: userProfile.lffpersonalnummer || '',
        role: userProfile.user.role || 'Student',
        handle: userProfile.handle || '',
        disabled: true,
      };

      setState(updatedState);
    }
  }, [errors, profile.profile]);

  const onDeleteClick = () => {
    dispatch(deleteAccount(state.user._id, history));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const profileData = {
      lastname: state.lastname,
      firstname: state.firstname,
      matrikelnummer: state.matrikelnummer,
      birthday: state.birthday,
      nationality: state.nationality,
      nationality2: state.nationality2,
      birthplace: state.birthplace,
      countryofbirth: state.countryofbirth,
      currentfieldofstudy: state.currentfieldofstudy,
      degree: state.degree,
      stipendiumend: state.stipendiumend,
      aufenthaltend: state.aufenthaltend,
      lffpersonalnummer: state.lffpersonalnummer,
      role: state.role,
      handle: state.handle,
    };
    dispatch(updateProfileOfId(profileData, match.params.id, history));
  };

  const onCSVDownloadClick = (e) => {
    e.preventDefault();
    dispatch(DSGVOExport(match.params.id));
  };

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const countryOptions = countryList.getData();

  const deleteButton =
    auth.user.role !== 'Manager' ? (
      <button style={{ marginBottom: '1rem' }} onClick={onDeleteClick} className="btn btn-danger">
        Delete Account
      </button>
    ) : null;

  const degreeOptions = [
    { label: 'None', value: '' },
    { label: 'Bachelor(FH,Uni)/Diplom(FH)/Master(FH)', value: 'Bachelor' },
    { label: 'Master(Uni)', value: 'Master' },
    { label: 'Diplom(Uni)', value: 'Diplom' },
  ];

  const scholarshipOptions = [
    { label: 'No', value: 'No' },
    { label: 'Yes', value: 'Yes' },
  ];

  let aufenthaltInput;
  let aufenthaltLabel;

  if (
    aufenthaltfreieCountries.indexOf(state.nationality) === -1 &&
    (aufenthaltfreieCountries.indexOf(state.nationality2) === -1 || state.nationality2 === '')
  ) {
    aufenthaltLabel = <label htmlFor="Aufenthalt">Aufenthalt Ende:</label>;
    aufenthaltInput = (
      <TextFieldGroup
        type={'date'}
        placeholder="Aufenthaltstitel Ende"
        onChange={onChange}
        value={moment.utc(state.aufenthaltend).format('YYYY-MM-DD')}
        name="aufenthaltend"
        error={errors.aufenthaltend}
        disabled={state.disabled}
      />
    );
  }

  const verfassungsPruefungTooltipNationality =
    verfassungsPruefung.indexOf(state.nationality) !== -1 ? (
      <h3 className="text-danger">Verfassungsprüfung notwendig!</h3>
    ) : null;

  const verfassungsPruefungTooltipCountryofbirth =
    verfassungsPruefung.indexOf(state.countryofbirth) !== -1 ? (
      <h3 className="text-danger">Verfassungsprüfung notwendig!</h3>
    ) : null;

  const verfassungsPruefungTooltipNationality2 =
    verfassungsPruefung.indexOf(state.nationality2) !== -1 ? (
      <h3 className="text-danger">Verfassungsprüfung notwendig!</h3>
    ) : null;

  let submitButton;
  if (!state.disabled) {
    submitButton = <input type="submit" value="Bestätigen" className="btn btn-info btn-block mt-4 mb-4" />;
  }

  return (
    <div className="view-profile">
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Profile of ${state.firstname} ${state.lastname}`} route={'/tutor-overview'} />
            <div className="btn-group">
              {auth.user.role !== 'Manager' ? (
                <LinkButton
                  to={{
                    pathname: `/changeAccountType/${match.params.id}`,
                  }}
                  text={'Rolle ändern'}
                  variant={'primary'}
                />
              ) : null}
              <LinkButton
                to={{
                  pathname: `/send-mail-to-user/${match.params.id}`,
                }}
                text={'Mail senden'}
                variant={'info'}
              />
              <button
                type="button"
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    disabled: !prevState.disabled,
                  }));
                }}
                className="btn btn-dark"
              >
                Bearbeiten an/aus
              </button>
              <button type="button" onClick={onCSVDownloadClick} className="btn btn-secondary">
                DSGVO Export
              </button>
            </div>

            <form onSubmit={onSubmit}>
              <label htmlFor="firstname">Vorname:</label>
              <TextFieldGroup
                placeholder="Vorname"
                onChange={onChange}
                value={state.firstname}
                name="firstname"
                error={errors.firstname}
                disabled={state.disabled}
              />
              <label htmlFor="lastname">Nachname:</label>
              <TextFieldGroup
                placeholder="Nachname"
                onChange={onChange}
                value={state.lastname}
                name="lastname"
                error={errors.lastname}
                disabled={state.disabled}
              />

              <label htmlFor="birthday">Geburtstag:</label>
              <TextFieldGroup
                type={'date'}
                placeholder="Geburtstag"
                onChange={onChange}
                value={moment.utc(state.birthday).format('YYYY-MM-DD')}
                name="birthday"
                error={errors.birthday}
                disabled={state.disabled}
              />
              <label htmlFor="birthplace">* Geburtsort:</label>
              <TextFieldGroup
                placeholder="Geburtsort"
                onChange={onChange}
                value={state.birthplace}
                name="birthplace"
                error={errors.birthplace}
                disabled={state.disabled}
              />
              <label htmlFor="countryofbirth">Geburtsland:</label>
              {verfassungsPruefungTooltipCountryofbirth}
              <SelectListGroup
                placeholder="* Country of Birth"
                onChange={onChange}
                value={state.countryofbirth}
                name="countryofbirth"
                error={errors.countryofbirth}
                options={countryOptions}
                disabled={state.disabled}
              />
              <label htmlFor="nationality">Nationaliät:</label>
              {verfassungsPruefungTooltipNationality}
              <SelectListGroup
                placeholder="* Nationality"
                onChange={onChange}
                value={state.nationality}
                name="nationality"
                error={errors.nationality}
                options={countryOptions}
                disabled={state.disabled}
              />
              <label htmlFor="nationality2">Zweite Nationalität:</label>
              {verfassungsPruefungTooltipNationality2}
              <SelectListGroup
                placeholder="Second Nationality"
                onChange={onChange}
                value={state.nationality2}
                name="nationality2"
                error={errors.nationality2}
                options={countryOptions}
                disabled={state.disabled}
              />

              {aufenthaltLabel}
              {aufenthaltInput}

              <label htmlFor="stipendiumend">TUM Stipendium:</label>
              <SelectListGroup
                placeholder="Stipendium End"
                onChange={onChange}
                value={state.stipendiumend}
                name="stipendiumend"
                error={errors.stipendiumend}
                options={scholarshipOptions}
                disabled={state.disabled}
              />

              <label htmlFor="matrikelnummer">Matrikelnummer:</label>
              <TextFieldGroup
                placeholder="* Matrikelnummer"
                onChange={onChange}
                value={state.matrikelnummer}
                name="matrikelnummer"
                error={errors.matrikelnummer}
                disabled={state.disabled}
              />

              <label htmlFor="lffpersonalnummer">LfF-Personalnummer:</label>
              <TextFieldGroup
                placeholder="LfF-Personalnummer"
                onChange={onChange}
                value={state.lffpersonalnummer}
                name="lffpersonalnummer"
                error={errors.lffpersonalnummer}
                disabled={state.disabled}
              />

              <label htmlFor="currentfieldofstudy">Studiengang:</label>
              <SelectListGroup
                placeholder="Current Field of Study"
                onChange={onChange}
                value={state.currentfieldofstudy}
                name="currentfieldofstudy"
                error={errors.currentfieldofstudy}
                options={currentfieldofstudy}
                disabled={state.disabled}
              />
              <label htmlFor="degree">Abschluss:</label>
              <SelectListGroup
                placeholder="Degree"
                onChange={onChange}
                value={state.degree}
                name="degree"
                error={errors.degree}
                options={degreeOptions}
                disabled={state.disabled}
              />
              {submitButton}
            </form>
            <hr />
            {deleteButton}
          </div>
        </div>
      </div>
    </div>
  );
};

ViewTutorProfile.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ViewTutorProfile);
