import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

// UI Components
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import PageHeader from 'components/ui/page-header/PageHeader';
import Spinner from 'components/ui/spinner/Spinner';

// Actions
import { clearSemester, editSemester, getSemesterById } from 'features/semester/semesterSlice';

// Utils
import isEmpty from 'utils/is-empty';

const EditSemester = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const semester = useSelector((state) => state.semester.semester);
  const semesterError = useSelector((state) => state.semester.errors);
  const semesterLoading = useSelector((state) => state.semester.isLoading);

  const [state, setState] = useState({
    name: '',
    from: '',
    to: '',
    coursefrom: '',
    courseto: '',
  });

  useLayoutEffect(() => {
    dispatch(getSemesterById(match.params.id));
  }, [match.params.id]);

  useEffect(() => {
    const sanitizedSemester = {
      name: isEmpty(semester.name) ? '' : semester.name,
      from: isEmpty(semester.from) ? '' : semester.from,
      to: isEmpty(semester.to) ? '' : semester.to,
      coursefrom: isEmpty(semester.coursefrom) ? '' : semester.coursefrom,
      courseto: isEmpty(semester.courseto) ? '' : semester.courseto,
    };

    setState({
      ...sanitizedSemester,
    });
  }, [semester]);

  useEffect(() => {
    return () => {
      dispatch(clearSemester());
    };
  }, [dispatch]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const { name, from, to, coursefrom, courseto } = state;
    const semesterData = {
      name,
      from,
      to,
      coursefrom,
      courseto,
    };
    const data = {
      id: match.params.id,
      semesterData,
    };
    try {
      await dispatch(editSemester(data)).unwrap();
      history.push('/semester-overview');
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <div className="editSemester">
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Semester Bearbeiten`} route={'/semester-overview'} />
            <small className="d-block pb-3">* = benötigte Felder</small>
            {semesterLoading ? (
              <Spinner />
            ) : (
              <form onSubmit={onSubmit}>
                <TextFieldGroup
                  placeholder="* Semester Bezeichnung"
                  onChange={onChange}
                  value={state.name}
                  name="name"
                  error={semesterError?.name}
                  info="* Semester Bezeichnung"
                />
                <TextFieldGroup
                  type={'date'}
                  placeholder="* Semester Start"
                  onChange={onChange}
                  value={moment.utc(state.from).format('YYYY-MM-DD')}
                  name="from"
                  error={semesterError?.from}
                  info="* Semester Start"
                />
                <TextFieldGroup
                  type={'date'}
                  placeholder="* Semester Ende"
                  onChange={onChange}
                  value={moment.utc(state.to).format('YYYY-MM-DD')}
                  name="to"
                  error={semesterError?.to}
                  info="* Semester Ende"
                />
                <TextFieldGroup
                  type={'date'}
                  placeholder="* Kurse von"
                  onChange={onChange}
                  value={moment.utc(state.coursefrom).format('YYYY-MM-DD')}
                  name="coursefrom"
                  error={semesterError?.coursefrom}
                  info="* Semester Kurse Start"
                />
                <TextFieldGroup
                  type={'date'}
                  placeholder="* Kurse bis"
                  onChange={onChange}
                  value={moment.utc(state.courseto).format('YYYY-MM-DD')}
                  name="courseto"
                  error={semesterError?.courseto}
                  info="* Semester Kurse Ende"
                />
                <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
              </form>
            )}
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSemester;
