import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Row, FormGroup } from 'react-bootstrap';

// UI Components
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import TextAreaFieldGroup from 'components/ui/text-field/TextAreaFieldGroup';
import PageHeader from 'components/ui/page-header/PageHeader';
import MultiList from 'components/ui/multi-list/MultiList';
import Spinner from 'components/ui/spinner/Spinner';

// Actions
import { editCourse, getCourseById } from 'actions/courseActions';
import { getMetacourses } from 'actions/metacourseActions';
import { getAdvisors, getAdmins } from 'actions/profileActions';

// Utils
import { isEmpty } from 'utils/utils';
import { getSemesters } from 'features/semester/semesterSlice';

const optionmap = new Map([]);
class EditCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      module: '',
      metacourse: '',
      semester: '',
      profile: '',
      studentnumber: 0,
      groupnumber: 0,
      groupsize: 0,
      tutorialhours: 0,
      homework: 0,
      exam: 0,
      midterm: 0,
      groupspertutor: 0,
      maxtutornumber: 0,
      weeklyhourspertutor: 0,
      overallhours: 0,
      from: '',
      till: '',
      weeks: '',
      requirement: '',
      admin: '',
      location: '',
      selectedAdvisor: [],
      advisors: [],
      status: '',
      manualmaxtutor: false,
      manualweekly: false,
      errors: {},
      advisorfirstname: '',
      advisorlastname: '',
      advisoremail: '',
      orgunit: '',
      finalexam: true,
      retakeexam: true,
      midtermexam: false,
      homeworkcorrection: false,
      compulsorybachelor: false,
      compulsorymaster: false,
      frequency: '',
      correctiontype: '',
      finalgraderelevance: '',
      contextteaching: 'None',
      specialissues: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateAdvisor = this.updateAdvisor.bind(this);
  }

  componentDidMount() {
    this.props.getMetacourses();
    this.props.getSemesters();
    this.props.getCourseById(this.props.match.params.id);
    this.props.getAdvisors();

    if (this.props.auth.user?.role === 'Admin' || this.props.auth.user?.role === 'Manager') {
      this.props.getAdmins();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.metacourse) {
      const { metacourses } = nextProps.metacourse;
      this.setState({
        metacourses: metacourses,
      });
    }
    if (nextProps.semester) {
      const { semesters } = nextProps.semester;
      this.setState({
        semesters: semesters,
      });
    }

    if (nextProps.course.course && nextProps.profile) {
      const { advisors, admins } = nextProps.profile;
      const { course } = nextProps.course;
      course.metacoursediff = !isEmpty(course.metacourse._id) ? course.metacourse._id : '';
      course.module = !isEmpty(course.metacourse.module) ? course.metacourse.module : '';
      course.semesterdiff = !isEmpty(course.semester.name) ? course.semester.name : '';
      course.studentnumber = !isEmpty(course.studentnumber) ? course.studentnumber : '';
      course.groupnumber = !isEmpty(course.groupnumber) ? course.groupnumber : '';
      course.tutorialhours = !isEmpty(course.tutorialhours) ? course.tutorialhours : '';
      course.homework = !isEmpty(course.homework) ? course.homework : '';
      course.exam = !isEmpty(course.exam) ? course.exam : '';
      course.groupspertutor = !isEmpty(course.groupspertutor) ? course.groupspertutor : '';
      course.maxtutornumber = !isEmpty(course.maxtutornumber) ? course.maxtutornumber : '';
      course.weeklyhourspertutor = !isEmpty(course.weeklyhourspertutor) ? course.weeklyhourspertutor : '';
      course.overallhours = !isEmpty(course.overallhours) ? course.overallhours : '';
      course.overallweeklyhours = !isEmpty(course.overallweeklyhours) ? course.overallweeklyhours : '';
      course.from = !isEmpty(course.from) ? course.from : '';
      course.till = !isEmpty(course.till) ? course.till : '';
      course.weeks = !isEmpty(course.weeks) ? course.weeks : '';
      course.requirement = !isEmpty(course.requirement) ? course.requirement : '';
      course.admin = !isEmpty(course.admin) ? course.admin : '';
      course.location = !isEmpty(course.location) ? course.location : '';
      course.status = !isEmpty(course.status) ? course.status : '';
      course.advisorfirstname = !isEmpty(course.advisorfirstname) ? course.advisorfirstname : '';
      course.advisorlastname = !isEmpty(course.advisorlastname) ? course.advisorlastname : '';
      course.advisoremail = !isEmpty(course.advisoremail) ? course.advisoremail : '';
      course.orgunit = !isEmpty(course.orgunit) ? course.orgunit : '';
      course.finalexam = !isEmpty(course.finalexam) ? course.finalexam : true;
      course.retakeexam = !isEmpty(course.retakeexam) ? course.retakeexam : true;
      course.midtermexam = !isEmpty(course.midtermexam) ? course.midtermexam : false;
      course.compulsorybachelor = !isEmpty(course.compulsorybachelor) ? course.compulsorybachelor : false;
      course.compulsorymaster = !isEmpty(course.compulsorymaster) ? course.compulsorymaster : false;
      course.homeworkcorrection = !isEmpty(course.homeworkcorrection) ? course.homeworkcorrection : false;
      course.frequency = !isEmpty(course.frequency) ? course.frequency : '';
      course.correctiontype = !isEmpty(course.correctiontype) ? course.correctiontype : '';
      course.finalgraderelevance = !isEmpty(course.finalgraderelevance) ? course.finalgraderelevance : '';
      course.contextteaching = !isEmpty(course.contextteaching) ? course.contextteaching : 'None';
      course.specialissues = !isEmpty(course.specialissues) ? course.specialissues : '';

      if (optionmap.size === 0) {
        for (let i = 0; i < advisors?.length; i++) {
          optionmap.set(advisors[i].user._id, {
            value: advisors[i].user?._id,
            name: advisors[i].firstname + ' ' + advisors[i].lastname,
          });
        }
      }

      this.setState({
        advisors: advisors,
        admins: admins,
        module: course.module,
        metacoursediff: course.metacoursediff,
        semesterdiff: course.semesterdiff,
        studentnumber: course.studentnumber,
        groupnumber: course.groupnumber,
        groupsize: course.groupsize,
        tutorialhours: course.tutorialhours,
        homework: course.homework,
        exam: course.exam,
        midterm: course.midterm,
        groupspertutor: course.groupspertutor,
        maxtutornumber: course.maxtutornumber,
        weeklyhourspertutor: course.weeklyhourspertutor,
        overallhours: course.overallhours,
        from: course.from,
        till: course.till,
        weeks: course.weeks,
        requirement: course.requirement,
        admin: course.admin,
        location: course.location,
        selectedAdvisor: course.advisor,
        status: course.status,
        advisorfirstname: course.advisorfirstname,
        advisorlastname: course.advisorlastname,
        advisoremail: course.advisoremail,
        orgunit: course.orgunit,
        finalexam: course.finalexam,
        retakeexam: course.retakeexam,
        midtermexam: course.midtermexam,
        compulsorybachelor: course.compulsorybachelor,
        compulsorymaster: course.compulsorymaster,
        homeworkcorrection: course.homeworkcorrection,
        frequency: course.frequency,
        correctiontype: course.correctiontype,
        finalgraderelevance: course.finalgraderelevance,
        contextteaching: course.contextteaching,
        specialissues: course.specialissues,
      });
    }
  }

  updateAdvisor(selectedAdvisor) {
    if (selectedAdvisor !== undefined) {
      this.setState({ selectedAdvisor: selectedAdvisor });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let frequency = this.state.frequency;
    let correctiontype = this.state.correctiontype;
    let finalgraderelevance = this.state.finalgraderelevance;
    if (!this.state.homeworkcorrection) {
      frequency = '';
      correctiontype = '';
      finalgraderelevance = '';
    }
    const courseData = {
      metacourse: this.state.metacoursediff,
      semester: this.state.semesterdiff,
      studentnumber: this.state.studentnumber,
      groupnumber: this.state.groupnumber,
      groupsize: this.state.groupsize,
      tutorialhours: this.state.tutorialhours,
      homework: this.state.homework,
      exam: this.state.exam,
      midterm: this.state.midterm,
      groupspertutor: this.state.groupspertutor,
      maxtutornumber: this.state.maxtutornumber,
      weeklyhourspertutor: this.state.weeklyhourspertutor,
      overallhours: this.state.overallhours,
      overallweeklyhours: this.state.overallweeklyhours,
      from: this.state.from,
      till: this.state.till,
      weeks: this.state.weeks,
      requirement: this.state.requirement,
      admin: this.state.admin,
      location: this.state.location,
      advisor: this.state.selectedAdvisor,
      status: this.state.status,
      advisorfirstname: this.state.advisorfirstname,
      advisorlastname: this.state.advisorlastname,
      advisoremail: this.state.advisoremail,
      orgunit: this.state.orgunit,
      finalexam: this.state.finalexam,
      retakeexam: this.state.retakeexam,
      midtermexam: this.state.midtermexam,
      compulsorybachelor: this.state.compulsorybachelor,
      compulsorymaster: this.state.compulsorymaster,
      homeworkcorrection: this.state.homeworkcorrection,
      frequency: frequency,
      correctiontype: correctiontype,
      finalgraderelevance: finalgraderelevance,
      contextteaching: this.state.contextteaching,
      specialissues: this.state.specialissues,
    };

    this.props.editCourse(this.props.match.params.id, courseData, this.props.history, this.props.auth.user.role);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'homeworkcorrection') {
      this.setState({
        [e.target.name]: e.target.value === 'true' ? true : false,
      });
    }
  }

  //Select options for Midterm
  midtermOptions = [
    { label: '0', value: '0' },
    { label: '0.5', value: '0.5' },
  ];

  //Select options for Exam
  examOptions = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
  ];

  //Select options
  booleanOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  gradebooleanOptions = [
    { label: 'Grade auswählen', value: '' },
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  //Select options for homework
  homeworkOptions = [
    { label: 'Frequency auswählen', value: '' },
    { label: 'Every Week', value: 'Every Week' },
    { label: 'Every 2nd Week', value: 'Every 2nd Week' },
    { label: 'Every Month', value: 'Every Month' },
  ];

  //Select options
  correctionOptions = [
    { label: 'Correction Type auswählen', value: '' },
    { label: 'Fully Automatic', value: 'Fully Automatic' },
    { label: 'Partial Automation', value: 'Partial Automation' },
    { label: 'Manual', value: 'Manual' },
  ];

  contextTeachingOptions = [
    {
      label: 'Course given for some other faculty/school',
      value: 'Course given for some other faculty/school',
    },
    {
      label: 'Course given by some other faculty/school to Informatik students',
      value: 'Course given by some other faculty/school to Informatik students',
    },
    { label: 'None', value: 'None' },
  ];

  statusOptions = [
    { label: 'Vorbereitung', value: 'Preparation' },
    { label: 'Offen', value: 'Open' },
    { label: 'Geschlossen', value: 'Closed' },
    { label: 'Archiv', value: 'Archive' },
  ];

  //Select options for Location
  locationOptions = [
    { label: 'Garching', value: 'Garching' },
    { label: 'München', value: 'München' },
    { label: 'Heilbronn', value: 'Heilbronn' },
  ];

  headers = [
    { label: 'Module', key: 'module' },
    { label: 'Course Name', key: 'metacoursediff' },
    { label: 'Advisor Email', key: 'advisoremail' },
    { label: 'Advisor Firstname', key: 'advisorfirstname' },
    { label: 'Advisor Lastname', key: 'advisorlastname' },
    { label: 'Context Teaching', key: 'contextteaching' },
    { label: 'From', key: 'from' },
    { label: 'Till', key: 'till' },
    { label: 'Correction Type', key: 'correctiontype' },
    { label: 'Exam', key: 'exam' },
    { label: 'Final Exam', key: 'finalexam' },
    { label: 'Final Grade Relevance', key: 'finalgraderelevance' },
    { label: 'Frequency', key: 'frequency' },
    { label: 'Group Number', key: 'groupnumber' },
    { label: 'Group Size', key: 'groupsize' },
    { label: 'Groups Per Tutor', key: 'groupspertutor' },
    { label: 'Homework', key: 'homework' },
    { label: 'Homework Correction', key: 'homeworkcorrection' },
    { label: 'Location', key: 'location' },
    { label: 'Maxt Tutor number', key: 'maxtutornumber' },
    { label: 'Midterm', key: 'midterm' },
    { label: 'Mid Term exam', key: 'midtermexam' },
    { label: 'Compulsory for Bachelor students', key: 'compulsorybachelor' },
    { label: 'Compulsory for Master students', key: 'compulsorymaster' },
    { label: 'Organization Unit', key: 'orgunit' },
    { label: 'Requirement', key: 'requirement' },
    { label: 'Retake exam', key: 'retakeexam' },
    { label: 'Semester', key: 'semesterdiff' },
    { label: 'Status', key: 'status' },
    { label: 'Student number', key: 'studentnumber' },
    { label: 'Tutorial hours', key: 'tutorialhours' },
    { label: 'Weeks', key: 'weeks' },
  ];

  render() {
    const { errors } = this.state;
    var { semesters } = this.state;
    var { metacourses } = this.state;
    let { courseloading } = this.props.course;
    let { metacourseloading } = this.props.metacourse;
    let { advisors, loading, advisorloading } = this.props.profile;
    let { semesterloading } = this.props.semester;

    this.state.overallweeklyhours = this.state.maxtutornumber * this.state.weeklyhourspertutor;

    //Select options for semester
    if (!semesters) {
      semesters = [];
    }
    const semesterOptions = semesters.map((el) => {
      return { label: el.name, value: el.name };
    });
    semesterOptions.unshift({ label: 'Semester auswählen', value: '' });

    //Select options for metacourse
    if (!metacourses) {
      metacourses = [];
    }

    if (typeof metacourses === 'object' && Object.entries(metacourses).length === 0) {
      metacourses = [];
    }

    const generateMetacourseOptions = (metacourses) => {
      const metacourseOptions = metacourses.map((el) => {
        const label = el.name.length > 90 ? el.name.substring(0, 87) + '...' : el.name;
        const fullLabel = label + (el.group?.title ? ' - ' + el.group?.title : '');
        return {
          label: fullLabel,
          value: el._id,
        };
      });

      metacourseOptions.unshift({
        label: 'Metaveranstaltung auswählen',
        value: '',
      });

      return metacourseOptions;
    };

    const metacourseOptions = generateMetacourseOptions(metacourses);

    //Select options for advisors
    if (!advisors) {
      advisors = [];
    }
    if (typeof advisors === 'object' && Object.entries(advisors).length === 0) {
      advisors = [];
    }

    const advisorOptions = advisors.map((el) => {
      return { name: el.firstname + ' ' + el.lastname, value: el.user._id };
    });

    const data = [
      {
        module: this.state.module,
        advisoremail: this.state.advisoremail,
        advisorfirstname: this.state.advisorfirstname,
        advisorlastname: this.state.advisorlastname,
        contextteaching: this.state.contextteaching,
        correctiontype: this.state.correctiontype,
        exam: this.state.exam,
        finalexam: this.state.finalexam,
        finalgraderelevance: this.state.finalgraderelevance,
        frequency: this.state.frequency,
        from: this.state.from,
        groupnumber: this.state.groupnumber,
        groupsize: this.state.groupsize,
        groupspertutor: this.state.groupspertutor,
        homework: this.state.homework,
        homeworkcorrection: this.state.homeworkcorrection,
        location: this.state.location,
        maxtutornumber: this.state.maxtutornumber,
        metacourse: this.state.metacourse,
        metacoursediff: this.state.metacoursediff,
        midterm: this.state.midterm,
        midtermexam: this.state.midtermexam,
        compulsorybachelor: this.state.compulsorybachelor,
        compulsorymaster: this.state.compulsorymaster,
        orgunit: this.state.orgunit,
        requirement: this.state.requirement,
        retakeexam: this.state.retakeexam,
        semester: this.state.semester,
        semesterdiff: this.state.semesterdiff,
        status: this.state.status,
        studentnumber: this.state.studentnumber,
        till: this.state.till,
        tutorialhours: this.state.tutorialhours,
        weeks: this.state.weeks,
      },
    ];

    const backRoute =
      this.props.auth.user.role === 'Admin' || this.props.auth.user.role === 'Manager'
        ? '/course-overview'
        : '/advisor-course-overview';

    const csvReport = {
      data: data,
      headers: this.headers,
      filename: this.state.metacoursediff + ' Veranstaltung.csv',
    };

    if (loading || courseloading || advisorloading || metacourseloading || semesterloading) {
      return <Spinner />;
    } else {
      return (
        <div className="editCourse">
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <PageHeader heading={`Veranstaltung bearbeiten`} route={backRoute} />
                <small className="d-block pb-3">* = benötigte Felder</small>
                <CSVLink className="btn btn-primary mb-4" {...csvReport}>
                  Daten exportieren .csv
                </CSVLink>

                <form onSubmit={this.onSubmit}>
                  {(this.props.auth.user?.role === 'Admin' || this.props.auth.user.role === 'Manager') && (
                    <div>
                      <label htmlFor="inputStatus">Status</label>
                      <SelectListGroup
                        placeholder="Status"
                        onChange={this.onChange}
                        value={this.state.status}
                        name="status"
                        error={errors.status}
                        options={this.statusOptions}
                      />

                      <label htmlFor="inputMetacourse">* Metaveranstaltung</label>
                      <SelectListGroup
                        placeholder="Metacourse"
                        onChange={this.onChange}
                        value={this.state.metacoursediff}
                        name="metacoursediff"
                        error={errors.metacourse}
                        options={metacourseOptions}
                      />
                      <label htmlFor="inputSemester">* Semester</label>
                      <SelectListGroup
                        placeholder="Semester"
                        onChange={this.onChange}
                        value={this.state.semesterdiff}
                        name="semesterdiff"
                        error={errors.semester}
                        options={semesterOptions}
                      />
                      <label htmlFor="inputLocation">* Standort</label>
                      <SelectListGroup
                        placeholder="Status"
                        onChange={this.onChange}
                        value={this.state.location}
                        name="location"
                        error={errors.location}
                        options={this.locationOptions}
                      />
                      <Row>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputMaxTutorNumber">* Maximale Anzahl an Hilfskräften</label>
                          <TextFieldGroup
                            placeholder="* Maximale Anzahl an Hilfskräften"
                            onChange={this.onChange}
                            value={this.state.maxtutornumber}
                            name="maxtutornumber"
                            error={errors.maxtutornumber}
                          />
                        </FormGroup>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputWeeklyhourspertutor">* Wochenstunden pro Hilfskraft</label>
                          <TextFieldGroup
                            placeholder="Wochenstunden pro Hilfskraft"
                            onChange={this.onChange}
                            value={this.state.weeklyhourspertutor}
                            name="weeklyhourspertutor"
                            error={errors.weeklyhourspertutor}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputStudentnumber">Anzahl Studenten</label>
                          <TextFieldGroup
                            placeholder="Number of Students"
                            onChange={this.onChange}
                            value={this.state.studentnumber}
                            name="studentnumber"
                            error={errors.studentnumber}
                          />
                        </FormGroup>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputGroupnumber">Anzahl Gruppen</label>
                          <TextFieldGroup
                            placeholder="Number of Groups"
                            onChange={this.onChange}
                            value={this.state.groupnumber}
                            name="groupnumber"
                            error={errors.groupnumber}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputGroupsize">Gruppengröße</label>
                          <TextFieldGroup
                            placeholder="Groupsize"
                            onChange={this.onChange}
                            value={this.state.groupsize}
                            name="groupsize"
                            error={errors.groupsize}
                          />
                        </FormGroup>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputTutorialhours">Dauer Tutorium</label>
                          <TextFieldGroup
                            placeholder="Tutorialhours"
                            onChange={this.onChange}
                            value={this.state.tutorialhours}
                            name="tutorialhours"
                            error={errors.tutorialhours}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputGroupsPerTutor">Gruppen pro Tutor</label>
                          <TextFieldGroup
                            placeholder="Groups per Tutor"
                            onChange={this.onChange}
                            value={this.state.groupspertutor}
                            name="groupspertutor"
                            error={errors.groupspertutor}
                          />
                        </FormGroup>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputHomework">Hausaufgaben</label>
                          <TextFieldGroup
                            placeholder="Homework"
                            onChange={this.onChange}
                            value={this.state.homework}
                            name="homework"
                            error={errors.homework}
                          />
                        </FormGroup>
                      </Row>

                      <Row>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputMidterm">Midtermfaktor</label>
                          <SelectListGroup
                            placeholder="Midterm"
                            onChange={this.onChange}
                            value={this.state.midterm}
                            name="midterm"
                            error={errors.midterm}
                            options={this.midtermOptions}
                          />
                        </FormGroup>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputExam">Klausurfaktor</label>
                          <SelectListGroup
                            placeholder="Exam"
                            onChange={this.onChange}
                            value={this.state.exam}
                            name="exam"
                            error={errors.exam}
                            options={this.examOptions}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputOverallhours">Wochen</label>
                          <TextFieldGroup
                            placeholder="Weeks"
                            onChange={this.onChange}
                            value={this.state.weeks}
                            name="weeks"
                            error={errors.weeks}
                          />
                        </FormGroup>
                        <FormGroup className=" col-md-6">
                          <label htmlFor="inputOverallWeeklyhours">Gesamtwochenstunden</label>
                          <TextFieldGroup
                            placeholder="Overall Weekly Hours"
                            onChange={this.onChange}
                            value={this.state.overallweeklyhours}
                            name="overallweeklyhours"
                            error={errors.overallweeklyhours}
                            disabled={true}
                          />
                        </FormGroup>
                      </Row>
                      <label htmlFor="inputOverallhours">Gesamtstunden</label>
                      <TextFieldGroup
                        placeholder="Overall Hours"
                        onChange={this.onChange}
                        value={this.state.overallhours}
                        name="overallhours"
                        error={errors.overallhours}
                        disabled={true}
                      />

                      <label htmlFor="inputFrom">Von</label>
                      <TextFieldGroup
                        type={'Date'}
                        placeholder="From"
                        onChange={this.onChange}
                        value={this.state.from}
                        name="from"
                        error={errors.from}
                      />
                      <label htmlFor="inputTill">Bis</label>
                      <TextFieldGroup
                        type={'Date'}
                        placeholder="Till"
                        onChange={this.onChange}
                        value={this.state.till}
                        name="till"
                        error={errors.till}
                      />
                      <label htmlFor="inputRequirements">Anforderungen</label>
                      <TextAreaFieldGroup
                        placeholder="Anforderungen"
                        onChange={this.onChange}
                        value={this.state.requirement}
                        name="requirement"
                        error={errors.requirement}
                      />

                      <label htmlFor="inputAdvisor">Übungsleitung</label>
                      <MultiList
                        options={advisorOptions}
                        setOptions={this.updateAdvisor}
                        error={errors.advisor}
                        preSelectedOption={this.state.selectedAdvisor}
                        placeholder="Übungsleitung auswählen"
                      />

                      <br></br>
                      <hr></hr>
                      <br></br>
                    </div>
                  )}

                  <label htmlFor="inputRequirements">Organisational Unit</label>
                  <TextFieldGroup
                    placeholder="Organisational Unit (e.g. Chair I10)"
                    onChange={this.onChange}
                    value={this.state.orgunit}
                    name="orgunit"
                    error={errors.orgunit}
                  />
                  <div className="grid-container">
                    <div className="grid-item">
                      <label htmlFor="inputRequirements">Course Manager Name</label>
                      <TextFieldGroup
                        placeholder="First Name"
                        onChange={this.onChange}
                        value={this.state.advisorfirstname}
                        name="advisorfirstname"
                        error={errors.advisorfirstname}
                      />
                    </div>
                    <div className="grid-item">
                      <label htmlFor="inputRequirements"></label>
                      <TextFieldGroup
                        placeholder="Last Name"
                        onChange={this.onChange}
                        value={this.state.advisorlastname}
                        name="advisorlastname"
                        error={errors.advisorlastname}
                      />
                    </div>
                  </div>

                  <label htmlFor="inputRequirements">Course Manager Email</label>
                  <TextFieldGroup
                    placeholder="Email"
                    onChange={this.onChange}
                    value={this.state.advisoremail}
                    name="advisoremail"
                    error={errors.advisoremail}
                  />
                  <div className="grid-container-3">
                    <div className="grid-item">
                      <label htmlFor="inputStatus">Final exam </label>
                      <SelectListGroup
                        placeholder="Final exam "
                        onChange={this.onChange}
                        value={this.state.finalexam}
                        name="finalexam"
                        error={errors.finalexam}
                        options={this.booleanOptions}
                      />
                    </div>
                    <div className="grid-item">
                      <label htmlFor="inputStatus">Retake exam </label>
                      <SelectListGroup
                        placeholder="Retake exam "
                        onChange={this.onChange}
                        value={this.state.retakeexam}
                        name="retakeexam"
                        error={errors.retakeexam}
                        options={this.booleanOptions}
                      />
                    </div>
                    <div className="grid-item">
                      <label htmlFor="inputStatus">Midterm exam </label>
                      <SelectListGroup
                        placeholder="Mideterm exam "
                        onChange={this.onChange}
                        value={this.state.midtermexam}
                        name="midtermexam"
                        error={errors.midtermexam}
                        options={this.booleanOptions}
                      />
                    </div>
                  </div>

                  <label htmlFor="inputStatus">Context teaching </label>
                  <SelectListGroup
                    placeholder="Context teaching"
                    onChange={this.onChange}
                    value={this.state.contextteaching}
                    name="contextteaching"
                    error={errors.contextteaching}
                    options={this.contextTeachingOptions}
                  />
                  <div className="grid-container">
                    <div className="grid-item">
                      <label htmlFor="inputStatus">Compulsory for Bachelor Students</label>
                      <SelectListGroup
                        placeholder="Compulsory for Bachelor Students"
                        onChange={this.onChange}
                        value={this.state.compulsorybachelor}
                        name="compulsorybachelor"
                        error={errors.compulsorybachelor}
                        options={this.booleanOptions}
                      />
                    </div>
                    <div className="grid-item">
                      <label htmlFor="inputStatus">Compulsory for Master Students</label>
                      <SelectListGroup
                        placeholder="Compulsory for Master Students"
                        onChange={this.onChange}
                        value={this.state.compulsorymaster}
                        name="compulsorymaster"
                        error={errors.compulsorymaster}
                        options={this.booleanOptions}
                      />
                    </div>
                  </div>
                  <label htmlFor="inputStatus">Homework correction </label>
                  <SelectListGroup
                    placeholder="Homework correction"
                    onChange={this.onChange}
                    value={this.state.homeworkcorrection}
                    name="homeworkcorrection"
                    error={errors.homeworkcorrection}
                    options={this.booleanOptions}
                  />
                  {this.state.homeworkcorrection && (
                    <div className="grid-container-3">
                      <div className="grid-item">
                        <label htmlFor="inputStatus">Frequency </label>
                        <SelectListGroup
                          placeholder="Frequency"
                          onChange={this.onChange}
                          value={this.state.frequency}
                          name="frequency"
                          error={errors.frequency}
                          options={this.homeworkOptions}
                        />
                      </div>
                      <div className="grid-item">
                        <label htmlFor="inputStatus">Correction Type </label>
                        <SelectListGroup
                          placeholder="Correction Type"
                          onChange={this.onChange}
                          value={this.state.correctiontype}
                          name="correctiontype"
                          error={errors.correctiontype}
                          options={this.correctionOptions}
                        />
                      </div>
                      <div className="grid-item">
                        <label htmlFor="inputStatus">Final Grade Relevance</label>
                        <SelectListGroup
                          placeholder="Final Grade Relevance"
                          onChange={this.onChange}
                          value={this.state.finalgraderelevance}
                          name="finalgraderelevance"
                          error={errors.finalgraderelevance}
                          options={this.gradebooleanOptions}
                        />
                      </div>
                    </div>
                  )}

                  <label htmlFor="inputRequirements">Special Issues</label>
                  <TextAreaFieldGroup
                    placeholder="Special Issues"
                    onChange={this.onChange}
                    value={this.state.specialissues}
                    name="specialissues"
                    error={errors.specialissues}
                  />
                  <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
                </form>
                <br />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

EditCourse.propTypes = (state) => ({
  semester: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  metacourse: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
});

const mapStateToProps = (state) => ({
  metacourse: state.metacourse,
  course: state.course,
  semester: state.semester,
  profile: state.profile,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  editCourse,
  getSemesters,
  getMetacourses,
  getAdvisors,
  getCourseById,
  getAdmins,
})(withRouter(EditCourse));
