import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// UI Components
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import TextAreaFieldGroup from 'components/ui/text-field/TextAreaFieldGroup';
import PageHeader from 'components/ui/page-header/PageHeader';

// Actions
import { getNotificationTemplates, sendNotifications } from 'actions/notificationActions';

// Utility
import { isEmpty } from 'utils/utils';

export const SendNotification = (props) => {
  const [text, setText] = useState('');
  const [subject, setSubject] = useState('');
  const [type, setType] = useState('');

  const dispatch = useDispatch();
  const notificationsTemplates = useSelector((state) => state?.notification?.notificationTemplates) || [];
  const errors = useSelector((state) => state.errors);

  const seletableTemplates = () => {
    notificationsTemplates.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    let templateOptions = notificationsTemplates.map((el) => {
      return {
        label: el.name,
        value: el._id,
      };
    });

    templateOptions.unshift({
      label: 'Vorlage auswählen',
      value: '',
    });

    return templateOptions;
  };

  useEffect(() => {
    dispatch(getNotificationTemplates());
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const templateData = { type, text, subject };
    if (window.confirm('Are you sure you want to send the notification?')) {
      dispatch(sendNotifications(templateData, props.history));
    }
  };

  const extractObjectById = (arr, id) => {
    return arr.find((obj) => obj._id === id) || null;
  };

  const onTemplateChange = (val) => {
    if (isEmpty(val)) {
      setText('');
      setSubject('');
    } else {
      const temp = extractObjectById(notificationsTemplates, val);
      setText(temp.text);
      setSubject(temp.subject);
    }
  };

  const onTypeChange = (val) => {
    setType(val);
  };

  const typeOptions = [{ label: 'Alle Tutoren', value: 'all' }];

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 m-auto">
          <PageHeader heading={'Benachrichtigungen versenden'} route={'/dashboard'} />
          <label htmlFor="inputStudent">Benachrichtigungen Typ</label>
          <SelectListGroup
            placeholder="Benachrichtigungen Typ"
            onChange={(e) => onTypeChange(e.target.value)}
            name="type"
            options={typeOptions}
          />

          <label htmlFor="inputStudent">Benachrichtigungen Vorlage</label>
          <SelectListGroup
            placeholder="Benachrichtigungen Vorlage"
            onChange={(e) => onTemplateChange(e.target.value)}
            name="template"
            options={seletableTemplates()}
          />

          <form onSubmit={onSubmit}>
            <TextFieldGroup
              placeholder="Betreff"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              name="subject"
              error={errors?.subject}
              info="Betreff"
            />
            <TextAreaFieldGroup
              placeholder="Text"
              rows={'10'}
              onChange={(e) => setText(e.target.value)}
              value={text}
              name="text"
              error={errors?.text}
              info="Text"
            />
            <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
          </form>
          <br />
        </div>
      </div>
    </div>
  );
};

SendNotification.propTypes = {
  history: PropTypes.object.isRequired,
};
