import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  faUserCircle,
  faAddressBook,
  faGraduationCap,
  faFileContract,
  faFile,
  faCalendarAlt,
  faEnvelope,
  faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons';
import { getRentals } from '../../actions/rentalActions';
import ActionList from './ActionList';

const ManagerProfileActions = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRentals());
  }, [dispatch]);

  const personalActions = [{ route: '/edit-profile', text: 'Profil bearbeiten', icon: faUserCircle }];

  const hilfskraefteManagementActions = [
    {
      route: '/tutor-overview',
      text: 'Benutzer Übersicht',
      icon: faAddressBook,
    },
    { route: '/allapplications-overview', text: 'Bewerbungen', icon: faFile },
    {
      route: '/course-overview',
      text: 'Veranstaltungen',
      icon: faGraduationCap,
    },
    { route: '/contracts', text: 'Verträge', icon: faFileContract },
  ];

  const setupActions = [
    {
      route: '/semester-overview',
      text: 'Semester Übersicht',
      icon: faCalendarAlt,
    },
    {
      route: '/metacourse-overview',
      text: 'Metaveranstaltungen',
      icon: faGraduationCap,
    },
  ];

  const rolesActions = [{ route: '/group-overview', text: 'Gruppen', icon: faPeopleGroup }];

  const mailActions = [
    { route: '/send-mail', text: 'Mail versenden', icon: faEnvelope },
    { route: '/mail-overview', text: 'Mail Vorlagen', icon: faAddressBook },
  ];

  return (
    <div className="mb-4">
      <ActionList items={personalActions} title="Persönlich" />
      <ActionList items={hilfskraefteManagementActions} title="Hilfskräfte Management" />
      <ActionList items={setupActions} title="Aufstellen" />
      <ActionList items={rolesActions} title="Rollen" />
      <ActionList items={mailActions} title="Mail" />
    </div>
  );
};

export default ManagerProfileActions;
