import React, { useEffect, useState } from 'react';
import SelectSearch from 'react-select-search';

export default function MultiList({ options, setOptions, preSelectedOption = [], disabled, placeholder, error = '' }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    let selection = [];
    for (let i = 0; i < selectedOptions?.length; i++) {
      selection.push(selectedOptions[i]);
    }
    setOptions(selection);
  }, [selectedOptions]);

  useEffect(() => {
    if (preSelectedOption?.length > 0) {
      setSelectedOptions(preSelectedOption);
    }
  }, [preSelectedOption?.length]);

  const onSelect = (value) => {
    setSelectedOptions(value);
  };

  return (
    <>
      <div>
        <SelectSearch
          options={options}
          value={selectedOptions}
          name="language"
          placeholder={placeholder}
          multiple
          printOptions="on-focus"
          onChange={onSelect}
          disabled={disabled}
          className={`select-search ${error !== '' ? 'error-border' : null}`}
        />
      </div>
      {error !== '' ? <p style={{ color: '#dc3545', fontSize: '0.875em' }}>{error}</p> : null}
    </>
  );
}
