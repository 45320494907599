import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRentalOfId } from 'actions/rentalActions';
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import TextAreaFieldGroup from 'components/ui/text-field/TextAreaFieldGroup';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import moment from 'moment';
import { updateRental, deleteRental } from 'actions/rentalActions';
import { getCurrentProfile, getAdvisors } from 'actions/profileActions';
import PageHeader from 'components/ui/page-header/PageHeader';
import MultiList from 'components/ui/multi-list/MultiList';

const ViewRental = ({ match, history }) => {
  const dispatch = useDispatch();
  const rentals = useSelector((state) => state.rentals);
  const profile = useSelector((state) => state.profile);
  const errors = useSelector((state) => state.errors);

  const rental = rentals?.rental;
  const advisors = profile?.advisors;

  useEffect(() => {
    if (match.params.id) {
      dispatch(getRentalOfId(match.params.id));
      dispatch(getCurrentProfile());
      dispatch(getAdvisors());
    }
  }, [match.params.id]);

  const onDeleteClick = (id) => {
    dispatch(deleteRental(id, history));
  };

  const [state, setState] = useState({
    processable: true,
    id: '',
    user: '',
    name: '',
    vorname: '',
    tumid: '',
    email: '',
    telefonnummer: '',
    veranstaltung: '',
    von: '',
    bis: '',
    ubungsleitung: [],
    betreuer: '',
    rückgabe: '',
    ipad: { deviceno: '', available: false },
    mikrofon: { deviceno: '', available: false },
    wacom: { deviceno: '', available: false },
    webcam: { deviceno: '', available: false },
    stativ: { deviceno: '', available: false },
    details: '',
    status: '',
    oldstatus: '',
  });

  const {
    processable,
    id,
    user,
    name,
    vorname,
    tumid,
    email,
    telefonnummer,
    veranstaltung,
    von,
    bis,
    ubungsleitung,
    betreuer,
    rückgabe,
    ipad,
    mikrofon,
    wacom,
    webcam,
    stativ,
    details,
    status,
    oldstatus,
  } = state;

  useEffect(() => {
    if (rental) {
      setState((prevState) => ({
        ...prevState,
        user: rental.user,
        id: rental._id,
        name: rental.name,
        vorname: rental.vorname,
        tumid: rental.tumid,
        email: rental.email,
        telefonnummer: rental.telefonnummer,
        veranstaltung: rental.veranstaltung,
        von: rental?.vertragslaufzeit?.von || '',
        bis: rental?.vertragslaufzeit?.bis || '',
        rückgabe: rental.rückgabe,
        ipad: rental.leihobjekt?.ipad ?? prevState.ipad,
        mikrofon: rental.leihobjekt?.mikrofon ?? prevState.mikrofon,
        wacom: rental.leihobjekt?.wacom ?? prevState.wacom,
        webcam: rental.leihobjekt?.webcam ?? prevState.webcam,
        stativ: rental.leihobjekt?.stativ ?? prevState.stativ,
        details: rental.details ?? prevState.details,
        status: rental.status,
        betreuer: rental.betreuer,
        ubungsleitung: rental.ubungsleitung,
        oldstatus: rental.status,
      }));
    }
  }, [rental]);

  const onSubmit = (e) => {
    e.preventDefault();
    const rentalData = {
      user,
      name,
      vorname,
      tumid,
      email,
      telefonnummer,
      veranstaltung,
      von,
      bis,
      ubungsleitung,
      rückgabe,
      ipad,
      mikrofon,
      wacom,
      webcam,
      stativ,
      details,
      handle: profile.profile.handle,
      status,
      betreuer,
      oldstatus,
    };

    dispatch(updateRental(id, rentalData, history));
  };

  const copyRental = () => {
    history.push({
      pathname: '/new-rental',
      state: { id: state?.id },
    });
  };

  const updateAdvisor = (selectedAdvisor) => {
    if (selectedAdvisor !== undefined) {
      setState((prevState) => ({
        ...prevState,
        ubungsleitung: selectedAdvisor,
      }));
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    if (['ipad', 'mikrofon', 'wacom', 'webcam', 'stativ'].includes(name)) {
      setState((prevState) => ({
        ...prevState,
        [name]: {
          deviceno: value,
          available: prevState[name].available,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const advisorOptions = advisors?.map((el) => {
    return { name: el.firstname + ' ' + el.lastname, value: el.user._id };
  });

  const statusOptions = [
    { label: 'HW angefragt', value: 'HW angefragt' },
    { label: 'HW ausgegeben', value: 'HW ausgegeben' },
    { label: 'Abgeschlossen', value: 'Abgeschlossen' },
  ];

  return (
    <div className="edit-rental">
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Ausleihe von ${vorname} ${name}`} route={'/rentals-overview'} />
            <form onSubmit={onSubmit}>
              <div className="mb-4">
                <div className="col">
                  <div className="btn-group">
                    <button
                      type="button"
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          processable: !prevState.processable,
                        }));
                      }}
                      className="btn btn-dark"
                    >
                      Bearbeiten an/aus
                    </button>
                    <button onClick={onSubmit} className="btn btn-primary">
                      Bestätigen
                    </button>
                    <button onClick={() => onDeleteClick(id)} className="btn btn-danger">
                      Löschen
                    </button>
                    <button type="button" onClick={copyRental} className="btn btn-info">
                      Eintrag kopieren
                    </button>
                  </div>
                </div>
                <p></p>
                <h6>Status der Leihe:</h6>
                <SelectListGroup
                  placeholder="* Status"
                  onChange={onChange}
                  value={status}
                  name="status"
                  error={errors.status}
                  options={statusOptions}
                  disabled={processable ? 'disabled' : ''}
                />
              </div>

              <h6>Leihgeräte</h6>
              <div>
                <div className="form-check d-flex justify-content-between">
                  <div className="mt-2 ml-5">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="ipad"
                      onChange={() => {
                        setState((prevState) => {
                          return {
                            ...prevState,
                            ipad: {
                              deviceno: !prevState?.ipad?.available ? prevState?.ipad?.deviceno : '',
                              available: !prevState?.ipad?.available,
                            },
                          };
                        });
                      }}
                      value={ipad}
                      checked={ipad?.available}
                      disabled={processable ? 'disabled' : ''}
                    />

                    <label className="form-check-label" htmlFor="ipad">
                      iPad Pro
                    </label>
                  </div>
                  <div className="mr-5">
                    <TextFieldGroup
                      placeholder="iPad device no."
                      disabled={processable || !ipad?.available ? 'disabled' : ''}
                      onChange={onChange}
                      value={ipad?.deviceno}
                      name="ipad"
                      error={errors.deviceno}
                    />
                  </div>
                </div>
                <div className="form-check d-flex justify-content-between">
                  <div className="mt-2 ml-5">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="mikrofon"
                      onChange={() => {
                        setState((prevState) => ({
                          ...prevState,
                          mikrofon: {
                            deviceno: !prevState?.mikrofon?.available ? prevState?.mikrofon?.deviceno : '',
                            available: !prevState?.mikrofon?.available,
                          },
                        }));
                      }}
                      value={mikrofon?.available}
                      checked={mikrofon?.available}
                      disabled={processable ? 'disabled' : ''}
                    />
                    <label className="form-check-label" htmlFor="mikrofon">
                      Mikrofon
                    </label>
                  </div>
                  <div className="mr-5">
                    <TextFieldGroup
                      placeholder="Mikrofon device no."
                      disabled={processable || !mikrofon?.available ? 'disabled' : ''}
                      onChange={onChange}
                      value={mikrofon?.deviceno}
                      name="mikrofon"
                      error={errors.deviceno}
                    />
                  </div>
                </div>
                <div className="form-check d-flex justify-content-between">
                  <div className="mt-2 ml-5">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="wacom"
                      onChange={() => {
                        setState((prevState) => ({
                          ...prevState,
                          wacom: {
                            deviceno: !prevState?.wacom?.available ? prevState?.wacom?.deviceno : '',
                            available: !prevState?.wacom?.available,
                          },
                        }));
                      }}
                      value={wacom?.available}
                      checked={wacom?.available}
                      disabled={processable ? 'disabled' : ''}
                    />
                    <label className="form-check-label" htmlFor="wacom">
                      Wacom Tablet
                    </label>
                  </div>
                  <div className="mr-5">
                    <TextFieldGroup
                      disabled={processable || !wacom?.available ? 'disabled' : ''}
                      placeholder="Wacom tablet device no."
                      onChange={onChange}
                      value={wacom?.deviceno}
                      name="wacom"
                      error={errors.deviceno}
                    />
                  </div>
                </div>
                <div className="form-check d-flex justify-content-between">
                  <div className="mt-2 ml-5">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="webcam"
                      onChange={() => {
                        setState((prevState) => ({
                          ...prevState,
                          webcam: {
                            deviceno: !prevState?.webcam?.available ? prevState?.webcam?.deviceno : '',
                            available: !prevState?.webcam?.available,
                          },
                        }));
                      }}
                      value={webcam?.available}
                      checked={webcam?.available}
                      disabled={processable ? 'disabled' : ''}
                    />
                    <label className="form-check-label" htmlFor="webcam">
                      Webcam
                    </label>
                  </div>
                  <div className="mr-5">
                    <TextFieldGroup
                      disabled={processable || !webcam?.available ? 'disabled' : ''}
                      placeholder="Webcam device no."
                      onChange={onChange}
                      value={webcam?.deviceno}
                      name="webcam"
                      error={errors.deviceno}
                    />
                  </div>
                </div>
                <div className="form-check d-flex mb-4 justify-content-between">
                  <div className="mt-2 ml-5">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="stativ"
                      onChange={() => {
                        setState((prevState) => ({
                          ...prevState,
                          stativ: {
                            deviceno: !prevState?.stativ?.available ? prevState?.stativ?.deviceno : '',
                            available: !prevState?.stativ?.available,
                          },
                        }));
                      }}
                      value={stativ?.available}
                      checked={stativ?.available}
                      disabled={processable ? 'disabled' : ''}
                    />
                    <label className="form-check-label" htmlFor="stativ">
                      Mikrofonstativ
                    </label>
                  </div>
                  <div className="mr-5 ml-5">
                    <TextFieldGroup
                      placeholder="Stativ device no."
                      disabled={processable || !stativ?.available ? 'disabled' : ''}
                      onChange={onChange}
                      value={stativ?.deviceno}
                      name="stativ"
                      error={errors.deviceno}
                    />
                  </div>
                </div>
              </div>

              <h6>Details:</h6>
              <TextAreaFieldGroup
                placeholder="Bemerkungen"
                onChange={onChange}
                value={details}
                name="details"
                error={errors.details}
                disabled={processable ? 'disabled' : ''}
              />

              <h6>Name:</h6>
              <TextFieldGroup
                placeholder="* 'Name'"
                onChange={onChange}
                value={name}
                name="name"
                error={errors.name}
                disabled={processable ? 'disabled' : ''}
              />
              <h6>Vorname:</h6>
              <TextFieldGroup
                placeholder="* 'Vorname'"
                onChange={onChange}
                value={vorname}
                name="vorname"
                error={errors.vorname}
                disabled={processable ? 'disabled' : ''}
              />
              <h6>TUM-ID:</h6>
              <TextFieldGroup
                placeholder="TUM-ID"
                onChange={onChange}
                value={tumid}
                name="tumid"
                error={errors.tumid}
                disabled={processable ? 'disabled' : ''}
              />
              <h6>Email-Adresse:</h6>
              <TextFieldGroup
                placeholder="Email"
                onChange={onChange}
                value={email}
                name="email"
                error={errors.email}
                disabled={processable ? 'disabled' : ''}
              />
              <h6>Telefonnummer:</h6>
              <TextFieldGroup
                placeholder="Telefonnummer"
                onChange={onChange}
                value={telefonnummer}
                name="telefonnummer"
                error={errors.telefonnummer}
                disabled={processable ? 'disabled' : ''}
              />
              <h6>Veranstaltung:</h6>
              <TextFieldGroup
                placeholder="Veranstaltung"
                onChange={onChange}
                value={veranstaltung}
                name="veranstaltung"
                error={errors.veranstaltung}
                disabled={processable ? 'disabled' : ''}
              />
              <h6>Vertragslaufzeit von:</h6>
              <TextFieldGroup
                type={'date'}
                onChange={onChange}
                value={moment.utc(von).format('YYYY-MM-DD')}
                name={'von'}
                error={errors.von}
                disabled={processable ? 'disabled' : ''}
              />
              <h6>bis:</h6>
              <TextFieldGroup
                type={'date'}
                onChange={onChange}
                value={moment.utc(bis).format('YYYY-MM-DD')}
                name={'bis'}
                error={errors.bis}
                disabled={processable ? 'disabled' : ''}
              />
              <div>
                <h6 htmlFor="inputAdvisor">Übungsleitung</h6>
                <MultiList
                  options={advisorOptions}
                  setOptions={updateAdvisor}
                  preSelectedOption={ubungsleitung}
                  placeholder="Übungsleitung auswählen"
                  disabled={processable ? true : false}
                />
              </div>
              <h6>Vorraussichtliche Rückgabe:</h6>
              <TextFieldGroup
                type={'date'}
                onChange={onChange}
                value={moment.utc(rückgabe).format('YYYY-MM-DD')}
                name={'rückgabe'}
                error={errors.rückgabe}
                disabled={processable ? 'disabled' : ''}
              />

              <input type="submit" value="Bestätigen" className="btn btn-info btn-block mt-4 mb-4" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRental;
