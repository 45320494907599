import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';

// UI Components
import LinkButton from 'components/ui/button/LinkButton';

export default function AdvisorRequest() {
  const { name } = useParams();
  const history = useHistory();
  window.history.replaceState(null, null, '/');
  useEffect(() => {
    const mailData = {
      // fullname: name,
      subject: `Request for Advisor Role Access - ${name}`,
      text: `Dear Admin team,

            ${name} is requesting for Advisor role access
            Please take a look at the profile and grant access accordingly.

            Sincerely,
            ${name}`,
    };
    sendMailToAdmins(mailData);
  }, []);

  //SendMailtoAllAdmins
  const sendMailToAdmins = (mailData) => {
    axios.post('/api/mail/adminmailsend', mailData).catch((err) => {
      console.log(err);
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '5rem',
      }}
      className="container"
    >
      <p>Your Request for Advisor Role has been sent to Admin team for approval</p>
      <p>Currently your Role is set to Student by default</p>
      <p>Please wait for 24hr for approval or directly contact tutorbetrieb team in case of emergency </p>
      <p>
        <strong>Email Address: tutorbetrieb@in.tum.de</strong>
      </p>

      <LinkButton to={{ pathname: '/dashboard' }} text={`Back to Dashboard`} variant={'info'} />
    </div>
  );
}
