import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Collapse } from 'react-bootstrap';

function ConfirmButton(props) {
  const [expanded, setExpanded] = useState(false);

  const rightButton = expanded ? (
    <Button
      variant={props.variant}
      onClick={() => setExpanded(false)}
      style={{ width: '5em' }}
      className="rounded-0 rounded-end"
    >
      Cancel
    </Button>
  ) : (
    <Button {...props} onClick={() => setExpanded(true)} style={{ width: '5em' }}>
      {props.children}
    </Button>
  );

  return (
    <span {...props} onClick={undefined} variant={undefined}>
      <Collapse in={expanded} dimension="width">
        <ButtonGroup>
          <Button disabled variant={`outline-${props.variant}`} style={{ 'white-space': 'nowrap' }}>
            Are you sure?
          </Button>
          <Button
            variant={props.variant}
            onClick={props.onClick}
            style={{ 'white-space': 'nowrap' }}
            className="rounded-0"
          >
            {props.children}
          </Button>
        </ButtonGroup>
      </Collapse>
      {rightButton}
    </span>
  );
}

ConfirmButton.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

export default ConfirmButton;
