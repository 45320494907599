import React from 'react';

function Datenschutz() {
  return (
    <div className="faq">
      <div className="container">
        <div>
          <div className="col-md-12 text-center">
            <h1 className="display-3 mb-4">Datenschutz</h1>
            <hr />
          </div>
          <div className="mt-5 mb-5 ml-4 mr-4">
            <h3> Datenschutzerklärung</h3>
            <br />
            <h5>A. Allgemeine Informationen</h5>
            <strong>NAME UND KONTAKTDATEN DES VERANTWORTLICHEN</strong>
            <br />
            Technische Universität München
            <br />
            Postanschrift: Arcisstr. 21, 80333 München
            <br />
            Telefon: 089/289-01
            <br />
            Email: poststelle@tum.de
            <br />
            <br />
            <strong>KONTAKTDATEN DES/DER DATENSCHUTZBEAUFTRAGTEN</strong>
            <br />
            Der/Die Datenschutzbeauftragte der Technischen Universität München
            <br />
            Postanschrift: Arcisstr. 21, 80333 München
            <br />
            Telefon: 089/289-17052
            <br />
            E-Mail: beauftragter@datenschutz.tum.de
            <br />
            <br />
            <strong>ZWECKE UND RECHTSGRUNDLAGEN FÜR DIE VERARBEITUNG PERSONENBEZOGENER DATEN</strong>
            <br />
            <p style={{ textAlign: 'justify' }}>
              Zweck der Verarbeitung ist das administrative Management des Tutorbetriebs, insbesondere die Erstellung
              und Verwaltung der Tutorverträge. Die Rechtsgrundlage für die Verarbeitung Ihrer Daten ergibt sich, soweit
              nichts anderes angegeben ist, aus Art. 4 Abs. 1 des Bayerischen Datenschutzgesetzes (BayDSG) in Verbindung
              mit Art. 6 Abs. 1 UAbs. 1 Buchst. e der Datenschutzgrundverordnung (DSGVO). Demnach ist es uns erlaubt,
              die zur Erfüllung einer uns obliegenden Aufgabe erforderlichen Daten zu verarbeiten.
            </p>
            <br />
            <strong>EMPFÄNGER VON PERSONENBEZOGENEN DATEN</strong>
            <br />
            Der technische Betrieb unserer Datenverarbeitungssysteme erfolgt durch:
            <br />
            Rechnerbetriebsgruppe der Fakultät für Informatik der TUM
            <br />
            Boltzmannstraße 3<br />
            D-85748 Garching bei München
            <br />
            Telefon: (089) 289-13123
            <br />
            E-Mail: rbg@in.tum.de
            <br />
            <br />
            Gegebenenfalls werden Ihre Daten an die zuständigen Aufsichts- und Rechnungsprüfungsbehörden zur Wahrnehmung
            der jeweiligen Kontrollrechte übermittelt.
            <br />
            Zur Abwehr von Gefahren für die Sicherheit in der Informationstechnik können bei elektronischer Übermittlung
            Daten an das Landesamt für Sicherheit in der Informationstechnik weitergeleitet werden und dort auf
            Grundlage der Art. 12 ff. des Bayerischen E-Government-Gesetzes verarbeitet werden.
            <br /> <br />
            <strong>DAUER DER SPEICHERUNG DER PERSONENBEZOGENEN DATEN</strong>
            <br />
            Ihre Daten werden grundsätzlich nur so lange gespeichert, wie dies unter Beachtung gesetzlicher
            Aufbewahrungsfristen zur Aufgabenerfüllung erforderlich ist. Um einen reibungslosen Ablauf des Tutorbetriebs
            auch im Falle einer Weiterbeschäftigung zu gewährleisten, werden Ihre Daten in der Regel maximal bis zu
            einer Dauer von 1,5 Jahren nach Beendigung des Arbeitsverhältnisses als Tutor gespeichert.
            <br />
            <br />
            <strong>IHRE RECHTE</strong>
            <br />
            Soweit wir von Ihnen personenbezogene Daten verarbeiten, stehen Ihnen als Betroffener nachfolgende Rechte
            zu:
            <br />
            <br />
            <p style={{ textAlign: 'justify' }}>
              Sie haben das Recht auf Auskunft über die zu Ihrer Person gespeicherten Daten (Art. 15 DSGVO).
              <br />
              Sollten unrichtige personenbezogene Daten verarbeitet werden, steht Ihnen ein Recht auf Berichtigung zu
              (Art. 16 DSGVO).
              <br />
              Liegen die gesetzlichen Voraussetzungen vor, so können Sie die Löschung oder Einschränkung der
              Verarbeitung verlangen (Art. 17 und 18 DSGVO).
              <br />
              Wenn Sie in die Verarbeitung eingewilligt haben oder ein Vertrag zur Datenverarbeitung besteht und die
              Datenverarbeitung mithilfe automatisierter Verfahren durchgeführt wird, steht Ihnen gegebenenfalls ein
              Recht auf Datenübertragbarkeit zu (Art. 20 DSGVO). Falls Sie in die Verarbeitung eingewilligt haben und
              die Verarbeitung auf dieser Einwilligung beruht, können Sie die Einwilligung jederzeit für die Zukunft
              widerrufen. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Datenverarbeitung
              wird durch diesen nicht berührt. Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
              ergeben, jederzeit gegen die Verarbeitung Ihrer Daten Widerspruch einzulegen, wenn die Verarbeitung
              ausschließlich auf Grundlage des Art. 6 Abs. 1 Buchst. e oder f DSGVO erfolgt (Art. 21 Abs. 1 Satz 1
              DSGVO).
            </p>
            <br />
            <strong>BESCHWERDERECHT BEI DER AUFSICHTSBEHÖRDE</strong>
            <br />
            Weiterhin besteht ein Beschwerderecht beim Bayerischen Landesbeauftragten für den Datenschutz. Diesen können
            Sie unter folgenden Kontaktdaten erreichen:
            <br />
            Postanschrift: Postfach 22 12 19, 80502 München
            <br />
            Adresse: Wagmüllerstraße 18, 80538 München
            <br />
            Telefon: 089 212672-0
            <br />
            Telefax: 089 212672-50
            <br />
            E-Mail: poststelle(at)datenschutz-bayern.de
            <br />
            https://www.datenschutz-bayern.de/
            <br />
            <br />
            <br />
            <strong>WEITERE INFORMATIONEN</strong>
            <br />
            Für nähere Informationen zur Verarbeitung Ihrer Daten und zu Ihren Rechten können Sie uns unter den oben (zu
            Beginn von A.) genannten Kontaktdaten erreichen.
            <br />
            <br />
            <h5>B. Informationen zum Internetauftritt</h5>
            <br />
            <strong>TECHNISCHE UMSETZUNG</strong>
            <br></br>
            Der technische Betrieb unserer Datenverarbeitungssysteme erfolgt durch:
            <br />
            Rechnerbetriebsgruppe der Fakultät für Informatik der TUM
            <br />
            Boltzmannstraße 3<br />
            D-85748 Garching bei München
            <br />
            Telefon: (089) 289-13123
            <br />
            E-Mail: rbg@in.tum.de
            <br />
            <br />
            <strong>PROTOKOLLIERUNG</strong>
            <br />
            Wenn Sie diese oder andere Internetseiten aufrufen, übermitteln Sie über Ihren Internetbrowser Daten an
            unseren Webserver. Die folgenden Daten werden während einer laufenden Verbindung zur Kommunikation zwischen
            Ihrem Internetbrowser und unserem Webserver temporär in einer Logdatei aufgezeichnet:
            <br />
            <br />
            <list>
              <ul>• IP-Adresse des anfragenden Rechners</ul>
              <ul>• Datum und Uhrzeit des Zugriffs</ul>
              <ul>• Name, URL und übertragene Datenmenge der abgerufenen Datei</ul>
              <ul>• Zugriffsstatus (angeforderte Datei übertragen, nicht gefunden etc.)</ul>
              <ul>
                • Erkennungsdaten des verwendeten Browser- und Betriebssystems (sofern vom anfragenden Webbrowser
                übermittelt)
              </ul>
              <ul>• Webseite, von der aus der Zugriff erfolgte (sofern vom anfragenden Webbrowser übermittelt)</ul>
            </list>
            <br />
            Die Verarbeitung der Daten in dieser Logdatei geschieht wie folgt:
            <br />
            Die Logeinträge werden kontinuierlich automatisch ausgewertet, um Angriffe auf die Webserver erkennen und
            entsprechend reagieren zu können. In Einzelfällen, d.h. bei gemeldeten Störungen, Fehlern und
            Sicherheitsvorfällen, erfolgt eine manuelle Analyse. Eine weitere Auswertung der Logdateien erfolgt nicht.
            <br />
            <br />
            <strong> COOKIES</strong>
            <br />
            Aus Sicherheitsgründen nutzen wir Shibboleth/WAYF für die Authentifizierung. Dazu ist es erforderlich, dass
            beim Zugriff auf dieses Internetangebot Cookies (kleine Dateien) von uns auf Ihrem Gerät gespeichert werden.
            Um eine wiederkehrende Nutzung ohne Login-Vorgang zu ermöglichen (Single-Sign-On) werden ebenfalls Cookies
            eingesetzt. Diese werden gelöscht, sobald Sie sich abmelden.
            <br />
            <br />
            <h5>C. Informationen zu einzelnen Verarbeitungen</h5>
            <br />
            <strong> ANMELDUNG</strong>
            <br />
            Bei Ihrer Anmeldung am System werden Ihre personenbezogenen Daten gegenüber dem Verzeichnisdienst der
            Technischen Universität München oder einer anderen von Ihnen ausgewählten akademischen Institution
            verifiziert.
            <br />
            <br />
            ZUR INFORMATION ist hier der Text Ihrer Einwilligung nochmals nachlesbar:
            <br />
            <strong>Bedingungen / Terms and Conditions</strong>
            <br />
            Für den Tutorbetrieb werden folgende personenbezogene Daten gespeichert: Vorname, Nachname, Emailadresse.
            Für die Rolle "Student“ verarbeiten wir darüber hinaus: Geburtstag, Geburtsort, Geburtsland, Nationalität
            Diese Daten sind für die Erstellung der Arbeitsverträge erforderlich.
            <br />
            <br />
            <strong>English Version</strong>
            <br />
            For the Tutor operations we store the following data: First name, last name, email address For the role
            „Student“ we additionally process: Date of birth, place of birth, country of birth, nationality These data
            is necessary to issue the respective contracts.
            <br />
            <br />
            <strong>Einwilligungserkärung / Statement of Consent</strong>
            <br />
            <p style={{ textAlign: 'justify' }}>
              Ich stimme zu, dass meine angegebenen personenbezogenen Daten für den Tutorbetrieb durch die Verwaltung
              der Technischen Universität verarbeitet werden. Ich kann meine Einwilligung jederzeit für die Zukunft
              widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
              Verarbeitung auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO berührt wird. Ihren Widerruf richten Sie bitte
              an tutorbetrieb@in.tum.de. Unter den gesetzlichen Voraussetzungen besteht ein Recht auf Auskunft, sowie
              auf Berichtigung oder Löschung oder auf Einschränkung der Verarbeitung oder eines Widerspruchsrechts gegen
              die Verarbeitung sowie des Rechts auf Datenübertragbarkeit. Es besteht zudem ein Beschwerderecht beim
              Bayerischen Landesbeauftragten für den Datenschutz. Bei Fragen können Sie Sich gerne an uns
              (tutorbetrieb@in.tum.de) oder an unseren Datenschutzbeauftragten (www.datenschutz.tum.de) wenden.
            </p>
            <strong>English Version</strong>
            <br />
            <p style={{ textAlign: 'justify' }}>
              I give my consent to the processing of the provided privacy relevant data for the tutor operation by the
              administration of the Technical University of Munich. I can revoke my agreement at any time for the
              future, without affecting the legitimacy of the data processing until the revocation on the basis of
              article 6 section 1 lit. a GDPR.
            </p>
            <p style={{ textAlign: 'justify' }}>
              In case of cancellation please direct your request to tutorbetrieb@in.tum.de. Among the legal
              preconditions there exists a right for disclosure, correction or deletion or for limitation of the
              processing or a right for revocation against the processing as well as the right for data transferability.
              In addition, there is a right for complaint at the Bavarian State Delegate for Data Protection. In case of
              questions feel free to contact us (tutorbetrieb@in.tum.de) or our data protection officer
              (www.datenschutz.tum.de).
            </p>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Datenschutz;
