import React from 'react';

function Filters({ selectedFilter, setFilter, options }) {
  return (
    <div style={{ marginRight: '1rem' }}>
      {options.map((option) => (
        <button
          key={option.value}
          className={selectedFilter === option.value ? 'btn btn-primary' : 'btn btn-light'}
          onClick={() => setFilter(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}

export default Filters;
