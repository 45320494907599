import axios from 'axios';

// Get contract by application ID
export const getContractByApplicationID = async (applicationId) => {
  try {
    const response = await axios.get(`/api/contract/application/user/${applicationId}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

// Get contract by course ID
export const getContractByCourseID = async (courseId) => {
  try {
    const response = await axios.get(`/api/contract/course/${courseId}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

// Get all previous contracts of a student
export const getAllContractsByStudentId = async (studentId) => {
  try {
    const response = await axios.get(`/api/contract/contract/userid/${studentId}`);
    return response.data || [];
  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch contracts');
  }
};
