import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getProfiles } from '../../actions/profileActions';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import PageHeader from '../ui/page-header/PageHeader';
import Filters from '../ui/table/Filters';
import Table from '../ui/table/Table';
import Spinner from '../ui/spinner/Spinner';
import SearchBar from '../ui/table/SearchBar';
import LinkButton from '../ui/button/LinkButton';
import moment from 'moment';

function Profiles(props) {
  const [fil, setFil] = useState('0');
  const dispatch = useDispatch();

  const { profiles, loading } = useSelector((state) => state.profile);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getProfiles());
  }, [dispatch]);

  const roles = [
    { value: '0', label: 'Alle' },
    { value: 'Admin', label: 'Admin' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Advisor', label: 'ÜbungsleiterInnen' },
    { value: 'Student', label: 'Studierende' },
    { value: 'RBG', label: 'ITO' },
  ];

  const betrachtenButton = (cell, row) => (
    <LinkButton
      to={{
        pathname: `/profile/${row._id}`,
      }}
      text={'Betrachten'}
      variant={'info'}
    />
  );

  let profileTable;

  let entries = profiles ? profiles : [];
  if (
    profiles !== null &&
    profiles !== undefined &&
    typeof profiles === 'object' &&
    profiles.constructor === Object &&
    Object.entries(profiles).length === 0
  ) {
    entries = [];
  }

  const newArray = entries.filter((el) => {
    if (!el.user) {
      return false;
    }

    switch (fil) {
      case '0':
        if (user.role !== 'Manager') {
          return true;
        }
        return el.user.role === 'Advisor' || el.user.role === 'Student';
      case 'Admin':
      case 'Advisor':
      case 'Student':
      case 'RBG':
      case 'Manager':
        return el.user.role === fil;
      default:
        return false;
    }
  });

  const defaultSorted = [
    {
      dataField: 'lastname',
      order: 'asc',
    },
  ];

  function dateFormat(value, row, index) {
    if (value) return moment(value).format('DD/MM/YYYY');
  }

  if (profiles === null || loading) {
    profileTable = <Spinner />;
  } else {
    if (profiles.length > 0) {
      const columns = [
        {
          dataField: 'lastname',
          text: 'Nachname',
          sort: true,
        },
        {
          dataField: 'firstname',
          text: 'Vorname',
          sort: true,
        },
        {
          dataField: 'user.email',
          text: 'Email',
          sort: true,
        },
        {
          dataField: 'matrikelnummer',
          text: 'Matrikelnummer',
          sort: true,
        },
        {
          dataField: 'date',
          text: 'Zuletzt bearbeitet',
          formatter: dateFormat,
          sort: true,
        },
        {
          dataField: 'dummyView',
          isDummyField: true,
          text: 'Betrachten',
          formatter: betrachtenButton,
        },
      ];

      profileTable = (
        <ToolkitProvider bootstrap4 keyField="_id" data={newArray} columns={columns} search>
          {(tableProps) => (
            <>
              <h6>Rollen Filter:</h6>
              <div className="d-flex flex-column flex-lg-row justify-content-between">
                <Filters selectedFilter={fil} setFilter={(value) => setFil(value)} options={roles} />
                <div>
                  <SearchBar {...tableProps.searchProps} />
                </div>
              </div>
              <hr />
              <Table baseProps={tableProps.baseProps} defaultSorted={defaultSorted} />
            </>
          )}
        </ToolkitProvider>
      );
    } else {
      profileTable = <h4>No profiles found...</h4>;
    }
  }

  return (
    <div className="profiles">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <PageHeader heading={'Benutzer Übersicht'} route={'/dashboard'} />
            {profileTable}
          </div>
        </div>
      </div>
    </div>
  );
}

Profiles.propTypes = {
  getProfiles: PropTypes.func.isRequired,
};

export default Profiles;
