import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { setCurrentUser } from '../../features/auth/authSlice';

function SSOLogin() {
  // state is needed, because useEffect is called after the redirect
  const [userIsSet, setUserIsSet] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');
    const role = query.get('role');
    const exp = query.get('exp');
    const firstname = query.get('firstname');
    const lastname = query.get('lastname');
    const matrikelnummer = query.get('matrikelnummer');
    const birthday = query.get('birthday').replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
    const countryofbirth = query.get('countryofbirth');
    const birthplace = query.get('birthplace');
    const currentfieldofstudy = query.get('currentfieldofstudy');

    if (id && role && exp && parseInt(exp) * 1000 > Date.now()) {
      localStorage.setItem('user', JSON.stringify({ id, role, exp }));
      dispatch(
        setCurrentUser({
          id,
          role,
          firstname,
          lastname,
          matrikelnummer,
          birthday,
          countryofbirth,
          birthplace,
          currentfieldofstudy,
        })
      );

      setUserIsSet(true);
    }
  }, [location, dispatch]);

  return userIsSet ? <Redirect to="/dashboard" /> : null;
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SSOLogin);
