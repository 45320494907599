import React from 'react';
import {
  faUserCircle,
  faGraduationCap,
  faUserTie,
  faQuestion,
  faLightbulb,
  faUniversity,
  faBoxTissue,
  faScroll,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import ActionList from './ActionList';

const StudentProfileActions = () => {
  const personalActions = [
    { route: '/edit-profile', text: 'Edit Profile', icon: faUserCircle },
    { route: '/add-experience', text: 'Add Experience', icon: faUserTie },
    { route: '/add-education', text: 'Add Education', icon: faGraduationCap },
    { route: '/faq', text: 'FAQ', icon: faQuestion },
    {
      route: '/tutor-information',
      text: 'General Information',
      icon: faLightbulb,
    },
  ];

  const studentAssistantActions = [
    {
      route: '/tutorapplication',
      text: 'Student Assistant Application',
      icon: faUniversity,
    },
    { route: '/myapplications', text: 'My Applications', icon: faBoxTissue },
    { route: '/mycontracts', text: 'My Contracts', icon: faScroll },
  ];

  const rentalOptionsActions = [
    {
      route: '/createrentalapplication',
      text: 'Apply for rental Hardware',
      icon: faScroll,
    },
    {
      route: '/studentrentalview',
      text: 'My Hardware Rentals',
      icon: faWrench,
    },
  ];

  return (
    <div>
      <ActionList items={personalActions} title="Personal Actions" help={true} />
      <ActionList items={studentAssistantActions} title="Student Assistant Actions" />
      <ActionList items={rentalOptionsActions} title="Rental Options" />
    </div>
  );
};

export default StudentProfileActions;
