import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import TextFieldGroup from '../ui/text-field/TextFieldGroup';
import { Row, FormGroup } from 'react-bootstrap';

import { getRentalstats, updateRentalstats } from '../../actions/rentalstatsActions';
import isEmpty from '../../utils/is-empty';
import PageHeader from '../ui/page-header/PageHeader';

class RentalstatsConfig extends Component {
  componentDidMount() {
    this.props.getRentalstats();
  }

  constructor(props) {
    super(props);
    this.state = {
      ipad: 0,
      mikrofon: 0,
      wacom: 0,
      webcam: 0,
      stativ: 0,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.rentalstats.rentalstats) {
      const rentalstats = nextProps.rentalstats.rentalstats;

      rentalstats.ipad = !isEmpty(rentalstats.ipad) ? rentalstats.ipad : 0;
      rentalstats.mikrofon = !isEmpty(rentalstats.mikrofon) ? rentalstats.mikrofon : 0;
      rentalstats.wacom = !isEmpty(rentalstats.wacom) ? rentalstats.wacom : 0;
      rentalstats.stativ = !isEmpty(rentalstats.stativ) ? rentalstats.stativ : 0;
      rentalstats.webcam = !isEmpty(rentalstats.webcam) ? rentalstats.webcam : 0;

      this.setState({
        ipad: rentalstats.ipad,
        mikrofon: rentalstats.mikrofon,
        wacom: rentalstats.wacom,
        stativ: rentalstats.stativ,
        webcam: rentalstats.webcam,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const rentalstatsData = {
      ipad: this.state.ipad,
      mikrofon: this.state.mikrofon,
      wacom: this.state.wacom,
      webcam: this.state.webcam,
      stativ: this.state.stativ,
    };
    this.props.updateRentalstats(rentalstatsData, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Leihobjektzahl bearbeiten`} route={'/rentalstats'} />
            <hr />
            <form onSubmit={this.onSubmit}>
              <Row>
                <FormGroup className="col-md-6">
                  <label htmlFor="inputGroupsize">Gesamtzahl iPads</label>
                  <TextFieldGroup
                    placeholder="iPads"
                    onChange={this.onChange}
                    value={this.state.ipad}
                    name="ipad"
                    error={errors.ipad}
                  />
                </FormGroup>
                <FormGroup className=" col-md-6">
                  <label htmlFor="inputTutorialhours">Gesamtzahl Mikrofone</label>
                  <TextFieldGroup
                    placeholder="Mikrofone"
                    onChange={this.onChange}
                    value={this.state.mikrofon}
                    name="mikrofon"
                    error={errors.mikrofon}
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className=" col-md-6">
                  <label htmlFor="inputGroupsize">Gesamtzahl Wacom Tablets</label>
                  <TextFieldGroup
                    placeholder="Wacom Tablets"
                    onChange={this.onChange}
                    value={this.state.wacom}
                    name="wacom"
                    error={errors.wacom}
                  />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <label htmlFor="inputTutorialhours">Gesamtzahl Stative</label>
                  <TextFieldGroup
                    placeholder="Stative"
                    onChange={this.onChange}
                    value={this.state.stativ}
                    name="stativ"
                    error={errors.stativ}
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className="col-md-6">
                  <label htmlFor="inputGroupsize">Gesamtzahl Webcams</label>
                  <TextFieldGroup
                    placeholder="Webcams"
                    onChange={this.onChange}
                    value={this.state.webcam}
                    name="webcam"
                    error={errors.webcam}
                  />
                </FormGroup>
              </Row>
              <input type="submit" value="Bestätigen" className="btn btn-info btn-block mt-4" />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

RentalstatsConfig.propTypes = {
  rentals: PropTypes.object.isRequired,
  rentalstats: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  rentals: state.rentals,
  rentalstats: state.rentalstats,
  auth: state.auth,
  errors: state.errors,
  deleteRental: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { getRentalstats, updateRentalstats })(withRouter(RentalstatsConfig));
