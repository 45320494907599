import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Moment from 'react-moment';
import ExperienceOverview from '../common/ExperienceOverview';
import EducationOverview from '../common/EducationOverview';
import countryList from '../countryselector/country-list';
import { getApplicationOfId, updateApplication } from '../../actions/applicationActions';

class AdvisorCourseApplicationView extends Component {
  state = {
    grade: '',
    details: '',
    errors: {},
    priority: '',
    courseid: '',
    lastname: '',
    firstname: '',
    matrikelnummer: '',
    currentfieldofstudy: '',
    nationality: '',
    nationality2: '',
    birthday: '',
    experience: [],
    education: [],
    email: '',
  };

  componentDidMount() {
    this.props.getApplicationOfId(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.application.application) {
      const application = nextProps.application.application;

      // Set default values using destructuring
      const {
        grade = '',
        details = '',
        priority = '',
        course: { _id: courseid = '' } = {},
        profile: {
          lastname = '',
          firstname = '',
          matrikelnummer = '',
          currentfieldofstudy = '',
          nationality = '',
          nationality2 = '',
          birthday = '',
          experience = [],
          education = [],
        } = {},
        user: { email = '' } = {},
      } = application;

      this.setState({
        grade,
        details,
        priority,
        courseid,
        lastname,
        firstname,
        matrikelnummer,
        currentfieldofstudy,
        nationality,
        nationality2,
        birthday,
        experience,
        education,
        email,
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    const applicationData = {
      grade: this.state.grade,
      details: this.state.details,
    };

    this.props.updateApplication(this.props.match.params.id, applicationData, this.props.history);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { user } = this.props.auth;
    const isAdminOrManager = user.role === 'Admin' || user.role === 'Manager';
    const linkTo =
      this.props.location?.fromAllAppView && isAdminOrManager
        ? '/allapplications-overview'
        : isAdminOrManager
          ? `/course-applications/${this.state.courseid}`
          : `/check-applications/${this.state.courseid}`;

    const nat = countryList.getLabel(this.state.nationality);
    const nat2 = this.state.nationality2 ? countryList.getLabel(this.state.nationality2) : '';

    return (
      <div className="Tutoroverview">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <Link to={linkTo} className="btn btn-light">
                back
              </Link>
              <h1 className="display-4 text-center">
                Application of {this.state.firstname} {this.state.lastname}
              </h1>
              <h5>Course Info</h5>
              <p className="lead text-muted">Grade: {this.state.grade} </p>
              <p className="lead text-muted">Details: {this.state.details} </p>
              <h5>Personal Info</h5>
              <p className="lead text-muted">Email: {this.state.email}</p>
              <p className="lead text-muted">Matrikelnummer: {this.state.matrikelnummer}</p>
              <p className="lead text-muted">Current Field Of Study: {this.state.currentfieldofstudy}</p>
              <p className="lead text-muted">
                Birthday: <Moment format="DD/MM/YYYY">{moment.utc(this.state.birthday)}</Moment>
              </p>
              <p className="lead text-muted">Nationality: {nat}</p>
              {nat2 && <p className="lead text-muted">Second Nationality: {nat2}</p>}
              <div>
                <ExperienceOverview experience={this.state.experience} />
                <EducationOverview education={this.state.education} />
              </div>
              <div style={{ marginBottom: '60px' }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdvisorCourseApplicationView.propTypes = {
  application: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  application: state.application,
  course: state.course,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getApplicationOfId,
  updateApplication,
})(AdvisorCourseApplicationView);
