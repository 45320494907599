import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { updateAccount, getProfile } from 'actions/profileActions';
import countryList from 'components/countryselector/country-list';
import { getAllGroups } from 'services/GroupService';
import PageHeader from 'components/ui/page-header/PageHeader';
import CustomSpinner from 'components/ui/spinner/Spinner';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import TempMultiSelect from 'components/ui/multi-select/TempMultiSelect';

const ChangeAccountType = ({ match, history }) => {
  const [state, setState] = useState({
    lastname: '',
    firstname: '',
    role: '',
    allGroups: [],
    currentGroupsSelected: [],
  });

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);
  const errors = useSelector((state) => state.errors);
  const auth = useSelector((state) => state.auth);
  const { currentGroupsSelected } = state;
  const loading = profile?.loading;
  const { user } = auth;

  useEffect(() => {
    dispatch(getProfile(match.params.id));
    getAllGroups().then((allGroups) => {
      setState((prevState) => ({ ...prevState, allGroups: allGroups?.data }));
    });
    if (countryList.data[0].value !== '') {
      countryList.setEmpty('Select a Country').getLabel('');
    }
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (errors) {
      setState((prevState) => ({ ...prevState, errors }));
    }
  }, [errors]);

  useEffect(() => {
    if (profile) {
      setState((prevState) => ({
        ...prevState,
        lastname: profile?.lastname,
        firstname: profile?.firstname,
        currentGroupsSelected: profile?.user?.group,
        role: profile?.user?.role,
      }));
    }
  }, [profile]);

  const onSubmit = (e) => {
    e.preventDefault();

    const accountData = {
      userID: profile.user._id,
      role: state.role,
      group: state.currentGroupsSelected,
    };

    dispatch(updateAccount(accountData, history));
  };

  const onChange = (e) => {
    const value = e.target.value;
    setState((prevState) => ({ ...prevState, role: value }));
  };

  const updateGroup = (selectedGroups) => {
    if (selectedGroups !== undefined) {
      const newGroups = selectedGroups.map((x) => x.value);
      setState((prevState) => ({
        ...prevState,
        currentGroupsSelected: newGroups,
      }));
    }
  };

  const accountTypeOptions = [
    { label: 'Student', value: 'Student' },
    { label: 'Admin', value: 'Admin' },
    { label: 'Manager', value: 'Manager' },
    { label: 'Übungsleiter', value: 'Advisor' },
    { label: 'ITO', value: 'RBG' },
  ];

  const filteredAccountTypeOptions = [
    { label: 'Student', value: 'Student' },
    { label: 'Übungsleiter', value: 'Advisor' },
  ];

  const groupOptions = state.allGroups.map((el) => ({
    label: el.title,
    value: el._id,
  }));

  const currentGroupsSelectedOptions = state.allGroups
    .filter((val) => currentGroupsSelected?.includes(val?._id))
    .map((val) => ({ label: val?.title, value: val?._id }));

  if (loading) {
    return <CustomSpinner />;
  }

  return (
    <div className={'create-profile'}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader
              heading={`Rolle ändern für ${state.firstname} ${state.lastname}`}
              route={`/profile/${match.params.id}`}
            />
            <form onSubmit={onSubmit}>
              <label htmlFor="accounttype">Rolle:</label>
              <SelectListGroup
                placeholder="Account Type"
                onChange={onChange}
                value={state.role}
                name="role"
                options={user.role === 'Manager' ? filteredAccountTypeOptions : accountTypeOptions}
                info="Rolle auswählen"
              />
              {(state.role === 'Manager' || state.role === 'Admin') && (
                <>
                  <label htmlFor="inputAdvisor">Departments:</label>
                  <TempMultiSelect
                    options={groupOptions}
                    isMulti={true}
                    setOptions={updateGroup}
                    defaultValue={currentGroupsSelectedOptions}
                    placeholder="Abteilung auswählen"
                  />
                </>
              )}
              <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
            </form>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

ChangeAccountType.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ChangeAccountType;
