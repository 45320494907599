import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUser, faEuroSign, faPen, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import 'react-quill/dist/quill.snow.css';
import './TutorInformationEdit.css';
import { Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

library.add(faClock, faUser, faEuroSign, faPen, faEnvelope);

const Block = Quill.import('blots/block');

class AddressBlot extends Block {}
AddressBlot.blotName = 'address';
AddressBlot.tagName = 'address';

Quill.register('formats/address', AddressBlot);

const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['address'],
  ],
};

const availableIcons = ['clock', 'user', 'euro-sign', 'pen', 'envelope'];

export default function TutorInformationEdit() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [title, setTitle] = useState('');
  const [icon, setIcon] = useState(null);
  const [content, setContent] = useState('');
  const [fileAttached, setFileAttached] = useState(false);
  const [lastFileName, setLastFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [fileDescription, setFileDescription] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fillData() {
      const res = await axios.get(`/api/tutor-information/entry/${id}`);
      const { title, icon, content, attachment } = res.data;
      setTitle(title);
      setIcon(icon);
      setContent(content);
      if (attachment) {
        setFileAttached(true);
        setLastFileName(attachment.filename);
        setFileDescription(attachment.description);
      }
      setLoading(false);
    }

    if (id !== 'new') {
      setLoading(true);
      fillData();
    } else {
      setLoading(false);
    }
  }, [id]);

  async function onSave() {
    const data = {
      title,
      icon,
      content,
    };

    let currentId = id;
    if (currentId === 'new') {
      const lang = new URLSearchParams(location.search).get('lang');
      const res = await axios.post(`/api/tutor-information/entry`, {
        lang,
        ...data,
      });
      currentId = res.data.id;
    } else {
      await axios.put(`/api/tutor-information/entry/${currentId}`, data);
    }

    if (fileAttached && file !== null) {
      const form = new FormData();
      form.append('description', fileDescription);
      form.append('file', file);

      await axios.put(`/api/tutor-information/entry/${currentId}/attachment`, form);
    }
    if (!fileAttached && lastFileName !== null) {
      await axios.delete(`/api/tutor-information/entry/${currentId}/attachment`);
    }

    history.push('/tutor-information');
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 m-auto d-grid gap-2 mb-3">
          <div>
            <Button variant="secondary" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </div>
          <div className="d-flex">
            <input
              className="fs-2"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              disabled={loading}
            />
            <DropdownButton
              className="ms-auto"
              title={icon !== null ? <FontAwesomeIcon icon={icon} size="2x" fixedWidth /> : 'no icon'}
            >
              <Dropdown.Item onClick={() => setIcon(null)}>no icon</Dropdown.Item>
              {availableIcons.map((icon) => (
                <Dropdown.Item key={icon} onClick={() => setIcon(icon)}>
                  <FontAwesomeIcon icon={icon} size="2x" fixedWidth />
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div>
            <ReactQuill theme="snow" value={content} onChange={setContent} modules={quillModules} readOnly={loading} />
          </div>
          <Form.Check
            id="fileAtached"
            label="Attach File"
            checked={fileAttached}
            onChange={(e) => setFileAttached(e.target.checked)}
            disabled={loading}
          />
          {fileAttached &&
            (lastFileName !== null ? (
              <div>
                <span className="font-monospace">{lastFileName}</span>
                <button className="btn btn-secondary ms-3" onClick={() => setLastFileName(null)}>
                  Replace
                </button>
              </div>
            ) : (
              <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
            ))}
          {fileAttached && (
            <Form.Group controlId="file-description">
              <Form.Label>File Description</Form.Label>
              <Form.Control value={fileDescription} onChange={(e) => setFileDescription(e.target.value)} />
            </Form.Group>
          )}
          <button className="btn btn-primary" onClick={onSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
