import React from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const NotificationList = ({ notifications, onClose }) => {
  return (
    <div className="position-absolute top-100 end-0 translate-bottom" style={{ zIndex: 4, width: '400px' }}>
      <ListGroup style={{ width: '100%' }}>
        {notifications.map((notification, index) => (
          <ListGroup.Item
            key={index}
            className="d-flex justify-content-between align-items-start"
            style={{ margin: 0, padding: 0 }}
          >
            <div className="w-100">
              <div className="text-white" style={{ backgroundColor: '#17a2b8' }}>
                <h6 className="p-2 w-100">{notification.title}</h6>
              </div>
              <p style={{ fontSize: '16px' }} className="px-2">
                {notification.content}
              </p>
            </div>
            <Button
              variant="link"
              className="text-decoration-none"
              style={{
                fontSize: '14px',
                backgroundColor: '#dc3545',
                color: 'white',
                borderRadius: '0px',
              }}
              onClick={() => onClose(notification._id)}
            >
              X
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationList;
