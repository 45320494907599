import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextFieldGroup from '../ui/text-field/TextFieldGroup';
import TextAreaFieldGroup from '../ui/text-field/TextAreaFieldGroup';
import { getGroupsByUserID } from '../../services/GroupService';
import { createTemplate } from '../../actions/mailActions';
import PageHeader from '../ui/page-header/PageHeader';
import MultiList from '../ui/multi-list/MultiList';

const CreateMailTemplate = ({ auth, errors, createTemplate }) => {
  const history = useHistory();

  const [state, setState] = useState({
    name: '',
    text: '',
    subject: '',
    allGroups: [],
    currentGroupsSelected: [],
  });

  const { name, text, subject, allGroups, currentGroupsSelected } = state;

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (errors) {
      setState((prevState) => ({
        ...prevState,
        errors,
      }));
    }
  }, [errors]);

  const fetchGroups = async () => {
    const result = await getGroupsByUserID(auth.user.id);
    const groupRetrieved = result?.data?.group || [];

    const groupOptions = groupRetrieved.map((group) => ({
      name: group.title,
      value: group._id,
    }));

    setState((prevState) => ({
      ...prevState,
      allGroups: groupOptions,
    }));
  };

  const updateGroup = (selectedGroups) => {
    setState((prevState) => ({
      ...prevState,
      currentGroupsSelected: selectedGroups,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateData = {
      name,
      text,
      subject,
      groups: currentGroupsSelected,
    };
    createTemplate(templateData, history);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="createMailTemplate">
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Vorlage Erstellen`} route={`/mail-overview`} />
            <form onSubmit={handleSubmit}>
              <TextFieldGroup
                placeholder="Vorlage Bezeichnung"
                onChange={handleChange}
                value={name}
                name="name"
                error={errors.name}
                info="Vorlage Bezeichnung"
              />
              <MultiList
                options={allGroups}
                setOptions={updateGroup}
                placeholder="Bitte Gruppe auswählen"
                error={errors.groups}
              />
              <small className="form-text text-muted">Gruppe</small>
              <br />
              <hr />
              <TextFieldGroup
                placeholder="Betreff"
                onChange={handleChange}
                value={subject}
                name="subject"
                error={errors.subject}
                info="Betreff"
              />
              <TextAreaFieldGroup
                placeholder="Text"
                rows="10"
                onChange={handleChange}
                value={text}
                name="text"
                error={errors.text}
                info="Text"
              />
              <input type="submit" value="Einreichen" className="btn btn-info btn-block mt-4" />
            </form>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

CreateMailTemplate.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { createTemplate })(withRouter(CreateMailTemplate));
