import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { addExperience } from '../../actions/profileActions';
import PageHeader from '../ui/page-header/PageHeader';
import TextFieldGroup from '../ui/text-field/TextFieldGroup';
import TextAreaFieldGroup from '../ui/text-field/TextAreaFieldGroup';

const AddExperience = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { errors } = useSelector((state) => state);

  const [experienceData, setExperienceData] = useState({
    company: '',
    title: '',
    location: '',
    from: '',
    to: '',
    current: false,
    description: '',
  });

  const { company, title, location, from, to, current, description } = experienceData;

  const onChange = (e) => {
    setExperienceData({ ...experienceData, [e.target.name]: e.target.value });
  };

  const onCheck = () => {
    setExperienceData({ ...experienceData, current: !current });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const expData = {
      company,
      title,
      location,
      from,
      to: current ? null : to,
      current,
      description,
    };

    dispatch(addExperience(expData, history));
  };

  return (
    <div className={'add-experience'}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Add experience`} route={'/dashboard'} />
            <p className="lead text-center">Add any job or position that you have had in the past or current</p>
            <small className="d-block pb-3">* = required fields</small>
            <form onSubmit={onSubmit}>
              <TextFieldGroup
                placeholder={'* Company'}
                onChange={onChange}
                value={company}
                name={'company'}
                error={errors.company}
              />
              <TextFieldGroup
                placeholder={'* Job title'}
                onChange={onChange}
                value={title}
                name={'title'}
                error={errors.title}
              />
              <TextFieldGroup
                placeholder="Location"
                onChange={onChange}
                value={location}
                name="location"
                error={errors.location}
                info="City or city & state suggested (eg. Boston, Ma)"
              />
              <h6>From Date</h6>
              <TextFieldGroup type={'date'} onChange={onChange} value={from} name={'from'} error={errors.from} />
              <h6>To Date</h6>
              <TextFieldGroup
                type={'date'}
                onChange={onChange}
                value={to}
                name={'to'}
                error={errors.to}
                disabled={current ? 'disabled' : ''}
              />
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name={'current'}
                  value={current}
                  checked={current}
                  onChange={onCheck}
                  id={'current'}
                />
                <label htmlFor="current" className="form-check-label">
                  Current Job
                </label>
              </div>
              <TextAreaFieldGroup
                placeholder={'Job Description'}
                onChange={onChange}
                value={description}
                name={'description'}
                error={errors.description}
                info={'Tell us about the position'}
              />
              <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

AddExperience.propTypes = {
  addExperience: PropTypes.func.isRequired,
};

export default AddExperience;
