import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { addEducation } from '../../actions/profileActions';
import PageHeader from '../ui/page-header/PageHeader';
import TextFieldGroup from '../ui/text-field/TextFieldGroup';
import TextAreaFieldGroup from '../ui/text-field/TextAreaFieldGroup';

const AddEducation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { errors } = useSelector((state) => state);

  const [educationData, setEducationData] = useState({
    school: '',
    degree: '',
    fieldofstudy: '',
    from: '',
    to: '',
    current: false,
    description: '',
  });

  const { school, degree, fieldofstudy, from, to, current, description } = educationData;

  const onChange = (e) => {
    setEducationData({ ...educationData, [e.target.name]: e.target.value });
  };

  const onCheck = () => {
    setEducationData({ ...educationData, current: !current });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const eduData = {
      school,
      degree,
      fieldofstudy,
      from,
      to: current ? null : to,
      current,
      description,
    };

    dispatch(addEducation(eduData, history));
  };

  return (
    <div className={'add-education'}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Add education`} route={'/dashboard'} />
            <p className="lead text-center">Add any school, bootcamp, etc that you have attended</p>
            <small className="d-block pb-3">* = required fields</small>
            <form onSubmit={onSubmit}>
              <TextFieldGroup
                placeholder={'* School'}
                onChange={onChange}
                value={school}
                name={'school'}
                error={errors.school}
              />
              <TextFieldGroup
                placeholder={'* Degree or Certification'}
                onChange={onChange}
                value={degree}
                name={'degree'}
                error={errors.degree}
              />
              <TextFieldGroup
                placeholder="* Field of Study"
                onChange={onChange}
                value={fieldofstudy}
                name="fieldofstudy"
                error={errors.fieldofstudy}
                info="Field of study"
              />
              <h6>From Date</h6>
              <TextFieldGroup type={'date'} onChange={onChange} value={from} name={'from'} error={errors.from} />
              <h6>To Date</h6>
              <TextFieldGroup
                type={'date'}
                onChange={onChange}
                value={to}
                name={'to'}
                error={errors.to}
                disabled={current ? 'disabled' : ''}
              />
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name={'current'}
                  value={current}
                  checked={current}
                  onChange={onCheck}
                  id={'current'}
                />
                <label htmlFor="current" className="form-check-label">
                  Current Institution
                </label>
              </div>
              <TextAreaFieldGroup
                placeholder={'Program Description'}
                onChange={onChange}
                value={description}
                name={'description'}
                error={errors.description}
                info={'Tell us about the program you were in'}
              />
              <input type="submit" value="Submit" className="btn btn-info btn-block mt-4 mb-4" />
              <br />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

AddEducation.propTypes = {
  addEducation: PropTypes.func.isRequired,
};

export default AddEducation;
