import { GET_RENTALSTATS, GET_DEVICEAVAILABILITY } from '../actions/types';

const initialState = {
  rentalstats: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RENTALSTATS:
      return {
        ...state,
        rentalstats: action.payload,
      };
    case GET_DEVICEAVAILABILITY:
      return {
        ...state,
        deviceavailability: action.payload,
      };
    default:
      return state;
  }
}
