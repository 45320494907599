import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRentals } from 'actions/rentalActions';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { faUserCircle, faChartPie } from '@fortawesome/free-solid-svg-icons';
import PageHeader from 'components/ui/page-header/PageHeader';
import Filters from 'components/ui/table/Filters';
import Table from 'components/ui/table/Table';
import LinkButton from 'components/ui/button/LinkButton';
import SearchBar from 'components/ui/table/SearchBar';
import { dateFormat } from 'utils/utils';

const RentalOverview = () => {
  const [fil, setFil] = useState('0');

  const dispatch = useDispatch();
  const rentals = useSelector((state) => state.rentals.rentals);

  useEffect(() => {
    dispatch(getRentals());
  }, []);

  const filterOptions = [
    { label: 'Alle', value: '0' },
    { label: 'HW angefragt', value: 'HW angefragt' },
    { label: 'HW ausgegeben', value: 'HW ausgegeben' },
    { label: 'Abgeschlossen', value: 'Abgeschlossen' },
  ];

  const columns = [
    {
      dataField: 'name',
      text: 'Nachname',
      sort: true,
    },
    {
      dataField: 'vorname',
      text: 'Vorname',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'rückgabe',
      text: 'Rückgabedatum',
      formatter: dateFormat,
      sort: true,
    },
    {
      dataField: 'leihobjekt.ipad.available',
      text: 'iPad',
      formatter: (value, row, index) => {
        if (value) {
          if (row.leihobjekt.ipad?.deviceno !== undefined) {
            return row.leihobjekt.ipad?.deviceno === '' ? 'X' : row.leihobjekt.ipad?.deviceno;
          }
        }
      },
      align: 'center',
      filterValue: (value, row) => {
        if (value) {
          return row.leihobjekt.ipad.deviceno;
        }
      },
      sort: true,
    },
    {
      dataField: 'leihobjekt.mikrofon.available',
      text: 'Mikrofon',
      formatter: (value, row, index) => {
        if (value) {
          if (row.leihobjekt.mikrofon?.deviceno !== undefined) {
            return row.leihobjekt.mikrofon?.deviceno === '' ? 'X' : row.leihobjekt.mikrofon?.deviceno;
          }
        }
      },
      align: 'center',
      filterValue: (value, row) => {
        if (value) {
          return row.leihobjekt.mikrofon.deviceno;
        }
      },
      sort: true,
    },
    {
      dataField: 'leihobjekt.wacom.available',
      text: 'Wacom',
      formatter: (value, row, index) => {
        if (value) {
          if (row.leihobjekt.wacom?.deviceno !== undefined) {
            return row.leihobjekt.wacom?.deviceno === '' ? 'X' : row.leihobjekt.wacom?.deviceno;
          }
        }
      },
      align: 'center',
      filterValue: (value, row) => {
        if (value) {
          return row.leihobjekt.wacom.deviceno;
        }
      },
      sort: true,
    },
    {
      dataField: 'leihobjekt.webcam.available',
      text: 'Webcam',
      formatter: (value, row, index) => {
        if (value) {
          if (row.leihobjekt.webcam?.deviceno !== undefined) {
            return row.leihobjekt.webcam?.deviceno === '' ? 'X' : row.leihobjekt.webcam?.deviceno;
          }
        }
      },
      align: 'center',
      filterValue: (value, row) => {
        if (value) {
          return row.leihobjekt.webcam.deviceno;
        }
      },
      sort: true,
    },
    {
      dataField: 'leihobjekt.stativ.available',
      text: 'Stativ',
      formatter: (value, row, index) => {
        if (value) {
          if (row.leihobjekt.stativ?.deviceno !== undefined) {
            return row.leihobjekt.stativ?.deviceno === '' ? 'X' : row.leihobjekt.stativ?.deviceno;
          }
        }
      },
      align: 'center',
      filterValue: (value, row) => {
        if (value) {
          return row.leihobjekt.stativ.deviceno;
        }
      },
      sort: true,
    },
    {
      dataField: 'date',
      text: 'Zuletzt bearbeitet',
      formatter: dateFormat,
      sort: true,
    },
    {
      dataField: 'lasthandle',
      text: 'Kürzel',
      sort: true,
    },

    {
      dataField: 'dummyView',
      isDummyField: true,
      text: 'Edit',
      formatter: betrachtenButton,
    },
  ];

  // Filter entries based on the selected filter
  const newArray = rentals
    ? rentals?.filter((el) => {
        if (fil === '0') return true;
        return el.status === fil;
      })
    : [];

  function betrachtenButton(cell, row) {
    return <LinkButton to={{ pathname: `/rental/${row._id}` }} text={`Edit`} variant={'info'} />;
  }

  return (
    <div className="container-fluid">
      <div className="container-fluid">
        <PageHeader heading={'Verleihübersicht'} route={'/dashboard'} />
        <ToolkitProvider bootstrap4 keyField="_id" data={newArray} columns={columns} search>
          {(props) => (
            <div>
              <div className="btn-group">
                <LinkButton
                  to={{ pathname: '/new-rental' }}
                  text={`Neue Ausleihe`}
                  variant={'info'}
                  icon={faUserCircle}
                />
                <LinkButton to={{ pathname: '/rentalstats' }} text={`Statistik`} variant={'info'} icon={faChartPie} />
              </div>

              <h6>Status Filter:</h6>
              <div style={{ display: 'flex' }}>
                <Filters selectedFilter={fil} setFilter={setFil} options={filterOptions} />
                <div className="ml-2">
                  <SearchBar {...props.searchProps} />
                </div>
              </div>
              <hr />
              <Table baseProps={props.baseProps} />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
};

export default RentalOverview;
