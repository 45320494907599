import {
  GET_NOTIFICATION_TEMPLATES,
  GET_NOTIFICATION_TEMPLATE,
  NOTIFICATION_TEMPLATES_LOADING,
} from '../actions/types';

const initialState = {
  notificationTemplates: [],
  notificationTemplate: null,
  notificationTemplatesLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_TEMPLATES_LOADING:
      return {
        ...state,
        notificationTemplatesLoading: true,
      };
    case GET_NOTIFICATION_TEMPLATES:
      return {
        ...state,
        notificationTemplates: action.payload,
        notificationTemplatesLoading: false,
      };
    case GET_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        notificationTemplate: action.payload,
        notificationTemplatesLoading: false,
      };
    default:
      return state;
  }
}
