import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import '../tutor-information/TutorInformation.css';
import ConfirmButton from '../common/ConfirmButton.js';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import PageHeader from '../ui/page-header/PageHeader';
import LinkButton from '../ui/button/LinkButton';

const SectionIcon = ({ icon }) => <FontAwesomeIcon icon={icon} size="7x" pull="right" fixedWidth />;

async function persistEntryOrder(order) {
  await axios.put(`/api/other/faqs/reorder/`, order);
}

function InfoEntry({ entry, index, edit, onCollectionUpdated }) {
  const onDelete = useCallback(
    async (id) => {
      await axios.delete(`/api/other/faq/${id}`);
      onCollectionUpdated();
    },
    [onCollectionUpdated]
  );

  return (
    <Draggable key={entry._id} draggableId={entry._id} index={index}>
      {(provided, snapshot) => (
        <Card ref={provided.innerRef} {...provided.draggableProps} className="mb-3">
          <Card.Body>
            {entry.icon && <SectionIcon icon={entry.icon} />}
            <h2 className="card-title">{entry.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: entry.content }} />
          </Card.Body>
          {edit && (
            <Card.Footer {...provided.dragHandleProps}>
              <div className="d-flex justify-content-between">
                <LinkButton
                  to={{
                    pathname: `/faq/edit/${entry._id}`,
                  }}
                  text={'Edit'}
                  variant={'primary'}
                />
                <div className="mt-2">
                  <FontAwesomeIcon icon={faEllipsisV} className="fas text-secondary" />
                  <FontAwesomeIcon icon={faEllipsisV} className="fas mx-1 text-secondary" />
                  <FontAwesomeIcon icon={faEllipsisV} className="fas text-secondary" />
                </div>
                <ConfirmButton variant="danger" onClick={() => onDelete(entry._id)} className="float-end">
                  Delete
                </ConfirmButton>
              </div>
            </Card.Footer>
          )}
        </Card>
      )}
    </Draggable>
  );
}

function FAQDetail(props) {
  const [entries, setEntries] = useState([]);
  const toggle = useSelector((state) => state.trigger);

  const fetchEntries = async () => {
    try {
      const res = await axios.get(`/api/other/faqs/all`);
      res.data.sort((a, b) => (a.position !== undefined && b.position !== undefined ? a.position - b.position : 0));
      setEntries(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEntries();
  }, [toggle]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const reorderedEntries = Array.from(entries);
      const [moved] = reorderedEntries.splice(result.source.index, 1);
      reorderedEntries.splice(result.destination.index, 0, moved);

      setEntries(reorderedEntries);

      const entryOrder = reorderedEntries.map((entry, index) => ({
        id: entry._id,
        pos: index,
      }));
      persistEntryOrder(entryOrder);
    },
    [entries]
  );

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto mb-3">
            <PageHeader heading={'FAQ'} route={'/dashboard'} />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {entries.map((entry, index) => (
                      <InfoEntry
                        key={entry._id}
                        entry={entry}
                        index={index}
                        edit={props.edit}
                        onCollectionUpdated={fetchEntries}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="d-flex justify-content-center">
              {props.edit && <LinkButton to={{ pathname: `/faq/edit/new` }} text={'+ Add New'} variant={'primary'} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

FAQDetail.propTypes = {
  edit: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  edit: state.auth.user.role === 'Admin',
});

export default connect(mapStateToProps)(FAQDetail);
