import React, { useState, useEffect } from 'react';
import { Row, FormGroup } from 'react-bootstrap';
import { useHistory } from 'react-router';

// UI Components
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import Spinner from 'components/ui/spinner/Spinner';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import PageHeader from 'components/ui/page-header/PageHeader';

// Services
import { getGroupByID, updateGroupByID } from 'services/GroupService';

function EditGroup(props) {
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState(null);
  const [errors, setErrors] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (group === null) {
      loadGroups();
    }
  }, [group]);

  const departmentOptions = [
    { label: 'School Office (CIT)', value: 'School Office (CIT)' },
    { label: 'Dep. of Computer Science', value: 'Dep. of Computer Science' },
    {
      label: 'Dep. of Computer Engineering',
      value: 'Dep. of Computer Engineering',
    },
    {
      label: 'Dep. of Electrical Engineering',
      value: 'Dep. of Electrical Engineering',
    },
    { label: 'Dep. of Mathematics', value: 'Dep. of Mathematics' },
  ];

  const loadGroups = async () => {
    setLoading(true);
    const result = await getGroupByID(props.match.params.id);
    setGroup(result?.data);
    setLoading(false);
  };

  const handleChange = (e) => {
    setGroup({ ...group, [e.target.name]: e.target.value });
  };

  const groupValidator = (group) => {
    let validationStatus = true;
    if (group.title === '') {
      setErrors({ ...errors, ['title']: 'Title Field cannot be empty' });
      validationStatus = false;
    }
    if (group.email === '') {
      setErrors({ ...errors, ['email']: 'Email Field cannot be empty' });
      validationStatus = false;
    }
    if (group.sapOrgID === '') {
      setErrors({ ...errors, ['sapOrgID']: 'SAP-Nr Field cannot be empty' });
      validationStatus = false;
    }
    return validationStatus;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (groupValidator(group)) {
      await updateGroupByID(props.match.params.id, group);
      history.push('/group-overview');
    }
  };

  return (
    <div className={'create-profile'}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Edit Group`} route={'/group-overview'} />
            {loading ? (
              <Spinner />
            ) : (
              <form onSubmit={handleSubmit}>
                <label htmlFor="title">* Lehrstuhl/Professur/Einrichtung:</label>
                <TextFieldGroup
                  placeholder="Lehrstuhl/Professur/Einrichtung"
                  onChange={handleChange}
                  value={group?.title}
                  name="title"
                  error={errors?.title}
                />

                <Row>
                  <FormGroup className="col-md-6">
                    <label htmlFor="title">* Ansprechpartner Vorname:</label>
                    <TextFieldGroup
                      placeholder="Ansprechpartner Vorname"
                      onChange={handleChange}
                      value={group?.AnsprechpartnerFirstName}
                      name="AnsprechpartnerFirstName"
                      error={errors?.AnsprechpartnerFirstName}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6">
                    <label htmlFor="title">* Ansprechpartner Nachname:</label>
                    <TextFieldGroup
                      placeholder="Ansprechpartner Nachname"
                      onChange={handleChange}
                      value={group?.AnsprechpartnerLastName}
                      name="AnsprechpartnerLastName"
                      error={errors?.AnsprechpartnerLastName}
                    />
                  </FormGroup>
                </Row>

                <label htmlFor="email">* Email:</label>
                <TextFieldGroup
                  placeholder="Email"
                  onChange={handleChange}
                  value={group?.email}
                  name="email"
                  error={errors?.email}
                />

                <label htmlFor="sapOrgID">* SAP-Nr:</label>
                <TextFieldGroup
                  placeholder="SAP-Nr"
                  onChange={handleChange}
                  value={group?.sapOrgID}
                  name="sapOrgID"
                  error={errors?.sapOrgID}
                />

                <label htmlFor="telefonnummer">Telefonnummer:</label>
                <TextFieldGroup
                  placeholder="Telefonnummer"
                  onChange={handleChange}
                  value={group?.telefonnummer}
                  name="telefonnummer"
                />

                <label htmlFor="department">Department:</label>
                <SelectListGroup
                  placeholder={'Department'}
                  onChange={handleChange}
                  value={group?.department}
                  name="department"
                  options={departmentOptions}
                />

                <label htmlFor="postkurzel">Postkurzel:</label>
                <TextFieldGroup
                  placeholder="Postkurzel"
                  onChange={handleChange}
                  value={group?.postkurzel}
                  name="postkurzel"
                />
                <input type="submit" value="Submit" className="btn btn-info btn-block mt-4 mb-2" />
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditGroup;
