import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StudentProfileActions from './StudentProfileActions';
import AdminProfileActions from './AdminProfileActions';
import ManagerProfileActions from './ManagerProfileActions';
import AdvisorActions from './AdvisorActions';
import Experience from './Experience';
import Education from './Education';
import axios from 'axios';
import Spinner from '../ui/spinner/Spinner';

import { getCurrentProfile, clearForDashboard } from '../../actions/profileActions';
import LinkButton from '../ui/button/LinkButton';
import Notification from '../ui/notification/Notification';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
      role: '',
      fetch_role_isLoading: false,
    };
    this.fetchUserRole = this.fetchUserRole.bind(this);
  }

  fetchUserRole() {
    this.setState({ fetch_role_isLoading: true });

    axios
      .get('/api/auth/role')
      .then((res) => {
        if (res.status >= 400) {
          throw new Error('Server responds with error!');
        }
        return res.data;
      })
      .then(
        (userdata) => {
          this.setState({
            role: userdata.role,
            fetch_role_isLoading: false,
          });
        },
        (err) => {
          this.setState({
            err,
            fetch_role_isLoading: false,
          });
        }
      );
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    this.props.clearForDashboard();
    this.fetchUserRole();
  }

  render() {
    const { profile, loading } = this.props.profile;
    let { role, err, fetch_role_isLoading } = this.state;
    if (err) {
      return <div> {err.message} </div>;
    }

    let dashboardContent;

    var aufenthaltToolTipp;
    var stipendiumToolTipp;
    if (profile) {
      if (profile.aufenthaltend) {
        if (new Date(profile.aufenthaltend) < Date.now()) {
          aufenthaltToolTipp = <h3 className="text-danger">Aufenthaltstitel abgelaufen!</h3>;
        }
      }
    }

    if (profile) {
      if (profile.stipendiumend) {
        if (new Date(profile.stipendiumend) < Date.now() && profile.stipendiumend) {
          stipendiumToolTipp = <h3 className="text-danger">Stipendium abgelaufen!</h3>;
        }
      }
    }

    if (profile === null || loading || fetch_role_isLoading) {
      dashboardContent = <Spinner />;
    } else {
      //Check if logged in user has profile data
      if (Object.keys(profile).length > 0) {
        if (role === 'Student') {
          var experience;
          var education;
          if (profile.experience.length !== 0 || profile.education.length !== 0) {
            experience = <Experience experience={profile.experience} />;
            education = <Education education={profile.education} />;
          } else {
            experience = <h3>If you add any experience or education it will show up here</h3>;
          }

          dashboardContent = (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="lead text-muted" style={{ flex: 1 }}>
                  Welcome {profile.firstname}
                </p>
                <Notification notifications={profile.notifications} />
              </div>

              {aufenthaltToolTipp}
              {stipendiumToolTipp}
              <StudentProfileActions />
              <hr />
              <div>
                {profile.experience.length !== 0 ? experience : null}
                {profile.education.length !== 0 ? education : null}
              </div>
              <div style={{ marginBottom: '60px' }} />
            </div>
          );
        } else if (role === 'Admin') {
          dashboardContent = (
            <div>
              <p className="lead text-muted">Willkommen {profile.firstname}</p>
              <AdminProfileActions />
            </div>
          );
        } else if (role === 'Manager') {
          dashboardContent = (
            <div>
              <p className="lead text-muted">Willkommen {profile.firstname}</p>
              <ManagerProfileActions />
            </div>
          );
        } else if (role === 'Advisor') {
          dashboardContent = (
            <div>
              <p className="lead text-muted">Welcome {profile.firstname}</p>
              <AdvisorActions />
            </div>
          );
        } else if (role === 'RBG') {
          this.props.history.push('/rentals-overview');
        }
      } else {
        // User is logged in but hast no profile
        if (role === 'Student') {
          dashboardContent = (
            <div>
              <p>You have not yet setup a profile, please add some info</p>
              <LinkButton to={{ pathname: '/terms' }} text={`Create Profile`} variant={'info'} />
            </div>
          );
        } else {
          dashboardContent = (
            <div>
              <p>You have not yet setup a profile, please add some info</p>
              <LinkButton
                to={{
                  pathname: '/create-advisorprofile',
                }}
                text={'Create Profile'}
                variant={'info'}
              />
            </div>
          );
        }
      }
    }

    return (
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="display-4">Dashboard </h1>
              {dashboardContent}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  clearForDashboard: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  clearForDashboard,
})(Dashboard);
