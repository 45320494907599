import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getContractOfID } from '../../actions/contractActions';
import { getCurrentProfile } from '../../actions/profileActions';
import { downloadPdf } from '../../actions/formsActions';
import isEmpty from '../../utils/is-empty';
import aufenthaltfreieCountries from '../common/AufenthaltCountries';
import moment from 'moment';
import ReadConfirmation from '../tutor-information/ReadConfirmation';
import LanguageSelector from '../common/LanguageSelector.js';
import PageHeader from '../ui/page-header/PageHeader';
import LinkButton from '../ui/button/LinkButton';

class ViewContract extends Component {
  componentDidMount() {
    this.props.getContractOfID(this.props.match.params.id);
    this.props.getCurrentProfile();
  }

  constructor(props) {
    super(props);
    this.state = {
      contractstart: '',
      contractstart2: '',
      aufenthaltend: '',
      contractend: '',
      contractend2: '',
      hours: '',
      hours2: '',
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.contract.contract) {
      const contract = nextProps.contract.contract;

      let useraufenthaltend = nextProps.contract.contract.profile?.aufenthaltend;
      if (useraufenthaltend === null || useraufenthaltend === undefined) {
        useraufenthaltend = 'Missing';
        if (contract.profile?.nationality) {
          if (
            aufenthaltfreieCountries.indexOf(contract.profile.nationality) !== -1 ||
            aufenthaltfreieCountries.indexOf(contract.profile.nationality2) !== -1
          ) {
            useraufenthaltend = 'Not necessary';
          }
        }
      } else {
        useraufenthaltend = 'Not necessary';
      }

      //If contract field doesn't exist, make empty string
      contract.contractstart = !isEmpty(contract.contractstart) ? contract.contractstart : '';
      contract.contractstart2 = !isEmpty(contract.contractstart2) ? contract.contractstart2 : '';
      contract.contractend = !isEmpty(contract.contractend) ? contract.contractend : '';
      contract.contractend2 = !isEmpty(contract.contractend2) ? contract.contractend2 : '';
      contract.hours = !isEmpty(contract.hours) ? contract.hours : '';
      contract.hours2 = !isEmpty(contract.hours2) ? contract.hours2 : '';

      //Set component fields state
      this.setState({
        contractstart: contract.contractstart,
        contractstart2: contract.contractstart2,
        contractend: contract.contractend,
        contractend2: contract.contractend2,
        hours: contract.hours,
        hours2: contract.hours2,
        aufenthaltend: useraufenthaltend,
      });
    }
  }

  onDownloadClick(name) {
    const formData = {
      name: name,
    };
    this.props.downloadPdf(formData);
  }

  onInformationRead() {
    this.props.getContractOfID(this.props.match.params.id);
  }

  render() {
    if (this.props.contract?.contract && !this.props.contract.contract.informationwasread) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <LinkButton
                to={{
                  pathname: '/mycontracts',
                }}
                text={'back'}
                variant={'light'}
              />
              <LanguageSelector
                component={ReadConfirmation}
                contractId={this.props.match.params.id}
                onInformationRead={this.onInformationRead.bind(this)}
              />
            </div>
          </div>
        </div>
      );
    }

    var contractdata = {};
    var studentStipendiumend = '';
    if (this.props.contract.contract) {
      contractdata = this.props.contract.contract;
      studentStipendiumend = this.props.contract.contract.profile?.stipendiumend;
    }

    var contractdata1 = (
      <div>
        <h5>Tutor-Contract</h5>
        <p className="lead text-muted">Contract Start: {moment(this.state.contractstart).format('DD/MM/YYYY')}</p>
        <p className="lead text-muted">Contract End: {moment(this.state.contractend).format('DD/MM/YYYY')}</p>
        <p className="lead text-muted">Weekly Hours: {this.state.hours} </p>
      </div>
    );

    var contractdata2;
    if (this.state.contractstart2) {
      var contractdata2 = (
        <div>
          <hr />
          <p className="lead text-muted">Contract Start: {moment(this.state.contractstart2).format('DD/MM/YYYY')}</p>
          <p className="lead text-muted">Contract End: {moment(this.state.contractend2).format('DD/MM/YYYY')}</p>
          <p className="lead text-muted">Weekly Hours: {this.state.hours2} </p>
        </div>
      );
    }

    const englishFormatter = (ele) => {
      if (ele === 'Fehlt') {
        return 'Missing';
      } else if (ele === 'Liegt vor' || ele === 'Liegt bei') {
        return 'Already filed';
      } else if (ele === 'Bereits Vorhanden') {
        return 'Already available';
      } else if (ele === 'Kein Bedarf') {
        return 'Not necessary';
      } else {
        return ele;
      }
    };

    var coursename = '';
    var coursesem = '';
    var immatrikulation2row;
    if (this.props.contract.contract) {
      coursename = this.props.contract.contract.course.metacourse.name;
      coursesem = this.props.contract.contract.course.semester.name;

      if (this.props.contract.contract.immatrikulationsbescheinigung2) {
        immatrikulation2row = (
          <tr
            className={`${contractdata.immatrikulationsbescheinigung2 === 'Fehlt' ? 'table-danger' : 'table-success'}`}
          >
            <th scope="row">Immatrikulationsbescheinigung next semester</th>
            <td>{englishFormatter(contractdata.immatrikulationsbescheinigung2)}</td>
            <td></td>
          </tr>
        );
      }
    }

    var immatrikulation0row;
    if (this.props.contract.contract) {
      coursename = this.props.contract.contract.course.metacourse.name;
      coursesem = this.props.contract.contract.course.semester.name;

      if (this.props.contract.contract.immatrikulationsbescheinigung0) {
        immatrikulation0row = (
          <tr
            className={`${contractdata.immatrikulationsbescheinigung0 === 'Fehlt' ? 'table-danger' : 'table-success'}`}
          >
            <th scope="row">Immatrikulationsbescheinigung previous semester</th>
            <td>{englishFormatter(contractdata.immatrikulationsbescheinigung0)}</td>
            <td></td>
          </tr>
        );
      }
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <PageHeader heading={`Contract for ${coursename}, ${coursesem}`} route={'/mycontracts'} />
            {contractdata1}
            {contractdata2}
            <h4 className={'text-center'}>Please hand in the following missing documents</h4>
            <h6 className={'text-center text-danger'}>
              *This document has to be submitted with an{' '}
              <b>
                <u>original signature</u>
              </b>{' '}
              (printouts of digital signatures are <u>not accepted</u>)
            </h6>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Form</th>
                  <th scope="col">Received</th>
                  <th scope="col">Download</th>
                  <th scope="col" className="w-auto">
                    Translation Aid
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className={`${this.state.aufenthaltend === 'Missing' ? 'table-danger' : 'table-success'}`}>
                  <th scope="row">
                    Expiration date of residence permit:
                    <br />
                    Note: Please add it in your edit profile section in homepage
                  </th>
                  <td>{englishFormatter(this.state.aufenthaltend)}</td>
                  <td></td>
                  <td className="table-light"></td>
                </tr>
                <tr className={`${contractdata.versicherungspflicht === 'Fehlt' ? 'table-danger' : 'table-success'}`}>
                  <th scope="row">
                    Determination of insurance obligation *
                    <br />
                    Note: Please leave the field “Rentenversicherungsnummer” (pension insurance number) empty in case
                    TUM is your first German employer
                  </th>
                  <td>{englishFormatter(contractdata.versicherungspflicht)}</td>
                  <td>
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'Versicherungspflicht')}
                      className="btn btn-primary"
                    >
                      Download
                    </button>
                  </td>
                  <td className="table-light">
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'Versicherungspflicht_translated')}
                      className="btn btn-info mx-2"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr className={`${contractdata.scientology === 'Fehlt' ? 'table-danger' : 'table-success'}`}>
                  <th scope="row">Questionnaire on Scientology *</th>
                  <td>{englishFormatter(contractdata.scientology)}</td>
                  <td>
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'Scientology')}
                      className="btn btn-primary"
                    >
                      Download
                    </button>
                  </td>
                  <td className="table-light">
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'Scientology_translated')}
                      className="btn btn-info mx-2"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr className={`${contractdata.verfassungstreue === 'Fehlt' ? 'table-danger' : 'table-success'}`}>
                  <th scope="row">Questionnaire on Allegiance to the Constitution *</th>
                  <td>{englishFormatter(contractdata.verfassungstreue)}</td>
                  <td>
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'Verfassungstreue')}
                      className="btn btn-primary"
                    >
                      Download
                    </button>
                  </td>
                  <td className="table-light">
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'Verfassungstreue_translated')}
                      className="btn btn-info mx-2"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                {immatrikulation0row}
                <tr
                  className={`${
                    contractdata.immatrikulationsbescheinigung === 'Fehlt' ? 'table-danger' : 'table-success'
                  }`}
                >
                  <th scope="row">Enrolment certificate</th>
                  <td>{englishFormatter(contractdata.immatrikulationsbescheinigung)}</td>
                  <td></td>
                  <td className="table-light"></td>
                </tr>
                {immatrikulation2row}
                <tr className={`${contractdata.aufenthaltstitel === 'Fehlt' ? 'table-danger' : 'table-success'}`}>
                  <th scope="row">Copy of residence permit with permission to work as a student assistant</th>
                  <td>{englishFormatter(contractdata.aufenthaltstitel)}</td>
                  <td></td>
                  <td className="table-light"></td>
                </tr>
                <tr
                  className={`${
                    contractdata.personalbogenbezuegestelle === 'Fehlt' ? 'table-danger' : 'table-success'
                  }`}
                >
                  <th scope="row">Staff questionnaire of the LfF (Finance Office) *</th>
                  <td>{englishFormatter(contractdata.personalbogenbezuegestelle)}</td>
                  <td>
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'PersonalbogenBezuegestelle')}
                      className="btn btn-primary"
                    >
                      Download
                    </button>
                  </td>
                  <td className="table-light">
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'PersonalbogenBezuegestelle_translated')}
                      className="btn btn-info mx-2"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr
                  className={`${contractdata.personalbogenstudierende === 'Fehlt' ? 'table-danger' : 'table-success'}`}
                >
                  <th scope="row">
                    Personal information form (Students) * Note: In case of prior employments at German Universities
                    and/or German research institutes, a proof of employment (a certificate or contract copy) has to be
                    submitted
                  </th>
                  <td>{englishFormatter(contractdata.personalbogenstudierende)}</td>
                  <td>
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'PersonalbogenStudierende')}
                      className="btn btn-primary"
                    >
                      Download
                    </button>
                  </td>
                  <td className="table-light">
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'PersonalbogenStudierende_translated')}
                      className="btn btn-info mx-2"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr className={`${contractdata.steuerId === 'Fehlt' ? 'table-danger' : 'table-success'}`}>
                  <th scope="row">Tax ID number</th>
                  <td>{englishFormatter(contractdata.steuerId)}</td>
                  <td></td>
                  <td className="table-light"></td>
                </tr>

                <tr
                  className={`${
                    contractdata.stipendium === 'Fehlt' && studentStipendiumend === 'Yes'
                      ? 'table-danger'
                      : 'table-success'
                  }`}
                >
                  <th scope="row">Scholarship certificate *</th>
                  <td>{studentStipendiumend == 'Yes' ? englishFormatter(contractdata.stipendium) : 'Not required'}</td>
                  <td>
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'Stipendiumsbescheinigung')}
                      className="btn btn-primary"
                    >
                      Download
                    </button>
                  </td>
                  <td className="table-light">
                    <button
                      type="button"
                      onClick={this.onDownloadClick.bind(this, 'Stipendiumsbescheinigung_translated')}
                      className="btn btn-info mx-2"
                    >
                      Download
                    </button>
                  </td>
                </tr>

                <tr className={`${contractdata.abschlusszeugnis === 'Fehlt' ? 'table-danger' : 'table-success'}`}>
                  <th scope="row">Bachelor certificate</th>
                  <td>{englishFormatter(contractdata.abschlusszeugnis)}</td>
                  <td></td>
                  <td className="table-light"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

ViewContract.propTypes = {
  getContractOfID: PropTypes.func.isRequired,
  contract: PropTypes.object.isRequired,
  downloadPdf: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  contract: state.contract,
});

export default connect(mapStateToProps, {
  getContractOfID,
  downloadPdf,
  getCurrentProfile,
})(withRouter(ViewContract));
