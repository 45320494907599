import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getCourseById } from '../../actions/courseActions';
import { getContractsForCourse } from '../../actions/contractActions';
import PageHeader from '../ui/page-header/PageHeader';

function BudgetControl(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourseById(props.match.params.id));
    dispatch(getContractsForCourse(props.match.params.id));
  }, [dispatch, props.match.params.id]);

  // Coursename and Semester
  const course = useSelector((state) => state.course.course);
  const coursename = course?.metacourse?.name || '';
  const coursesem = course?.semester?.name || '';

  const diff_weeks = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    return Math.abs(Math.round(diff));
  };

  var numberContracts = 0;
  var overallcontracthours = 0;
  var overallcontractweeklyhours = 0;
  const contracts = useSelector((state) => state.contract.contracts);

  if (contracts) {
    numberContracts = contracts.length;
    contracts.forEach((element) => {
      // Wochenlänge berechnen und das mal Stunden in den Wochen
      var weeks1 = 0;
      var weeks2 = 0;
      if (element.contractstart && element.contractend) {
        weeks1 = diff_weeks(new Date(element.contractstart), new Date(element.contractend));
      }

      if (element.contractstart2 && element.contractend2) {
        weeks2 = diff_weeks(new Date(element.contractstart2), new Date(element.contractend2));
      }
      var hours = element.hours ? element.hours : 0;
      var hours2 = element.hours2 ? element.hours : 0;

      var allhours = weeks1 * hours + weeks2 * hours2;
      overallcontracthours += allhours;
      overallcontractweeklyhours += hours;
    });
  }

  // Numbers for the course
  const overallweeklyhours = course?.overallweeklyhours || 0;
  const overallhours = course?.overallhours || 0;

  // className for Table
  var tableClassName = 'table';
  var hoursTooltipp;
  if (overallcontracthours > overallhours) {
    hoursTooltipp = <h3 className={'text-danger text-center'}>Vertragsstunden zu hoch</h3>;
    tableClassName = 'table table-danger';
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <PageHeader
            heading={`Budgetübersicht für ${coursename} in ${coursesem}`}
            route={`/course-applications/${props.match.params.id}`}
          />
          {hoursTooltipp}
          <table className={tableClassName}>
            <thead>
              <tr>
                <th scope="col">Anzahl Verträge</th>
                <th scope="col">Verträge Gesamtstunden</th>
                <th scope="col">Veranstaltung Gesamtstunden</th>
                <th scope="col">Verträge Gesamtwochenstunden</th>
                <th scope="col">Veranstaltung Gesamtwochenstunden</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{numberContracts}</td>
                <td>{overallcontracthours}</td>
                <td>{overallhours}</td>
                <td>{overallcontractweeklyhours}</td>
                <td>{overallweeklyhours}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

BudgetControl.propTypes = {
  match: PropTypes.object.isRequired,
};

export default BudgetControl;
