import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  semesters: [],
  semester: {},
  errors: null,
  isLoading: false,
};

// Thunk for getting all semesters
export const getSemesters = createAsyncThunk('semester/getSemesters', async (_, { dispatch }) => {
  try {
    const res = await axios.get('/api/semester/');
    dispatch(semesterSlice.actions.setSemesters(res.data));
  } catch (error) {
    dispatch(semesterSlice.actions.setErrors(error.response.data));
  }
});

// Thunk for getting semester by ID
export const getSemesterById = createAsyncThunk('semester/getSemesterById', async (id, { dispatch }) => {
  try {
    const res = await axios.get(`/api/semester/${id}`);
    dispatch(semesterSlice.actions.setSemester(res.data));
  } catch (error) {
    dispatch(semesterSlice.actions.setErrors(error.response.data));
  }
});

// Thunk for creating a semester
export const createSemester = createAsyncThunk('semester/createSemester', async (semesterData, { dispatch }) => {
  try {
    const res = await axios.post('/api/semester', semesterData);
    dispatch(semesterSlice.actions.setErrors(null));
  } catch (error) {
    dispatch(semesterSlice.actions.setErrors(error.response.data));
    throw error;
  }
});

// Thunk for editing a semester
export const editSemester = createAsyncThunk('semester/editSemester', async ({ id, semesterData }, { dispatch }) => {
  try {
    const res = await axios.post(`/api/semester/${id}`, semesterData);
    dispatch(semesterSlice.actions.setErrors(null));
  } catch (error) {
    dispatch(semesterSlice.actions.setErrors(error.response.data));
    throw error;
  }
});

// Create a slice
const semesterSlice = createSlice({
  name: 'semester',
  initialState,
  reducers: {
    setSemesters: (state, action) => {
      state.semesters = action.payload;
    },
    setSemester: (state, action) => {
      state.semester = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    clearSemesters: (state) => {
      state.semesters = [];
      state.errors = null;
    },
    clearSemester: (state) => {
      state.semester = {};
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSemesters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSemesters.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getSemesters.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSemesterById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSemesterById.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getSemesterById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createSemester.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSemester.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createSemester.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editSemester.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editSemester.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(editSemester.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setSemesters, setSemester, setErrors, clearSemesters, clearSemester, clearErrors } =
  semesterSlice.actions;

export default semesterSlice.reducer;
