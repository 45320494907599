import React from 'react';
import LinkButton from '../ui/button/LinkButton';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const ActionList = ({ items, title, help = false, showExtra = false }) => {
  return (
    <div>
      <h6 className="mt-2">{title}</h6>
      <div className="d-flex flex-wrap justify-content-between">
        <div>
          {items.map((item, index) => (
            <>
              <LinkButton
                key={index}
                to={{ pathname: item.route }}
                text={item.text}
                icon={item.icon}
                showExtra={showExtra}
                extraIcon={item?.extraIcon}
                variant="light"
              />
            </>
          ))}
        </div>

        {help ? (
          <div>
            <LinkButton to={{ pathname: '/help' }} text="Help" icon={faInfoCircle} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ActionList;
