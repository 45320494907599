import axios from 'axios';

// Get all groups
export const getAllGroups = async () => {
  try {
    const response = await axios.get('/api/group/');
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch all groups');
  }
};

// Get groups by user ID
export const getGroupsByUserID = async (userId) => {
  try {
    const response = await axios.get(`/api/group/user/${userId}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

// Get group by ID
export const getGroupByID = async (id) => {
  try {
    const response = await axios.get(`/api/group/${id}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

// Update group by ID
export const updateGroupByID = async (id, groupData) => {
  try {
    await axios.put(`/api/group/${id}`, groupData);
  } catch (error) {
    console.error(error);
  }
};
