import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextAreaFieldGroup from '../ui/text-field/TextAreaFieldGroup';
import isEmpty from '../../utils/is-empty';

import { getTemplateById, editTemplate } from '../../actions/mailActions';
import TextFieldGroup from '../ui/text-field/TextFieldGroup';
import { getGroupsByUserID } from '../../services/GroupService';
import PageHeader from '../ui/page-header/PageHeader';
import MultiList from '../ui/multi-list/MultiList';

const EditMailTemplate = ({ mail, errors, auth, getTemplateById, editTemplate }) => {
  const { id } = useParams();
  const history = useHistory();

  const [state, setState] = useState({
    name: '',
    text: '',
    subject: '',
    allGroups: [],
    currentGroupsSelected: [],
  });

  useEffect(() => {
    getTemplateById(id);
    fetchGroups();
  }, []);

  useEffect(() => {
    if (mail.template) {
      const template = mail.template;
      template.name = !isEmpty(template.name) ? template.name : '';
      template.subject = !isEmpty(template.subject) ? template.subject : '';
      template.text = !isEmpty(template.text) ? template.text : '';
      template.groups = !isEmpty(template.groups) ? template.groups : [];

      setState((prevState) => ({
        ...prevState,
        name: template.name,
        subject: template.subject,
        text: template.text,
        currentGroupsSelected: template.groups,
      }));
    }
  }, [mail.template]);

  const fetchGroups = async () => {
    const result = await getGroupsByUserID(auth.user.id);
    const groupRetrieved = result?.data?.group || [];

    const groupOptions = groupRetrieved.map((group) => ({
      name: group.title,
      value: group._id,
    }));

    setState((prevState) => ({
      ...prevState,
      allGroups: groupOptions,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateData = {
      name: state.name,
      text: state.text,
      subject: state.subject,
      groups: state.currentGroupsSelected,
    };

    editTemplate(id, templateData, history);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateGroup = (selectedGroups) => {
    setState((prevState) => ({
      ...prevState,
      currentGroupsSelected: selectedGroups,
    }));
  };

  const { name, text, subject, allGroups, currentGroupsSelected } = state;

  return (
    <div className="editMailTemplate">
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Vorlage Bearbeiten`} route={`/mail-overview`} />
            <form onSubmit={handleSubmit}>
              <TextFieldGroup
                placeholder="Vorlage Bezeichnung"
                onChange={handleChange}
                value={name}
                name="name"
                error={errors.name}
                info="Vorlage Bezeichnung"
              />
              <MultiList
                options={allGroups}
                setOptions={updateGroup}
                preSelectedOption={currentGroupsSelected}
                placeholder="Bitte Gruppen auswählen"
                error={errors.groups}
              />
              <small className="form-text text-muted">Gruppe</small>
              <br />
              <hr />
              <TextFieldGroup
                placeholder="Betreff"
                onChange={handleChange}
                value={subject}
                name="subject"
                error={errors.subject}
                info="Betreff"
              />
              <TextAreaFieldGroup
                placeholder="Text"
                rows={'10'}
                onChange={handleChange}
                value={text}
                name="text"
                error={errors.text}
                info="Text"
              />
              <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

EditMailTemplate.propTypes = {
  mail: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getTemplateById: PropTypes.func.isRequired,
  editTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  mail: state.mail,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { getTemplateById, editTemplate })(withRouter(EditMailTemplate));
