import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { getContracts } from 'actions/contractActions';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { EVExcelExport, AdvancedEVExcelExport } from 'actions/formsActions';
import PageHeader from 'components/ui/page-header/PageHeader';
import Filters from 'components/ui/table/Filters';
import Spinner from 'components/ui/spinner/Spinner';
import SearchBar from 'components/ui/table/SearchBar';
import LinkButton from 'components/ui/button/LinkButton';
import ConfirmationModal from 'components/ui/modal/Modal';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';

class ContractOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fil: '0',
      sort: '',
      isConfirmationModalVisible: false,
      filterStatus: '',
      filterStartDate: '',
      filterEndDate: '',
      startDateError: '',
      endDateError: '',
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.getContracts();
    this.setState({ filterStatus: 'In Process' });
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    this.setState({ filterStartDate: yesterday, filterEndDate: yesterday });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onEVExportClick(e) {
    e.preventDefault();
    this.props.EVExcelExport();
  }

  render() {
    // Multisort not yet implemented in package but is planned
    var defaultSorted = [
      {
        dataField: 'lastchangeddate',
        order: 'desc',
      },
    ];
    var contracts;

    if (this.props.contract) {
      contracts = this.props.contract.contracts;
    }
    let contractTable;

    // Data for Table
    let entries = contracts ? contracts : [];
    if (
      contracts !== null &&
      contracts !== undefined &&
      typeof contracts === 'object' &&
      contracts.constructor === Object &&
      Object.entries(contracts).length === 0
    ) {
      entries = [];
    }

    var newArray = entries?.filter((el) => {
      if (this.state.fil === '0') return el.status !== 'Completed';
      if (this.state.fil === 'Created') return el.status === 'Created';
      if (this.state.fil === 'Signable') return el.status === 'Signable';
      if (this.state.fil === 'Incomplete') return el.status === 'Incomplete';
      if (this.state.fil === 'Versendet') return el.status === 'Versendet';
      if (this.state.fil === 'In Process') return el.status === 'In Process';
      if (this.state.fil === 'Completed') return el.status === 'Completed';
      if (this.state.fil === 'All') return el;
    });

    const showConfirmationModal = () => {
      this.setState({ isConfirmationModalVisible: true });
    };

    const hideConfirmationModal = () => {
      this.setState({ isConfirmationModalVisible: false });
    };

    const handleExportConfirmation = () => {
      const { filterStartDate, filterEndDate } = this.state;
      let startDateError = '';
      let endDateError = '';
    
      // Check if both start date and end date are valid dates
      if (!filterStartDate || isNaN(new Date(filterStartDate).getTime())) {
        startDateError = 'Please enter a valid start date.';
      }
      if (!filterEndDate || isNaN(new Date(filterEndDate).getTime())) {
        endDateError = 'Please enter a valid end date.';
      }
    
      // Check if start date is after end date
      if (filterStartDate && filterEndDate && new Date(filterStartDate) > new Date(filterEndDate)) {
        startDateError = 'Start date cannot be after end date.';
      }
    
      // Update the state with errors or proceed with export
      if (startDateError || endDateError) {
        this.setState({ startDateError, endDateError });
      } else {
        // Proceed with export logic
        this.setState({ startDateError: '', endDateError: '' });
        this.props.AdvancedEVExcelExport(this.state.filterStatus, filterStartDate, filterEndDate);
        hideConfirmationModal();
      }
    };

    const betrachtenButton = (cell, row, rowIndex, formatExtraData) => {
      return <LinkButton to={{ pathname: `/edit-contract/${row._id}` }} text={`Edit`} variant={'info'} />;
    };

    function dateFormat(value) {
      if (value) return moment(value).format('DD/MM/YYYY');
    }

    const contractstarts = (value, cell, row, rowIndex, formatExtraData) => {
      var start1 = '';
      var start2 = '';
      if (cell.contractstart) {
        start1 = dateFormat(cell.contractstart);
      }
      if (cell.contractstart2) {
        start2 = dateFormat(cell.contractstart2);
      }
      return (
        <div>
          {start1} <br /> {start2}
        </div>
      );
    };

    const contractends = (value, cell, row, rowIndex, formatExtraData) => {
      var end1 = '';
      var end2 = '';
      if (cell.contractend) {
        end1 = moment(cell.contractend).format('DD/MM/YYYY');
      }
      if (cell.contractend2) {
        end2 = moment(cell.contractend2).format('DD/MM/YYYY');
      }
      return (
        <div>
          {end1} <br /> {end2}
        </div>
      );
    };

    const contracthours = (value, cell, row, rowIndex, formatExtraData) => {
      var hours1 = '';
      var hours2 = '';
      if (cell.hours) {
        hours1 = cell.hours;
      }
      if (cell.hours2) {
        hours2 = cell.hours2;
      }
      return (
        <div>
          {hours1} <br /> {hours2}
        </div>
      );
    };

    const nameFormater = (value) => {
      return (
        <span
          style={{
            display: 'block',
            width: 100,
            wordWrap: 'break-word',
          }}
        >
          {value}
        </span>
      );
    };

    const courseFormatter = (value) => {
      return (
        <span
          style={{
            display: 'block',
            width: 150,
            wordWrap: 'break-word',
          }}
        >
          {value}
        </span>
      );
    };

    const statusFormatter = (value, cell, row, rowIndex, formatExtraData) => {
      let status = '';
      if (value === 'Created') {
        status = 'Erstellt';
      } else if (value === 'Signable') {
        status = 'Unterschriftsbereit';
      } else if (value === 'In Process') {
        status = 'In Bearbeitung';
      } else if (value === 'Versendet') {
        status = 'Versendet';
      } else if (value === 'Completed') {
        status = 'Abgeschlossen';
      } else if (value === 'Incomplete') {
        status = 'Unvollständig';
      }

      return (
        <span
          style={{
            display: 'block',
            width: 100,
            wordWrap: 'break-word',
          }}
        >
          {status}
        </span>
      );
    };

    if (contracts === null || this.props.contract.contractloading) {
      contractTable = <Spinner />;
    } else {
      if (!contracts || contracts.length > 0) {
        const columns = [
          {
            dataField: 'profile.lastname',
            text: 'Nachname',
            sort: true,
            formatter: nameFormater,
          },
          {
            dataField: 'profile.firstname',
            text: 'Vorname',
            sort: true,
            formatter: nameFormater,
          },
          {
            dataField: 'course.metacourse.name',
            text: 'Kurs',
            sort: true,
            formatter: courseFormatter,
          },
          {
            text: 'Vertrag Start',
            dataField: 'contractstart',
            formatter: contractstarts,
            sort: true,
          },
          {
            dataField: 'contractend',
            text: 'Vertrag Ende',
            formatter: contractends,
            sort: true,
          },
          {
            dataField: 'hours',
            text: 'W-Stunden',
            formatter: contracthours,
            sort: true,
          },
          {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: statusFormatter,
          },
          {
            dataField: 'lasthandle',
            text: 'Kürzel',
            sort: true,
          },
          {
            dataField: 'lastchangeddate',
            text: 'Letzte Änderung',
            sort: true,
            formatter: dateFormat,
          },
          {
            dataField: 'creationdate',
            text: 'Erstellungsdatum',
            sort: true,
            headerStyle: () => {
              return { maxWidth: '120px', wordWrap: 'break-word' };
            },
            formatter: dateFormat,
          },
          {
            dataField: 'dummyView',
            isDummyField: true,
            text: 'Vertrag bearbeiten',
            formatter: betrachtenButton,
          },
        ];

        // Sort in Filter change
        const nameSort = () => {
          this.table.sortContext.handleSort(columns[0]);
        };

        if (this.state.fil !== '0' && this.state.sort !== 'asc') {
          nameSort();
          this.setState({ sort: 'asc' });
        }

        function onColumnMatch({ searchText, value, column, row }) {
          if (typeof value !== 'undefined') {
            if (value) {
              if (column.dataField === 'profile.firstname') {
                const fullname = `${row.profile.firstname} ${row.profile.lastname}`;
                return fullname?.toLowerCase().startsWith(searchText);
              } else {
                return value?.toString()?.toLowerCase().startsWith(searchText);
              }
            }
          }
          return false;
        }

        contractTable = (
          <ToolkitProvider bootstrap4 keyField="_id" data={newArray} columns={columns} search={{ onColumnMatch }}>
            {(props) => (
              <div>
                <h6>Status Filter:</h6>
                <div className="d-flex flex-column flex-lg-row justify-content-between">
                  <Filters
                    selectedFilter={this.state.fil}
                    setFilter={(value) => {
                      this.setState({ fil: value });
                      nameSort();
                    }}
                    options={[
                      { value: '0', label: 'Offen' },
                      { value: 'Created', label: 'Erstellt' },
                      { value: 'Incomplete', label: 'Unvollständig' },
                      { value: 'In Process', label: 'In Bearbeitung' },
                      { value: 'Signable', label: 'Unterschriftsbereit' },
                      { value: 'Versendet', label: 'Versendet' },
                      { value: 'Completed', label: 'Abgeschlossen' },
                      { value: 'All', label: 'Alle' },
                    ]}
                  />
                  <SearchBar {...props.searchProps} />
                </div>
                <hr />
                <BootstrapTable
                  ref={(n) => (this.table = n)}
                  striped
                  {...props.baseProps}
                  pagination={paginationFactory()}
                  defaultSorted={defaultSorted}
                  defaultSortDirection="asc"
                  classes="table-responsive-md"
                  wrapperClasses="table-responsive"
                />
              </div>
            )}
          </ToolkitProvider>
        );
      }
    }

    //Select options for Status
    const statusOptions = [
      { value: 'Created', label: 'Erstellt' },
      { value: 'Incomplete', label: 'Unvollständig' },
      { value: 'In Process', label: 'In Bearbeitung' },
      { value: 'Signable', label: 'Unterschriftsbereit' },
      { value: 'Versendet', label: 'Versendet' },
      { value: 'Completed', label: 'Abgeschlossen' },
    ];

    return (
      <div className="container-fluid">
        <div className="container">
          <PageHeader heading={'Verträge'} route={'/dashboard'} />
            <div className="btn-group my-3 flex flex-wrap">
              <LinkButton
                to={{ pathname: '/createseparatecontract' }}
                text={`Leeren Vertrag anlegen`}
                variant={'info'}
              />
              <LinkButton to={{ pathname: '/contractstats' }} text={`Vertragsstatistiken`} variant={'primary'} />
              <button type="button" onClick={this.onEVExportClick.bind(this)} className="btn btn-info">
                Excel exportieren
              </button>
              <button type="button" onClick={() => showConfirmationModal()} className="btn btn-secondary">
                Erweiterter Exportfilter
              </button>
          </div>
          {contractTable}
          <ConfirmationModal
            show={this.state.isConfirmationModalVisible}
            onClose={hideConfirmationModal}
            title="Erweiterter Exportfilter"
            closeButtonLabel="Cancel"
            saveButtonLabel="Export"
            onSave={handleExportConfirmation}
          >
            <label htmlFor="inputStudent">Filterstatus</label>
            <SelectListGroup
              placeholder="Filter Status"
              onChange={this.onChange}
              value={this.state.filterStatus}
              name="filterStatus"
              options={statusOptions}
            />
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="filterStartDate">Startdatum</label>
                <TextFieldGroup
                  type={'date'}
                  placeholder="Startdatum"
                  onChange={this.onChange}
                  value={this.state.filterStartDate}
                  error={this.state.startDateError}
                  name="filterStartDate"
                  max={new Date().toISOString().slice(0, 10)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="filterEndDate">Enddatum</label>
                <TextFieldGroup
                  type={'date'}
                  placeholder="Enddatum"
                  onChange={this.onChange}
                  value={this.state.filterEndDate}
                  error={this.state.endDateError}
                  name="filterEndDate"
                  max={new Date().toISOString().slice(0, 10)}
                />
              </div>
            </div>
          </ConfirmationModal>
        </div>
      </div>
    );
  }
}

ContractOverview.propTypes = {
  getContracts: PropTypes.func.isRequired,
  contract: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  contract: state.contract,
});

export default connect(mapStateToProps, {
  getContracts,
  EVExcelExport,
  AdvancedEVExcelExport,
})(ContractOverview);
