import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';

import App from './app/App';
import store from './app/store';
import { logoutUser, setCurrentUser } from './features/auth/authSlice';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Required for Content-Security-Policy
fontAwesomeConfig.autoAddCss = false;

// Function to restore user claims from local storage
function restoreClaims() {
  if (localStorage.length !== 0) {
    const storageItem = localStorage.getItem('user');
    if (!storageItem) {
      localStorage.clear();
      return;
    }

    const { id, role, exp } = JSON.parse(storageItem);

    if (!id || !role || !exp || parseInt(exp) * 1000 < Date.now()) {
      store.dispatch(logoutUser());
      return;
    }

    store.dispatch(setCurrentUser({ id, role }));
  }
}

// Function to set up axios configuration
async function axiosSetup() {
  // Set backend base URL; defaults to client site
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
  // Send cookies during for cross-origin backend
  axios.defaults.withCredentials = true;

  axios.interceptors.response.use(undefined, (error) => {
    if (error.response?.status === 401) {
      store.dispatch(logoutUser());
    }

    return Promise.reject(error);
  });

  // Get CSRF token
  const res = await axios.get('/api/auth/csrf');
  axios.defaults.headers.common['X-CSRF-Token'] = res.data.token;
}

// Call functions to restore claims and set up axios
restoreClaims();
axiosSetup();

// Render the application
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
