import React from 'react';
import { useState, useEffect } from 'react';
import { getAllCourses, getLoginUserProfile } from '../../services/CourseService';
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import moment from 'moment';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../ui/page-header/PageHeader';
import Table from '../ui/table/Table';
import LinkButton from '../ui/button/LinkButton';
import Spinner from '../ui/spinner/Spinner';
import SearchBar from '../ui/table/SearchBar';

function AdminAllApplicationView() {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  let applicationTable;
  const defaultSorted = [{ dataField: 'grade', order: 'asc' }];
  var applications = [];
  var entries = [];

  useEffect(() => {
    if (courses === null) {
      loadCourses();
    }
  }, [courses]);

  const loadCourses = async () => {
    setLoading(true);
    const result = await getAllCourses();
    const userDetails = await getLoginUserProfile();
    setCourses(result?.data);
    setUserProfile(userDetails?.data);
    setLoading(false);
  };

  if (courses !== null) {
    for (let i = 0; i < courses?.length; i++) {
      for (let j = 0; j < courses[i].applications.length; j++) {
        courses[i].applications[j]['firstname'] = '';
        courses[i].applications[j]['lastname'] = '';
        courses[i].applications[j]['coursename'] = courses[i].metacourse[0]?.name;
        if (courses[i]?.applications[j].fname !== undefined) {
          courses[i].applications[j]['firstname'] = courses[i]?.applications[j].fname;
        }
        if (courses[i]?.applications[j].lname !== undefined) {
          courses[i].applications[j]['lastname'] = courses[i]?.applications[j].lname;
        }
        courses[i].applications[j]['semester'] = courses[i]?.semester[0]?.name;
        applications.push(courses[i].applications[j]);
      }
    }
    entries = applications;
  }

  //{{ pathname: `/comment/${row._id}`, fromAllAppView: true }}
  function betrachtenButton(cell, row, rowIndex, formatExtraData) {
    return (
      <LinkButton
        to={{
          pathname: `/applicationdetails/${row._id}`,
          fromAllAppView: true,
        }}
        text={'Profile'}
        variant={'info'}
      />
    );
  }

  function contractButton(cell, row, rowIndex, formatExtraData) {
    if (row.status === 'Accepted') {
      return (
        <LinkButton
          to={{
            pathname: `/create-contract/${row._id}`,
            fromAllAppView: true,
          }}
          text={'Vertrag erstellen'}
          variant={'primary'}
        />
      );
    }
  }

  function acceptButton(cell, row, rowIndex, formatExtraData) {
    if (row) {
      if (row.status) {
        if (row.status === 'Applied') {
          return (
            <button
              onClick={() => {
                axios.post(`/api/application/accept/${row._id}`).then((res) => {
                  window.location.reload();
                });
              }}
              className="btn btn-primary"
            >
              Annehmen
            </button>
          );
        } else if (row.status === 'Accepted' || row.status === 'Declined') {
          return (
            <button
              onClick={() => {
                axios.post(`/api/application/applied/${row._id}`).then((res) => {
                  window.location.reload();
                });
              }}
              className="btn btn-secondary"
            >
              Zurücksetzen
            </button>
          );
        }
      }
    }
  }

  function declineButton(cell, row, rowIndex, formatExtraData) {
    if (row.status) {
      if (row.status === 'Applied') {
        return (
          <button
            onClick={() => {
              axios.post(`/api/application/decline/${row._id}`).then((res) => {
                window.location.reload();
              });
            }}
            className="btn btn-danger"
          >
            Ablehnen
          </button>
        );
      }
    }
  }

  const prioFormatter = (value, cell, row, rowIndex, formatExtraData) => {
    if (value === '3') {
      return 'Hoch';
    } else if (value === '2') {
      return 'Mittel';
    } else if (value === '1') {
      return 'Niedrig';
    }
  };

  const commentFormatter = (value, row, rowIndex, formatExtraData) => {
    let icon;
    let iconStyle;
    if (row.commentdate > moment.utc(userProfile.user.lastlogin).unix()) {
      icon = faExclamationCircle;
      iconStyle = 'text-danger';
    }

    return (
      <LinkButton
        to={{ pathname: `/comment/${row._id}`, fromAllAppView: true }}
        text={`Kommentar`}
        icon={icon}
        iconStyle={iconStyle}
        variant={'info'}
      />
    );
  };

  if (!applications || applications.length > 0) {
    const columns = [
      {
        dataField: 'lastname',
        text: 'Nachname',
        sort: true,
      },
      {
        dataField: 'firstname',
        text: 'Vorname',
        sort: true,
      },
      {
        dataField: 'grade',
        text: 'Note',
        sort: true,
      },
      {
        dataField: 'coursename',
        text: 'Kurs',
        sort: true,
      },
      {
        dataField: 'semester',
        text: 'Semester',
        sort: true,
      },
      {
        dataField: 'priority',
        text: 'Prio',
        sort: true,
        formatter: prioFormatter,
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
      },
      {
        dataField: 'dummyView',
        isDummyField: true,
        text: 'Bewerbung ansehen',
        formatter: betrachtenButton,
      },
      {
        dataField: 'dummyAccept',
        isDummyField: true,
        text: 'Annehmen',
        formatter: acceptButton,
      },
      {
        dataField: 'dummyDecline',
        isDummyField: true,
        text: 'Ablehnen',
        formatter: declineButton,
      },
      {
        dataField: 'dummyContract',
        isDummyField: true,
        text: 'Vertrag erstellen',
        formatter: contractButton,
      },
      {
        dataField: 'dummyComment',
        isDummyField: true,
        text: 'Comment',
        formatter: commentFormatter,
        style: { width: '150px' },
      },
    ];

    if (loading) {
      applicationTable = <Spinner />;
    } else {
      applicationTable = (
        <ToolkitProvider bootstrap4 keyField="_id" data={entries} columns={columns} search>
          {(props) => (
            <>
              <SearchBar {...props.searchProps} />
              <hr />
              <Table baseProps={props.baseProps} defaultSorted={defaultSorted} />
            </>
          )}
        </ToolkitProvider>
      );
    }
  }
  var courseApplicationView;
  if (!entries) {
    courseApplicationView = <h3 className={'text-center m-5'}>Noch keine Bewerbungen</h3>;
  } else {
    courseApplicationView = applicationTable;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <PageHeader heading={'Bewerbungen'} route={'/dashboard'} />
          {loading ? <Spinner /> : courseApplicationView}
        </div>
      </div>
    </div>
  );
}

// export default connect(mapStateToProps, { getCurrentProfile })(AdminAllApplicationView);
export default AdminAllApplicationView;
