import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

function LanguageSelector({ component, ...otherProps }) {
  const Component = component;

  return (
    <Tabs defaultActiveKey="en" className="mb-3">
      <Tab eventKey="en" title="EN">
        <Component lang="en" {...otherProps} />
      </Tab>
      <Tab eventKey="de" title="DE">
        <Component lang="de" {...otherProps} />
      </Tab>
    </Tabs>
  );
}

LanguageSelector.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default LanguageSelector;
