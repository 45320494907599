import React, { useLayoutEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Font Awesome Icons
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

// UI Components
import PageHeader from 'components/ui/page-header/PageHeader';
import LinkButton from 'components/ui/button/LinkButton';
import Spinner from 'components/ui/spinner/Spinner';
import FilterTable from 'components/ui/table/FilterTable';

// Actions
import { clearSemesters, getSemesters } from 'features/semester/semesterSlice';

import { dateFormat } from 'utils/utils';

const SemesterOverview = () => {
  const semesters = useSelector((state) => state.semester.semesters);
  const semestersLoading = useSelector((state) => state.semester.isLoading);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(getSemesters());

    return () => {
      dispatch(clearSemesters());
    };
  }, [dispatch]);

  const betrachtenButton = useCallback(
    (cell, row) =>
      user.role === 'Admin' ? (
        <LinkButton to={{ pathname: `/edit-semester/${row._id}` }} text="Edit" variant="info" />
      ) : null,
    [user.role]
  );

  const columns = useMemo(
    () => [
      { dataField: 'name', text: 'Semester', sort: true },
      { dataField: 'from', text: 'Von', sort: true, formatter: dateFormat },
      { dataField: 'to', text: 'Bis', sort: true, formatter: dateFormat },
      {
        dataField: 'coursefrom',
        text: 'Kurse von',
        sort: true,
        formatter: dateFormat,
      },
      {
        dataField: 'courseto',
        text: 'Kurse bis',
        sort: true,
        formatter: dateFormat,
      },
      {
        dataField: 'dummyView',
        isDummyField: true,
        text: 'Edit',
        formatter: betrachtenButton,
      },
    ],
    [betrachtenButton]
  );

  const newSemesterButton = () => {
    if (user.role === 'Admin') {
      return (
        <div className="mb-2">
          <LinkButton to={{ pathname: '/create-semester' }} text="Neues Semester" variant="info" icon={faUserCircle} />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="semesterOverview">
      <div className="container">
        <PageHeader heading="Semester Übersicht" route="/dashboard" />
        {semestersLoading ? (
          <Spinner />
        ) : (
          <>
            {newSemesterButton()}
            <FilterTable data={semesters} columns={columns} />
          </>
        )}
      </div>
    </div>
  );
};

export default SemesterOverview;
