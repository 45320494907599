import React from 'react';
import { getRentalOfUserId } from 'actions/rentalActions';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axios from 'axios';
import moment from 'moment';
import PageHeader from 'components/ui/page-header/PageHeader';
import Table from 'components/ui/table/Table';
import Spinner from 'components/ui/spinner/Spinner';

const StudentRentalView = () => {
  const dispatch = useDispatch();
  const rental = useSelector((state) => state.rentals);
  const [rentals, setRentals] = useState([]);
  const [errorStatus, setErrorStatus] = useState([false, false, false]);

  let applicationTable;

  useEffect(() => {
    getRentalOfUserId()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (rental.rentals !== null) {
      let temprental = [];
      for (let i = 0; i < rental.rentals.length; i++) {
        if (rental.rentals[i].status === 'HW ausgegeben') {
          rental.rentals[i].status = 'Hardware handed out';
          let itemsname = '';
          if (rental.rentals[i].leihobjekt.ipad?.available) itemsname = itemsname + ' iPad,';
          if (rental.rentals[i].leihobjekt.mikrofon?.available) itemsname = itemsname + ' Microphone,';
          if (rental.rentals[i].leihobjekt.stativ?.available) itemsname = itemsname + ' Stativ,';
          if (rental.rentals[i].leihobjekt.wacom?.available) itemsname = itemsname + ' Wacom,';
          if (rental.rentals[i].leihobjekt.webcam?.available) itemsname = itemsname + ' Webcam,';
          rental.rentals[i].items = itemsname.slice(0, itemsname.length - 1);
          rental.rentals[i].returndate = '';
          rental.rentals[i].returntime = '';
          rental.rentals[i].expectedreturndate = '';
          temprental.push(rental.rentals[i]);
        }
      }
      setRentals(temprental);
    }
  }, [rental]);

  function onChange(e) {
    if (e.target.name === 'appointmentdate') {
      let rentalStore = rentals;
      for (let i = 0; i < rentalStore.length; i++) {
        if (rentalStore[i]._id === e.target.placeholder) {
          rentalStore[i].returndate = e.target.value;
          setRentals(rentalStore);
        }
      }
    }
    if (e.target.name === 'extensiondate') {
      let rentalStore = rentals;
      for (let i = 0; i < rentalStore.length; i++) {
        if (rentalStore[i]._id === e.target.placeholder) {
          rentalStore[i].expectedreturndate = e.target.value;
          setRentals(rentalStore);
        }
      }
    }
    if (e.target.name === 'appointmenttime') {
      let rentalStore = rentals;
      for (let i = 0; i < rentalStore.length; i++) {
        if (rentalStore[i]._id === e.target.placeholder) {
          rentalStore[i].returntime = e.target.value;
          setRentals(rentalStore);
        }
      }
    }
  }

  function returnButton(value, row, index) {
    return (
      <div>
        <div>
          <table className="table mb-2">
            <thead>
              <tr>
                <th scope="col">Appointment Date:</th>
                <th scope="col">Appointment Time:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <TextFieldGroup
                    type={'date'}
                    value={rentals.returndate}
                    onChange={onChange}
                    name="appointmentdate"
                    placeholder={row._id}
                    required
                  />
                </td>
                <td>
                  <TextFieldGroup
                    type={'time'}
                    value={rentals.returntime}
                    onChange={onChange}
                    name="appointmenttime"
                    placeholder={row._id}
                    required
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                if (row.returndate === '') {
                  setErrorStatus([true, errorStatus[1], false]);
                } else if (row.returntime === '') {
                  setErrorStatus([errorStatus[0], true], false);
                } else {
                  let dateformatter = row.returndate.split('-');
                  let formattedreturndate = dateformatter[2] + '-' + dateformatter[1] + '-' + dateformatter[0];
                  const mailData = {
                    vorname: row.vorname,
                    familyname: row.name,
                    returndate: formattedreturndate,
                    returntime: row.returntime,
                    items: row.items,
                    type: 'hwreturn',
                  };
                  let confirmationMsg =
                    'Would you like to confirm your appointment request? Hardware can also be returned without any appointment!';
                  if (window.confirm(confirmationMsg) == true) {
                    axios.post(`/api/mail/notifyrentalmail`, mailData).then((res) => {
                      window.alert(
                        'Thank you for your hardware return request. Our team will try to get back to you as soon as possible'
                      );
                      window.history.back('/dashboard/');
                    });
                  }
                }
              }}
              className="btn btn-primary"
            >
              Request Return
            </button>
          </div>
        </div>
      </div>
    );
  }
  function contractButton(cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        <table className="table mb-2">
          <thead>
            <tr>
              <th scope="col">Expected Return Date:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TextFieldGroup
                  type={'date'}
                  value={rentals.expectedreturndate}
                  onChange={onChange}
                  name="extensiondate"
                  placeholder={row._id}
                  required
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex justify-content-center">
          <button
            onClick={() => {
              if (row.expectedreturndate === '') {
                setErrorStatus([errorStatus[0], errorStatus[1], true]);
              } else {
                if (window.confirm('Are you sure you want to apply for a hardware extension?') === true) {
                  let dateformatter = row.expectedreturndate.split('-');
                  let formattedexpectedreturndate = dateformatter[2] + '-' + dateformatter[1] + '-' + dateformatter[0];
                  const mailData = {
                    vorname: row.vorname,
                    familyname: row.name,
                    expectedreturndate: formattedexpectedreturndate,
                    items: row.items,
                    type: 'hwext',
                  };

                  axios.post(`/api/mail/notifyrentalmail`, mailData).then((res) => {
                    window.alert(
                      'Thank you for your hardware extension request. Our team will try to get back to you as soon as possible'
                    );
                    window.history.back('/dashboard/');
                  });
                }
              }
            }}
            className="btn btn-primary"
          >
            Notify Admin
          </button>
        </div>
      </div>
    );
    // }
  }
  function dateFormat(value, row, index) {
    if (value) return moment(value).format('DD/MM/YYYY');
  }

  const columns = [
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'rückgabe',
      text: 'Return date',
      formatter: dateFormat,
      sort: true,
    },
    {
      dataField: 'items',
      text: 'Rented Items',
    },
    {
      dataField: 'dummyContract',
      isDummyField: true,
      text: 'Apply for Extension',
      formatter: contractButton,
    },
    {
      dataField: 'dummyReturnMail',
      isDummyField: true,
      text: '* Appointment to Return Items (Processing time is 24 hrs)',
      formatter: returnButton,
    },
  ];
  if (rental.loading) {
    applicationTable = <Spinner />;
  } else {
    applicationTable = (
      <ToolkitProvider bootstrap4 keyField="_id" data={rentals} columns={columns} search>
        {(props) => <Table baseProps={props.baseProps} />}
      </ToolkitProvider>
    );
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <PageHeader heading={`Rentals`} route={'/dashboard'} />
          <br />
          <div className="panel panel-info">
            <div className="panel-heading">Return point for Rental Hardware</div>
            <div className="panel-body">
              The rental hardware can be returned directly at the ITO hardware group{' '}
              <strong>(Operating Hours: Mon to Fri - 9:00 to 16:00, Location: Boltzmannstr. 3, room 00.07.054)</strong>,
              without any appointment. Only for guaranteed availability of a ITO-employee, please schedule an
              appointment.
            </div>
          </div>

          <br />
          {errorStatus[0] && <div className="text-danger">* Please select a date for your appointment!</div>}
          {errorStatus[1] && <div className="text-danger">* Please select a time for your appointment!</div>}
          {errorStatus[2] && (
            <div className="text-danger">* Please fill expected return date till you need the devices!</div>
          )}
          <div className="overflow-auto">{applicationTable}</div>
        </div>
      </div>
    </div>
  );
};

export default StudentRentalView;
