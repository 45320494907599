import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { getTemplates, deleteTemplate } from '../../actions/mailActions';
import axios from 'axios';
import PageHeader from '../ui/page-header/PageHeader';
import LinkButton from '../ui/button/LinkButton';
import FilterTable from 'components/ui/table/FilterTable';

class MailOverview extends Component {
  componentWillMount() {
    this.props.getTemplates();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  render() {
    const { templates } = this.props.mail;
    const templateArray = templates ? templates : [];

    const betrachtenButton = (cell, row, rowIndex, formatExtraData) => {
      return (
        <LinkButton
          to={{
            pathname: `/edit-template/${row._id}`,
          }}
          text={'Bearbeiten'}
          variant={'info'}
        />
      );
    };

    const deleteButton = (cell, row, rowIndex, formatExtraData) => {
      return (
        <button
          onClick={() => {
            if (window.confirm('Sind Sie sicher, dass Sie die Vorlage löschen möchten?')) {
              axios.delete(`/api/mail/deletetemplate/${row._id}`).then((res) => {
                window.location.reload();
              });
            }
          }}
          className="btn btn-danger"
        >
          Vorlage löschen!
        </button>
      );
    };

    const columns = [
      {
        dataField: 'name',
        text: 'Vorlage',
        sort: true,
      },
      {
        dataField: 'dummyView',
        isDummyField: true,
        text: 'Bearbeiten',
        formatter: betrachtenButton,
      },
      {
        dataField: 'dummyDelete',
        isDummyField: true,
        text: 'Löschen',
        formatter: deleteButton,
      },
    ];

    return (
      <div className="Mail Overview">
        <div className="container">
          <PageHeader heading={'Mail Vorlagen Übersicht'} route={'/dashboard'} />
          <div className="mb-2">
            <LinkButton
              to={{ pathname: `/create-template` }}
              text={`Neue Vorlage`}
              variant={'info'}
              icon={faUserCircle}
            />
          </div>
          <FilterTable data={templateArray} columns={columns} />
        </div>
      </div>
    );
  }
}

MailOverview.propTypes = {
  mail: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  mail: state.mail,
  auth: state.auth,
});

export default connect(mapStateToProps, { getTemplates, deleteTemplate })(withRouter(MailOverview));
