import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function PageHeader({ route, heading }) {
  const history = useHistory();

  const handleBackClick = (e) => {
    if (!route) {
      e.preventDefault();
      history.goBack();
    } else {
      history.replace(route);
    }
  };

  return (
    <>
      <Button variant="light" onClick={handleBackClick}>
        zurück
      </Button>
      <h1 className="display-4 text-center">{heading}</h1>
    </>
  );
}

export default PageHeader;
