import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import { getAllContractsByStudentId } from '../../services/ContractService';

const ContractTable = ({ data }) => (
  <Table striped bordered hover size="sm" responsive>
    <thead>
      <tr>
        <th>#</th>
        <th>Semestername</th>
        <th>Metakursname</th>
        <th>Vertrag 1 Start</th>
        <th>Vertrag 1 Ende</th>
        <th>Vertrag 1 Stunden</th>
        <th>Vertrag 2 Start</th>
        <th>Vertrag 2 Ende</th>
        <th>Vertrag 2 Stunden</th>
      </tr>
    </thead>
    <tbody>
      {data?.map((contract) => (
        <tr key={contract.id}>
          <td>{contract.id}</td>
          <td>{contract.semesterName}</td>
          <td>{contract.metacourseName}</td>
          <td>{contract.contract1start}</td>
          <td>{contract.contract1end}</td>
          <td>{contract.contract1hours}</td>
          <td>{contract.contract2start}</td>
          <td>{contract.contract2end}</td>
          <td>{contract.contract2hours}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

const OldContractTable = ({ studentId, semesterId, currentContractId = '' }) => {
  const [currentSemesterContractData, setCurrentSemesterContractData] = useState([]);
  const [allContractData, setAllContractData] = useState([]);

  const formatDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    }
    return '-';
  };

  const processContracts = (contracts) => {
    if (!Array.isArray(contracts)) {
      return [];
    }

    return contracts
      .filter((contract) => contract?._id !== currentContractId)
      .map((contract, index) => ({
        id: index + 1,
        semesterName: contract?.course?.semester?.name || '-',
        metacourseName: contract?.course?.metacourse?.name || '-',
        contract1start: formatDate(contract?.contractstart),
        contract1end: formatDate(contract?.contractend),
        contract1hours: contract?.hours || '-',
        contract2start: formatDate(contract?.contractstart2),
        contract2end: formatDate(contract?.contractend2),
        contract2hours: contract?.hours2 || '-',
      }));
  };

  const extractCurrentSemesterContracts = (contracts, semesterId) => {
    return contracts.filter((contract) => contract?.course?.semester?._id === semesterId);
  };

  useEffect(() => {
    const loadAllContracts = async () => {
      try {
        if (studentId) {
          const allContracts = await getAllContractsByStudentId(studentId);
          const modifiedAllContracts = processContracts(allContracts);
          setAllContractData(modifiedAllContracts);
          const filterCurrentSemesterCourses = extractCurrentSemesterContracts(allContracts, semesterId);
          const modifiedFilteredContracts = processContracts(filterCurrentSemesterCourses);
          setCurrentSemesterContractData(modifiedFilteredContracts);
        }
      } catch (error) {
        console.error('Error loading all contracts:', error);
      }
    };

    loadAllContracts();
  }, [studentId]);

  // Conditionally render the header and table only if there is data
  return (
    <>
      {currentSemesterContractData.length > 0 ? (
        <>
          <h5>Aktuelle Verträge in diesem Semester</h5>
          <ContractTable data={currentSemesterContractData} />
        </>
      ) : null}
      {allContractData?.length > 0 && (
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5>Vertragshistorie</h5>
            </Accordion.Header>
            <Accordion.Body>
              <ContractTable data={allContractData} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};

export default OldContractTable;
