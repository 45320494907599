import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// React Bootstrap Table Components
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

// UI Components
import PageHeader from 'components/ui/page-header/PageHeader';
import Table from 'components/ui/table/Table';
import SearchBar from 'components/ui/table/SearchBar';
import LinkButton from 'components/ui/button/LinkButton';

// Actions
import { getMetacourses, getTUMMetacourses } from 'actions/metacourseActions';
import { getAdvisorCourses } from 'actions/courseActions';

class AdvisorMetacourseOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metacourses: [],
    };
  }
  componentWillMount() {
    if (this.props.tummetacourses?.tummetacourses === null) {
      this.props.getTUMMetacourses();
    }
    this.props.getAdvisorCourses();
    // this.props.getMetacourses();
  }

  componentWillReceiveProps(nextProps) {
    let advisormetacourses = [];
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    let tempmetacourses = nextProps.course.courses;
    if (tempmetacourses) {
      for (let i = 0; i < tempmetacourses?.length; i++) {
        for (let j = 0; j < tempmetacourses[i]?.metacourse?.length; j++) {
          advisormetacourses.push(tempmetacourses[i]?.metacourse[j]);
        }
      }
      this.setState({ metacourses: advisormetacourses });
    }
  }

  render() {
    const metacourses = this.state.metacourses;

    const metaArray = metacourses ? metacourses : [];

    function betrachtenButton(cell, row, rowIndex, formatExtraData) {
      return <LinkButton to={{ pathname: `/edit-metacourse/${row._id}` }} text={`Edit`} variant={'info'} />;
    }

    const columns = [
      {
        dataField: 'name',
        text: 'Metaveranstaltung',
        sort: true,
      },
      {
        dataField: 'scheme',
        text: 'Maßnahme',
        sort: true,
      },
      {
        dataField: 'fondsnumber',
        text: 'Fondsnummer',
        sort: true,
      },
      {
        dataField: 'costcentre',
        text: 'Kostenstelle',
        sort: true,
      },
      {
        dataField: 'abbreviation',
        text: 'Abkürzung',
        sort: true,
      },
      {
        dataField: 'module',
        text: 'Modul',
        sort: true,
      },
      {
        text: 'Edit',
        header: 'Bearbeiten',
        id: 'links',
        formatter: betrachtenButton,
      },
    ];

    return (
      <div className="Metacourse Overview">
        <div className="container overflowX" style={{ overflowX: 'auto', paddingBottom: '2rem' }}>
          <PageHeader heading={'Metaveranstaltung Übersicht'} route={'/dashboard'} />
          <h6></h6>
          <ToolkitProvider bootstrap4 keyField="id" data={metaArray} columns={columns} search>
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <hr />
                <Table baseProps={props.baseProps} />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    );
  }
}

AdvisorMetacourseOverview.propTypes = {
  getAdvisorCourses: PropTypes.func.isRequired,
  getMetacourses: PropTypes.func.isRequired,
  metacourse: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  tummetacourses: PropTypes.object.isRequired,
  getTUMMetacourses: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  metacourse: state.metacourse,
  tummetacourses: state.tummetacourses,
  course: state.course,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getMetacourses,
  getAdvisorCourses,
  getTUMMetacourses,
})(withRouter(AdvisorMetacourseOverview));
