const verfassungsPruefung = [
  'AF',
  'EG',
  'DZ',
  'BH',
  'BD',
  'ER',
  'ID',
  'IQ',
  'IR',
  'IL',
  'YE',
  'JO',
  'KZ',
  'KG',
  'KW',
  'LB',
  'LY',
  'MA',
  'MR',
  'OM',
  'PK',
  'SA',
  'SO',
  'SY',
  'TJ',
  'TN',
  'TM',
  'UZ',
  'AE',
];

export default verfassungsPruefung;
