import moment from 'moment';

export function isEmptyObject(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
}

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const convertIsoToDate = (isoString) => {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const calculateAge = (birthdate) => {
  const year = new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear();
  const age = Math.abs(year - 1970);
  return age;
};

export const dateFormat = (value) => {
  if (value) return moment(value).format('DD/MM/YYYY');
  return value;
};

export const tutorOfficeSignature = `
<div>
    --<br> School Office - Tutor Coordination Office<br> School of Computation, Information and Technology (CIT)<br> Technical University of Munich<br> Boltzmannstr. 3, D-85748 Garching near Munich, Germany
</div>
`;
