import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SelectListGroup from '../ui/select-list-group/SelectListGroup';
import { createSeparateContract, getUserContracts } from '../../actions/contractActions';
import { getCourses } from '../../actions/courseActions';
import { getTutors } from '../../actions/profileActions';
import { getCurrentProfile } from '../../actions/profileActions';
import PageHeader from '../ui/page-header/PageHeader';
import isEmpty from '../../utils/is-empty';

class CreateSeparateContract extends Component {
  componentDidMount() {
    this.props.getTutors();
    this.props.getCourses();
    this.props.getCurrentProfile();
  }

  constructor(props) {
    super(props);
    this.state = {
      tutors: '',
      courses: '',
      user: '',
      profile: '',
      course: '',
      status: 'Created',
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const contractData = {
      user: this.state.user,
      profile: this.state.profile,
      course: this.state.course,
      status: this.state.status,
      lasthandle: this.props.profile.profile.handle,
    };

    this.props.createSeparateContract(contractData, this.props.history);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.profile) {
      const { tutors } = nextProps.profile;
      this.setState({
        tutors: tutors,
      });
    }

    if (nextProps.course) {
      const { courses } = nextProps.course;
      this.setState({
        courses: courses,
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    var { tutors, courses } = this.state;

    //Select options for tutors
    if (isEmpty(tutors)) {
      tutors = [];
    }
    tutors.sort(function (a, b) {
      if (a.lastname < b.lastname) {
        return -1;
      }
      if (a.lastname > b.lastname) {
        return 1;
      }
      return 0;
    });
    const tutorOptions = tutors.map((el) => {
      return { label: el.lastname + ' ' + el.firstname, value: el._id };
    });

    tutorOptions.unshift({ label: 'Student:in auswählen', value: '' });

    //Set User for selected Tutor
    if (this.state.profile) {
      var index = tutors.filter((x) => x._id === this.state.profile);
      if (index[0].user._id !== this.state.user) {
        this.setState({ user: index[0].user._id });
      }
    }

    //Select options for courses
    if (isEmpty(courses)) {
      courses = [];
    }
    courses.sort(function (a, b) {
      if (a.metacourse[0].name < b.metacourse[0].name) {
        return -1;
      }
      if (a.metacourse[0].name > b.metacourse[0].name) {
        return 1;
      }
      return 0;
    });
    const courseOptions = courses.map((el) => {
      return {
        label: el.metacourse[0].name + ', ' + el.semester[0].name,
        value: el._id,
      };
    });

    courseOptions.unshift({ label: 'Veranstaltung auswählen', value: '' });

    //Select options for status of contract
    const statusOptions = [
      { label: 'Erstellt', value: 'Created' },
      { label: 'Unvollständig', value: 'Incomplete' },
      { label: 'In Bearbeitung', value: 'In Process' },
      { label: 'Unterschriftsbereit', value: 'Signable' },
      { label: 'Versendet', value: 'Versendet' },
      { label: 'Abgeschlossen', value: 'Completed' },
    ];

    return (
      <div className="createContract">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <PageHeader heading={'Leeren Vertrag Erstellen'} route={'/contracts'} />
              <form onSubmit={this.onSubmit}>
                <label htmlFor="inputStudent">Student:in</label>
                <SelectListGroup
                  placeholder="Student"
                  onChange={this.onChange}
                  value={this.state.profile}
                  name="profile"
                  error={errors.profile}
                  options={tutorOptions}
                />
                <label htmlFor="inputourse">Veransaltung</label>
                <SelectListGroup
                  placeholder="Veransaltung"
                  onChange={this.onChange}
                  value={this.state.course}
                  name="course"
                  error={errors.course}
                  options={courseOptions}
                />
                <label htmlFor="status">Status</label>
                <SelectListGroup
                  placeholder="status"
                  onChange={this.onChange}
                  value={this.state.status}
                  name="status"
                  error={errors.status}
                  options={statusOptions}
                />
                <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
              </form>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
CreateSeparateContract.propTypes = (state) => ({
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
});

const mapStateToProps = (state) => ({
  application: state.application,
  contract: state.contract,
  profile: state.profile,
  course: state.course,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  createSeparateContract,
  getTutors,
  getUserContracts,
  getCourses,
  getCurrentProfile,
})(withRouter(CreateSeparateContract));
