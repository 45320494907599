import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

// UI Components
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import PageHeader from 'components/ui/page-header/PageHeader';

// Actions
import { editMetacourse, getMetacourseById } from 'actions/metacourseActions';

// Services
import { getGroupsByUserID } from 'services/GroupService';

// Utils
import { isEmpty } from 'utils/utils';

const EditMetacourse = ({ auth, metacourse, errors, editMetacourse, getMetacourseById, match }) => {
  const history = useHistory();
  const [state, setState] = useState({
    name: '',
    scheme: '',
    fondsnumber: '',
    costcentre: '',
    abbreviation: '',
    module: '',
    kurstype: '',
    degree: '',
    compulsory: '',
    semesterdate: '',
    group: '',
    groupOptions: [],
    loading: false,
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (errors) {
      setState((prevState) => ({
        ...prevState,
        errors,
      }));
    }
  }, [errors]);

  const fetchData = async () => {
    const metacourseId = match.params.id;
    getMetacourseById(metacourseId);
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const result = await getGroupsByUserID(auth.user.id);
    const groupRetrieved = result?.data?.group || [];
    const groupOptions = [
      { label: 'Bitte Gruppe auswählen', value: '' },
      ...groupRetrieved.map((group) => ({
        label: group.title,
        value: group._id,
      })),
    ];
    setState((prevState) => ({
      ...prevState,
      groupOptions,
      loading: false,
    }));
  };

  useEffect(() => {
    if (metacourse && !isEmpty(metacourse.metacourse)) {
      const { metacourse: fetchedMetacourse } = metacourse;

      setState((prevState) => ({
        ...prevState,
        name: fetchedMetacourse?.name || '',
        scheme: fetchedMetacourse?.scheme || '',
        fondsnumber: fetchedMetacourse?.fondsnumber || '',
        costcentre: fetchedMetacourse?.costcentre || '',
        abbreviation: fetchedMetacourse?.abbreviation || '',
        module: fetchedMetacourse?.module || '',
        kurstype: fetchedMetacourse?.kurstype || '',
        degree: fetchedMetacourse?.degree || '',
        compulsory: fetchedMetacourse?.compulsory || '',
        semesterdate: fetchedMetacourse?.semesterdate || '',
        group: fetchedMetacourse?.group || '',
      }));
    }
  }, [metacourse]);

  const onSubmit = (e) => {
    e.preventDefault();
    const metacourseData = {
      name: state.name,
      scheme: state.scheme,
      fondsnumber: state.fondsnumber,
      costcentre: state.costcentre,
      abbreviation: state.abbreviation,
      module: state.module,
      kurstype: state.kurstype,
      degree: state.degree,
      compulsory: state.compulsory,
      semesterdate: state.semesterdate,
      group: state.group,
    };

    editMetacourse(match.params.id, metacourseData, history, auth.user.role);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const {
    loading,
    name,
    scheme,
    fondsnumber,
    costcentre,
    abbreviation,
    module,
    kurstype,
    degree,
    compulsory,
    semesterdate,
    group,
    groupOptions,
  } = state;
  const { user } = auth;

  function addMandoryField(val) {
    if (user.role !== 'Manager') {
      return '* ' + val;
    }
    return val;
  }

  const MaßnahmeOptions = [
    { label: addMandoryField('Maßnahme'), value: '' },
    { label: 'Tutorien 1.21.', value: 'Tutorien 1.21.' },
    { label: 'Vorkurse 1.22.', value: 'Vorkurse 1.22.' },
    { label: 'Repetitorien 1.2.', value: 'Repetitorien 1.2.' },
    { label: 'Programmierprojekte 1.3.', value: 'Programmierprojekte 1.3.' },
  ];

  const typeOptions = [
    { label: 'Kurstyp auswählen', value: '' },
    { label: 'Vorlesung', value: 'Vorlesung' },
    { label: 'Vorlesung-Übung', value: 'Vorlesung-Übung' },
    {
      label: 'Vorlesung mit integrierten Übungen',
      value: 'Vorlesung mit integrierten Übungen',
    },
    { label: 'Seminar', value: 'Seminar' },
    { label: 'Praktikum', value: 'Praktikum' },
    { label: 'Forschungspraktikum', value: 'Forschungspraktikum' },
    { label: 'Other', value: 'Other' },
  ];

  const degreeOptions = [
    { label: 'Akademischer Grad auswählen', value: '' },
    { label: 'Bachelor', value: 'Bachelor' },
    { label: 'Master', value: 'Master' },
  ];

  const booleanOptions = [
    { label: 'Auswählen', value: '' },
    { label: 'Ja', value: 'true' },
    { label: 'Nein', value: 'false' },
  ];

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Module', key: 'module' },
    { label: 'Abbreviation', key: 'abbreviation' },
    { label: 'Scheme', key: 'scheme' },
    { label: 'Semester Date', key: 'semesterdate' },
    { label: 'Pflichtfach', key: 'compulsory' },
    { label: 'Cost Centre', key: 'costcentre' },
    { label: 'Akademischer Grad', key: 'degree' },
    { label: 'Fonds Number', key: 'fondsnumber' },
    { label: 'Kurstyp', key: 'kurstype' },
  ];

  const data = [
    {
      name: name,
      scheme: scheme,
      fondsnumber: fondsnumber,
      costcentre: costcentre,
      abbreviation: abbreviation,
      module: module,
      kurstype: kurstype,
      degree: degree,
      compulsory: compulsory,
      semesterdate: semesterdate,
    },
  ];

  const csvReport = {
    data: data,
    headers: headers,
    filename: name + '.csv',
  };

  const backRoute =
    user.role === 'Admin' || user.role === 'Manager' ? '/metacourse-overview' : '/advisor-metacourse-overview';

  return (
    <div className="editMetacourse">
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Metaveranstaltung Bearbeiten`} route={backRoute} />
            <small className="d-block pb-3">* = benötigte Felder</small>
            <CSVLink className="btn btn-primary mb-4" {...csvReport}>
              Export Data .csv
            </CSVLink>

            <form onSubmit={onSubmit}>
              {(user.role === 'Admin' || user.role === 'Manager') && (
                <div>
                  <TextFieldGroup
                    placeholder="* Bezeichnung Metaveranstaltung"
                    onChange={onChange}
                    value={name}
                    name="name"
                    error={errors.name}
                    info="* Bezeichnung Metaveranstaltung"
                  />
                  <SelectListGroup
                    placeholder={addMandoryField('Maßnahme')}
                    onChange={onChange}
                    value={scheme}
                    name="scheme"
                    error={errors.scheme}
                    options={MaßnahmeOptions}
                    info={addMandoryField('Maßnahme (nur bei Studienbeiträgen)')}
                  />
                  <TextFieldGroup
                    placeholder="* Fondsnummer"
                    onChange={onChange}
                    value={fondsnumber}
                    name="fondsnumber"
                    error={errors.fondsnumber}
                    info="* Fondsnummer"
                  />
                  <TextFieldGroup
                    placeholder="Kostenstelle"
                    onChange={onChange}
                    value={costcentre}
                    name="costcentre"
                    error={errors.costcentre}
                    info="Kostenstelle"
                  />
                  <TextFieldGroup
                    placeholder={addMandoryField('Abkürzung')}
                    onChange={onChange}
                    value={abbreviation}
                    name="abbreviation"
                    error={errors.abbreviation}
                    info={addMandoryField('Abkürzung')}
                  />
                  <TextFieldGroup
                    placeholder="Modul"
                    onChange={onChange}
                    value={module}
                    name="module"
                    error={errors.module}
                    info="Modul"
                  />
                </div>
              )}
              <SelectListGroup
                placeholder="Kurstyp"
                onChange={onChange}
                value={kurstype}
                name="kurstype"
                error={errors.kurstype}
                options={typeOptions}
                info={addMandoryField('Kurstyp')}
              />
              <SelectListGroup
                placeholder="Akademischer Grad"
                onChange={onChange}
                value={degree}
                name="degree"
                error={errors.degree}
                options={degreeOptions}
                info="Akademischer Grad"
              />
              <SelectListGroup
                placeholder="Pflichtfach"
                onChange={onChange}
                value={compulsory}
                name="compulsory"
                error={errors.compulsory}
                options={booleanOptions}
                info="Pflichtfach"
              />
              <label htmlFor="Semester">Semester:</label>
              <TextFieldGroup
                placeholder="Semester"
                onChange={onChange}
                value={semesterdate}
                name="semesterdate"
                error={errors.semesterdate}
                info="From which semester student can take this course"
              />
              <SelectListGroup
                placeholder="Group"
                onChange={onChange}
                value={group}
                name="group"
                error={errors.group}
                options={groupOptions}
                info="* Group"
              />
              <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
            </form>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

EditMetacourse.propTypes = {
  metacourse: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  editMetacourse: PropTypes.func.isRequired,
  getMetacourseById: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  metacourse: state.metacourse,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { editMetacourse, getMetacourseById })(withRouter(EditMetacourse));
