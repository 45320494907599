import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import BSNavbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '../../assets/logo/logo.png';
import Container from 'react-bootstrap/Container';
import { logoutUser } from '../../features/auth/authSlice';

const Navbar = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const onLogoutClick = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };

  const authLinks = (
    <Nav as="ul" className="ms-auto">
      <Nav.Item as="li">
        <Nav.Link as={NavLink} to="/dashboard" className="text-white">
          Dashboard
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link onClick={onLogoutClick} className="text-white">
          Logout
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );

  const guestLinks = <ul className="navbar-nav ms-auto"></ul>;

  return (
    <BSNavbar className="mb-4 tum-logo p-0" expand="sm" variant="dark">
      <Container>
        <BSNavbar.Brand as={NavLink} to="/" style={{ padding: '0px' }}>
          <img src={logo} alt="Logo" className="img-fluid" style={{ width: '11rem', height: 'auto' }} />
        </BSNavbar.Brand>
        <BSNavbar.Toggle aria-controls="mobile-nav" />

        <BSNavbar.Collapse id="mobile-nav">{isAuthenticated ? authLinks : guestLinks}</BSNavbar.Collapse>
      </Container>
    </BSNavbar>
  );
};

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

export default Navbar;
