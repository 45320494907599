import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

// UI Components
import TextAreaFieldGroup from 'components/ui/text-field/TextAreaFieldGroup';
import PageHeader from 'components/ui/page-header/PageHeader';

// Actions
import { editCourseDetails, getCourseById } from 'actions/courseActions';

const EditAdvisorDetails = () => {
  const [requirement, setRequirement] = useState('');
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const course = useSelector((state) => state.course.course);
  const auth = useSelector((state) => state.auth);
  const errorState = useSelector((state) => state.errors);

  useEffect(() => {
    dispatch(getCourseById(id));
  }, [id]);

  useEffect(() => {
    if (errorState) {
      setErrors(errorState);
    }

    if (course) {
      setRequirement(course?.requirement);
    }
  }, [errorState, course]);

  const onSubmit = (e) => {
    e.preventDefault();
    const courseData = {
      requirement,
      role: auth.user.role,
    };

    dispatch(editCourseDetails(id, courseData, history));
  };

  const onChangeRequirement = (e) => {
    setRequirement(e.target.value);
  };

  return (
    <div className="editCourseDetails">
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Edit Requirements`} />
            <form onSubmit={onSubmit}>
              <p>*Please enter the minimum requirements for selection</p>
              <label htmlFor="inputRequirements">Requirements</label>
              <TextAreaFieldGroup
                placeholder="Requirements"
                onChange={onChangeRequirement}
                value={requirement}
                name="requirement"
                error={errors?.requirement}
              />
              <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
            </form>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdvisorDetails;