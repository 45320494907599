import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getApplicationsOfCourse,
  acceptApplication,
  declineApplication,
  appliedApplication,
} from '../../actions/applicationActions';
import { TutorDataExport } from '../../actions/formsActions';
import { getCurrentProfile } from '../../actions/profileActions';
import { getCourseById } from '../../actions/courseActions';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import PageHeader from '../ui/page-header/PageHeader';
import Table from '../ui/table/Table';
import SearchBar from '../ui/table/SearchBar';
import LinkButton from '../ui/button/LinkButton';
import moment from 'moment';

const AdvisorApplicationView = (props) => {
  const dispatch = useDispatch();
  const { id } = props.match.params;

  useEffect(() => {
    dispatch(getApplicationsOfCourse(id));
    dispatch(getCourseById(id));
    dispatch(getCurrentProfile());
  }, [dispatch, id]);

  const applications = useSelector((state) => state.application.applications);
  const course = useSelector((state) => state.course?.course);
  const profile = useSelector((state) => state.profile.profile);

  const [fil, setFil] = useState('0');
  const [errors, setErrors] = useState([]);

  const coursename = course?.metacourse?.name;
  const coursesem = course?.semester?.name;

  const onDownloadClick = (e) => {
    e.preventDefault();
    dispatch(TutorDataExport(id));
  };

  let applicationTable;

  //Array for Table
  const entries = applications ? applications : [];

  //Stats Calculations
  let maxNumberTutors;
  if (course) {
    maxNumberTutors = course.maxtutornumber;
  }

  let currentTutorsNumber = 0;
  entries.forEach((e) => {
    if (e.status === 'Accepted' || e.status === 'Contract') {
      currentTutorsNumber += 1;
    }
  });

  let fontStats;
  if (currentTutorsNumber > maxNumberTutors) {
    fontStats = 'text-center text-danger';
  } else {
    fontStats = 'text-center';
  }

  //Dash Button Filter
  let newArray = entries.filter((el) => {
    if (el.user) {
      if (fil === '0') return el;
      if (fil === 'Applied') return el.status === 'Applied';
      if (fil === 'Accepted') return el.status === 'Accepted';
      if (fil === 'Contract') return el.status === 'Contract';
      if (fil === 'Declined') return el.status === 'Declined';
      if (fil === 'LastLogin') {
        if (profile.user.lastlogin) {
          if (el.date >= profile.user.lastlogin) {
            return true;
          }
        }
      }
    }
  });

  //Accept Button
  function acceptButton(cell, row, rowIndex, formatExtraData) {
    if (row) {
      if (row.status) {
        if (row.status === 'Applied') {
          return (
            <button onClick={() => dispatch(acceptApplication(row._id, id))} className="btn btn-primary">
              Accept Application
            </button>
          );
        } else if (row.status === 'Accepted' || row.status === 'Declined') {
          return (
            <button onClick={() => dispatch(appliedApplication(row._id, id))} className="btn btn-secondary">
              Reset Application
            </button>
          );
        }
      }
    }
  }

  function declineButton(cell, row, rowIndex, formatExtraData) {
    if (row.status) {
      if (row.status === 'Applied') {
        return (
          <button onClick={() => dispatch(declineApplication(row._id, id))} className="btn btn-danger">
            Decline Application
          </button>
        );
      }
    }
  }

  function betrachtenButton(cell, row, rowIndex, formatExtraData) {
    return (
      <LinkButton
        to={{
          pathname: `/applicationdetails/${row._id}`,
        }}
        text={'Profile'}
        variant={'info'}
      />
    );
  }

  function contractViewButton(cell, row, rowIndex, formatExtraData) {
    return (
      <LinkButton
        to={{
          pathname: `/contract/${row._id}`,
        }}
        text={'View'}
        variant={'info'}
      />
    );
  }

  const prioFormatter = (value) => {
    if (value === '3') {
      return 'High';
    } else if (value === '2') {
      return 'Medium';
    } else if (value === '1') {
      return 'Low';
    }
  };

  const commentFormatter = (value, row, rowIndex, formatExtraData) => {
    return (
      <LinkButton
        to={{
          pathname: `/comment/${row._id}`,
        }}
        text={'Comment'}
        variant={'info'}
      />
    );
  };

  function dateFormat(value, row, index) {
    if (value) return moment(value).format('DD/MM/YYYY');
  }

  //TODO: Filter nach Applied, Accepted, Declined, New

  if (!applications || applications.length > 0) {
    const columns = [
      {
        dataField: 'profile.lastname',
        text: 'Nachname',
        sort: true,
      },
      {
        dataField: 'profile.firstname',
        text: 'Vorname',
        sort: true,
      },
      {
        dataField: 'date',
        text: 'Application Date',
        sort: true,
        formatter: dateFormat,
      },
      {
        dataField: 'grade',
        text: 'Grade',
        sort: true,
      },
      {
        dataField: 'priority',
        text: 'Priority',
        sort: true,
        formatter: prioFormatter,
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
      },
      {
        dataField: 'dummyView',
        isDummyField: true,
        text: 'View Profile',
        formatter: betrachtenButton,
      },
      {
        dataField: 'dummyAccept',
        isDummyField: true,
        text: 'Accept',
        formatter: acceptButton,
      },
      {
        dataField: 'dummyDecline',
        isDummyField: true,
        text: 'Decline',
        formatter: declineButton,
      },
      {
        dataField: 'dummyComment',
        isDummyField: true,
        text: 'Comment',
        formatter: commentFormatter,
      },
      {
        dataField: 'contract',
        isDummyField: true,
        text: 'View Contract',
        formatter: contractViewButton,
      },
    ];

    applicationTable = (
      <ToolkitProvider bootstrap4 keyField="_id" data={newArray} columns={columns} search>
        {(props) => (
          <div>
            <h6 className="mt-2">Filter:</h6>
            <div style={{ overflowY: 'auto', marginBottom: '1rem' }}>
              <div className="btn-group" style={{ marginRight: '1rem' }}>
                <button className={fil === '0' ? 'btn btn-primary' : 'btn btn-light'} onClick={() => setFil('0')}>
                  {' '}
                  Alle
                </button>
                <button
                  className={fil === 'Applied' ? 'btn btn-primary' : 'btn btn-light'}
                  onClick={() => setFil('Applied')}
                >
                  {' '}
                  Applied
                </button>

                <button
                  className={fil === 'Accepted' ? 'btn btn-primary' : 'btn btn-light'}
                  onClick={() => setFil('Accepted')}
                >
                  {' '}
                  Accepted
                </button>

                <button
                  className={fil === 'Declined' ? 'btn btn-primary' : 'btn btn-light'}
                  onClick={() => setFil('Declined')}
                >
                  {' '}
                  Declined
                </button>

                <button
                  className={fil === 'Contract' ? 'btn btn-primary' : 'btn btn-light'}
                  onClick={() => setFil('Contract')}
                >
                  {' '}
                  Contract
                </button>

                <button
                  className={fil === 'LastLogin' ? 'btn btn-primary' : 'btn btn-light'}
                  title="* New applications since last Login"
                  onClick={() => setFil('LastLogin')}
                >
                  New since Last Login
                </button>
              </div>
              <SearchBar {...props.searchProps} />
            </div>
            <h3 className={fontStats}>
              Accepted Postions: {currentTutorsNumber} / {maxNumberTutors}
            </h3>
            <p><strong>Disclaimer:</strong> The grades displayed below for the students are provided as entered by the students themselves and have not been officially verified.</p>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <LinkButton
                to={{
                  pathname: `/contract/course/${id}`,
                }}
                text={'View All Contracts'}
                variant={'primary'}
              />
            </div>
            <hr />
            <Table baseProps={props.baseProps} />
          </div>
        )}
      </ToolkitProvider>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <PageHeader heading={`Applications for ${coursename} in ${coursesem}`} route={'/advisor-classes'} />
          <br />
          <div className="btn-group">
            <button
              type="button"
              title="Export the data of tutors, that already have a contract to an excel file"
              onClick={(e) => onDownloadClick(e)}
              className="btn btn-info"
            >
              Export Contracts
            </button>
            <LinkButton to={{ pathname: `/advisor-edit-course/${id}` }} text={`Edit Requirements`} variant={'secondary'} />
          </div>
          {applicationTable}
        </div>
      </div>
    </div>
  );
};

AdvisorApplicationView.propTypes = {
  getApplicationsOfCourse: PropTypes.func.isRequired,
  acceptApplication: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

export default AdvisorApplicationView;
