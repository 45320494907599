import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getTutorApplications } from '../../actions/applicationActions';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import PageHeader from '../ui/page-header/PageHeader';
import Table from '../ui/table/Table';
import SearchBar from '../ui/table/SearchBar';
import LinkButton from '../ui/button/LinkButton';

class MyApplications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      help: false,
    };
  }

  componentDidMount() {
    this.props.getTutorApplications();
  }

  onApplyClick(id) {
    this.props.postApplication(id);
  }

  render() {
    const { applications } = this.props.application;
    let applicationTable;

    //Data for Table
    const entries = applications ? applications : [];

    var newArray = entries.filter((el) => {
      // if (el.course) {
      //   if (el.course.semester) {
      //     if (el.course.semester.courseto) {
      //       return new Date(Date.now()) < new Date(el.course.semester.to);
      //     }
      //   }
      // }
      return el;
    });

    function betrachtenButton(cell, row, course, rowIndex, formatExtraData) {
      if (row.status === 'Applied') {
        return (
          <LinkButton
            to={{
              pathname: `/updateapplication/${row._id}`,
            }}
            text={'Edit'}
            variant={'info'}
          />
        );
      }
    }

    var help;
    if (this.state.help === true) {
      help = (
        <div>
          <h6>
            Here you can see all your Applications: If the Status is "Accepted" you have been accepted to be a tutor for
            this course. If the status is "Contract" a contract has been created for you - Check "My Contracts"
          </h6>
          <hr />
        </div>
      );
    }

    const prioFormatter = (value, cell, row, rowIndex, formatExtraData) => {
      if (value === '3') {
        return 'High';
      } else if (value === '2') {
        return 'Medium';
      } else if (value === '1') {
        return 'Low';
      }
    };

    if (applications && applications.length > 0) {
      const columns = [
        {
          dataField: 'course.metacourse.name',
          text: 'Course',
          sort: true,
        },
        {
          dataField: 'course.metacourse.module',
          text: 'Module',
          sort: true,
        },
        {
          dataField: 'grade',
          text: 'My Grade',
          sort: true,
        },
        {
          dataField: 'priority',
          text: 'Priority',
          sort: true,
          formatter: prioFormatter,
        },
        {
          dataField: 'status',
          text: 'Status',
          sort: true,
        },
        {
          dataField: 'dummyView',
          isDummyField: true,
          text: 'Edit',
          formatter: betrachtenButton,
        },
      ];

      applicationTable = (
        <ToolkitProvider bootstrap4 keyField="_id" data={newArray} columns={columns} search>
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <button
                type="button"
                onClick={() => {
                  this.setState((prevState) => ({
                    help: !prevState.help,
                  }));
                }}
                className="btn btn-info float-right"
              >
                Help
              </button>{' '}
              <hr />
              {help}
              <Table baseProps={props.baseProps} />
            </div>
          )}
        </ToolkitProvider>
      );
    } else {
      applicationTable = (
        <div>
          <hr />
          <h3 className="text-center">You have not applied yet for an open position</h3>
        </div>
      );
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <PageHeader heading={`My Applications`} route={'/dashboard'} />
            {applicationTable}
          </div>
        </div>
      </div>
    );
  }
}

MyApplications.propTypes = {
  getTutorApplications: PropTypes.func.isRequired,
  getCoursesForApplication: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  application: state.application,
  course: state.course,
});

export default connect(mapStateToProps, {
  getTutorApplications,
})(MyApplications);
