import React from 'react';

function Impressum() {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-12 text-center">
          <h1 className="display-4 mb-4">Impressum</h1>
          <hr className="hr-style" />
        </div>
      </div>

      <div className="row mt-5 mb-5">
        <div className="col-md-12">
          <section>
            <h4 className="section-title">Herausgeber</h4>
            <p>
              Technische Universität München
              <br />
              Arcisstraße 21
              <br />
              80333 München
              <br />
              Telefon: +4989289-01
              <br />
              Email: <a href="mailto:poststelle@tum.de">poststelle@tum.de</a>
            </p>
          </section>

          <section>
            <h4 className="section-title">Rechtsform und Vertretung</h4>
            <p>
              Die Technische Universität München ist eine Körperschaft des Öffentlichen Rechts und staatliche
              Einrichtung (Art. 11 Abs. 1 BayHSchG). Sie wird gesetzlich vertreten durch den Präsidenten Prof. Dr.
              Thomas F. Hofmann.
            </p>
          </section>

          <section>
            <h4 className="section-title">Zuständige Aufsichtsbehörde</h4>
            <p>Bayerisches Staatsministerium für Wissenschaft und Kunst</p>
          </section>

          <section>
            <h4 className="section-title">Umsatzsteueridentifikationsnummer</h4>
            <p>DE811193231 (gemäß § 27a Umsatzsteuergesetz)</p>
          </section>

          <section>
            <h4 className="section-title">Inhaltlich verantwortlich</h4>
            <p>
              Dr. Anton Frank
              <br />
              Boltzmannstr. 3<br />
              85748 Garching
              <br />
              Email: <a href="mailto:anton.frank@tum.de">anton.frank@tum.de</a>
            </p>
          </section>

          <section>
            <h4 className="section-title">Technische Umsetzung</h4>
            <p>
              Dieser Webauftritt ist ein Angebot des Tutorbetriebs der Fakultät für Informatik der TUM. Technischer
              Ansprechpartner ist der Tutorbetrieb, erreichbar unter{' '}
              <a href="mailto:tutorbetrieb@in.tum.de">tutorbetrieb@in.tum.de</a>.
            </p>
          </section>

          <section>
            <h4 className="section-title">Nutzungsbedingungen</h4>
            <p>
              Texte, Bilder, Grafiken sowie die Gestaltung dieses Webauftritts können dem Urheberrecht unterliegen. Auch
              über das Bestehen möglicher Rechte Dritter geben Ihnen die für den Inhalt Verantwortlichen nähere
              Auskünfte. Eine Vervielfältigung oder Verwendung urheberrechtlich geschützten Materials dieser Seiten oder
              Teilen davon in anderen elektronischen oder gedruckten Publikationen und deren Veröffentlichung ist nur
              mit unserer Einwilligung gestattet. Diese Einwilligung erteilen auf Anfrage die für den Inhalt
              Verantwortlichen.
            </p>
          </section>

          <section>
            <h4 className="section-title">Haftungsausschluss</h4>
            <p>
              Alle in diesem Webauftritt bereitgestellten Informationen haben wir nach bestem Wissen und Gewissen
              erarbeitet und geprüft. Eine Gewähr für die jederzeitige Aktualität, Richtigkeit, Vollständigkeit und
              Verfügbarkeit der bereitgestellten Informationen können wir allerdings nicht übernehmen. Ein
              Vertragsverhältnis mit den Nutzern des Webauftritts kommt nicht zustande. Wir haften nicht für Schäden,
              die durch die Nutzung dieses Webauftritts entstehen. Dieser Haftungsausschluss gilt nicht, soweit die
              Vorschriften des § 839 BGB (Haftung bei Amtspflichtverletzung) einschlägig sind. Für etwaige Schäden, die
              beim Aufrufen oder Herunterladen von Daten durch Schadsoftware oder der Installation oder Nutzung von
              Software verursacht werden, übernehmen wir keine Haftung.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Impressum;
