import React from 'react';
import Select from 'react-select';

const TempMultiSelect = ({ options, isMulti, setOptions, placeholder, defaultValue, error }) => {
  const handleChange = (selected) => {
    setOptions(selected);
  };

  return (
    <div>
      <Select
        placeholder={<div>{placeholder}</div>}
        options={options}
        isMulti={isMulti}
        onChange={handleChange}
        value={defaultValue}
      />
      {error && <p style={{ color: '#dc3545', fontSize: '0.875em' }}>{error}</p>}
    </div>
  );
};

export default TempMultiSelect;
