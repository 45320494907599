import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getApplicationOfId, commentApplication } from 'actions/applicationActions';
import isEmpty from 'utils/is-empty';
import TextAreaFieldGroup from 'components/ui/text-field/TextAreaFieldGroup';
import PageHeader from 'components/ui/page-header/PageHeader';

const AddComment = ({ match, history }) => {
  const [comment, setComment] = useState('');
  const [courseID, setCourseID] = useState('');
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const applicationState = useSelector((state) => state.application);
  const authState = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getApplicationOfId(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (applicationState.application) {
      const { application } = applicationState;
      const updatedComment = !isEmpty(application.comment) ? application.comment : '';
      setComment(updatedComment);
      if (application.course) {
        setCourseID(application.course._id);
      }
    }
  }, [applicationState.application]);

  const onSubmit = (e) => {
    e.preventDefault();
    const applicationData = {
      comment,
      role: authState.user.role,
      courseID,
    };
    dispatch(commentApplication(match.params.id, applicationData, history));
  };

  const onChange = (e) => {
    setComment(e.target.value);
  };

  let name = '';
  if (applicationState.application && applicationState.application.profile) {
    const { firstname, lastname } = applicationState.application.profile;
    name = `${firstname} ${lastname}`;
  }

  let backRoute = '';
  if (authState.user.role === 'Advisor') {
    backRoute = `/check-applications/${courseID}`;
  } else if (location?.fromAllAppView && (authState.user.role === 'Admin' || authState.user.role === 'Manager')) {
    backRoute = `/allapplications-overview`;
  } else if (authState.user.role === 'Admin' || authState.user.role === 'Manager') {
    backRoute = `/course-applications/${courseID}`;
  }

  return (
    <div className="AddComment">
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={`Application Comment for ${name}`} route={backRoute} />
            <form onSubmit={onSubmit}>
              <label htmlFor="inputComment">Comment</label>
              <TextAreaFieldGroup
                placeholder="Comment"
                onChange={onChange}
                value={comment}
                name="comment"
                error={errors.comment}
                info="This comment field is for internal purposes only (e.g. notes on the application process, planned contract, etc.). It is not shown to the applicant."
              />
              <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
            </form>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

AddComment.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(AddComment);
