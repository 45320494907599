import React from 'react';
import LanguageSelector from '../common/LanguageSelector.js';
import TutorInformation from './TutorInformation.js';
import PageHeader from '../ui/page-header/PageHeader.js';

export default function TutorInformationStandalone() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 m-auto mb-3">
          <PageHeader heading={'ASTRA Info'} route={'/dashboard'} />
          <LanguageSelector component={TutorInformation} />
        </div>
      </div>
    </div>
  );
}
