import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import TutorInformation from './TutorInformation';

async function confirm(contractId) {
  await axios.put(`/api/contract/informationread/${contractId}`);
}

function ReadConfirmation(props) {
  const [confirmed, setConfirmed] = useState(false);

  let labelText;
  let buttonText;
  switch (props.lang) {
    case 'en':
      labelText = 'I hereby confirm that I have read all information carefully that is provided here.';
      buttonText = 'Confirm';
      break;

    case 'de':
      labelText = 'Ich bestätige, dass ich diese Informationen aufmerksam gelesen habe.';
      buttonText = 'Bestätigen';
      break;

    default:
      break;
  }

  return (
    <>
      <TutorInformation lang={props.lang} />
      <Form.Check
        id={`confirm-${props.lang}`}
        label={labelText}
        checked={confirmed}
        onChange={(e) => setConfirmed(e.target.checked)}
        className="mb-3"
      />

      <button
        className="btn btn-primary btn-block mb-3"
        onClick={() => confirm(props.contractId).then(props.onInformationRead)}
        disabled={!confirmed}
      >
        {buttonText}
      </button>
    </>
  );
}

ReadConfirmation.propTypes = {
  lang: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  onInformationRead: PropTypes.func,
};

export default ReadConfirmation;
