import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Accordion from '../ui/accordion/Accordion';
import PageHeader from '../ui/page-header/PageHeader';
import { GET_ERRORS } from '../../actions/types';
import CustomSpinner from '../ui/spinner/Spinner';

function Faq() {
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const dispatch = useDispatch();

  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = () => {
    axios
      .get('/api/other/faqs/all')
      .then((res) => {
        const sortedResult = res?.data.sort((a, b) => a.position - b.position);
        setFaqs(sortedResult);
        setIsLoading(false);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: {},
        });
        setIsLoading(false); // Turn off loading indicator in case of an error
      });
  };

  return (
    <div className="faq">
      <div className="container">
        <PageHeader heading={'FAQ'} route={'/dashboard'} />
        <div className="mt-5 mb-5">
          {isLoading ? (
            <CustomSpinner />
          ) : (
            faqs.map((faqitem, index) => <Accordion key={index} title={faqitem.title} content={faqitem.content} />)
          )}
        </div>
      </div>
    </div>
  );
}

export default Faq;
