import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Font Awesome Icons
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

// UI Components
import PageHeader from 'components/ui/page-header/PageHeader';
import LinkButton from 'components/ui/button/LinkButton';
import FilterTable from 'components/ui/table/FilterTable';

// Actions
import { getNotificationTemplates, deleteTemplate } from 'actions/notificationActions';

export const NotificationTemplates = (props) => {
  const dispatch = useDispatch();
  const notificationsTemplates = useSelector((state) => state?.notification?.notificationTemplates) || [];

  useEffect(() => {
    dispatch(getNotificationTemplates());
  }, [notificationsTemplates.length]);

  const betrachtenButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <LinkButton
        to={{
          pathname: `/notification-edit-template/${row._id}`,
        }}
        text={'Bearbeiten'}
        variant={'info'}
      />
    );
  };

  const deleteButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button
        onClick={() => {
          if (window.confirm('Are you sure you want to delete this template?')) {
            dispatch(deleteTemplate(row._id, props.history));
          }
        }}
        className="btn btn-danger"
      >
        Vorlage löschen!
      </button>
    );
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Vorlage',
      sort: true,
    },
    {
      dataField: 'dummyView',
      isDummyField: true,
      text: 'Bearbeiten',
      formatter: betrachtenButton,
    },
    {
      dataField: 'dummyDelete',
      isDummyField: true,
      text: 'Löschen',
      formatter: deleteButton,
    },
  ];

  return (
    <div className="container">
      <PageHeader heading={'Benachrichtigungen Vorlagen Übersicht'} route={'/dashboard'} />
      <div className="mb-2">
        <LinkButton
          to={{
            pathname: '/create-notification-template',
          }}
          text={'Neue Vorlage'}
          variant={'info'}
          icon={faUserCircle}
        />
      </div>
      <FilterTable data={notificationsTemplates} columns={columns} />
    </div>
  );
};
