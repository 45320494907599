import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './TutorInformation.css';
import ConfirmButton from '../common/ConfirmButton.js';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import LinkButton from '../ui/button/LinkButton';

const backendUrl = process.env.REACT_APP_BACKEND_URL || '';
const SectionIcon = ({ icon }) => <FontAwesomeIcon icon={icon} size="7x" pull="right" fixedWidth />;

async function persistEntryOrder(order) {
  await axios.put(`/api/tutor-information/reorder`, order);
}

function InfoEntry({ entry, index, edit, onCollectionUpdated }) {
  const onDelete = useCallback(
    async (id) => {
      await axios.delete(`/api/tutor-information/entry/${id}`);
      onCollectionUpdated();
    },
    [onCollectionUpdated]
  );

  return (
    <Draggable key={entry._id} draggableId={entry._id} index={index}>
      {(provided, snapshot) => (
        <Card ref={provided.innerRef} {...provided.draggableProps} className="mb-3">
          <Card.Body>
            {entry.icon && <SectionIcon icon={entry.icon} />}
            <h2 className="card-title">{entry.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: entry.content }} />
            {entry.attachment && (
              <a
                className="card-link btn btn-info"
                href={`${backendUrl}/api/tutor-information/entry/${entry._id}/attachment`}
              >
                {entry.attachment?.description}
              </a>
            )}
          </Card.Body>
          {edit && (
            <Card.Footer {...provided.dragHandleProps}>
              <div className="d-flex justify-content-between">
                <LinkButton
                  to={{
                    pathname: `/tutor-information/edit/${entry._id}`,
                    fromAllAppView: true,
                  }}
                  text={'Edit'}
                  variant={'primary'}
                />
                <div className="mt-2">
                  <FontAwesomeIcon icon={faEllipsisV} className="fas text-secondary" />
                  <FontAwesomeIcon icon={faEllipsisV} className="fas mx-1 text-secondary" />
                  <FontAwesomeIcon icon={faEllipsisV} className="fas text-secondary" />
                </div>
                <ConfirmButton variant="danger" onClick={() => onDelete(entry._id)} className="float-end">
                  Delete
                </ConfirmButton>
              </div>
            </Card.Footer>
          )}
        </Card>
      )}
    </Draggable>
  );
}

function TutorInformation(props) {
  const [entries, setEntries] = useState([]);

  const fetchEntries = useCallback(async () => {
    const res = await axios.get(`/api/tutor-information/entries?lang=${props.lang}`);
    res.data.sort((a, b) => (a.position !== undefined && b.position !== undefined ? a.position - b.position : 0));
    setEntries(res.data);
  }, [props.lang]);

  useEffect(() => {
    fetchEntries();
  }, [fetchEntries]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const reorderedEntries = Array.from(entries);
      const [moved] = reorderedEntries.splice(result.source.index, 1);
      reorderedEntries.splice(result.destination.index, 0, moved);

      setEntries(reorderedEntries);

      const entryOrder = reorderedEntries.map((entry, index) => ({
        id: entry._id,
        pos: index,
      }));
      persistEntryOrder(entryOrder);
    },
    [entries]
  );

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {entries.map((entry, index) => (
                <InfoEntry
                  key={entry._id}
                  entry={entry}
                  index={index}
                  edit={props.edit}
                  onCollectionUpdated={fetchEntries}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {props.edit && (
        <div className="d-flex justify-content-center">
          <LinkButton
            to={{
              pathname: `/tutor-information/edit/new?lang=${props.lang}`,
              fromAllAppView: true,
            }}
            text={'+ Add New'}
            variant={'primary'}
          />
        </div>
      )}
    </>
  );
}

TutorInformation.propTypes = {
  lang: PropTypes.string.isRequired,
  edit: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  edit: state.auth.user.role === 'Admin',
});

export default connect(mapStateToProps)(TutorInformation);
