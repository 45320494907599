import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { deleteNotification } from '../../../actions/profileActions';
import isEmpty from '../../../utils/is-empty';
import NotificationList from './NotificationList';
import PropTypes from 'prop-types';

const Notification = ({ notifications }) => {
  const dispatch = useDispatch();

  const [showNotifications, setShowNotifications] = useState(false);

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleCloseNotification = (id) => {
    dispatch(deleteNotification(id));
  };

  return (
    <>
      <button
        style={{
          border: 'none',
          backgroundColor: 'inherit',
        }}
        onClick={handleNotificationClick}
      >
        <span>
          <FontAwesomeIcon icon={faBell} color={'#3070b3'} size={'lg'} />{' '}
          {isEmpty(notifications) ? null : <span className="badge bg-danger">{notifications.length}</span>}
        </span>
      </button>

      <div className="position-relative">
        {showNotifications && <NotificationList notifications={notifications} onClose={handleCloseNotification} />}
      </div>
    </>
  );
};

Notification.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default Notification;
