import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import moment from 'moment';

function EducationOverview({ education }) {
  const columns = [
    {
      dataField: 'school',
      text: 'School',
    },
    {
      dataField: 'degree',
      text: 'Degree',
    },
    {
      dataField: 'from',
      text: 'Years',
      formatter: (cell, row) => (
        <span>
          <Moment format="DD/MM/YYYY">{moment.utc(row.from)}</Moment> -{' '}
          {row.to === null ? ' Now' : <Moment format="DD/MM/YYYY">{moment.utc(row.to)}</Moment>}
        </span>
      ),
    },
    {
      dataField: 'description',
      text: 'Description',
    },
  ];

  return (
    <div>
      <h5 className="mb-4">Education Credentials</h5>
      <BootstrapTable
        keyField="_id"
        data={education}
        columns={columns}
        classes="table-responsive-md"
        wrapperClasses="table-responsive"
      />
    </div>
  );
}

EducationOverview.propTypes = {
  education: PropTypes.array.isRequired,
};

export default EducationOverview;
