import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const CustomSpinner = () => {
  return (
    <div className="d-flex justify-content-center align-items-center p-4">
      <Spinner animation="border" role="status" variant="primary" size="md">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default CustomSpinner;
