import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ConfirmationModal = ({
  show,
  onClose,
  title = 'Modal heading',
  closeButtonLabel = 'Close',
  saveButtonLabel = 'Save Changes',
  onSave,
  children,
}) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {closeButtonLabel}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            onSave();
          }}
        >
          {saveButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
