import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// React Bootstrap Table Components
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

// UI Components
import PageHeader from 'components/ui/page-header/PageHeader';
import Table from 'components/ui/table/Table';
import SearchBar from 'components/ui/table/SearchBar';
import LinkButton from 'components/ui/button/LinkButton';

// Actions
import { getAdvisorCourses } from 'actions/courseActions';
import { getTUMMetacourses } from 'actions/metacourseActions';
import { TutorAdminDataExport } from 'actions/formsActions';

class AdvisorEventOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fil: 'Open',
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    if (this.props.tummetacourses?.tummetacourses === null) {
      this.props.getTUMMetacourses();
    }
    this.props.getAdvisorCourses();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { courses } = this.props.course;
    const entries = courses ? courses : [];

    const defaultSorted = [
      {
        dataField: 'status',
        order: 'desc',
      },
    ];

    var courseArray = entries.filter((el) => {
      if (this.state.fil === 'Alle') return el.status !== 'Archive';
      if (this.state.fil === 'Open') return el.status === 'Open';
    });

    function betrachtenButton(cell, row, rowIndex, formatExtraData) {
      return (
        <LinkButton
          to={{
            pathname: `/edit-course/${row._id}`,
          }}
          text={'Edit'}
          variant={'info'}
        />
      );
    }

    const numberApplications = (cell, row, rowIndex, formatExtraData) => {
      if (row.applications) {
        return row.applications.length;
      }
    };

    const numberAccepted = (cell, row, rowIndex, formatExtraData) => {
      if (row.applications) {
        return row.applications.filter((x) => x.status === 'Accepted').length;
      }
    };

    const numberVertrag = (cell, row, rowIndex, formatExtraData) => {
      if (row.applications) {
        return row.applications.filter((x) => x.status === 'Contract').length;
      }
    };

    const statusFormatter = (value, cell, row, rowIndex, formatExtraData) => {
      if (value === 'Open') {
        return 'Offen';
      } else if (value === 'Closed') {
        return 'Geschlossen';
      }
    };

    const columns = [
      {
        dataField: 'metacourse[0].name',
        text: 'Veranstaltung',
        sort: true,
      },
      {
        dataField: 'semester[0].name',
        text: 'Semester',
        sort: true,
      },
      {
        dataField: 'weeklyhourspertutor',
        text: (
          <span display="inline" data-toggle="tooltip" data-placement="top" title="Wochenstunden">
            WS
          </span>
        ),
        sort: true,
      },
      {
        text: (
          <span display="inline" data-toggle="tooltip" data-placement="bottom" title="Bewerbungen">
            Bew
          </span>
        ),
        formatter: numberApplications,
        sort: true,
      },
      {
        text: (
          <span display="inline" data-toggle="tooltip" data-placement="top" title="Angenommen">
            Ang
          </span>
        ),
        formatter: numberAccepted,
        sort: true,
      },
      {
        text: (
          <span display="inline" data-toggle="tooltip" data-placement="top" title="Verträge">
            Ver
          </span>
        ),
        formatter: numberVertrag,
        sort: true,
      },
      {
        dataField: 'maxtutornumber',
        text: (
          <span display="inline" data-toggle="tooltip" data-placement="top" title="Maximale Anzahl Tutoren">
            MaxT
          </span>
        ),
        sort: true,
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        formatter: statusFormatter,
      },
      {
        text: 'Edit',
        header: 'Edit',
        id: 'links',
        formatter: betrachtenButton,
      },
    ];

    return (
      <div className="container overflowX" style={{ overflowX: 'auto', paddingBottom: '2rem' }}>
        <PageHeader heading={'Veranstaltungsübersicht'} route={'/dashboard'} />
        <h6></h6>
        <ToolkitProvider bootstrap4 keyField="id" data={courseArray} columns={columns} search>
          {(props) => (
            <div>
              <h6>Status Filter:</h6>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <div className="btn-group">
                    <button
                      className={this.state.fil === 'Open' ? 'btn btn-primary' : 'btn btn-light'}
                      onClick={() => {
                        this.setState({
                          fil: 'Open',
                        });
                      }}
                    >
                      {' '}
                      Offen
                    </button>
                    <button
                      className={this.state.fil === 'Alle' ? 'btn btn-primary' : 'btn btn-light'}
                      onClick={() => {
                        this.setState({
                          fil: 'Alle',
                        });
                      }}
                    >
                      {' '}
                      Alle
                    </button>
                  </div>
                </div>
                <div>
                  <SearchBar {...props.searchProps} />
                </div>
              </div>
              <hr />
              <Table baseProps={props.baseProps} defaultSorted={defaultSorted} />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

AdvisorEventOverview.propTypes = {
  getAdvisorCourses: PropTypes.func.isRequired,
  course: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getTUMMetacourses: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  course: state.course,
  tummetacourses: state.tummetacourses,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAdvisorCourses,
  TutorAdminDataExport,
  getTUMMetacourses,
})(withRouter(AdvisorEventOverview));
