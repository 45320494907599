import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { updateExperience } from '../../actions/profileActions';
import TextFieldGroup from '../ui/text-field/TextFieldGroup';
import TextAreaFieldGroup from '../ui/text-field/TextAreaFieldGroup';
import { convertIsoToDate } from 'utils/utils';
import PageHeader from '../ui/page-header/PageHeader';

const EditExperience = ({ errors, match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state);

  const [experienceData, setExperienceData] = useState({
    company: '',
    title: '',
    location: '',
    from: '',
    to: '',
    current: false,
    description: '',
  });

  useEffect(() => {
    const { id } = match.params;
    const experience = getExperience(id, profile.profile.experience);

    if (!experience) {
      history.replace('/dashboard');
    } else {
      const updatedTo = experience.to ? convertIsoToDate(experience.to) : null;
      setExperienceData({
        company: experience.company,
        title: experience.title,
        location: experience.location,
        from: convertIsoToDate(experience.from),
        to: updatedTo,
        current: experience.current,
        description: experience.description,
      });
    }
  }, []);

  const getExperience = (id, experienceData) => {
    return experienceData.find((exp) => exp._id === id);
  };

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    setExperienceData({
      ...experienceData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const updatedTo = experienceData.current ? null : experienceData.to;
    const expData = {
      company: experienceData.company,
      title: experienceData.title,
      location: experienceData.location,
      from: experienceData.from,
      to: updatedTo,
      current: experienceData.current,
      description: experienceData.description,
    };
    dispatch(updateExperience(match.params.id, expData, history));
  };

  return (
    <>
      <div className={'edit-experience'}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <PageHeader heading={`Edit experience`} route={'/dashboard'} />
              <p className="lead text-center">Add any job or position that you have had in the past or current</p>
              <small className="d-block pb-3">* = required fields</small>
              <form onSubmit={onSubmit}>
                <TextFieldGroup
                  placeholder={'* Company'}
                  name={'company'}
                  onChange={onChange}
                  value={experienceData.company}
                  error={errors?.company}
                />
                <TextFieldGroup
                  placeholder={'* Job title'}
                  name={'title'}
                  onChange={onChange}
                  value={experienceData.title}
                  error={errors?.title}
                />
                <TextFieldGroup
                  placeholder={'Location'}
                  name={'location'}
                  onChange={onChange}
                  value={experienceData.location}
                  error={errors?.location}
                  info="City or city & state suggested (eg. Boston, Ma)"
                />
                <h6>From Date</h6>
                <TextFieldGroup
                  type={'date'}
                  name={'from'}
                  onChange={onChange}
                  value={experienceData.from}
                  error={errors?.from}
                />
                <h6>To Date</h6>
                <TextFieldGroup
                  type={'date'}
                  name={'to'}
                  onChange={onChange}
                  value={experienceData.to}
                  error={errors?.to}
                  disabled={experienceData.current ? 'disabled' : ''}
                />
                <div className="form-check mb-4">
                  <input
                    type="checkbox"
                    name={'current'}
                    className="form-check-input"
                    checked={experienceData.current}
                    onChange={onChange}
                    id={'current'}
                  />
                  <label htmlFor="current" className="form-check-label">
                    Current Institution
                  </label>
                </div>
                <TextAreaFieldGroup
                  placeholder={'Program Description'}
                  name={'description'}
                  onChange={onChange}
                  value={experienceData.description}
                  error={errors?.description}
                  info={'Tell us about the position'}
                />
                <input type="submit" value="Submit" className="btn btn-info btn-block mt-4 mb-4" />
                <br />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EditExperience.propTypes = {
  errors: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default EditExperience;
