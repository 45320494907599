import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

// React Bootstrap Table Components
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// React CSV
import { CSVLink } from 'react-csv';

// UI Components
import PageHeader from 'components/ui/page-header/PageHeader';
import Table from 'components/ui/table/Table';
import SearchBar from 'components/ui/table/SearchBar';
import LinkButton from 'components/ui/button/LinkButton';
import Filters from 'components/ui/table/Filters';

// Actions
import { getCourses } from '../../actions/courseActions';
import { getTUMMetacourses } from '../../actions/metacourseActions';
import { TutorAdminDataExport } from '../../actions/formsActions';

var coursedata = [];

function CourseOverview(props) {
  const [fil, setFil] = useState('Open');
  const { courses } = props.course;
  let entries = courses
    ? courses.map((course) => ({
        ...course,
        applicationsCount: course.applications ? course.applications.length : 0,
        acceptedCount: course.applications ? course.applications.filter((app) => app.status === 'Accepted').length : 0,
        contractsCount: course.applications ? course.applications.filter((app) => app.status === 'Contract').length : 0,
      }))
    : [];

  useEffect(() => {
    if (props.tummetacourses?.tummetacourses === null) {
      props.getTUMMetacourses();
    }
    props.getCourses();
  }, []);

  useEffect(() => {
    if (props.errors) {
      // Handle errors here
    }
  }, [props.errors]);

  const onChange = (e) => {
    setFil(e.target.value);
  };

  const defaultSorted = [
    {
      dataField: 'status',
      order: 'desc',
    },
  ];

  var courseArray = entries.filter((el) => {
    if (fil === 'Alle') return true;
    if (fil === 'Open') return el.status === 'Open';
    if (fil === 'Closed') return el.status === 'Closed';
    if (fil === 'Preparation') return el.status === 'Preparation';
    if (fil === 'Archive') return el.status === 'Archive';
  });

  const betrachtenButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <LinkButton
        to={{
          pathname: `/edit-course/${row._id}`,
        }}
        text={'Edit'}
        variant={'info'}
      />
    );
  };

  const exportButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button type="button" onClick={TutorAdminDataExport(row._id)} className="btn btn-info">
        Export
      </button>
    );
  };

  const statusFormatter = (value, cell, row, rowIndex, formatExtraData) => {
    if (value === 'Open') {
      return 'Offen';
    } else if (value === 'Closed') {
      return 'Geschlossen';
    } else if (value === 'Preparation') {
      return 'Vorbereitung';
    } else if (value === 'Closed') {
      return 'Geschlossen';
    } else if (value === 'Archive') {
      return 'Archiv';
    }
  };

  const courseFormatter = (value, row, rowIndex, formatExtraData) => {
    return (
      <Link to={`/course-applications/${row._id}`} className={'text'}>
        {row?.studentnumberchanged ? <FontAwesomeIcon icon={faExclamationCircle} /> : null} {value}
      </Link>
    );
  };

  const columns = [
    {
      dataField: 'metacourse[0].name',
      text: 'Veranstaltung',
      sort: true,
      formatter: courseFormatter,
    },
    {
      dataField: 'semester[0].name',
      text: 'Semester',
      sort: true,
    },
    {
      dataField: 'weeklyhourspertutor',
      text: 'WS',
      headerAttrs: { title: 'Wochenstunden' },
      sort: true,
    },
    {
      dataField: 'applicationsCount',
      text: 'Bew',
      sort: true,
    },
    {
      dataField: 'acceptedCount',
      text: 'Ang',
      sort: true,
    },
    {
      dataField: 'contractsCount',
      text: 'Ver',
      sort: true,
    },
    {
      dataField: 'maxtutornumber',
      text: 'MaxT',
      headerAttrs: { title: 'Maximale Anzahl Tutoren' },
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: statusFormatter,
    },
    {
      dataField: 'dummyView',
      isDummyField: true,
      text: 'Edit',
      formatter: betrachtenButton,
    },
    {
      dataField: 'dummyExport',
      isDummyField: true,
      text: 'Contracts',
      formatter: exportButton,
    },
  ];

  const headers = [
    { label: 'Module', header: 'Module', key: 'module' },
    { label: 'Course Name', header: 'Course Name', key: 'kursname' },
    { label: 'Advisor Email', header: 'Advisor Email', key: 'advisoremail' },
    {
      label: 'Advisor Firstname',
      header: 'Advisor Firstname',
      key: 'advisorfirstname',
    },
    {
      label: 'Advisor Lastname',
      header: 'Advisor Lastname',
      key: 'advisorlastname',
    },
    {
      label: 'Context Teaching',
      header: 'Context Teaching',
      key: 'contextteaching',
    },
    { label: 'From', header: 'From', key: 'from' },
    { label: 'Till', header: 'Till', key: 'till' },
    {
      label: 'Correction Type',
      header: 'Correction Type',
      key: 'correctiontype',
    },
    { label: 'Exam', header: 'Exam', key: 'exam' },
    { label: 'Final Exam', header: 'Final Exam', key: 'finalexam' },
    {
      label: 'Final Grade Relevance',
      header: 'Final Grade Relevance',
      key: 'finalgraderelevance',
    },
    { label: 'Frequency', header: 'Frequency', key: 'frequency' },
    { label: 'Group Number', header: 'Group Number', key: 'groupnumber' },
    { label: 'Group Size', header: 'Group Size', key: 'groupsize' },
    {
      label: 'Groups Per Tutor',
      header: 'Groups Per Tutor',
      key: 'groupspertutor',
    },
    { label: 'Homework', header: 'Homework', key: 'homework' },
    {
      label: 'Homework Correction',
      header: 'Homework Correction',
      key: 'homeworkcorrection',
    },
    { label: 'Location', header: 'Location', key: 'location' },
    {
      label: 'Maxt Tutor number',
      header: 'Maxt Tutor number',
      key: 'maxtutornumber',
    },
    { label: 'Midterm', header: 'Midterm', key: 'midterm' },
    { label: 'Mid Term exam', header: 'Mid Term exam', key: 'midtermexam' },
    {
      label: 'Compulsory for Bachelor students',
      header: 'Compulsory for Bachelor students',
      key: 'compulsorybachelor',
    },
    {
      label: 'Compulsory for Master students',
      header: 'Compulsory for Master students',
      key: 'compulsorymaster',
    },
    {
      label: 'Organization Unit',
      header: 'Organization Unit',
      key: 'orgunit',
    },
    { label: 'Requirement', header: 'Requirement', key: 'requirement' },
    { label: 'Retake exam', header: 'Retake exam', key: 'retakeexam' },
    { label: 'Semester', header: 'Semester', key: 'semestername' },
    { label: 'Status', header: 'Status', key: 'status' },
    {
      label: 'Student number',
      header: 'Student number',
      key: 'studentnumber',
    },
    {
      label: 'Tutorial hours',
      header: 'Tutorial hours',
      key: 'tutorialhours',
    },
    { label: 'Weeks', header: 'Weeks', key: 'weeks' },
  ];

  if (coursedata.length === 0) {
    for (let i = 0; i < entries.length; i++) {
      entries[i].semestername = entries[i].semester[0]?.name;
      entries[i].kursname = entries[i].metacourse[0]?.name;
      entries[i].module = entries[i].metacourse[0]?.module;
      coursedata.push(entries[i]);
    }
  }

  const data = coursedata;

  const csvReport = {
    data: data,
    headers: headers,
    filename: 'Veranstaltung.csv',
  };

  const roles = [
    { value: 'Open', label: 'Offen' },
    { value: 'Preparation', label: 'Vorbereitung' },
    { value: 'Closed', label: 'Geschlossen' },
    { value: 'Archive', label: 'Archiv' },
    { value: 'Alle', label: 'Alle' },
  ];

  return (
    <div className="container overflowX" style={{ overflowX: 'auto', paddingBottom: '2rem' }}>
      <PageHeader heading={'Veranstaltungsübersicht'} route={'/dashboard'} />
      <div className="btn-group">
        <LinkButton
          to={{ pathname: `/create-course` }}
          text={`Neue Veranstaltung`}
          variant={'info'}
          icon={faUserCircle}
        />
        <LinkButton to={{ pathname: `/budget-overview` }} text={`Budgetübersicht`} variant={'primary'} />
        <CSVLink className="btn btn-info" {...csvReport}>
          Daten exportieren .csv
        </CSVLink>
      </div>

      <h6></h6>
      <ToolkitProvider bootstrap4 keyField="_id" data={courseArray} columns={columns} search>
        {(props) => (
          <div>
            <h6>Status Filter:</h6>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Filters selectedFilter={fil} setFilter={(value) => setFil(value)} options={roles} />
              <div>
                <SearchBar {...props.searchProps} />
              </div>
            </div>
            <hr />
            <Table baseProps={props.baseProps} defaultSorted={defaultSorted} fullSize={true} />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}

CourseOverview.propTypes = {
  getCourses: PropTypes.func.isRequired,
  course: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getTUMMetacourses: PropTypes.func.isRequired,
  tummetacourses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  course: state.course,
  auth: state.auth,
  tummetacourses: state.tummetacourses,
});

export default connect(mapStateToProps, {
  getCourses,
  TutorAdminDataExport,
  getTUMMetacourses,
})(withRouter(CourseOverview));
