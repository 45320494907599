import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment';
import { deleteExperience } from '../../actions/profileActions';
import LinkButton from '../ui/button/LinkButton';

const Experience = ({ deleteExperience, experience }) => {
  const onDeleteClick = (id) => {
    if (window.confirm('Are you sure you want to delete this experience?')) {
      deleteExperience(id);
    }
  };

  const columns = [
    {
      dataField: 'company',
      text: 'Company',
    },
    {
      dataField: 'title',
      text: 'Title',
    },
    {
      dataField: 'location',
      text: 'Location',
    },
    {
      dataField: 'from',
      text: 'Years',
      formatter: (cell, row) => (
        <span>
          <Moment format="DD/MM/YYYY">{moment.utc(row.from)}</Moment> -{' '}
          {row.to === null ? ' Now' : <Moment format="DD/MM/YYYY">{moment.utc(row.to)}</Moment>}
        </span>
      ),
    },
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'edit',
      text: 'Edit',
      formatter: (cell, row) => (
        <LinkButton
          to={{
            pathname: `/edit-experience/${row._id}`,
          }}
          text={'Edit'}
          variant={'info'}
        />
      ),
    },
    {
      dataField: 'delete',
      text: 'Delete',
      formatter: (cell, row) => (
        <button onClick={() => onDeleteClick(row._id)} className="btn btn-danger">
          Delete
        </button>
      ),
    },
  ];

  return (
    <div>
      <h4 className="mb-4">Experience Credentials</h4>
      <BootstrapTable
        keyField="_id"
        striped
        data={experience}
        columns={columns}
        classes="table-responsive-md"
        wrapperClasses="table-responsive"
      />
    </div>
  );
};

Experience.propTypes = {
  deleteExperience: PropTypes.func.isRequired,
  experience: PropTypes.array.isRequired,
};

export default connect(null, { deleteExperience })(Experience);
