import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateMitarbeiterRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated === true && (auth.user.role === 'Admin' || auth.user.role === 'RBG') ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};

PrivateMitarbeiterRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateMitarbeiterRoute;
