import React, { useState } from 'react';
import { Row, FormGroup } from 'react-bootstrap';
import { useHistory } from 'react-router';
import axios from 'axios';

// UI Components
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import PageHeader from 'components/ui/page-header/PageHeader';

const CreateGroup = () => {
  const [groupDetails, setgroupDetails] = useState({
    title: '',
    AnsprechpartnerFirstName: '',
    AnsprechpartnerLastName: '',
    email: '',
    sapOrgID: '',
    telefonnummer: '',
    department: 'School Office (CIT)',
    postkurzel: '',
  });
  const history = useHistory();
  const [error, setError] = useState({});

  const departmentOptions = [
    { label: 'School Office (CIT)', value: 'School Office (CIT)' },
    { label: 'Dep. of Computer Science', value: 'Dep. of Computer Science' },
    {
      label: 'Dep. of Computer Engineering',
      value: 'Dep. of Computer Engineering',
    },
    {
      label: 'Dep. of Electrical Engineering',
      value: 'Dep. of Electrical Engineering',
    },
    { label: 'Dep. of Mathematics', value: 'Dep. of Mathematics' },
  ];

  const handleChange = (e) => {
    setgroupDetails({ ...groupDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await axios.post('/api/group/', groupDetails);
      history.push('/dashboard');
    } catch (error) {
      if (error.response) {
        setError(error.response?.data);
      } else {
        setError(error?.message);
      }
    }
  };

  return (
    <div className={'create-profile'}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <PageHeader heading={'Create Group'} route={'/dashboard'} />
            <form onSubmit={handleSubmit}>
              <label htmlFor="title">* Lehrstuhl/Professur/Einrichtung:</label>
              <TextFieldGroup
                placeholder="Lehrstuhl/Professur/Einrichtung"
                onChange={handleChange}
                value={groupDetails.title}
                name="title"
                error={error.title}
              />
              <Row>
                <FormGroup className="col-md-6">
                  <label htmlFor="title">* Ansprechpartner Vorname:</label>
                  <TextFieldGroup
                    placeholder="Ansprechpartner Vorname"
                    onChange={handleChange}
                    value={groupDetails.AnsprechpartnerFirstName}
                    name="AnsprechpartnerFirstName"
                    error={error.AnsprechpartnerFirstName}
                  />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <label htmlFor="title">* Ansprechpartner Nachname:</label>
                  <TextFieldGroup
                    placeholder="Ansprechpartner Nachname"
                    onChange={handleChange}
                    value={groupDetails.AnsprechpartnerLastName}
                    name="AnsprechpartnerLastName"
                    error={error.AnsprechpartnerLastName}
                  />
                </FormGroup>
              </Row>
              <label htmlFor="email">* Email:</label>
              <TextFieldGroup
                placeholder="Email"
                onChange={handleChange}
                value={groupDetails.email}
                name="email"
                error={error.email}
              />

              <label htmlFor="sapOrgID">* SAP-Nr:</label>
              <TextFieldGroup
                placeholder="SAP-Nr"
                onChange={handleChange}
                value={groupDetails.sapOrgID}
                name="sapOrgID"
                error={error.sapOrgID}
              />

              <label htmlFor="telefonnummer">Telefonnummer:</label>
              <TextFieldGroup
                placeholder="Telefonnummer"
                onChange={handleChange}
                value={groupDetails.telefonnummer}
                name="telefonnummer"
              />

              <label htmlFor="department">Department:</label>
              <SelectListGroup
                placeholder={'Department'}
                onChange={handleChange}
                value={groupDetails.department}
                name="department"
                error={error.department}
                options={departmentOptions}
              />

              <label htmlFor="postkurzel">Postkurzel:</label>
              <TextFieldGroup
                placeholder="Postkurzel"
                onChange={handleChange}
                value={groupDetails.postkurzel}
                name="postkurzel"
              />
              <input type="submit" value="Submit" className="btn btn-info btn-block mt-4 mb-2" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateGroup;
