import axios from 'axios';

import {
  GET_NOTIFICATION_TEMPLATES,
  NOTIFICATION_TEMPLATES_LOADING,
  GET_NOTIFICATION_TEMPLATE,
  GET_ERRORS,
} from './types';

// Get all notification templates
export const getNotificationTemplates = () => (dispatch) => {
  dispatch(setNotificationTemplatesLoading());
  axios
    .get('/api/notification/templates/all')
    .then((res) => {
      dispatch({ type: GET_NOTIFICATION_TEMPLATES, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get notification template of id
export const getNotificationTemplateById = (id) => (dispatch) => {
  dispatch(setNotificationTemplatesLoading());
  axios
    .get(`/api/notification/template/${id}`)
    .then((res) => dispatch({ type: GET_NOTIFICATION_TEMPLATE, payload: res.data }))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Create Notification Template
export const createNotificationTemplate = (templateData, history) => (dispatch) => {
  axios
    .post('/api/notification/template', templateData)
    .then((res) => {
      history.push('/notification-templates');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Edit Notification Template
export const editNotificationTemplate = (id, templateData, history) => (dispatch) => {
  axios
    .put(`/api/notification/updateTemplate/${id}`, templateData)
    .then((res) => {
      history.push('/notification-templates');
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Delete Notification Template
export const deleteTemplate = (id, history) => (dispatch) => {
  axios
    .delete(`/api/notification/deleteTemplate/${id}`)
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATION_TEMPLATES,
        payload: res.data.notificationTemplates,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Send Notification
export const sendNotifications = (notificationData, history) => (dispatch) => {
  axios
    .post(`/api/notification/sendNotification`, notificationData)
    .then((res) => {
      history.push('/dashboard');
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Notification template loading
export const setNotificationTemplatesLoading = () => {
  return {
    type: NOTIFICATION_TEMPLATES_LOADING,
  };
};
