import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, FormGroup } from 'react-bootstrap';

// UI Components
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import TextAreaFieldGroup from 'components/ui/text-field/TextAreaFieldGroup';
import PageHeader from 'components/ui/page-header/PageHeader';
import MultiList from 'components/ui/multi-list/MultiList';
import Spinner from 'components/ui/spinner/Spinner';

// Actions
import { createCourse } from 'actions/courseActions';
import { getSemesters } from 'features/semester/semesterSlice';
import { getMetacourses } from 'actions/metacourseActions';
import { getAdvisors, getAdmins } from 'actions/profileActions';

// Utils
import { isEmpty } from 'utils/utils';

const courseOptions = [];
const courseOptionsMap = new Map();
const advisorsMap = new Map();
class CreateCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metacourse: '',
      semester: '',
      profile: '',
      studentnumber: 0,
      groupnumber: 0,
      groupsize: 0,
      tutorialhours: 0,
      homework: 0,
      exam: 0,
      midterm: 0,
      groupspertutor: 1,
      maxtutornumber: 0,
      weeklyhourspertutor: 0,
      overallweeklyhours: 0,
      overallhours: 0,
      from: '',
      till: '',
      weeks: 1,
      requirement: '',
      location: 'Garching',
      advisor: [],
      status: 'Preparation',
      manualmaxtutor: false,
      manualweekly: false,
      errors: {},
      advisorfirstname: '',
      advisorlastname: '',
      advisoremail: '',
      orgunit: '',
      finalexam: true,
      retakeexam: true,
      midtermexam: false,
      homeworkcorrection: false,
      compulsorybachelor: false,
      compulsorymaster: false,
      frequency: '',
      correctiontype: '',
      finalgraderelevance: '',
      contextteaching: 'None',
      specialissues: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateAdvisor = this.updateAdvisor.bind(this);
  }

  componentDidMount() {
    this.props.getMetacourses();
    this.props.getSemesters();
    this.props.getAdvisors();
    this.props.getAdmins();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.metacourse) {
      const { metacourses } = nextProps.metacourse;
      this.setState({
        metacourses: metacourses,
      });
    }
    if (nextProps.semester) {
      const { semesters } = nextProps.semester;
      this.setState({
        semesters: semesters,
      });
    }
    if (nextProps.profile) {
      const { advisors, admins } = nextProps.profile;
      this.setState({
        advisors: advisors,
        admins: admins,
      });
    }
  }

  updateAdvisor(selectedAdvisor) {
    if (selectedAdvisor !== undefined) {
      this.setState({ advisor: selectedAdvisor });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const courseData = {
      metacourse: this.state.metacourse,
      semester: this.state.semester,
      studentnumber: this.state.studentnumber,
      groupnumber: this.state.groupnumber,
      groupsize: this.state.groupsize,
      tutorialhours: this.state.tutorialhours,
      homework: this.state.homework,
      exam: this.state.exam,
      midterm: this.state.midterm,
      groupspertutor: this.state.groupspertutor,
      maxtutornumber: this.state.maxtutornumber,
      weeklyhourspertutor: this.state.weeklyhourspertutor,
      overallhours: this.state.overallhours,
      overallweeklyhours: this.state.overallweeklyhours,
      from: this.state.from,
      till: this.state.till,
      weeks: this.state.weeks,
      requirement: this.state.requirement,
      location: this.state.location,
      advisor: this.state.advisor,
      status: this.state.status,
      advisorfirstname: this.state.advisorfirstname,
      advisorlastname: this.state.advisorlastname,
      advisoremail: this.state.advisoremail,
      orgunit: this.state.orgunit,
      finalexam: this.state.finalexam,
      retakeexam: this.state.retakeexam,
      midtermexam: this.state.midtermexam,
      compulsorybachelor: this.state.compulsorybachelor,
      compulsorymaster: this.state.compulsorymaster,
      homeworkcorrection: this.state.homeworkcorrection,
      frequency: this.state.frequency,
      correctiontype: this.state.correctiontype,
      finalgraderelevance: this.state.finalgraderelevance,
      contextteaching: this.state.contextteaching,
      specialissues: this.state.specialissues,
    };

    this.props.createCourse(courseData, this.props.history);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'homeworkcorrection') {
      this.setState({
        [e.target.name]: e.target.value === 'true' ? true : false,
      });
    }
  }

  render() {
    const { errors } = this.state;
    var { semesters } = this.state;
    var { metacourses } = this.state;
    var { advisors } = this.state;
    let tummetacourseloading = this.props.tummetacourses?.tummetacourseloading;
    let tumcourses = this.props.tummetacourses?.tummetacourses;

    if (courseOptions.length === 0) {
      for (let i = 0; i < tumcourses?.length; i++) {
        courseOptions.push({
          label: tumcourses[i].courseName,
          value: tumcourses[i].courseName,
        });
        let tumcontacts = tumcourses[i].contacts;
        for (let j = 0; j < tumcontacts.length; j++) {
          let roles = tumcontacts[j].role;
          let role = '';

          if (roles.length !== undefined) {
            role = roles[0]?._attributes?.roleID;
            for (let k = 0; k < roles.length; k++) {
              if (roles[k]?._attributes?.roleID === 'L') {
                role = 'L';
                break;
              }
            }
          } else {
            role = roles._attributes?.roleID;
          }
          tumcontacts[j].roles = role;
        }
        courseOptionsMap.set(tumcourses[i].courseName, tumcourses[i].contacts);
      }
    }

    if (advisorsMap.length === 0 && advisors?.length > 0) {
      for (let i = 0; i < advisors?.length; i++) {
        advisorsMap.set(advisors[i].firstname + ' ' + advisors[i].lastname, advisors[i].user.email);
      }
    }

    //Select options for Midterm
    const midtermOptions = [
      { label: '0', value: '0' },
      { label: '0.5', value: '0.5' },
    ];

    //Select options for Exam
    const examOptions = [
      { label: '0', value: '0' },
      { label: '1', value: '1' },
    ];

    //Select options for Status
    const statusOptions = [
      { label: 'Vorbereitung', value: 'Preparation' },
      { label: 'Offen', value: 'Open' },
      { label: 'Geschlossen', value: 'Closed' },
      { label: 'Archiv', value: 'Archive' },
    ];

    //Select options for Location
    const locationOptions = [
      { label: 'Garching', value: 'Garching' },
      { label: 'München', value: 'München' },
      { label: 'Heilbronn', value: 'Heilbronn' },
    ];

    //Select options
    const booleanOptions = [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ];

    const gradebooleanOptions = [
      { label: 'Grade auswählen', value: '' },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ];

    //Select options for homework
    const homeworkOptions = [
      { label: 'Frequency auswählen', value: '' },
      { label: 'Every Week', value: 'Every Week' },
      { label: 'Every 2nd Week', value: 'Every 2nd Week' },
      { label: 'Every Month', value: 'Every Month' },
    ];

    //Select options
    const correctionOptions = [
      { label: 'Correction Type auswählen', value: '' },
      { label: 'Fully Automatic', value: 'Fully Automatic' },
      { label: 'Partial Automation', value: 'Partial Automation' },
      { label: 'Manual', value: 'Manual' },
    ];

    const contextTeachingOptions = [
      {
        label: 'Course given for some other faculty/school',
        value: 'Course given for some other faculty/school',
      },
      {
        label: 'Course given by some other faculty/school to Informatik students',
        value: 'Course given by some other faculty/school to Informatik students',
      },
      { label: 'None', value: 'None' },
    ];

    this.state.overallweeklyhours = this.state.maxtutornumber * this.state.weeklyhourspertutor;

    this.state.overallhours = this.state.overallweeklyhours * this.state.weeks;

    //Select options for semester
    if (!semesters) {
      semesters = [];
    }
    const semesterOptions = semesters.map((el) => {
      return { label: el.name, value: el.name };
    });
    semesterOptions.unshift({ label: 'Semester auswählen', value: '' });
    //Select options for metacourse
    if (!metacourses) {
      metacourses = [];
    }
    if (
      metacourses !== null &&
      metacourses !== undefined &&
      typeof metacourses === 'object' &&
      metacourses.constructor === Object &&
      Object.entries(metacourses).length === 0
    ) {
      metacourses = [];
    }

    const generateMetacourseOptions = (metacourses) => {
      const metacourseOptions = metacourses.map((el) => {
        const label = el.name.length > 90 ? el.name.substring(0, 87) + '...' : el.name;
        const fullLabel = label + (el.group?.title ? ' - ' + el.group?.title : '');
        return {
          label: fullLabel,
          value: el._id,
        };
      });

      metacourseOptions.unshift({
        label: 'Metaveranstaltung auswählen',
        value: '',
      });

      return metacourseOptions;
    };

    const metacourseOptions = generateMetacourseOptions(metacourses);

    //Select options for advisors
    if (isEmpty(advisors)) {
      advisors = [];
    }

    const advisorOptions = advisors.map((el) => {
      return { name: el.firstname + ' ' + el.lastname, value: el.user._id };
    });

    if (tummetacourseloading) {
      return <Spinner />;
    } else {
      return (
        <div className="createCourse">
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <PageHeader heading={`Veranstaltung erstellen`} route={'/course-overview'} />
                <small className="d-block pb-3">* = benötigte Felder</small>
                <form onSubmit={this.onSubmit}>
                  {(this.props.auth.user?.role === 'Admin' || this.props.auth.user?.role === 'Manager') && (
                    <div>
                      <label htmlFor="inputMetacourse4">* Metaveranstaltung</label>
                      <SelectListGroup
                        placeholder="Metaveranstaltung"
                        onChange={this.onChange}
                        value={this.state.metacourse}
                        name="metacourse"
                        error={errors.metacourse}
                        options={metacourseOptions}
                      />
                      <label htmlFor="inputSemester">* Semester</label>
                      <SelectListGroup
                        placeholder="Semester"
                        onChange={this.onChange}
                        value={this.state.semester}
                        name="semester"
                        error={errors.semester}
                        options={semesterOptions}
                      />
                      <label htmlFor="inputLocation">* Standort</label>
                      <SelectListGroup
                        placeholder="Status"
                        onChange={this.onChange}
                        value={this.state.location}
                        name="location"
                        error={errors.location}
                        options={locationOptions}
                      />
                      <Row>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputMaxTutorNumber">* Maximale Anzahl an Hilfskräften</label>
                          <TextFieldGroup
                            placeholder="* Maximale Anzahl Tutoren"
                            onChange={this.onChange}
                            value={this.state.maxtutornumber}
                            name="maxtutornumber"
                            error={errors.maxtutornumber}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputWeeklyhourspertutor">* Wochenstunden pro Hilfskraft </label>
                          <TextFieldGroup
                            placeholder="Wochenstunden pro Hilfskraft"
                            onChange={this.onChange}
                            value={this.state.weeklyhourspertutor}
                            name="weeklyhourspertutor"
                            error={errors.weeklyhourspertutor}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputStudentnumber">Anzahl Studenten</label>
                          <TextFieldGroup
                            placeholder="Number of Students"
                            onChange={this.onChange}
                            value={this.state.studentnumber}
                            name="studentnumber"
                            error={errors.studentnumber}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputGroupnumber">Gruppenzahl</label>
                          <TextFieldGroup
                            placeholder="Number of Groups"
                            onChange={this.onChange}
                            value={this.state.groupnumber}
                            name="groupnumber"
                            error={errors.groupnumber}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputGroupsize">Gruppenstärke</label>
                          <TextFieldGroup
                            placeholder="Groupsize"
                            onChange={this.onChange}
                            value={this.state.groupsize}
                            name="groupsize"
                            error={errors.groupsize}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputTutorialhours">Übg. Stunden</label>
                          <TextFieldGroup
                            placeholder="Tutorialhours"
                            onChange={this.onChange}
                            value={this.state.tutorialhours}
                            name="tutorialhours"
                            error={errors.tutorialhours}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputHomework">Hausaufgaben</label>
                          <TextFieldGroup
                            placeholder="Homework"
                            onChange={this.onChange}
                            value={this.state.homework}
                            name="homework"
                            error={errors.homework}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputExam">Klausur</label>
                          <SelectListGroup
                            placeholder="Exam"
                            onChange={this.onChange}
                            value={this.state.exam}
                            name="exam"
                            error={errors.exam}
                            options={examOptions}
                          />
                        </FormGroup>
                      </Row>

                      <Row>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputMidterm">Midterm</label>
                          <SelectListGroup
                            placeholder="Midterm"
                            onChange={this.onChange}
                            value={this.state.midterm}
                            name="midterm"
                            error={errors.midterm}
                            options={midtermOptions}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputGroupsPerTutor">Gruppen pro Tutor</label>
                          <TextFieldGroup
                            placeholder="Groups per Tutor"
                            onChange={this.onChange}
                            value={this.state.groupspertutor}
                            name="groupspertutor"
                            error={errors.groupspertutor}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputOverallWeeklyhours">Gesamtaufwand in Wochenstunden</label>
                          <TextFieldGroup
                            placeholder="Overall Weekly Hours"
                            value={this.state.overallweeklyhours}
                            name="overallweeklyhours"
                            error={errors.overallweeklyhours}
                            disabled={true}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <label htmlFor="inputOverallhours">Wochen</label>
                          <TextFieldGroup
                            placeholder="Wochen"
                            onChange={this.onChange}
                            value={this.state.weeks}
                            name="weeks"
                            error={errors.weeks}
                          />
                        </FormGroup>
                      </Row>
                      <label htmlFor="inputOverallhours">Jahresstunden</label>
                      <TextFieldGroup
                        onChange={this.onChange}
                        placeholder="Overall Hours"
                        value={this.state.overallhours}
                        name="overallhours"
                        error={errors.overallhours}
                        disabled={true}
                      />

                      <label htmlFor="inputFrom">Von</label>
                      <TextFieldGroup
                        type={'Date'}
                        placeholder="From"
                        onChange={this.onChange}
                        value={this.state.from}
                        name="from"
                        error={errors.from}
                      />
                      <label htmlFor="inputTill">Bis</label>
                      <TextFieldGroup
                        type={'Date'}
                        placeholder="Till"
                        onChange={this.onChange}
                        value={this.state.till}
                        name="till"
                        error={errors.till}
                      />
                      <label htmlFor="inputRequirements">Anforderungen</label>
                      <TextAreaFieldGroup
                        placeholder="Anforderungen"
                        onChange={this.onChange}
                        value={this.state.requirement}
                        name="requirement"
                        error={errors.requirement}
                      />

                      <label htmlFor="inputAdvisor">Übungsleitung</label>
                      <MultiList
                        options={advisorOptions}
                        setOptions={this.updateAdvisor}
                        error={errors.advisor}
                        placeholder="Übungsleitung auswählen"
                      />
                      <div style={{ marginBottom: '15px' }} />
                      <label htmlFor="inputStatus">Status</label>
                      <SelectListGroup
                        placeholder="Status"
                        onChange={this.onChange}
                        value={this.state.status}
                        name="status"
                        error={errors.status}
                        options={statusOptions}
                      />
                      <br></br>
                      <hr></hr>
                      <br></br>
                    </div>
                  )}
                  <label htmlFor="inputRequirements">Organisational Unit</label>
                  <TextFieldGroup
                    placeholder="Organisational Unit (e.g. Chair I10)"
                    onChange={this.onChange}
                    value={this.state.orgunit}
                    name="orgunit"
                    error={errors.orgunit}
                  />
                  <div className="grid-container">
                    <div className="grid-item">
                      <label htmlFor="inputRequirements">Course Manager Name</label>
                      <TextFieldGroup
                        placeholder="First Name"
                        onChange={this.onChange}
                        value={this.state.advisorfirstname}
                        name="advisorfirstname"
                        error={errors.advisorfirstname}
                      />
                    </div>
                    <div className="grid-item">
                      <label htmlFor="inputRequirements"></label>
                      <TextFieldGroup
                        placeholder="Last Name"
                        onChange={this.onChange}
                        value={this.state.advisorlastname}
                        name="advisorlastname"
                        error={errors.advisorlastname}
                      />
                    </div>
                  </div>

                  <label htmlFor="inputRequirements">Course Manager Email</label>
                  <TextFieldGroup
                    placeholder="Email"
                    onChange={this.onChange}
                    value={this.state.advisoremail}
                    name="advisoremail"
                    error={errors.advisoremail}
                  />
                  <div className="grid-container-3">
                    <div className="grid-item">
                      <label htmlFor="inputStatus">Final exam </label>
                      <SelectListGroup
                        placeholder="Final exam "
                        onChange={this.onChange}
                        value={this.state.finalexam}
                        name="finalexam"
                        error={errors.finalexam}
                        options={booleanOptions}
                      />
                    </div>
                    <div className="grid-item">
                      <label htmlFor="inputStatus">Retake exam </label>
                      <SelectListGroup
                        placeholder="Retake exam "
                        onChange={this.onChange}
                        value={this.state.retakeexam}
                        name="retakeexam"
                        error={errors.retakeexam}
                        options={booleanOptions}
                      />
                    </div>
                    <div className="grid-item">
                      <label htmlFor="inputStatus">Midterm exam </label>
                      <SelectListGroup
                        placeholder="Mideterm exam "
                        onChange={this.onChange}
                        value={this.state.midtermexam}
                        name="midtermexam"
                        error={errors.midtermexam}
                        options={booleanOptions}
                      />
                    </div>
                  </div>
                  <label htmlFor="inputStatus">Context teaching </label>
                  <SelectListGroup
                    placeholder="Context teaching"
                    onChange={this.onChange}
                    value={this.state.contextteaching}
                    name="contextteaching"
                    error={errors.contextteaching}
                    options={contextTeachingOptions}
                  />
                  <div className="grid-container">
                    <div className="grid-item">
                      <label htmlFor="inputStatus">Compulsory for Bachelor Students</label>
                      <SelectListGroup
                        placeholder="Compulsory for Bachelor Students"
                        onChange={this.onChange}
                        value={this.state.compulsorybachelor}
                        name="compulsorybachelor"
                        error={errors.compulsorybachelor}
                        options={booleanOptions}
                      />
                    </div>
                    <div className="grid-item">
                      <label htmlFor="inputStatus">Compulsory for Master Students</label>
                      <SelectListGroup
                        placeholder="Compulsory for Master Students"
                        onChange={this.onChange}
                        value={this.state.compulsorymaster}
                        name="compulsorymaster"
                        error={errors.compulsorymaster}
                        options={booleanOptions}
                      />
                    </div>
                  </div>
                  <label htmlFor="inputStatus">Homework correction </label>
                  <SelectListGroup
                    placeholder="Homework correction"
                    onChange={this.onChange}
                    value={this.state.homeworkcorrection}
                    name="homeworkcorrection"
                    error={errors.homeworkcorrection}
                    options={booleanOptions}
                  />
                  {this.state.homeworkcorrection && (
                    <div className="grid-container-3">
                      <div className="grid-item">
                        <label htmlFor="inputStatus">Frequency </label>
                        <SelectListGroup
                          placeholder="Frequency"
                          onChange={this.onChange}
                          value={this.state.frequency}
                          name="frequency"
                          error={errors.frequency}
                          options={homeworkOptions}
                        />
                      </div>
                      <div className="grid-item">
                        <label htmlFor="inputStatus">Correction Type </label>
                        <SelectListGroup
                          placeholder="Correction Type"
                          onChange={this.onChange}
                          value={this.state.correctiontype}
                          name="correctiontype"
                          error={errors.correctiontype}
                          options={correctionOptions}
                        />
                      </div>
                      <div className="grid-item">
                        <label htmlFor="inputStatus">Final Grade Relevance</label>
                        <SelectListGroup
                          placeholder="Final Grade Relevance"
                          onChange={this.onChange}
                          value={this.state.finalgraderelevance}
                          name="finalgraderelevance"
                          error={errors.finalgraderelevance}
                          options={gradebooleanOptions}
                        />
                      </div>
                    </div>
                  )}

                  <label htmlFor="inputRequirements">Special Issues</label>
                  <TextAreaFieldGroup
                    placeholder="Special Issues"
                    onChange={this.onChange}
                    value={this.state.specialissues}
                    name="specialissues"
                    error={errors.specialissues}
                  />
                  <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
                </form>
                <br />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

CreateCourse.propTypes = (state) => ({
  semester: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  metacourse: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  tummetacourses: PropTypes.object.isRequired,
});

const mapStateToProps = (state) => ({
  metacourse: state.metacourse,
  course: state.course,
  semester: state.semester,
  profile: state.profile,
  errors: state.errors,
  auth: state.auth,
  tummetacourses: state.tummetacourses,
});

export default connect(mapStateToProps, {
  createCourse,
  getSemesters,
  getMetacourses,
  getAdvisors,
  getAdmins,
})(withRouter(CreateCourse));
