import React, { useEffect, useState } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { getContractByApplicationID, getContractByCourseID } from '../../services/ContractService';
import PageHeader from '../ui/page-header/PageHeader';
import Table from '../ui/table/Table';
import Spinner from '../ui/spinner/Spinner';
import SearchBar from '../ui/table/SearchBar';
import moment from 'moment';

function MiniContractView(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [returnURL, setreturnURL] = useState('/advisor-classes');
  const [title, setTitle] = useState('Contracts');

  useEffect(() => {
    if (props.match.path === '/contract/:applicationId') {
      loadContractByApplicationID(props.match.params.applicationId);
    }
    if (props.match.path === '/contract/course/:courseId' || props.match.path === '/advisor-classes/course/:courseId') {
      loadContractByCourseID(props.match.params.courseId);
      setTitle('Contracts');
    }
  }, []);

  const procesContractDataByCourse = (contracts) => {
    let details = [];
    if (contracts.length > 0) {
      if (props.match.path !== '/advisor-classes/course/:courseId') {
        setreturnURL(`/check-applications/${contracts[0]?.course}`);
      }
      for (let i = 0; i < contracts.length; i++) {
        let contractDetails = {};
        contractDetails._id = contracts[i]._id;

        contractDetails.contractstart = contracts[i].contractstart;
        contractDetails.contractend = contracts[i].contractend;
        contractDetails.hours = contracts[i].hours;
        contractDetails.contractstart2 = contracts[i].contractstart2;
        contractDetails.contractend2 = contracts[i].contractend2;
        contractDetails.hours2 = contracts[i].hours2;

        contractDetails.firstname = contracts[i].profile.firstname;
        contractDetails.lastname = contracts[i].profile.lastname;

        details.push(contractDetails);
      }
    }
    return details;
  };
  const loadContractByCourseID = async (courseId) => {
    const result = await getContractByCourseID(courseId);
    const details = procesContractDataByCourse(result.data);
    setData(details);
    setLoading(false);
  };

  const loadContractByApplicationID = async (applicationId) => {
    const result = await getContractByApplicationID(applicationId);
    const contract = result.data;
    let details = [];
    if (contract.length > 0) {
      let contractDetails = {};
      contractDetails._id = contract[0]._id;

      if (contract[0].contracts?.length > 0 && contract[0].profiles?.length > 0) {
        contractDetails.contractstart = contract[0].contracts[0].contractstart;
        contractDetails.contractend = contract[0].contracts[0].contractend;
        contractDetails.hours = contract[0].contracts[0].hours;
        contractDetails.contractstart2 = contract[0].contracts[0].contractstart2;
        contractDetails.contractend2 = contract[0].contracts[0].contractend2;
        contractDetails.hours2 = contract[0].contracts[0].hours2;

        contractDetails.firstname = contract[0].profiles[0].firstname;
        contractDetails.lastname = contract[0].profiles[0].lastname;
      } else {
        setError({ message: 'Contract not created' });
      }
      setreturnURL(`/check-applications/${contract[0]?.course}`);
      setTitle(`${contract[0].profiles[0].firstname} Contract`);
      details.push(contractDetails);
    }
    setData(details);
    setLoading(false);
  };

  function dateFormat(value, row, index) {
    if (value) return moment(value).format('DD/MM/YYYY');
  }

  const columns = [
    {
      dataField: 'lastname',
      text: 'Nachname',
      sort: true,
    },
    {
      dataField: 'firstname',
      text: 'Vorname',
      sort: true,
    },
    {
      dataField: 'contractstart',
      text: 'Contract Start',
      formatter: dateFormat,
    },
    {
      dataField: 'contractend',
      text: 'Contract End',
      formatter: dateFormat,
    },
    {
      dataField: 'hours',
      text: 'Weekly Hours',
    },
    {
      dataField: 'contractstart2',
      text: 'Contract Start 2',
      formatter: dateFormat,
    },
    {
      dataField: 'contractend2',
      text: 'Contract End 2',
      formatter: dateFormat,
    },
    {
      dataField: 'hours2',
      text: 'Weekly Hours 2',
    },
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {error ? (
            <>
              <PageHeader heading={`Contract is not created yet!`} route={returnURL} />
            </>
          ) : (
            <>
              <PageHeader heading={title} route={returnURL} />
              <br></br>
              <div>
                <div style={{ overflowY: 'auto', marginBottom: '1rem' }}>
                  <ToolkitProvider bootstrap4 keyField="_id" data={data} columns={columns} search>
                    {(data) => (
                      <div>
                        {title === 'Contracts' && (
                          <>
                            <SearchBar {...data.searchProps} />
                            <hr />
                          </>
                        )}
                        <Table baseProps={data.baseProps} />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MiniContractView;
