import authReducer from 'features/auth/authSlice';
import semesterReducer from 'features/semester/semesterSlice';
import errorsReducer from './errorsReducer';
import profileReducer from './profileReducer';
import metacourseReducer from './metacourseReducer';
import tummetacourseReducer from './tummetacourseReducer';
import courseReducer from './courseReducer';
import applicationReducer from './applicationReducer';
import contractReducer from './contractReducer';
import formsReducer from './formsReducer';
import mailReducer from './mailReducer';
import rentalReducer from './rentalReducer';
import rentalstatsReducer from './rentalstatsReducer';
import triggerReducer from './triggerReducer';
import notificationReducer from './notificationReducer';

const reducers = {
  auth: authReducer,
  application: applicationReducer,
  contract: contractReducer,
  course: courseReducer,
  errors: errorsReducer,
  forms: formsReducer,
  mail: mailReducer,
  metacourse: metacourseReducer,
  tummetacourses: tummetacourseReducer,
  profile: profileReducer,
  rentals: rentalReducer,
  rentalstats: rentalstatsReducer,
  semester: semesterReducer,
  trigger: triggerReducer,
  notification: notificationReducer,
};

export default reducers;
