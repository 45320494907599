import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import {
  faUserCircle,
  faGraduationCap,
  faUniversity,
  faUserTie,
  faScroll,
  faBoxTissue,
} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../ui/page-header/PageHeader';
import LinkButton from '../ui/button/LinkButton';

class Help extends Component {
  render() {
    return (
      <div className="help">
        <div className="container mt-5">
          <PageHeader heading={`Help`} route={'/dashboard'} />

          {/* Edit Profile Section */}
          <div className="row mb-4">
            <div className="col-md-8">
              <h5>Click here to edit your profile information</h5>
            </div>
            <div className="col-md-4 text-center">
              <LinkButton
                to={{
                  pathname: '/edit-profile',
                }}
                icon={faUserCircle}
                text={'Edit Profile'}
                variant={'light'}
              />
            </div>
          </div>

          {/* Add Experience Section */}
          <div className="row mb-4">
            <div className="col-md-8">
              <h5>Add work experience (will be shown in your applications)</h5>
            </div>
            <div className="col-md-4 text-center">
              <LinkButton to="/add-experience" icon={faUserTie} text={'Add Experience'} variant={'light'} />
            </div>
          </div>

          {/* Add Education Section */}
          <div className="row mb-4">
            <div className="col-md-8">
              <h5>Add your education (will be shown in your applications)</h5>
            </div>
            <div className="col-md-4 text-center">
              <LinkButton
                to={{
                  pathname: '/add-education',
                }}
                icon={faGraduationCap}
                text={'Add Education'}
                variant={'light'}
              />
            </div>
          </div>

          {/* Tutor Application Section */}
          <div className="row mb-4">
            <div className="col-md-8">
              <h5>Check here to see and apply for courses with vacant tutor positions</h5>
            </div>
            <div className="col-md-4 text-center">
              <LinkButton
                to={{
                  pathname: '/tutorapplication',
                }}
                icon={faUniversity}
                text={'Tutor Application'}
                variant={'light'}
              />
            </div>
          </div>

          {/* My Applications Section */}
          <div className="row mb-4">
            <div className="col-md-8">
              <h5>Click here to check the status of your applications</h5>
            </div>
            <div className="col-md-4 text-center">
              <LinkButton
                to={{
                  pathname: '/myapplications',
                }}
                icon={faBoxTissue}
                text={'My Applications'}
                variant={'light'}
              />
            </div>
          </div>

          {/* My Contracts Section */}
          <div className="row mb-4">
            <div className="col-md-8">
              <h5>Check your contracts and see which documents you have to provide</h5>
            </div>
            <div className="col-md-4 text-center">
              <LinkButton
                to={{
                  pathname: '/mycontracts',
                }}
                icon={faScroll}
                text={'My Contracts'}
                variant={'light'}
              />
            </div>
          </div>

          <hr />

          {/* Additional Information */}
          <div className="row">
            <div className="col-md-12 text-center">
              <h5>
                For further information, please see the FAQ page linked below or contact the Tutorbetrieb via Email
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Help.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps)(Help);
