import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// UI Components
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import TextAreaFieldGroup from 'components/ui/text-field/TextAreaFieldGroup';
import PageHeader from 'components/ui/page-header/PageHeader';

// Actions
import { getNotificationTemplateById, editNotificationTemplate } from 'actions/notificationActions';

export const NotificationEditTemplate = ({ match, history }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const [subject, setSubject] = useState('');
  const errors = useSelector((state) => state.errors);

  const notification = useSelector((state) => state?.notification?.notificationTemplate) || null;

  useEffect(() => {
    dispatch(getNotificationTemplateById(match.params.id));

    return () => {
      setName('');
      setText('');
      setSubject('');
    };
  }, []);

  useEffect(() => {
    setName(notification?.name);
    setText(notification?.text);
    setSubject(notification?.subject);
  }, [notification]);

  const onSubmit = (e) => {
    e.preventDefault();
    const templateData = { name, text, subject };
    dispatch(editNotificationTemplate(match.params.id, templateData, history));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 m-auto">
          <PageHeader heading={`Vorlage Bearbeiten`} route={'/notification-templates'} />
          <form onSubmit={onSubmit}>
            <TextFieldGroup
              placeholder="Vorlage Bezeichnung"
              onChange={(e) => setName(e.target.value)}
              value={name}
              name="name"
              error={errors?.name}
              info="Vorlage Bezeichnung"
            />

            <TextFieldGroup
              placeholder="Betreff"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              name="subject"
              error={errors?.subject}
              info="Betreff"
            />
            <TextAreaFieldGroup
              placeholder="Text"
              rows={'10'}
              onChange={(e) => setText(e.target.value)}
              value={text}
              name="text"
              error={errors?.text}
              info="Text"
            />
            <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
          </form>
          <br />
        </div>
      </div>
    </div>
  );
};

NotificationEditTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default NotificationEditTemplate;
