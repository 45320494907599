import React, { useState, useEffect } from 'react';
import { getAllGroups } from '../../services/GroupService';

// UI Components
import PageHeader from 'components/ui/page-header/PageHeader';
import Spinner from 'components/ui/spinner/Spinner';
import LinkButton from 'components/ui/button/LinkButton';
import FilterTable from 'components/ui/table/FilterTable';

const formatAnsprechpartner = (data) => {
  const firstName = data?.AnsprechpartnerFirstName || '';
  const lastName = data?.AnsprechpartnerLastName || '';
  data.Ansprechpartner = firstName + (lastName ? ` ${lastName}` : '');
};

const GroupsOverview = () => {
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState(null);

  useEffect(() => {
    if (groups === null) {
      loadGroups();
    }
  }, [groups]);

  const loadGroups = async () => {
    setLoading(true);
    try {
      const result = await getAllGroups();
      result?.data.forEach(formatAnsprechpartner);
      setGroups(result?.data);
    } catch (error) {
      console.error('Error loading groups:', error);
    } finally {
      setLoading(false);
    }
  };

  const editGroup = (cell, row) => (
    <LinkButton
      to={{
        pathname: `/group/${row._id}`,
        fromAllAppView: true,
      }}
      text={'Edit'}
      variant={'info'}
    />
  );

  const columns = [
    {
      dataField: 'title',
      text: 'Lehrstuhl/Professur/Einrichtung',
      sort: true,
    },
    {
      dataField: 'Ansprechpartner',
      text: 'Ansprechpartner',
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'sapOrgID',
      text: 'SAP OrgID',
      sort: true,
    },
    {
      dataField: 'telefonnummer',
      text: 'Telefonnummer',
      sort: true,
    },
    {
      dataField: 'department',
      text: 'Department',
      sort: true,
    },
    {
      dataField: 'postkurzel',
      text: 'Postkurzel',
      sort: true,
    },
    {
      dataField: 'dummyView',
      isDummyField: true,
      text: 'Edit Details',
      formatter: editGroup,
    },
  ];

  return (
    <div className="container">
      <PageHeader heading={'Groups Overview'} route={'/dashboard'} />
      {loading ? (
        <Spinner />
      ) : !groups ? (
        <h3 className="text-center m-5">Noch keine Groups</h3>
      ) : (
        <FilterTable data={groups} columns={columns} />
      )}
    </div>
  );
};

export default GroupsOverview;
