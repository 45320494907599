export const currentfieldofstudy = [
  { label: 'None', value: '' },
  { label: 'Other', value: 'Other' },
  {
    label: 'Informatics - Bachelor of Science (B.Sc.)',
    value: 'Informatics - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Informatics - Master of Science (M.Sc.)',
    value: 'Informatics - Master of Science (M.Sc.)',
  },
  {
    label: 'Informatics: Games Engineering - Bachelor of Science (B.Sc.)',
    value: 'Informatics: Games Engineering - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Informatics: Games Engineering - Master of Science (M.Sc.)',
    value: 'Informatics: Games Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Information Engineering (at TUM Campus Heilbronn) - Bachelor of Science (B.Sc.)',
    value: 'Information Engineering (at TUM Campus Heilbronn) - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Information Systems - Bachelor of Science (B.Sc.)',
    value: 'Information Systems - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Information Systems - Master of Science (M.Sc.)',
    value: 'Information Systems - Master of Science (M.Sc.)',
  },
  {
    label: 'Computational Science and Engineering (CSE) - Master of Science (M.Sc.)',
    value: 'Computational Science and Engineering (CSE) - Master of Science (M.Sc.)',
  },
  {
    label: 'Software Engineering - Master of Science (M.Sc.)',
    value: 'Software Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Bioinformatics - Bachelor of Science (B.Sc.)',
    value: 'Bioinformatics - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Bioinformatics - Master of Science (M.Sc.)',
    value: 'Bioinformatics - Master of Science (M.Sc.)',
  },
  {
    label: 'Biomedical Computing (BMC) - Master of Science (M.Sc.)',
    value: 'Biomedical Computing (BMC) - Master of Science (M.Sc.)',
  },
  {
    label: 'Robotics, Cognition, Intelligence - Master of Science (M.Sc.)',
    value: 'Robotics, Cognition, Intelligence - Master of Science (M.Sc.)',
  },
  {
    label: 'Aerospace - Bachelor of Science (B.Sc.)',
    value: 'Aerospace - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Aerospace - Master of Science (M.Sc.)',
    value: 'Aerospace - Master of Science (M.Sc.)',
  },
  {
    label: 'Aerospace Engineering - Master of Science (M.Sc.)',
    value: 'Aerospace Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Agricultural Biosciences - Master of Science (M.Sc.)',
    value: 'Agricultural Biosciences - Master of Science (M.Sc.)',
  },
  {
    label: 'Agricultural and Horticultural Sciences - Bachelor of Science (B.Sc.)',
    value: 'Agricultural and Horticultural Sciences - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Agrosystem Sciences - Master of Science (M.Sc.)',
    value: 'Agrosystem Sciences - Master of Science (M.Sc.)',
  },
  {
    label: 'Architecture - Bachelor of Arts (B.A.)',
    value: 'Architecture - Bachelor of Arts (B.A.)',
  },
  {
    label: 'Architecture - Master of Arts (M.A.)',
    value: 'Architecture - Master of Arts (M.A.)',
  },
  {
    label: 'Automotive Engineering - Master of Science (M.Sc.)',
    value: 'Automotive Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Biochemistry - Bachelor of Science (B.Sc.)',
    value: 'Biochemistry - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Biochemistry - Master of Science (M.Sc.)',
    value: 'Biochemistry - Master of Science (M.Sc.)',
  },
  {
    label: 'Bioeconomics - Bachelor of Science (B.Sc.)',
    value: 'Bioeconomics - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Bioeconomy - Master of Science (M.Sc.)',
    value: 'Bioeconomy - Master of Science (M.Sc.)',
  },
  {
    label: 'Biogenic Materials - Bachelor of Science (B.Sc.)',
    value: 'Biogenic Materials - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Biology - Master of Science (M.Sc.)',
    value: 'Biology - Master of Science (M.Sc.)',
  },
  {
    label: 'Biomass Technology - Master of Science (M.Sc.)',
    value: 'Biomass Technology - Master of Science (M.Sc.)',
  },
  {
    label: 'Biomedical Engineering and Medical Physics - Master of Science (M.Sc.)',
    value: 'Biomedical Engineering and Medical Physics - Master of Science (M.Sc.)',
  },
  {
    label: 'Biomedical Neuroscience - Master of Science (M.Sc.)',
    value: 'Biomedical Neuroscience - Master of Science (M.Sc.)',
  },
  { label: 'Brewing- Master brewer', value: 'Brewing- Master brewer' },
  {
    label: 'Brewing and Beverage Technology - Bachelor of Science (B.Sc.)',
    value: 'Brewing and Beverage Technology - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Brewing and Beverage Technology - Master of Science (M.Sc.)',
    value: 'Brewing and Beverage Technology - Master of Science (M.Sc.)',
  },
  {
    label: 'Brewing and Beverage Technology Modulstudien - Other',
    value: 'Brewing and Beverage Technology Modulstudien - Other',
  },
  {
    label: 'Business Education I - Master of Science (M.Sc.)',
    value: 'Business Education I - Master of Science (M.Sc.)',
  },
  {
    label: 'Business Education II with second teaching subject - Master of Science (M.Sc.)',
    value: 'Business Education II with second teaching subject - Master of Science (M.Sc.)',
  },
  {
    label: 'Cartography - Master of Science (M.Sc.)',
    value: 'Cartography - Master of Science (M.Sc.)',
  },
  {
    label: 'Chemical Biotechnology - Bachelor of Science (B.Sc.)',
    value: 'Chemical Biotechnology - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Chemical Biotechnology - Master of Science (M.Sc.)',
    value: 'Chemical Biotechnology - Master of Science (M.Sc.)',
  },
  {
    label: 'Chemical Engineering - Bachelor of Engineering (B.Eng.)',
    value: 'Chemical Engineering - Bachelor of Engineering (B.Eng.)',
  },
  {
    label: 'Chemical Engineering - Bachelor of Science (B.Sc.)',
    value: 'Chemical Engineering - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Chemical Engineering - Master of Science (M.Sc.)',
    value: 'Chemical Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Chemistry - Bachelor of Science (B.Sc.)',
    value: 'Chemistry - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Chemistry - Master of Science (M.Sc.)',
    value: 'Chemistry - Master of Science (M.Sc.)',
  },
  {
    label: 'Civil Engineering - Bachelor of Science (B.Sc.)',
    value: 'Civil Engineering - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Civil Engineering - Master of Science (M.Sc.)',
    value: 'Civil Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Communications Engineering - Master of Science (M.Sc.)',
    value: 'Communications Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Computational Mechanics - Master of Science (M.Sc.)',
    value: 'Computational Mechanics - Master of Science (M.Sc.)',
  },
  {
    label: 'Consumer Science - Master of Science (M.Sc.)',
    value: 'Consumer Science - Master of Science (M.Sc.)',
  },
  {
    label: 'Data Engineering and Analytics - Master of Science (M.Sc.)',
    value: 'Data Engineering and Analytics - Master of Science (M.Sc.)',
  },
  {
    label: 'Development, Production and Management in Mechanical Engineering - Master of Science (M.Sc.)',
    value: 'Development, Production and Management in Mechanical Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'ESPACE – Earth Oriented Space Science and Technology - Master of Science (M.Sc.)',
    value: 'ESPACE – Earth Oriented Space Science and Technology - Master of Science (M.Sc.)',
  },
  {
    label: 'Ecological Engineering - Master of Science (M.Sc.)',
    value: 'Ecological Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Electrical Engineering and Information Technology - Bachelor of Science (B.Sc.)',
    value: 'Electrical Engineering and Information Technology - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Electrical Engineering and Information Technology - Master of Science (M.Sc.)',
    value: 'Electrical Engineering and Information Technology - Master of Science (M.Sc.)',
  },
  {
    label: 'Electronics and Data Engineering - Bachelor of Engineering (B.Eng.)',
    value: 'Electronics and Data Engineering - Bachelor of Engineering (B.Eng.)',
  },
  {
    label: 'Energy and Process Engineering - Master of Science (M.Sc.)',
    value: 'Energy and Process Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Engineering Geology and Hydrogeology - Master of Science (M.Sc.)',
    value: 'Engineering Geology and Hydrogeology - Master of Science (M.Sc.)',
  },
  {
    label: 'Engineering Science - Bachelor of Science (B.Sc.)',
    value: 'Engineering Science - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Environmental Engineering - Bachelor of Science (B.Sc.)',
    value: 'Environmental Engineering - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Environmental Engineering - Master of Science (M.Sc.)',
    value: 'Environmental Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Ergonomics – Human Factors Engineering - Master of Science (M.Sc.)',
    value: 'Ergonomics – Human Factors Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Executive MBA in Business IT - Master of Business Administration (MBA)',
    value: 'Executive MBA in Business IT - Master of Business Administration (MBA)',
  },
  {
    label: 'Executive MBA in General Management - Master of Business Administration (MBA)',
    value: 'Executive MBA in General Management - Master of Business Administration (MBA)',
  },
  {
    label: 'Executive MBA in Innovation and Business Creation - Master of Business Administration (MBA)',
    value: 'Executive MBA in Innovation and Business Creation - Master of Business Administration (MBA)',
  },
  {
    label: 'Finance and Information Management (FIM) - Master of Science (M.Sc.)',
    value: 'Finance and Information Management (FIM) - Master of Science (M.Sc.)',
  },
  {
    label: 'Food Chemistry - Bachelor of Science (B.Sc.)',
    value: 'Food Chemistry - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Food Chemistry - Master of Science (M.Sc.)',
    value: 'Food Chemistry - Master of Science (M.Sc.)',
  },
  {
    label: 'Food Technology - Bachelor of Science (B.Sc.)',
    value: 'Food Technology - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Food Technology and Biotechnology - Master of Science (M.Sc.)',
    value: 'Food Technology and Biotechnology - Master of Science (M.Sc.)',
  },
  {
    label: 'Forest Science and Resource Management - Bachelor of Science (B.Sc.)',
    value: 'Forest Science and Resource Management - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Forestry and Wood Science - Master of Science (M.Sc.)',
    value: 'Forestry and Wood Science - Master of Science (M.Sc.)',
  },
  {
    label: 'GeoThermie / GeoEnergie - Master of Science (M.Sc.)',
    value: 'GeoThermie / GeoEnergie - Master of Science (M.Sc.)',
  },
  {
    label: 'Geodesy and Geoinformation - Bachelor of Science (B.Sc.)',
    value: 'Geodesy and Geoinformation - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Geodesy and Geoinformation - Master of Science (M.Sc.)',
    value: 'Geodesy and Geoinformation - Master of Science (M.Sc.)',
  },
  {
    label: 'Geologie und Geodäsie - Other',
    value: 'Geologie und Geodäsie - Other',
  },
  {
    label: 'Geosciences - Bachelor of Science (B.Sc.)',
    value: 'Geosciences - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Green Electronics - Master of Science (M.Sc.)',
    value: 'Green Electronics - Master of Science (M.Sc.)',
  },
  {
    label: 'Health Science – Prevention and Health Promotion - Master of Science (M.Sc.)',
    value: 'Health Science – Prevention and Health Promotion - Master of Science (M.Sc.)',
  },
  {
    label: 'Health Sciences - Bachelor of Science (B.Sc.)',
    value: 'Health Sciences - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Industrial Biotechnology - Master of Science (M.Sc.)',
    value: 'Industrial Biotechnology - Master of Science (M.Sc.)',
  },
  {
    label: 'Industrial Chemistry - Master of Science (M.Sc.)',
    value: 'Industrial Chemistry - Master of Science (M.Sc.)',
  },
  {
    label: 'Integrated Circuit Design - Master of Science (M.Sc.)',
    value: 'Integrated Circuit Design - Master of Science (M.Sc.)',
  },
  {
    label: 'Land Management (Partial degree) - Other',
    value: 'Land Management (Partial degree) - Other',
  },
  {
    label: 'Land Management and Geospatial Science - Master of Science (M.Sc.)',
    value: 'Land Management and Geospatial Science - Master of Science (M.Sc.)',
  },
  {
    label: 'Landscape Architecture - Master of Arts (M.A.)',
    value: 'Landscape Architecture - Master of Arts (M.A.)',
  },
  {
    label: 'Landscape Architecture and Landscape Planning - Bachelor of Science (B.Sc.)',
    value: 'Landscape Architecture and Landscape Planning - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Lehramt Beruf und Wirtschaft (vormals Arbeitslehre) (Didaktikfach) - State Exam Program',
    value: 'Lehramt Beruf und Wirtschaft (vormals Arbeitslehre) (Didaktikfach) - State Exam Program',
  },
  {
    label: 'Lehramt Beruf und Wirtschaft (vormals Arbeitslehre) (Unterrichtsfach) - State Exam Program',
    value: 'Lehramt Beruf und Wirtschaft (vormals Arbeitslehre) (Unterrichtsfach) - State Exam Program',
  },
  {
    label: 'Lehramt Sport (Erweiterungsfach/Drittfach) - State Exam Program',
    value: 'Lehramt Sport (Erweiterungsfach/Drittfach) - State Exam Program',
  },
  {
    label: 'Lehramt an Grundschulen - Didaktikfach Sport - State Exam Program',
    value: 'Lehramt an Grundschulen - Didaktikfach Sport - State Exam Program',
  },
  {
    label: 'Lehramt an Grundschulen und Mittelschulen - Unterrichtsfach Sport - State Exam Program',
    value: 'Lehramt an Grundschulen und Mittelschulen - Unterrichtsfach Sport - State Exam Program',
  },
  {
    label: 'Lehramt an Gymnasien - Unterrichtsfach Sport - State Exam Program',
    value: 'Lehramt an Gymnasien - Unterrichtsfach Sport - State Exam Program',
  },
  {
    label: 'Lehramt an Mittelschulen - Didaktikfach Sport - State Exam Program',
    value: 'Lehramt an Mittelschulen - Didaktikfach Sport - State Exam Program',
  },
  {
    label: 'Lehramt an Realschulen - Unterrichtsfach Sport - State Exam Program',
    value: 'Lehramt an Realschulen - Unterrichtsfach Sport - State Exam Program',
  },
  {
    label:
      'Lehramt an beruflichen Schulen - Masterstudiengang Berufliche Bildung Integriert - Master of Education (M.Ed.)',
    value:
      'Lehramt an beruflichen Schulen - Masterstudiengang Berufliche Bildung Integriert - Master of Education (M.Ed.)',
  },
  {
    label: 'Life Sciences Biology - Bachelor of Science (B.Sc.)',
    value: 'Life Sciences Biology - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Life Sciences Nutrition - Bachelor of Science (B.Sc.)',
    value: 'Life Sciences Nutrition - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Management (Main Location: Heilbronn) - Master of Science (M.Sc.)',
    value: 'Management (Main Location: Heilbronn) - Master of Science (M.Sc.)',
  },
  {
    label: 'Management (Main Location: Munich) - Master of Science (M.Sc.)',
    value: 'Management (Main Location: Munich) - Master of Science (M.Sc.)',
  },
  {
    label: 'Management and Innovation - Master of Science (M.Sc.)',
    value: 'Management and Innovation - Master of Science (M.Sc.)',
  },
  {
    label: 'Management and Technology - Bachelor of Science (B.Sc.)',
    value: 'Management and Technology - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Management and Technology (TUM-BWL) - Bachelor of Science (B.Sc.)',
    value: 'Management and Technology (TUM-BWL) - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Management and Technology (TUM-BWL) - Master of Science (M.Sc.)',
    value: 'Management and Technology (TUM-BWL) - Master of Science (M.Sc.)',
  },
  {
    label: 'Materials Science and Engineering - Master of Science (M.Sc.)',
    value: 'Materials Science and Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Mathematical Finance and Actuarial Science - Master of Science (M.Sc.)',
    value: 'Mathematical Finance and Actuarial Science - Master of Science (M.Sc.)',
  },
  {
    label: 'Mathematics - Bachelor of Science (B.Sc.)',
    value: 'Mathematics - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Mathematics - Master of Science (M.Sc.)',
    value: 'Mathematics - Master of Science (M.Sc.)',
  },
  {
    label: 'Mathematics in Data Science - Master of Science (M.Sc.)',
    value: 'Mathematics in Data Science - Master of Science (M.Sc.)',
  },
  {
    label: 'Mathematics in Operations Research - Master of Science (M.Sc.)',
    value: 'Mathematics in Operations Research - Master of Science (M.Sc.)',
  },
  {
    label: 'Mathematics in Science and Engineering - Master of Science (M.Sc.)',
    value: 'Mathematics in Science and Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Matter to Life - Master of Science (M.Sc.)',
    value: 'Matter to Life - Master of Science (M.Sc.)',
  },
  {
    label: 'Mechanical Engineering - Bachelor of Science (B.Sc.)',
    value: 'Mechanical Engineering - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Mechanical Engineering - Master of Science (M.Sc.)',
    value: 'Mechanical Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Mechatronics and Robotics - Master of Science (M.Sc.)',
    value: 'Mechatronics and Robotics - Master of Science (M.Sc.)',
  },
  {
    label: 'Medical Engineering and Assistance Systems - Master of Science (M.Sc.)',
    value: 'Medical Engineering and Assistance Systems - Master of Science (M.Sc.)',
  },
  {
    label: 'Medical Life Science and Technology (PhD-Program) - Doctorate Program',
    value: 'Medical Life Science and Technology (PhD-Program) - Doctorate Program',
  },
  {
    label: 'Medicine (Clinical Work) - State Exam Program',
    value: 'Medicine (Clinical Work) - State Exam Program',
  },
  {
    label: 'Molecular Biotechnology - Bachelor of Science (B.Sc.)',
    value: 'Molecular Biotechnology - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Molecular Biotechnology - Master of Science (M.Sc.)',
    value: 'Molecular Biotechnology - Master of Science (M.Sc.)',
  },
  {
    label: 'Neuroengineering - Master of Science (M.Sc.)',
    value: 'Neuroengineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Nutrition and Biomedicine - Master of Science (M.Sc.)',
    value: 'Nutrition and Biomedicine - Master of Science (M.Sc.)',
  },
  {
    label: 'Pharmaceutical Bioprocess Engineering - Bachelor of Science (B.Sc.)',
    value: 'Pharmaceutical Bioprocess Engineering - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Pharmaceutical Bioprocess Engineering - Master of Science (M.Sc.)',
    value: 'Pharmaceutical Bioprocess Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Physics - Bachelor of Science (B.Sc.)',
    value: 'Physics - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Physics (Applied and Engineering Physics) - Master of Science (M.Sc.)',
    value: 'Physics (Applied and Engineering Physics) - Master of Science (M.Sc.)',
  },
  {
    label: 'Physics (Biophysics) - Master of Science (M.Sc.)',
    value: 'Physics (Biophysics) - Master of Science (M.Sc.)',
  },
  {
    label: 'Physics (Condensed Matter Physics) - Master of Science (M.Sc.)',
    value: 'Physics (Condensed Matter Physics) - Master of Science (M.Sc.)',
  },
  {
    label: 'Physics (Nuclear, Particle and Astrophysics) - Master of Science (M.Sc.)',
    value: 'Physics (Nuclear, Particle and Astrophysics) - Master of Science (M.Sc.)',
  },
  {
    label: 'Political Science - Bachelor of Science (B.Sc.)',
    value: 'Political Science - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Politics Technology - Master of Science (M.Sc.)',
    value: 'Politics Technology - Master of Science (M.Sc.)',
  },
  {
    label: 'Power Engineering - Master of Science (M.Sc.)',
    value: 'Power Engineering - Master of Science (M.Sc.)',
  },
  {
    label: 'Quantum Science Technology - Master of Science (M.Sc.)',
    value: 'Quantum Science Technology - Master of Science (M.Sc.)',
  },
  {
    label: 'Radiation Biology - Master of Science (M.Sc.)',
    value: 'Radiation Biology - Master of Science (M.Sc.)',
  },
  {
    label: 'Rail, Transport and Logistics - Master of Science (M.Sc.)',
    value: 'Rail, Transport and Logistics - Master of Science (M.Sc.)',
  },
  {
    label: 'Research on Teaching and Learning - Master of Education (M.Ed.)',
    value: 'Research on Teaching and Learning - Master of Education (M.Ed.)',
  },
  {
    label: 'Resource Efficient and Sustainable Building - Master of Science (M.Sc.)',
    value: 'Resource Efficient and Sustainable Building - Master of Science (M.Sc.)',
  },
  {
    label: 'esponsibility in Science, Engineering and Technology (RESET) - Master of Arts (M.A.)',
    value: 'esponsibility in Science, Engineering and Technology (RESET) - Master of Arts (M.A.)',
  },
  {
    label: 'Science and Technology Studies (STS) - Master of Arts (M.A.)',
    value: 'Science and Technology Studies (STS) - Master of Arts (M.A.)',
  },
  {
    label: 'Sustainable Management and Technology - Bachelor of Science (B.Sc.)',
    value: 'Sustainable Management and Technology - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Sustainable Management and Technology - Master of Science (M.Sc.)',
    value: 'Sustainable Management and Technology - Master of Science (M.Sc.)',
  },
  {
    label: 'Sustainable Resource Management - Master of Science (M.Sc.)',
    value: 'Sustainable Resource Management - Master of Science (M.Sc.)',
  },
  {
    label: 'Technology of Biogenic Resources - Bachelor of Science (B.Sc.)',
    value: 'Technology of Biogenic Resources - Bachelor of Science (B.Sc.)',
  },
  {
    label: 'Technology of Biogenic Resources - Master of Science (M.Sc.)',
    value: 'Technology of Biogenic Resources - Master of Science (M.Sc.)',
  },
  {
    label: 'Transportation Systems - Master of Science (M.Sc.)',
    value: 'Transportation Systems - Master of Science (M.Sc.)',
  },
];
