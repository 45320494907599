import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Font Awesome Icons
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

// React CSV
import { CSVLink } from 'react-csv';

// UI Components
import PageHeader from 'components/ui/page-header/PageHeader';
import LinkButton from 'components/ui/button/LinkButton';
import FilterTable from 'components/ui/table/FilterTable';

// Actions
import { getMetacourses, getTUMMetacourses, deleteMetacourseById } from 'actions/metacourseActions';

const MetacourseOverview = () => {
  const dispatch = useDispatch();
  const metacourseState = useSelector((state) => state.metacourse);
  const tummetacoursesState = useSelector((state) => state.tummetacourses);

  useEffect(() => {
    if (tummetacoursesState.tummetacourses === null) {
      dispatch(getTUMMetacourses());
    }
    dispatch(getMetacourses());
  }, [tummetacoursesState.tummetacourses]);

  const betrachtenButton = (cell, row, rowIndex, formatExtraData) => {
    return <LinkButton to={{ pathname: `/edit-metacourse/${row._id}` }} text={`Bearbeiten`} variant={'info'} />;
  };

  const deleteButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button
        onClick={() => {
          if (window.confirm('Sind Sie sicher, dass Sie die Metaveranstaltung löschen möchten?')) {
            dispatch(deleteMetacourseById(row._id));
          }
        }}
        className="btn btn-danger"
      >
        Löschen
      </button>
    );
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Metaveranstaltung',
      sort: true,
    },
    {
      dataField: 'scheme',
      text: 'Maßnahme',
      sort: true,
    },
    {
      dataField: 'fondsnumber',
      text: 'Fondsnummer',
      sort: true,
    },
    {
      dataField: 'costcentre',
      text: 'Kostenstelle',
      sort: true,
    },
    {
      dataField: 'abbreviation',
      text: 'Abkürzung',
      sort: true,
    },
    {
      dataField: 'module',
      text: 'Modul',
      sort: true,
    },
    {
      dataField: 'dummyView',
      isDummyField: true,
      text: 'Bearbeiten',
      formatter: betrachtenButton,
    },
    {
      dataField: 'dummyView',
      isDummyField: true,
      text: 'Löschen',
      formatter: deleteButton,
    },
  ];

  const headers = [
    { label: 'Name', header: 'Name', key: 'name' },
    { label: 'Module', header: 'Module', key: 'module' },
    { label: 'Abbreviation', header: 'Abbreviation', key: 'abbreviation' },
    { label: 'Scheme', header: 'Scheme', key: 'scheme' },
    { label: 'Semester Date', header: 'Semester Date', key: 'semesterdate' },
    { label: 'Pflichtfach', header: 'Pflichtfach', key: 'compulsory' },
    { label: 'Cost Centre', header: 'Cost Centre', key: 'costcentre' },
    { label: 'Akademischer Grad', header: 'Akademischer Grad', key: 'degree' },
    { label: 'Fonds Number', header: 'Fonds Number', key: 'fondsnumber' },
    { label: 'Kurstyp', header: 'Kurstyp', key: 'kurstype' },
  ];

  const metaArray = Array.isArray(metacourseState.metacourses) ? metacourseState.metacourses : [];

  const data = metaArray;

  const csvReport = {
    data: data,
    headers: headers,
    filename: 'Metaveranstaltung.csv',
  };

  return (
    <div className="Metacourse Overview">
      <div className="container overflowX" style={{ overflowX: 'auto', paddingBottom: '2rem' }}>
        <PageHeader heading={'Metaveranstaltung Übersicht'} route={'/dashboard'} />
        <div className="btn-group mb-2">
          <LinkButton
            to={{ pathname: `/create-metacourse` }}
            text={`Neue Metaveranstaltung`}
            variant={'info'}
            icon={faUserCircle}
          />
          <CSVLink className="btn btn-primary" {...csvReport}>
            Daten exportieren .csv
          </CSVLink>
        </div>
        <FilterTable data={metaArray} columns={columns} />
      </div>
    </div>
  );
};

export default MetacourseOverview;
