import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getTutorApplications } from '../../actions/applicationActions';
import { getCoursesForApplication } from '../../actions/courseActions';
import { getAdvisorsName } from '../../actions/profileActions';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axios from 'axios';
import PageHeader from '../ui/page-header/PageHeader';
import Table from '../ui/table/Table';
import Spinner from '../ui/spinner/Spinner';
import SearchBar from '../ui/table/SearchBar';
import LinkButton from '../ui/button/LinkButton';

class TutorApplicationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      advisorsNames: new Map(),
    };
  }

  componentDidMount() {
    this.props.getTutorApplications();
    this.props.getCoursesForApplication();
    this.props.getAdvisorsName();
  }
  componentWillReceiveProps(nextProps) {
    let advisors = nextProps.profile.advisors;
    if (advisors && this.state.advisorsNames.size === 0) {
      let advisorsMap = new Map();
      for (let i = 0; i < advisors?.length; i++) {
        advisorsMap.set(advisors[i].user._id, advisors[i].firstname + ' ' + advisors[i].lastname);
      }
      this.setState({
        advisorsNames: advisorsMap,
      });
    }
  }

  onApplyClick(id) {
    this.props.postApplication(id);
  }

  render() {
    let { applications, applicationloading } = this.props.application;
    let { courses, courseloading } = this.props.course;
    let { advisors, advisorloading } = this.props.profile;
    let applicationTable;

    applications = applications ? applications : [];
    let applicationsCounter = 0;
    applications.forEach((e) => {
      if (e.status === 'Applied') {
        applicationsCounter += 1;
      }
    });

    var applicationTooltip;
    if (applicationsCounter >= 3) {
      applicationTooltip = <h3 className="text-danger text-center">Maximum Number of Applications reached!</h3>;
      if (!this.state.disabled) {
        this.setState({ disabled: true });
      }
    }

    if (
      applications === null ||
      courses === null ||
      advisors === null ||
      applicationloading ||
      courseloading ||
      advisorloading
    ) {
      applicationTable = <Spinner />;
    } else {
      //Data for Table
      const entries = courses ? courses : [];

      const applyButton = (value, row, rowIndex, formatExtraData) => {
        var result = applications.filter((obj) => {
          if (obj.course) return obj.course._id === value;
        });
        if (result[0]) {
          if (result[0].status !== 'Contract' && result[0].status !== 'Declined') {
            return (
              <LinkButton
                to={{
                  pathname: `/updateapplication/${row._id}`,
                }}
                text={'Edit'}
                variant={'info'}
              />
            );
          } else {
            return '';
          }
        } else {
          if (!this.state.disabled) {
            return (
              <LinkButton
                to={{
                  pathname: `/tutorapply/${row._id}`,
                }}
                text={'Apply'}
                variant={'info'}
              />
            );
          } else if (this.state.disabled) {
            return (
              <button
                className="btn btn-info"
                type="button"
                disabled={this.state.disabled}
                data-toggle="tooltip"
                data-placement="top"
                title="Tooltip on top"
              >
                Apply
              </button>
            );
          }
        }
      };

      const deleteButton = (value, row, rowIndex, formatExtraData) => {
        var result = applications.filter((obj) => {
          if (obj.course) return obj.course._id === value;
        });
        if (result[0]) {
          if (result[0].status === 'Applied') {
            return (
              <button
                onClick={() => {
                  axios.delete(`/api/application/${result[0]._id}`).then((res) => {
                    window.location.reload();
                  });
                }}
                className="btn btn-danger"
              >
                Delete Application
              </button>
            );
          } else {
            return '';
          }
        } else {
          return '';
        }
      };

      const statusFormatter = (value, row, rowIndex, formatExtraData) => {
        var result = applications.filter((obj) => {
          if (obj.course) return obj.course._id === value;
        });
        if (result[0]) {
          return result[0].status;
        } else {
          return '';
        }
      };

      const advisorFormatter = (value, row, rowIndex, formatExtraData) => {
        let advisornames = '';
        for (let j = 0; j < value?.length; j++) {
          if (j >= 3) break;
          let advisorname =
            this.state.advisorsNames.get(value[j]) !== undefined ? this.state.advisorsNames.get(value[j]) : '';
          advisornames = advisornames + advisorname + ',';
        }
        return advisornames.slice(0, -1);
      };

      if (courses && courses.length > 0) {
        const columns = [
          {
            dataField: 'metacourse.name',
            text: 'Course',
            sort: true,
          },
          {
            dataField: 'metacourse.module',
            text: 'Module',
            sort: true,
          },
          {
            dataField: 'location',
            text: 'Location',
            sort: true,
          },
          {
            dataField: 'requirement',
            text: 'Requirements',
            sort: true,
          },
          {
            dataField: 'weeklyhourspertutor',
            text: 'Weekly Hours',
            sort: true,
          },
          {
            dataField: 'advisor',
            text: 'Advisor',
            sort: true,
            formatter: advisorFormatter,
          },
          {
            dataField: '_id',
            text: 'Status',
            formatter: statusFormatter,
          },
          {
            dataField: '_id',
            text: 'Apply',
            header: 'Apply',
            id: 'links',
            formatter: applyButton,
          },
          {
            dataField: '_id',
            text: 'Delete',
            formatter: deleteButton,
          },
        ];

        applicationTable = (
          <ToolkitProvider bootstrap4 keyField="_id" data={entries} columns={columns} search>
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <hr />
                <div style={{ overflowY: 'auto' }}>
                  <Table baseProps={props.baseProps} />
                </div>
              </div>
            )}
          </ToolkitProvider>
        );
      }
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <PageHeader heading={`Courses to Apply`} route={'/dashboard'} />
            <h6 className="text-center">
              Note: For most lectures, the contract will be split in lecture period and lecture-free period. Weekly
              hours will vary in the two periods.
            </h6>
            <hr />
            {applicationTooltip}
            {applicationTable}
          </div>
        </div>
      </div>
    );
  }
}

TutorApplicationView.propTypes = {
  getTutorApplications: PropTypes.func.isRequired,
  getCoursesForApplication: PropTypes.func.isRequired,
  getAdvisorsName: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  application: state.application,
  course: state.course,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getTutorApplications,
  getCoursesForApplication,
  getAdvisorsName,
})(TutorApplicationView);
