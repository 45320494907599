export const GET_ERRORS = 'GET_ERRORS';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_ADMINS = 'GET_ADMINS';
export const GET_ADVISORS = 'GET_ADVISORS';
export const GET_TUTORS = 'GET_TUTORS';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const ADVISOR_PROFILE_LOADING = 'ADVISOR_PROFILE_LOADING';
export const SEMESTER_LOADING = 'SEMESTER_LOADING';
export const METACOURSE_LOADING = 'METACOURSE_LOADING';
export const COURSE_LOADING = 'COURSE_LOADING';
export const APPLICATION_LOADING = 'APPLICATION_LOADING';
export const CONTRACT_LOADING = 'CONTRACT_LOADING';
export const RENTALS_LOADING = 'RENTALS_LOADING';
export const FORMS_LOADING = 'FORMS_LOADING';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const GET_METACOURSE = 'GET_METACOURSE';
export const GET_METACOURSES = 'GET_METACOURSES';
export const DELETE_METACOURSE = 'DELETE_METACOURSE';
export const GET_TUMMETACOURSES = 'GET_TUMMETACOURSES';
export const TUMMETACOURSES_LOADING = 'TUMMETACOURSES_LOADING';
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSE = 'GET_COURSE';
export const SET_STUDENT_NUMBER_CHANGED = 'SET_STUDENT_NUMBER_CHANGED';
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_APPLICATION = 'GET_APPLICATION';
export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_CONTRACTS = 'GET_CONTRACTS';
export const GET_FORM = 'GET_FORM';
export const GET_FORMS = 'GET_FORMS';
export const CLEAR_FOR_DASHBOARD = 'CLEAR_FOR_DASHBOARD';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const TEMPLATES_LOADING = 'TEMPLATES_LOADING';
export const GET_RENTALS = 'GET_RENTALS';
export const GET_RENTAL = 'GET_RENTAL';
export const GET_RENTALSTATS = 'GET_RENTALSTATS';
export const GET_DEVICEAVAILABILITY = 'GET_DEVICEAVAILABILITY';
export const DELETE_RENTAL = 'DELETE_RENTAL';
export const RENTAL_LOADING = 'RENTAL_LOADING';
export const GET_RENTALAPPLICATION = 'GET_RENTALAPPLICATION';
export const GET_RENTALAPPLICATIONS = 'GET_RENTALAPPLICATIONS';
export const TOGGLE = 'TOGGLE';
export const GET_NOTIFICATION_TEMPLATES = 'GET_NOTIFICATION_TEMPLATES';
export const GET_NOTIFICATION_TEMPLATE = 'GET_NOTIFICATION_TEMPLATE';
export const NOTIFICATION_TEMPLATES_LOADING = 'NOTIFICATION_TEMPLATES_LOADING';
