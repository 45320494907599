import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextFieldGroup from '../ui/text-field/TextFieldGroup';
import TextAreaFieldGroup from '../ui/text-field/TextAreaFieldGroup';
import SelectListGroup from '../ui/select-list-group/SelectListGroup';
import { createRental } from '../../actions/rentalActions';
import { getCurrentProfile, getAdvisors } from '../../actions/profileActions';
import PageHeader from '../ui/page-header/PageHeader';
import MultiList from '../ui/multi-list/MultiList';
import Spinner from '../ui/spinner/Spinner';

class CreateRental extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      vorname: '',
      tumid: '',
      email: '',
      telefonnummer: '',
      veranstaltung: '',
      von: '',
      bis: '',
      ubungsleitung: [],
      rückgabe: '',
      ipad: { deviceno: '', available: false },
      mikrofon: { deviceno: '', available: false },
      wacom: { deviceno: '', available: false },
      webcam: { deviceno: '', available: false },
      stativ: { deviceno: '', available: false },
      details: '',
      status: 'HW angefragt',
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateAdvisor = this.updateAdvisor.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  componentDidMount() {
    this.props.getAdvisors();
    this.props.getCurrentProfile();

    const stateId = this.props.location.state ? this.props.location.state.id : null;
    if (stateId && stateId === this.props.rental._id) {
      const { name, vorname, tumid, email, telefonnummer } = this.props.rental;

      this.setState({
        name: name,
        vorname: vorname,
        tumid: tumid,
        email: email,
        telefonnummer: telefonnummer,
      });
    }

    // this.props.getRentalOfId(this.props.location.state.id);
    window.onbeforeunload = function (e) {
      var dialogText = 'Vorgang wirklich abbrechen?';
      e.returnValue = dialogText;
      return dialogText;
    };
  }

  updateAdvisor(selectedAdvisor) {
    if (selectedAdvisor !== undefined) {
      this.setState({ ubungsleitung: selectedAdvisor });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const rentalData = {
      name: this.state.name,
      vorname: this.state.vorname,
      tumid: this.state.tumid,
      email: this.state.email,
      telefonnummer: this.state.telefonnummer,
      veranstaltung: this.state.veranstaltung,
      von: this.state.von,
      bis: this.state.bis,
      rückgabe: this.state.rückgabe,
      ipad: this.state.ipad,
      mikrofon: this.state.mikrofon,
      wacom: this.state.wacom,
      webcam: this.state.webcam,
      stativ: this.state.stativ,
      details: this.state.details,
      status: this.state.status,
      ubungsleitung: this.state.ubungsleitung,
      handle: this.props.profile.profile.handle,
      fromapp: false,
    };
    let flag = false;
    let errorstatus = {};
    if (rentalData.name === '') {
      errorstatus.name = 'The last name field cannot be empty';
      flag = true;
    }
    if (rentalData.vorname === '') {
      errorstatus.vorname = 'The first name field cannot be empty';
      flag = true;
    }
    if (rentalData.email === '') {
      errorstatus.email = 'The EMail field cannot be empty';
      flag = true;
    }

    if (rentalData.telefonnummer === '') {
      errorstatus.telefonnummer = 'The telephone number field cannot be empty';
      flag = true;
    }
    if (
      !rentalData.ipad.available &&
      !rentalData.mikrofon.available &&
      !rentalData.wacom.available &&
      !rentalData.webcam.available &&
      !rentalData.stativ.available
    ) {
      errorstatus.leihgeräte = 'You have to select at leat one device';
      flag = true;
    }

    if (!flag) {
      this.props.createRental(rentalData, this.props.history);
    } else {
      this.setState({ errors: errorstatus });
    }
  }

  onChange(e) {
    if (e.target.name === 'ipad') {
      this.setState({
        ipad: {
          deviceno: e.target.value,
          available: this.state.ipad.available,
        },
      });
      return;
    }
    if (e.target.name === 'mikrofon') {
      this.setState({
        mikrofon: {
          deviceno: e.target.value,
          available: this.state.mikrofon.available,
        },
      });
      return;
    }
    if (e.target.name === 'wacom') {
      this.setState({
        wacom: {
          deviceno: e.target.value,
          available: this.state.wacom.available,
        },
      });
      return;
    }
    if (e.target.name === 'webcam') {
      this.setState({
        webcam: {
          deviceno: e.target.value,
          available: this.state.webcam.available,
        },
      });
      return;
    }
    if (e.target.name === 'stativ') {
      this.setState({
        stativ: {
          deviceno: e.target.value,
          available: this.state.stativ.available,
        },
      });
      return;
    }

    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const errors = this.state.errors;
    let { advisors, loading, advisorloading } = this.props.profile;

    //Select options for advisors
    if (!advisors) {
      advisors = [];
    }
    if (typeof advisors === 'object' && Object.entries(advisors).length === 0) {
      advisors = [];
    }
    const advisorOptions = advisors.map((el) => {
      return { name: el.firstname + ' ' + el.lastname, value: el.user._id };
    });

    const statusOptions = [
      { label: 'HW angefragt', value: 'HW angefragt' },
      { label: 'HW ausgegeben', value: 'HW ausgegeben' },
      { label: 'Abgeschlossen', value: 'Abgeschlossen' },
    ];
    if (loading || advisorloading) {
      return <Spinner />;
    } else {
      return (
        <div className={'create-rental'}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <PageHeader heading={`Neue Ausleihe`} route={'/rentals-overview'} />
                <small className="d-block pb-3">* = Pflichtfelder</small>
                <form onSubmit={this.onSubmit}>
                  <TextFieldGroup
                    placeholder="* Nachname"
                    onChange={this.onChange}
                    value={this.state.name}
                    name="name"
                    error={errors.name}
                  />
                  <TextFieldGroup
                    placeholder="* Vorname"
                    onChange={this.onChange}
                    value={this.state.vorname}
                    name="vorname"
                    error={errors.vorname}
                  />

                  <TextFieldGroup
                    placeholder="Tum-ID"
                    onChange={this.onChange}
                    value={this.state.tumid}
                    name="tumid"
                    error={errors.tumid}
                  />
                  <TextFieldGroup
                    placeholder="Email"
                    onChange={this.onChange}
                    value={this.state.email}
                    name="email"
                    error={errors.email}
                  />
                  <TextFieldGroup
                    placeholder="Telefonnummer"
                    onChange={this.onChange}
                    value={this.state.telefonnummer}
                    name="telefonnummer"
                    error={errors.telefonnummer}
                  />
                  <TextFieldGroup
                    placeholder="Veranstaltung"
                    onChange={this.onChange}
                    value={this.state.veranstaltung}
                    name="veranstaltung"
                    error={errors.veranstaltung}
                  />
                  <h6>Vertragslaufzeit von:</h6>
                  <TextFieldGroup
                    type={'date'}
                    onChange={this.onChange}
                    value={this.state.von}
                    name={'von'}
                    error={errors.von}
                  />
                  <h6>bis:</h6>
                  <TextFieldGroup
                    type={'date'}
                    onChange={this.onChange}
                    value={this.state.bis}
                    name={'bis'}
                    error={errors.bis}
                  />

                  <h6 htmlFor="inputAdvisor">Übungsleitung</h6>
                  <MultiList
                    options={advisorOptions}
                    setOptions={this.updateAdvisor}
                    placeholder="Übungsleitung auswählen"
                    disabled={this.state.processable ? true : false}
                  />

                  <h6 className="mt-2">Vorraussichtliche Rückgabe:</h6>
                  <TextFieldGroup
                    type={'date'}
                    onChange={this.onChange}
                    value={this.state.rückgabe}
                    name={'rückgabe'}
                    error={errors.rückgabe}
                  />
                  <h6>Leihgeräte</h6>
                  {errors.leihgeräte && (
                    <p style={{ color: 'red', fontSize: '80%' }}> You have to select at leat one device </p>
                  )}
                  <div>
                    <div className="form-check d-flex justify-content-between">
                      <div className="mt-2 ml-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="ipad"
                          onChange={() => {
                            this.setState((prevState) => {
                              return {
                                ipad: {
                                  deviceno: prevState.ipad.deviceno,
                                  available: !prevState.ipad.available,
                                },
                              };
                            });
                          }}
                          value={this.state.ipad}
                        />

                        <label className="form-check-label" htmlFor="ipad">
                          iPad Pro
                        </label>
                      </div>
                      <div className="mr-5">
                        <TextFieldGroup
                          placeholder="iPad device no."
                          disabled={!this.state.ipad.available ? 'disabled' : ''}
                          onChange={this.onChange}
                          value={this.state.ipad.deviceno}
                          name="ipad"
                          error={errors.deviceno}
                        />
                      </div>
                    </div>
                    <div className="form-check d-flex justify-content-between">
                      <div className="mt-2 ml-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="mikrofon"
                          onChange={() => {
                            this.setState((prevState) => ({
                              mikrofon: {
                                deviceno: prevState.mikrofon.deviceno,
                                available: !prevState.mikrofon.available,
                              },
                            }));
                          }}
                          value={this.state.mikrofon.available}
                        />
                        <label className="form-check-label" htmlFor="mikrofon">
                          Mikrofon
                        </label>
                      </div>
                      <div className="mr-5">
                        <TextFieldGroup
                          placeholder="Mikrofon device no."
                          disabled={!this.state.mikrofon.available ? 'disabled' : ''}
                          onChange={this.onChange}
                          value={this.state.mikrofon.deviceno}
                          name="mikrofon"
                          error={errors.deviceno}
                        />
                      </div>
                    </div>
                    <div className="form-check d-flex justify-content-between">
                      <div className="mt-2 ml-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="wacom"
                          onChange={() => {
                            this.setState((prevState) => ({
                              wacom: {
                                deviceno: prevState.wacom.deviceno,
                                available: !prevState.wacom.available,
                              },
                            }));
                          }}
                          value={this.state.wacom.available}
                        />
                        <label className="form-check-label" htmlFor="wacom">
                          Wacom Tablet
                        </label>
                      </div>
                      <div className="mr-5">
                        <TextFieldGroup
                          disabled={!this.state.wacom.available ? 'disabled' : ''}
                          placeholder="Wacom tablet device no."
                          onChange={this.onChange}
                          value={this.state.wacom.deviceno}
                          name="wacom"
                          error={errors.deviceno}
                        />
                      </div>
                    </div>
                    <div className="form-check d-flex justify-content-between">
                      <div className="mt-2 ml-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="webcam"
                          onChange={() => {
                            this.setState((prevState) => ({
                              webcam: {
                                deviceno: prevState.webcam.deviceno,
                                available: !prevState.webcam.available,
                              },
                            }));
                          }}
                          value={this.state.webcam.available}
                        />
                        <label className="form-check-label" htmlFor="webcam">
                          Webcam
                        </label>
                      </div>
                      <div className="mr-5">
                        <TextFieldGroup
                          disabled={!this.state.webcam.available ? 'disabled' : ''}
                          placeholder="Webcam device no."
                          onChange={this.onChange}
                          value={this.state.webcam.deviceno}
                          name="webcam"
                          error={errors.deviceno}
                        />
                      </div>
                    </div>
                    <div className="form-check d-flex mb-4 justify-content-between">
                      <div className="mt-2 ml-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="stativ"
                          onChange={() => {
                            this.setState((prevState) => ({
                              stativ: {
                                deviceno: prevState.stativ.deviceno,
                                available: !prevState.stativ.available,
                              },
                            }));
                          }}
                          value={this.state.stativ.available}
                        />
                        <label className="form-check-label" htmlFor="stativ">
                          Mikrofonstativ
                        </label>
                      </div>
                      <div className="mr-5 ml-5">
                        <TextFieldGroup
                          placeholder="Stativ device no."
                          disabled={!this.state.stativ.available ? 'disabled' : ''}
                          onChange={this.onChange}
                          value={this.state.stativ.deviceno}
                          name="stativ"
                          error={errors.deviceno}
                        />
                      </div>
                    </div>
                  </div>

                  <TextAreaFieldGroup
                    placeholder="Bemerkungen"
                    onChange={this.onChange}
                    value={this.state.details}
                    name="details"
                    error={errors.details}
                  />

                  <SelectListGroup
                    placeholder="* Status"
                    onChange={this.onChange}
                    value={this.state.status}
                    name="status"
                    error={errors.status}
                    options={statusOptions}
                    info={'Status der Leihe'}
                  />

                  <input type="submit" value="Bestätigen" className="btn btn-info btn-block mt-4 mb-4" />
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

CreateRental.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  rental: PropTypes.object.isRequired,
  rentals: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  rental: state.rentals.rental,
  auth: state.auth,
  profile: state.profile,
  rentals: state.rentals,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  createRental,
  getCurrentProfile,
  getAdvisors,
})(withRouter(CreateRental));
