import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import { getUserContracts } from 'actions/contractActions';
import { getCurrentProfile } from 'actions/profileActions';
import { createRentalApplication } from 'actions/rentalActions';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import { getHardwareAvailabilityDetails } from 'actions/rentalstatsActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import PageHeader from 'components/ui/page-header/PageHeader';
import Spinner from 'components/ui/spinner/Spinner';

class CreateRentalApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      vorname: '',
      tumid: '',
      email: '',
      profile: '',
      appointmentdate: '',
      appointmenttime: '',
      telefonnummer: '',
      ipad: { deviceno: '', available: false },
      mikrofon: { deviceno: '', available: false },
      wacom: { deviceno: '', available: false },
      webcam: { deviceno: '', available: false },
      stativ: { deviceno: '', available: false },
      validcontracts: [],
      selectedContract: '',
      errors: {},
      rentalstats: [],
      showAppointmentSelection: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.profile.profile && nextProps.rentalstats.deviceavailability) {
      this.setState({
        name: nextProps.profile.profile.lastname,
        vorname: nextProps.profile.profile.firstname,
        email: nextProps.profile.profile.user?.email,
        profile: nextProps.profile.profile._id,
        validcontracts: nextProps.contract.contracts,
        rentalstats: nextProps.rentalstats.deviceavailability,
      });
    }
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    this.props.getHardwareAvailabilityDetails();
    this.props.getUserContracts(this.props.auth.user.id);
  }

  onSubmit(e) {
    e.preventDefault();

    let deviceNotAvailable = '';
    let unavailableDeviceCount = 0;
    if (this.state.ipad?.available && this.state.rentalstats?.ipad <= 0) {
      deviceNotAvailable = deviceNotAvailable + 'iPad, ';
      unavailableDeviceCount = unavailableDeviceCount + 1;
    }
    if (this.state.mikrofon?.available && this.state.rentalstats?.mikrofon <= 0) {
      deviceNotAvailable = deviceNotAvailable + 'mikrofon, ';
      unavailableDeviceCount = unavailableDeviceCount + 1;
    }
    if (this.state.wacom?.available && this.state.rentalstats?.wacom <= 0) {
      deviceNotAvailable = deviceNotAvailable + 'wacom, ';
      unavailableDeviceCount = unavailableDeviceCount + 1;
    }
    if (this.state.webcam?.available && this.state.rentalstats?.webcam <= 0) {
      deviceNotAvailable = deviceNotAvailable + 'webcam, ';
      unavailableDeviceCount = unavailableDeviceCount + 1;
    }
    if (this.state.stativ?.available && this.state.rentalstats?.stativ <= 0) {
      deviceNotAvailable = deviceNotAvailable + 'stativ, ';
      unavailableDeviceCount = unavailableDeviceCount + 1;
    }

    if (deviceNotAvailable !== '') {
      deviceNotAvailable = deviceNotAvailable.slice(0, -2);
    }

    const rentalapplicationData = {
      // profile: this.state.profile,
      user: this.props.auth.user.id,
      name: this.state.name,
      vorname: this.state.vorname,
      tumid: this.state.tumid,
      email: this.state.email,
      telefonnummer: this.state.telefonnummer,
      ipad: this.state.ipad,
      mikrofon: this.state.mikrofon,
      wacom: this.state.wacom,
      webcam: this.state.webcam,
      stativ: this.state.stativ,
      appointmentdate: this.state.appointmentdate,
      appointmenttime: this.state.appointmenttime,
      rentalstats: deviceNotAvailable,
      unavailableDeviceCount: unavailableDeviceCount,
    };

    let contractdetails = this.state.selectedContract.split('   ');
    rentalapplicationData.contract = {
      contract_id: contractdetails[0],
      coursename: contractdetails[1],
    };
    this.props.createRentalApplication(rentalapplicationData, this.props.history);
  }

  onChange(e) {
    if (e.target.name === 'ipad') {
      this.setState({
        ipad: {
          deviceno: e.target.value,
          available: this.state.ipad.available,
        },
      });
      return;
    }
    if (e.target.name === 'mikrofon') {
      this.setState({
        mikrofon: {
          deviceno: e.target.value,
          available: this.state.mikrofon.available,
        },
      });
      return;
    }
    if (e.target.name === 'wacom') {
      this.setState({
        wacom: {
          deviceno: e.target.value,
          available: this.state.wacom.available,
        },
      });
      return;
    }
    if (e.target.name === 'webcam') {
      this.setState({
        webcam: {
          deviceno: e.target.value,
          available: this.state.webcam.available,
        },
      });
      return;
    }
    if (e.target.name === 'stativ') {
      this.setState({
        stativ: {
          deviceno: e.target.value,
          available: this.state.stativ.available,
        },
      });
      return;
    }

    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { contracts, contract, contractloading } = this.props.contract;
    const errors = this.state.errors;
    var deviceserror = <div></div>;
    var selectedContracterror = <div></div>;
    if (errors.leihgeräte) {
      deviceserror = <h6 className="text-danger">{errors.leihgeräte}</h6>;
    }
    if (errors.selectedContract) {
      selectedContracterror = <h6 className="text-danger">{errors.leihgeräte}</h6>;
    }

    var eligibleContracts = [{ label: 'Select course with contract', value: '' + '   ' + '' }];
    if (!contractloading) {
      for (let i = 0; i < contracts?.length; i++) {
        let contractstartdate = contracts[i]?.contractstart;
        let contractenddate = contracts[i]?.contractend;
        if (contracts[i]?.contractstart2 < contractstartdate) {
          contractstartdate = contracts[i]?.contractstart2;
        }
        if (contracts[i]?.contractend2 > contractenddate) {
          contractenddate = contracts[i]?.contractend2;
        }
        if (
          new Date(contractstartdate) <= new Date(new Date(Date.now() + 12096e5)) &&
          new Date(contractenddate) >= new Date(new Date(Date.now()))
        ) {
          eligibleContracts.push({
            label: contracts[i].course.metacourse.name,
            value: contracts[i]._id + '   ' + contracts[i].course.metacourse.name,
          });
        }
      }
    }

    const validcontracts = this.state.validcontracts;

    const checkEligibility = (contracts) => {
      let flag = false;
      for (let i = 0; i < contracts?.length; i++) {
        let contractstartdate = contracts[i]?.contractstart;
        let contractenddate = contracts[i]?.contractend;
        if (contracts[i]?.contractstart2 < contractstartdate) {
          contractstartdate = contracts[i]?.contractstart2;
        }
        if (contracts[i]?.contractend2 > contractenddate) {
          contractenddate = contracts[i]?.contractend2;
        }
        if (
          new Date(contractstartdate) <= new Date(new Date(Date.now() + 12096e5)) &&
          new Date(contractenddate) >= new Date(new Date(Date.now()))
        ) {
          flag = true;
          return flag;
        }
      }
      return flag;
    };

    if (contractloading) {
      return <Spinner />;
    } else {
      return (
        <div className={'create-rental'}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <PageHeader heading={`Hardware Request`} route={'/dashboard'} />
                <small className="d-block pb-3">* = necessary fields</small>
                {!checkEligibility(validcontracts) && (
                  <p className="text-danger">* You cannot apply for hardware without valid contract</p>
                )}
                {/* <PopupModal /> */}
                <form onSubmit={this.onSubmit}>
                  <TextFieldGroup
                    placeholder="* Firstname"
                    onChange={this.onChange}
                    value={this.state.vorname}
                    name="vorname"
                    error={errors.vorname}
                    disabled
                  />
                  <TextFieldGroup
                    placeholder="* Lastname"
                    onChange={this.onChange}
                    value={this.state.name}
                    name="name"
                    error={errors.name}
                    disabled="disabled"
                  />
                  <TextFieldGroup
                    placeholder="* TUM-ID (e.g. `go42tum`)"
                    onChange={this.onChange}
                    value={this.state.tumid}
                    name="tumid"
                    error={errors.tumid}
                  />
                  <TextFieldGroup
                    placeholder="* Email"
                    onChange={this.onChange}
                    value={this.state.email}
                    name="email"
                    error={errors.email}
                  />
                  <TextFieldGroup
                    placeholder="* Telephone Number"
                    onChange={this.onChange}
                    value={this.state.telefonnummer}
                    name="telefonnummer"
                    error={errors.telefonnummer}
                  />
                  <label htmlFor="selectedContract">* Select Contract:</label>
                  {selectedContracterror}
                  <SelectListGroup
                    placeholder="* Select Contracts:"
                    onChange={this.onChange}
                    value={this.state.selectedContract}
                    name="selectedContract"
                    options={eligibleContracts}
                    error={errors.contract}
                  />
                  <div className="d-flex">
                    <h6 className="mt-2">* Devices</h6>
                    <div className="dropdown mydropdowncss">
                      <a href="#" className="btn dropdown-toggle" data-toggle="dropdown">
                        <FontAwesomeIcon icon={faInfoCircle} className="fas text-primary" />{' '}
                        <span className="caret"></span>
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <div className="d-flex justify-content-between px-2">
                            iPad Pro:&nbsp;{' '}
                            {this.state.rentalstats?.ipad <= 0 ? (
                              <div className="fas text-danger"> Not Available</div>
                            ) : (
                              <div className="fas text-success"> Available</div>
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between px-2">
                            Microphone:&nbsp;{' '}
                            {this.state.rentalstats?.mikrofon <= 0 ? (
                              <div className="fas text-danger"> Not Available</div>
                            ) : (
                              <div className="fas text-success"> Available</div>
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between px-2">
                            Wacom Tablet:&nbsp;{' '}
                            {this.state.rentalstats?.wacom <= 0 ? (
                              <div className="fas text-danger"> Not Available</div>
                            ) : (
                              <div className="fas text-success"> Available</div>
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between px-2">
                            Webcam:&nbsp;{' '}
                            {this.state.rentalstats?.webcam <= 0 ? (
                              <div className="fas text-danger"> Not Available</div>
                            ) : (
                              <div className="fas text-success"> Available</div>
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between px-2">
                            Microphone Stand:&nbsp;{' '}
                            {this.state.rentalstats?.stativ <= 0 ? (
                              <div className="fas text-danger"> Not Available</div>
                            ) : (
                              <div className="fas text-success"> Available</div>
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {deviceserror}
                  <div>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="ipad"
                        onChange={() => {
                          this.setState((prevState) => {
                            return {
                              ipad: {
                                deviceno: prevState.ipad.deviceno,
                                available: !prevState.ipad.available,
                              },
                            };
                          });
                        }}
                        value={this.state.ipad.available}
                      />
                      <label
                        style={this.state.rentalstats?.ipad <= 0 ? { color: 'red' } : { color: 'black' }}
                        className="form-check-label"
                        htmlFor="ipad"
                      >
                        iPad Pro
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="mikrofon"
                        onChange={() => {
                          this.setState((prevState) => ({
                            mikrofon: {
                              deviceno: prevState.mikrofon.deviceno,
                              available: !prevState.mikrofon.available,
                            },
                          }));
                        }}
                        value={this.state.mikrofon.available}
                      />
                      <label
                        style={this.state.rentalstats?.mikrofon <= 0 ? { color: 'red' } : { color: 'black' }}
                        className="form-check-label"
                        htmlFor="mikrofon"
                      >
                        Microphone (including tripod desk stand)
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="wacom"
                        onChange={() => {
                          this.setState((prevState) => ({
                            wacom: {
                              deviceno: prevState.wacom.deviceno,
                              available: !prevState.wacom.available,
                            },
                          }));
                        }}
                        value={this.state.wacom.available}
                      />
                      <label
                        style={this.state.rentalstats?.wacom <= 0 ? { color: 'red' } : { color: 'black' }}
                        className="form-check-label"
                        htmlFor="wacom"
                      >
                        Wacom Tablet
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="webcam"
                        onChange={() => {
                          this.setState((prevState) => ({
                            webcam: {
                              deviceno: prevState.webcam.deviceno,
                              available: !prevState.webcam.available,
                            },
                          }));
                        }}
                        value={this.state.webcam.available}
                      />
                      <label
                        style={this.state.rentalstats?.webcam <= 0 ? { color: 'red' } : { color: 'black' }}
                        className="form-check-label"
                        htmlFor="webcam"
                      >
                        Webcam
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="stativ"
                        onChange={() => {
                          this.setState((prevState) => ({
                            stativ: {
                              deviceno: prevState.stativ.deviceno,
                              available: !prevState.stativ.available,
                            },
                          }));
                        }}
                        value={this.state.stativ.available}
                      />
                      <label
                        style={this.state.rentalstats?.stativ <= 0 ? { color: 'red' } : { color: 'black' }}
                        className="form-check-label"
                        htmlFor="stativ"
                      >
                        Additional Microphone Stand
                      </label>
                    </div>
                  </div>
                  <br></br>
                  <strong> Pick up and return point: &nbsp;</strong>
                  <br />
                  After successful submission of the hardware request, the rental hardware can be picked up at the ITO
                  hardware group
                  <strong>&nbsp;(Boltzmannstr. 3, room 00.07.054)</strong>. Please also return the hardware at the ITO
                  hardware group at the end of your contract or in case the device is no longer needed.
                  <br />
                  <br />
                  <label>Note: Option for appointment request</label>
                  <br />
                  <button
                    type="button"
                    className="btn btn-secondary my-2"
                    onClick={() => {
                      this.setState((prevState) => ({
                        showAppointmentSelection: !prevState.showAppointmentSelection,
                      }));
                    }}
                  >
                    Schedule Appointment{' '}
                    <FontAwesomeIcon
                      icon={this.state.showAppointmentSelection ? faArrowUp : faArrowDown}
                      className="fas text-light"
                    />
                  </button>
                  {this.state.showAppointmentSelection && (
                    <>
                      <div className="text-primary">
                        For a guaranteed availability of a ITO-employee, please select an appointment date/time, and
                        send your request to ITO. *24 hrs are required to process the request so select hardware pickup
                        date and time accordingly.
                      </div>
                      <table className="table mt-1">
                        <thead>
                          <tr>
                            <th scope="col">Pickup Date: Operating days Mon to Fri</th>
                            <th scope="col">Pickup Time: Opening hours 9:00 to 16:00</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">
                              <TextFieldGroup
                                type={'date'}
                                onChange={this.onChange}
                                value={this.state.appointmentdate}
                                name="appointmentdate"
                                error={errors.appointmentdate}
                                min={new Date(Date.now() + 86400000).toISOString().split('T')[0]}
                              />
                            </td>
                            <td>
                              <TextFieldGroup
                                type={'time'}
                                onChange={this.onChange}
                                value={this.state.appointmenttime}
                                name="appointmenttime"
                                error={errors.appointmenttime}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                  {checkEligibility(validcontracts) ? (
                    <input type="submit" value="Submit" className="btn btn-info btn-block mt-4 mb-4" />
                  ) : (
                    <div>
                      <input
                        type="submit"
                        value="Submit"
                        className="btn btn-info btn-block mt-4 mb-4"
                        disabled="disabled"
                      />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

CreateRentalApplication.propTypes = {
  rentals: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  rentalstats: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  rentals: state.rentals,
  contract: state.contract,
  rentalstats: state.rentalstats,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getHardwareAvailabilityDetails,
  createRentalApplication,
  getUserContracts,
})(withRouter(CreateRentalApplication));
