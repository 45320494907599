import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getRentals } from '../../actions/rentalActions';
import { faUserTie, faQuestion, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import ActionList from './ActionList';

const AdvisorActions = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRentals());
  }, [dispatch]);

  const advisorActions = [
    { route: '/advisor-classes', text: 'Meine Kurse', icon: faUserTie },
    {
      route: '/advisor-course-overview',
      text: 'Veranstaltungen',
      icon: faGraduationCap,
    },
    {
      route: '/advisor-metacourse-overview',
      text: 'Metaveranstaltungen',
      icon: faGraduationCap,
    },
    { route: '/faq', text: 'FAQ', icon: faQuestion },
  ];

  return (
    <div className="mb-4">
      <ActionList items={advisorActions} title="Berateraktionen" />
    </div>
  );
};

export default AdvisorActions;
