import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LinkButton = ({ to, icon, iconStyle, text, variant, extraIcon, showExtra }) => {
  return (
    <Link to={to} className={`btn btn-${variant}`}>
      {showExtra && extraIcon ? <FontAwesomeIcon icon={extraIcon} className={`text-danger`} /> : null}{' '}
      {icon ? <FontAwesomeIcon icon={icon} className={`text-primary ${iconStyle}`} /> : null} {text}
    </Link>
  );
};

export default LinkButton;
