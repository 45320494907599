import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import isEmpty from '../../utils/is-empty';
import axios from 'axios';

const initialState = {
  isAuthenticated: false,
  user: {},
  logoutStatus: 'idle',
};

export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, { rejectWithValue }) => {
  try {
    await axios.get('/api/auth/logout');
    localStorage.clear();
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.isAuthenticated = !isEmpty(action.payload);
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutUser.pending, (state) => {
        state.logoutStatus = 'loading';
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.user = {};
        state.logoutStatus = 'succeeded';
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.logoutStatus = 'failed';
        console.error('Logout failed', action.payload);
      });
  },
});

export const { setCurrentUser } = authSlice.actions;
export default authSlice.reducer;
