import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// UI Components
import TextFieldGroup from 'components/ui/text-field/TextFieldGroup';
import SelectListGroup from 'components/ui/select-list-group/SelectListGroup';
import { getGroupsByUserID } from 'services/GroupService';
import PageHeader from 'components/ui/page-header/PageHeader';
import Spinner from 'components/ui/spinner/Spinner';

// Actions
import { createMetacourse } from 'actions/metacourseActions';

const CreateMetacourse = ({ auth, errors, createMetacourse }) => {
  const history = useHistory();
  const [state, setState] = useState({
    kursname: '',
    scheme: '',
    fondsnumber: '',
    costcentre: '',
    abbreviation: '',
    module: '',
    kurstype: '',
    degree: '',
    compulsory: '',
    semesterdate: '',
    group: '',
    groupOptions: [],
    loading: true,
  });

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (errors) {
      setState((prevState) => ({
        ...prevState,
        errors,
      }));
    }
  }, [errors]);

  const fetchGroups = async () => {
    const result = await getGroupsByUserID(auth.user.id);
    const groupRetrieved = result?.data?.group || [];

    const groupOptions = [
      { label: 'Bitte Gruppe auswählen', value: '' },
      ...groupRetrieved.map((group) => ({
        label: group.title,
        value: group._id,
      })),
    ];

    setState((prevState) => ({
      ...prevState,
      groupOptions,
      loading: false,
      group: groupOptions[0].value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const metacourseData = {
      name: state.kursname,
      scheme: state.scheme,
      fondsnumber: state.fondsnumber,
      costcentre: state.costcentre,
      abbreviation: state.abbreviation,
      module: state.module,
      kurstype: state.kurstype,
      degree: state.degree,
      compulsory: state.compulsory,
      group: state.group,
      semesterdate: state.semesterdate,
    };

    createMetacourse(metacourseData, history);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const {
    loading,
    kursname,
    scheme,
    fondsnumber,
    costcentre,
    abbreviation,
    module,
    kurstype,
    degree,
    compulsory,
    semesterdate,
    group,
    groupOptions,
  } = state;
  const { user } = auth;

  const MaßnahmeOptions = [
    { label: 'Maßnahme', value: '' },
    { label: 'Tutorien 1.21.', value: 'Tutorien 1.21.' },
    { label: 'Vorkurse 1.22.', value: 'Vorkurse 1.22.' },
    { label: 'Repetitorien 1.2.', value: 'Repetitorien 1.2.' },
    { label: 'Programmierprojekte 1.3.', value: 'Programmierprojekte 1.3.' },
  ];

  function addMandoryField(val) {
    if (user.role !== 'Manager') {
      return '* ' + val;
    }
    return val;
  }

  const typeOptions = [
    { label: 'Kurstyp auswählen', value: '' },
    { label: 'Vorlesung', value: 'Vorlesung' },
    { label: 'Vorlesung-Übung', value: 'Vorlesung-Übung' },
    {
      label: 'Vorlesung mit integrierten Übungen',
      value: 'Vorlesung mit integrierten Übungen',
    },
    { label: 'Seminar', value: 'Seminar' },
    { label: 'Praktikum', value: 'Praktikum' },
    { label: 'Forschungspraktikum', value: 'Forschungspraktikum' },
    { label: 'Other', value: 'Other' },
  ];

  const degreeOptions = [
    { label: 'Akademischer Grad auswählen', value: '' },
    { label: 'Bachelor', value: 'Bachelor' },
    { label: 'Master', value: 'Master' },
  ];

  const booleanOptions = [
    { label: 'Auswählen', value: '' },
    { label: 'Ja', value: 'true' },
    { label: 'Nein', value: 'false' },
  ];

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <div className="createMetacourse">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <PageHeader heading={`Metaveranstaltung Erstellen`} route={'/metacourse-overview'} />
              <small className="d-block pb-3">* = benötigte Felder</small>

              <form onSubmit={onSubmit}>
                {(user.role === 'Admin' || user.role === 'Manager') && (
                  <div>
                    <TextFieldGroup
                      placeholder="* Bezeichnung Metaveranstaltung"
                      info="* Bezeichnung Metaveranstaltung"
                      onChange={onChange}
                      value={kursname}
                      name="kursname"
                      error={errors.name}
                    />

                    <SelectListGroup
                      placeholder={'Maßnahme'}
                      onChange={onChange}
                      value={scheme}
                      name="scheme"
                      error={errors.scheme}
                      options={MaßnahmeOptions}
                      info={'Maßnahme (nur bei Studienbeiträgen)'}
                    />
                    <TextFieldGroup
                      placeholder="* Fondsnummer"
                      onChange={onChange}
                      value={fondsnumber}
                      name="fondsnumber"
                      error={errors.fondsnumber}
                      info="* Fondsnummer"
                    />
                    <TextFieldGroup
                      placeholder="Kostenstelle"
                      onChange={onChange}
                      value={costcentre}
                      name="costcentre"
                      error={errors.costcentre}
                      info="Kostenstelle"
                    />
                    <TextFieldGroup
                      placeholder={addMandoryField('Abkürzung')}
                      onChange={onChange}
                      value={abbreviation}
                      name="abbreviation"
                      error={errors.abbreviation}
                      info={addMandoryField('Abkürzung')}
                    />
                    <TextFieldGroup
                      placeholder="Modul"
                      onChange={onChange}
                      value={module}
                      name="module"
                      error={errors.module}
                      info="Modul"
                    />
                  </div>
                )}

                <SelectListGroup
                  placeholder="Kurstyp"
                  onChange={onChange}
                  value={kurstype}
                  name="kurstype"
                  error={errors.kurstype}
                  options={typeOptions}
                  info={addMandoryField('Kurstyp')}
                />
                <SelectListGroup
                  placeholder="Akademischer Grad"
                  onChange={onChange}
                  value={degree}
                  name="degree"
                  error={errors.degree}
                  options={degreeOptions}
                  info="Akademischer Grad"
                />
                <SelectListGroup
                  placeholder="Pflichtfach"
                  onChange={onChange}
                  value={compulsory}
                  name="compulsory"
                  error={errors.compulsory}
                  options={booleanOptions}
                  info="Pflichtfach"
                />

                <label htmlFor="Semester">Semester:</label>
                <TextFieldGroup
                  placeholder="Semester"
                  onChange={onChange}
                  value={semesterdate}
                  name="semesterdate"
                  error={errors.semesterdate}
                  info="From which semester student can take this course"
                />

                <SelectListGroup
                  placeholder="Group"
                  onChange={onChange}
                  value={group}
                  name="group"
                  error={errors.group}
                  options={groupOptions}
                  info="* Group"
                />

                <input type="submit" value="Submit" className="btn btn-info btn-block mt-4" />
              </form>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

CreateMetacourse.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createMetacourse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { createMetacourse })(withRouter(CreateMetacourse));
