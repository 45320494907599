import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import SelectListGroup from '../ui/select-list-group/SelectListGroup';
import { uploadFile } from '../../actions/formsActions';
import PageHeader from '../ui/page-header/PageHeader';

class FormsUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      selectedFile: null,
      disabledSubmit: true,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    //this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const fileData = new FormData();
    fileData.append('file', this.state.selectedFile, this.state.name);
    fileData.append('name', this.state.name);
    const formsData = {
      name: this.state.name,
      fileData: fileData,
    };
    this.props.uploadFile(fileData, this.props.history);
  }

  onChange(e) {
    if (e.target.name === 'selectedFile') {
      this.setState({
        selectedFile: e.target.files[0],
        loaded: 0,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  render() {
    const { errors } = this.state;

    //Select options for status of contract
    const fileNameOptions = [
      { label: '* Formular auswählen', value: '' },
      { label: 'Einstellungsvorschlag', value: 'Einstellungsvorschlag' },
      {
        label: 'Einstellungsvorschlag (Translated)',
        value: 'Einstellungsvorschlag_translated',
      },
      {
        label: 'Feststellung der Versicherungspflicht',
        value: 'Versicherungspflicht',
      },
      {
        label: 'Feststellung der Versicherungspflicht (Translated)',
        value: 'Versicherungspflicht_translated',
      },
      { label: 'Fragebogen zu Scientology', value: 'Scientology' },
      {
        label: 'Fragebogen zu Scientology (Translated)',
        value: 'Scientology_translated',
      },
      { label: 'Fragebogen zur Verfassungstreue', value: 'Verfassungstreue' },
      {
        label: 'Fragebogen zur Verfassungstreue (Translated)',
        value: 'Verfassungstreue_translated',
      },
      {
        label: 'Personalbogen Bezuegestelle',
        value: 'PersonalbogenBezuegestelle',
      },
      {
        label: 'Personalbogen Bezuegestelle (Translated)',
        value: 'PersonalbogenBezuegestelle_translated',
      },
      {
        label: 'Personalbogen Studierende',
        value: 'PersonalbogenStudierende',
      },
      {
        label: 'Personalbogen Studierende (Translated)',
        value: 'PersonalbogenStudierende_translated',
      },
      {
        label: 'Stipendiumsbescheinigung',
        value: 'Stipendiumsbescheinigung',
      },
      {
        label: 'Stipendiumsbescheinigung (Translated)',
        value: 'Stipendiumsbescheinigung_translated',
      },
    ];
    if (this.state.name && this.state.selectedFile && this.state.disabledSubmit) {
      this.setState({
        disabledSubmit: false,
      });
    }

    return (
      <div className="uploadForm">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <PageHeader heading={`Formular Upload`} route={'/forms-administration'} />
              <form onSubmit={this.onSubmit}>
                <label htmlFor="filename">File zum Upload:</label>
                <SelectListGroup
                  placeholder="Filename"
                  onChange={this.onChange}
                  value={this.state.name}
                  name="name"
                  error={errors.name}
                  options={fileNameOptions}
                />
                <input type="file" name="selectedFile" onChange={this.onChange} />
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-info btn-block mt-4"
                  disabled={this.state.disabledSubmit}
                />
              </form>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FormsUpload.propTypes = (state) => ({
  forms: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  uploadFile: PropTypes.func.isRequired,
});

const mapStateToProps = (state) => ({
  forms: state.metacourse,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { uploadFile })(withRouter(FormsUpload));
