import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const Table = ({ baseProps, defaultSorted, fullSize = false }) => {
  const getPaginationOptions = () => {
    const baseOptions = {
      sizePerPageList: [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    };

    if (fullSize) {
      // Apply full size options
      return { ...baseOptions, page: 1, sizePerPage: 100 };
    }

    return baseOptions;
  };

  return (
    <div>
      <BootstrapTable
        {...baseProps}
        pagination={paginationFactory(getPaginationOptions())}
        defaultSorted={defaultSorted}
        striped
        classes="table-responsive-md"
        wrapperClasses="table-responsive"
      />
    </div>
  );
};

export default Table;
